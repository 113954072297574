import type { EmptyObject, Middleware } from '@reduxjs/toolkit'
import { RollbackErrorReasonDTO } from '@capture/client-api/src/orval'
import { isType } from '../common/actions'
import { currentUserReducerMapObj } from '../currentUser/reducer'
import { getUsedStorageRatio } from '../currentUser/selectors'
import { FilesCopiedToTimelineFailed } from '../files/actions'
import {
    FilesRestorationFailed,
    JobCopiedToTimelineFailed,
} from '../job/actions'
import {
    AddedMoreFilesThanAvailableStorage,
    FileWasAddedToUploadQueue,
    UploaderFinished,
} from '../uploader/actions'
import { uploaderReducerMapObj } from '../uploader/reducer'
import { getEnquedFiles, isCurrentlyUploading } from '../uploader/selectors'
import { PopupTriggered } from './actions'

export const popupMiddlewareReducerMapObj = {
    ...currentUserReducerMapObj,
    ...uploaderReducerMapObj,
}

type PopupMiddlewareState = StateOfReducerMapObj<
    typeof popupMiddlewareReducerMapObj
>

export const popupMiddleware: Middleware<EmptyObject, PopupMiddlewareState> = (
    store,
) => {
    let usedQuotaAsUploadStart = 0

    return (next) => (action) => {
        const state = store.getState()

        if (isType(action, AddedMoreFilesThanAvailableStorage)) {
            store.dispatch(PopupTriggered('AddedMoreFilesThanAvailableStorage'))
        }

        if (
            isType(action, FileWasAddedToUploadQueue) &&
            getEnquedFiles(state).length === 0 &&
            !isCurrentlyUploading(state)
        ) {
            usedQuotaAsUploadStart = getUsedStorageRatio(state)
        }

        if (isType(action, UploaderFinished)) {
            if (
                usedQuotaAsUploadStart < 0.95 &&
                getUsedStorageRatio(state) > 0.95
            ) {
                store.dispatch(PopupTriggered('UsedMoreThan95PercentOfStorage'))
            } else if (
                usedQuotaAsUploadStart < 0.8 &&
                getUsedStorageRatio(state) > 0.8
            ) {
                store.dispatch(PopupTriggered('UsedMoreThan80PercentOfStorage'))
            }
        }

        if (
            isType(action, FilesCopiedToTimelineFailed) ||
            isType(action, JobCopiedToTimelineFailed)
        ) {
            if (action.payload.reason === 'out_of_storage') {
                store.dispatch(
                    PopupTriggered('AddedMoreFilesThanAvailableStorage'),
                )
            }
        }

        if (isType(action, FilesRestorationFailed)) {
            if (
                action.payload.reasons.includes(
                    RollbackErrorReasonDTO.quota_exceeded_error,
                )
            ) {
                store.dispatch(
                    PopupTriggered('RestoredMoreFilesThanAvailableStorage'),
                )
            }
        }

        return next(action)
    }
}
