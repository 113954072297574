import type { Dispatch } from '~/state/common/actions'
import type { CaptureFile } from '~/state/files/selectors'
import { JobWasDeleted } from '~/state/jobInfo/actions'
import {
    DeleteShareFailed,
    DeleteShareSucceeded,
    DownloadShareClicked,
    DownloadShareFailed,
    DownloadShareSucceeded,
    ShareCopiedToTimeline,
    ShareCopiedToTimelineFailed,
} from '~/state/share/actions'
import {
    LongRunningTaskFinished,
    LongRunningTaskStarted,
} from '~/state/statusNotifications/actions'
import { tryCopyFilesToJobCompletely } from './job'
import { getServiceProvider } from './HostProvider'

export const downloadShare = async (dispatch: Dispatch, shareID: JobID) => {
    dispatch(DownloadShareClicked(shareID))
    try {
        const service = await getServiceProvider().getAppServiceForJob(shareID)
        await service.downloadJobAsArchive('export', shareID)
        dispatch(DownloadShareSucceeded(shareID))
    } catch (_e) {
        dispatch(DownloadShareFailed(shareID))
    }
}

export const copyShareFilesToTimeline = async (
    dispatch: Dispatch,
    timelineJobID: JobID,
    files: CaptureFile[],
) => {
    try {
        dispatch(LongRunningTaskStarted('filesAreBeingCopied'))
        await tryCopyFilesToJobCompletely(dispatch, timelineJobID, files)
        dispatch(ShareCopiedToTimeline())
    } catch (_e) {
        dispatch(ShareCopiedToTimelineFailed())
    }

    dispatch(LongRunningTaskFinished('filesAreBeingCopied'))
}

export const deleteShare = async (dispatch: Dispatch, shareID: JobID) => {
    try {
        const service = await getServiceProvider().getAppServiceForJob(shareID)
        await service.deleteJob(shareID)
        dispatch(DeleteShareSucceeded(shareID))
    } catch (_e) {
        dispatch(DeleteShareFailed(shareID))
    }
}

export const flushShare = (dispatch: Dispatch, shareID: JobID) => {
    dispatch(JobWasDeleted(shareID))
}
