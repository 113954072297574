import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { StoragePlanType } from '@capture/capture-components'
import { RightPeriodDTO } from '@capture/client-api/src/orval'
import { _ } from '~/assets/localization/util'
import {
    getStoragePlanGroups,
    getCurrentStripeStoragePlan,
    getUpcomingStripeProduct,
    getStoragePlans,
    type UnavailableReason,
} from '~/state/storagePlan/selectors'

function getPlanLabel(
    priceString: string,
    period: RightPeriodDTO.monthly | RightPeriodDTO.yearly,
) {
    return `${priceString} ${period === RightPeriodDTO.monthly ? _('monthly') : _('yearly')}`
}

// Product wants to display yearly plans price per month for marketing purpose
function getPlanLabelForMarketing(
    price: number,
    currency: string,
    isMonthly: boolean,
) {
    const pricePerMonth = isMonthly ? price / 100 : price / 12 / 100
    const normalisedCurrency = pricePerMonth.toFixed(price % 100 === 0 ? 0 : 2)
    return `${currency.toUpperCase()} ${normalisedCurrency} / ${_(
        'month',
    ).toLowerCase()}`
}

export const useStorageSelect = (selectedPlanId: string | undefined) => {
    const storagePlanGroups = useSelector(getStoragePlanGroups)
    const storagePlans = useSelector(getStoragePlans)

    const currentStripeStoragePlan = useSelector(getCurrentStripeStoragePlan)
    const upcomingStripeProduct = useSelector(getUpcomingStripeProduct)

    const planGroups = useMemo(() => {
        function getPlanTag(id: string) {
            if (id === currentStripeStoragePlan?.id) {
                return _('active')
            }

            if (id === upcomingStripeProduct?.id) {
                return _('upcoming')
            }
        }

        function getPlanType(
            id: string,
            unavailableReason: UnavailableReason | undefined,
        ) {
            if (id == selectedPlanId) {
                return StoragePlanType.Selected
            }
            if (id === currentStripeStoragePlan?.id) {
                return StoragePlanType.Active
            }

            if (id === upcomingStripeProduct?.id) {
                return StoragePlanType.Upcoming
            }

            if (unavailableReason !== undefined) {
                return StoragePlanType.Unavailable
            }
            return StoragePlanType.Available
        }

        return storagePlanGroups.map(({ storage, monthly, yearly }) => {
            return {
                storage,
                monthlyPlan: {
                    label: getPlanLabelForMarketing(
                        monthly.price,
                        monthly.currency,
                        true,
                    ),
                    tag: getPlanTag(monthly.id),
                    id: monthly.id,
                    type: getPlanType(monthly.id, monthly.unavailableReason),
                },
                yearlyPlan: {
                    label: getPlanLabelForMarketing(
                        yearly.price,
                        monthly.currency,
                        false,
                    ),
                    tag: getPlanTag(yearly.id),
                    id: yearly.id,
                    type: getPlanType(yearly.id, yearly.unavailableReason),
                },
            }
        })
    }, [
        storagePlanGroups,
        currentStripeStoragePlan?.id,
        upcomingStripeProduct?.id,
        selectedPlanId,
    ])

    const selectedStoragePlan = useMemo(
        () => storagePlans.find(({ id }) => id === selectedPlanId),
        [selectedPlanId, storagePlans],
    )

    const selectedPlan = useMemo(
        () =>
            selectedStoragePlan !== undefined
                ? {
                      id: selectedStoragePlan.id,
                      storage: selectedStoragePlan.storage,
                      label: getPlanLabel(
                          selectedStoragePlan.priceString,
                          selectedStoragePlan.period,
                      ),
                      period: selectedStoragePlan.period,
                      priceString: selectedStoragePlan.priceString,
                  }
                : undefined,
        [selectedStoragePlan],
    )

    return {
        planGroups,
        selectedPlan,
    }
}
