import type { TimelineMonthDTO } from '@capture/client-api/src/orval'
import { createActionCreator, createVoidActionCreator } from '../common/actions'
import type { TimelineFilterMode, TimelineMonthCountDetail } from './reducers'
import type { TimelineSectionReference } from './'

type TimelineMonthsFetchedPayload = {
    jobID: JobID
    months: TimelineMonthDTO[]
}
export const TimelineMonthsFetched =
    createActionCreator<TimelineMonthsFetchedPayload>('TIMELINE_MONTHS_FETCHED')

type TimelineSectionsFetchedPayload = {
    jobID: JobID
    sections: TimelineSection[]
}
export const TimelineSectionsFetched =
    createActionCreator<TimelineSectionsFetchedPayload>(
        'TIMELINE_SECTIONS_FETCHED',
    )

export const TimelineFilterSet = createActionCreator<TimelineFilterMode>(
    'TIMELINE_FILTER_SET',
)

export const TimelineFileSelectingStarted = createVoidActionCreator(
    'TIMELINE_FILE_SELECTING_STARTED',
)
export const MaxSelectionCountReached = createVoidActionCreator(
    'MAX_SELECTION_COUNT_REACHED',
)

type SectionWithCounts = TimelineSectionReference & {
    counts: TimelineMonthCountDetail
}
export const SectionsWereExpired = createActionCreator<{
    expiredSections: SectionWithCounts[]
    jobID: JobID
}>('SECTIONS_WERE_EXPIRED')
