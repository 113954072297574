import * as React from 'react'
import { connect } from 'react-redux'
import type { NavigateFunction } from 'react-router-dom'
import { deleteAlbum, unsubscribeFromAlbum } from '~/API/album'
import { deleteFile, removeConnectedDevice } from '~/API/job'
import { _ } from '~/assets/localization/util'
import { PRODUCT_NAME } from '~/config/constants'
import type { Dispatch } from '~/state/common/actions'
import type { ExtendedJobFile } from '~/state/files/reducer'
import { getFileByID } from '~/state/files/selectors'
import { getTimelineJobID } from '~/state/timeline/selectors'
import {
    type PendingUserAction,
    UserActionAlertHandled,
    UserActionConfirmHandled,
    UserActionTypes,
} from '~/state/userActions/actions'
import {
    getUserActionAlert,
    getUserActionPendingConfirm,
} from '~/state/userActions/selectors'
import type { WithRouterProps } from '~/utilities/navigation'
import { withRouter } from '~/utilities/navigation'
import { ConfirmPromptOverlay, OkPromptOverlay } from './DialoguePromptOverlay'

type StateProps = {
    getFile: (fileID: FileID) => ExtendedJobFile | undefined
    userActionConfirm: PendingUserAction | undefined
    userActionAlert: PendingUserAction | undefined
    timelineID: JobID | undefined
}

type DispatchProps = {
    dispatch: Dispatch
}

type Props = StateProps & DispatchProps & WithRouterProps

type DialogContent = {
    text: (target: string) => string
    header?: string
    action?: (
        dispatch: Dispatch,
        target: string,
        navigate?: NavigateFunction,
    ) => void
}

class DialogComponent extends React.PureComponent<Props> {
    private alerts: DictionaryOf<DialogContent> = {
        [UserActionTypes.FILE_COUNT_EXCEEDS_DOWNLOAD_LIMIT]: {
            text: (_target: string) => _('file_count_exceeds_download_limit'),
        },
        [UserActionTypes.SIZE_EXCEEDS_DOWNLOAD_LIMIT]: {
            text: (_target: string) => _('size_exceeds_download_limit'),
        },
        [UserActionTypes.ALBUM_FILE_COUNT_EXCEEDS_DOWNLOAD_LIMIT]: {
            text: (_target: string) =>
                _('album_file_count_exceeds_download_limit'),
        },
        [UserActionTypes.ALBUM_SIZE_EXCEEDS_DOWNLOAD_LIMIT]: {
            text: (_target: string) => _('album_size_exceeds_download_limit'),
        },
    }

    private confirms: DictionaryOf<DialogContent> = {
        [UserActionTypes.DELETE_ALBUM]: {
            text: () => _('delete_album_prompt_text'),
            action: deleteAlbum,
        },
        [UserActionTypes.UNSUBSCRIBE_ALBUM]: {
            text: () => _('unsubscribe_album_prompt_text'),
            action: unsubscribeFromAlbum,
        },
        [UserActionTypes.DELETE_FILE]: {
            text: (target: string) => {
                const file = this.props.getFile(target)
                if (file && file.jobID === this.props.timelineID) {
                    return _('delete_single_file_prompt')
                }
                return _('delete_album_file_prompt_text')
            },
            action: (dispatch: Dispatch, target) => {
                const file = this.props.getFile(target)
                if (file) {
                    deleteFile(dispatch, file.jobID, target)
                }
            },
        },
        [UserActionTypes.DELETE_DEVICE]: {
            text: () =>
                _('remove_device_prompt__format').replace('%s', PRODUCT_NAME),
            action: removeConnectedDevice,
        },
    }

    private onUserActionConfirm = () => {
        if (this.props.userActionConfirm) {
            const { type, target } = this.props.userActionConfirm
            const action = this.confirms[type].action
            if (action) {
                action(
                    this.props.dispatch,
                    target,
                    type === UserActionTypes.DELETE_ALBUM
                        ? this.props.navigate
                        : undefined,
                )
            }
        }

        this.onConfirmPromptHandled()
    }

    private onConfirmPromptHandled = () => {
        this.props.dispatch(UserActionConfirmHandled())
    }

    private onErrorPromptHandled = () => {
        this.props.dispatch(UserActionAlertHandled())
    }

    public render() {
        if (this.props.userActionConfirm) {
            const { type, target } = this.props.userActionConfirm
            let confirmText, cancelText

            switch (type) {
                case UserActionTypes.DELETE_ALBUM:
                case UserActionTypes.DELETE_FILE:
                    confirmText = _('delete_')
                    break
                case UserActionTypes.UNSUBSCRIBE_ALBUM:
                    confirmText = _('unsubscribe')
                    break
                case UserActionTypes.DELETE_DEVICE:
                    confirmText = _('log_out')
                    break
            }
            return (
                <ConfirmPromptOverlay
                    onConfirm={this.onUserActionConfirm}
                    onCancel={this.onConfirmPromptHandled}
                    confirmText={confirmText}
                    cancelText={cancelText}>
                    {this.confirms[type].text(target)}
                </ConfirmPromptOverlay>
            )
        }

        if (
            this.props.userActionAlert &&
            this.alerts[this.props.userActionAlert.type]
        ) {
            const { type, target } = this.props.userActionAlert
            return (
                <OkPromptOverlay
                    onOK={this.onErrorPromptHandled}
                    header={this.alerts[type].header}>
                    {this.alerts[type].text(target)}
                </OkPromptOverlay>
            )
        }

        return null
    }
}

type UserActionDialogPropsStoreState = StateOfSelector<
    typeof getUserActionPendingConfirm
> &
    StateOfSelector<typeof getUserActionAlert> &
    StateOfSelector<typeof getFileByID> &
    StateOfSelector<typeof getTimelineJobID>
const mapStateToProps = (
    state: UserActionDialogPropsStoreState,
): StateProps => ({
    userActionConfirm: getUserActionPendingConfirm(state),
    userActionAlert: getUserActionAlert(state),
    getFile: (fileID: FileID) => getFileByID(state, fileID),
    timelineID: getTimelineJobID(state),
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({ dispatch })

export const UserActionDialogPlacement = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(DialogComponent),
)
