/**
 * Generated by orval v7.7.0 🍺
 * Do not edit manually.
 * FastAPI
 * OpenAPI spec version: 0.1.0
 */

export enum RightPeriodDTO {
    monthly = 'monthly',
    yearly = 'yearly',
    unknown = 'unknown',
}
