import {
    FlowPrompt,
    SubscriptionPerkTable,
    Typography,
} from '@capture/capture-components'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { getIfNewUserCreated } from '~/API/externals'
import { _ } from '~/assets/localization/util'
import { colors } from '~/assets/styleConstants'
import { PRODUCT_NAME, WELCOME_FAQ } from '~/config/constants'
import { getAvailableTrialPeriod } from '~/state/storagePlan/selectors'

export const perkTitle = {
    free: _('subscribe_free'),
    sub: _('subscribe_sub'),
}

export const perkDetails = [
    {
        text: _('subscribe_text1'),
        free: false,
        sub: true,
    },
    {
        text: _('subscribe_text2'),
        free: false,
        sub: true,
    },
    {
        text: _('subscribe_text3'),
        free: false,
        sub: true,
    },
    {
        text: _('subscribe_text4'),
        free: false,
        sub: true,
    },
    {
        text: _('subscribe_text5'),
        free: true,
        sub: true,
    },
]

const WelcomeTextDescription = styled.p`
    .freeTrial {
        font-weight: 700;
    }
    a {
        text-underline-offset: 2px;
        text-decoration: underline;
        color: ${colors.info};
    }
`

export const StepWelcome = (props: {
    closeModal: () => void
    onPrimaryButtonClick: () => void
    onSecondaryButtonClick: () => void
}) => {
    const { closeModal, onPrimaryButtonClick, onSecondaryButtonClick } = props
    const availableTrialPeriod = useSelector(getAvailableTrialPeriod)
    const isNewUser = getIfNewUserCreated() // Close button not available for new signups

    return (
        <FlowPrompt.Content
            title={_('subscribe_title').replace('%product_name%', PRODUCT_NAME)}
            hideCloseButton={isNewUser}
            preventCloseOnClickOutside={true}>
            <Typography.Paragraph>
                <WelcomeTextDescription
                    dangerouslySetInnerHTML={{
                        __html: _('subscribe_welcome_text_html__format')
                            .replace('%product_name%', PRODUCT_NAME)
                            .replace(
                                '%num_free_trial%',
                                availableTrialPeriod.toString(),
                            ),
                    }}
                />
                <WelcomeTextDescription
                    dangerouslySetInnerHTML={{
                        __html: _('subscribe_welcome_text2_html__format')
                            .replace('%product_name%', PRODUCT_NAME)
                            .replace('%faq_pages%', WELCOME_FAQ),
                    }}
                />
            </Typography.Paragraph>
            <SubscriptionPerkTable
                perkTitle={perkTitle}
                perkDetails={perkDetails}
            />
            <FlowPrompt.Form
                onSubmit={(e) => {
                    e.preventDefault()
                    closeModal()
                }}>
                <FlowPrompt.ButtonsSection>
                    <FlowPrompt.ActionButton
                        variant="primary"
                        type="submit"
                        onPress={onPrimaryButtonClick}
                        key="primary">
                        {_('timeline_subscription_required_sub').replace(
                            '!',
                            '',
                        )}
                    </FlowPrompt.ActionButton>
                    <FlowPrompt.ActionButton
                        variant="secondary"
                        $fluid
                        onPress={onSecondaryButtonClick}
                        key="secondary">
                        {_('subscribe_skip')}
                    </FlowPrompt.ActionButton>
                </FlowPrompt.ButtonsSection>
            </FlowPrompt.Form>
        </FlowPrompt.Content>
    )
}
