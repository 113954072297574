import { useEffect } from 'react'

/**
 * Hook to ease the use of waiting for a keypress
 * @param callback The function we need to call on the keypress
 * @param targetKey A string coresponding to the pressed key. https://developer.mozilla.org/en-US/docs/Web/API/UI_Events/Keyboard_event_key_values
 * @param eventType Is optional. By default is 'keydown' but can accept also as value 'keyup'
 */
export const useKeyPress = (
    callback: () => void,
    targetKey: string,
    eventType?: 'keydown' | 'keyup',
) => {
    const downHandler = ({ key }: { key: string }): void => {
        if (key === targetKey) {
            callback()
        }
    }

    const upHandler = ({ key }: { key: string }): void => {
        if (key === targetKey) {
            callback()
        }
    }

    useEffect(() => {
        if (eventType === 'keyup') {
            window.addEventListener('keyup', upHandler)
        } else {
            window.addEventListener('keydown', downHandler)
        }

        return () => {
            if (eventType === 'keyup') {
                window.removeEventListener('keyup', upHandler)
            } else {
                window.removeEventListener('keydown', downHandler)
            }
        }
    }, [])
}
