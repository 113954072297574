import type { StripeSetupIntentCreateResponseDTO } from '@capture/client-api/src/orval'
import { useState, useMemo, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { getStripeObject } from '~/API/3rdParty/stripe'
import { getStripeService } from '~/API/services/StripeService'
import { ResponseNotOKError } from '~/API/toolbox'
import {
    getNameOfLoggedInUser,
    getEmailAdressOfLoggedInUser,
} from '~/state/currentUser/selectors'

export const useStripeCustomer = (shouldCreateSetupIntentOnMount: boolean) => {
    const userName = useSelector(getNameOfLoggedInUser)
    const userEmailAddress = useSelector(getEmailAdressOfLoggedInUser)
    const [intentInfo, setIntentInfo] = useState<
        StripeSetupIntentCreateResponseDTO | undefined
    >(undefined)
    // keep using the same stripe promise object in re-renders
    const stripePromise = useMemo(() => getStripeObject(), [])

    const guaranteeStripeCustomer = useCallback(async () => {
        if (userEmailAddress === undefined) {
            return false
        }

        try {
            await getStripeService().checkCustomerExists()
            return true
        } catch (e) {
            if (e instanceof ResponseNotOKError) {
                // We get 404 Not Found when user is not a stripe customer
                if (e.response.status === 404) {
                    // stripe customer does not exist
                    await getStripeService().createCustomer({
                        name: userName ?? userEmailAddress,
                        email: userEmailAddress,
                    })
                    return true
                } else if (e.response.status === 409) {
                    /* Users outside of Norway, Sweden and Bulgaria are not allowed to be a stripe customer
                       so we should not create a setup intent for them */
                    return false
                }
            }
            return false
        }
    }, [userEmailAddress, userName])

    const createStripeSetupIntent = useCallback(async () => {
        const isStripeCustomer = await guaranteeStripeCustomer()
        if (!isStripeCustomer) {
            return
        }

        const { data } = await getStripeService().createSetupIntent()
        setIntentInfo(data)
    }, [guaranteeStripeCustomer])

    useEffect(() => {
        if (shouldCreateSetupIntentOnMount) {
            createStripeSetupIntent()
        }
    }, [createStripeSetupIntent, shouldCreateSetupIntentOnMount])

    return {
        stripePromise,
        createStripeSetupIntent,
        intentInfo,
    }
}
