import {
    AccordionItem,
    DigitalLegacyRadioGroup,
    Prompt,
    Typography,
} from '@capture/capture-components'
import { getServiceProvider } from '@capture/client/API/HostProvider'
import { _ } from '@capture/client/assets/localization/util'
import { getCurrentUserUUID } from '@capture/client/state/currentUser/selectors'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import {
    DigitalLegacyChoiceDTO,
    type DigitalLegacyContactDTO,
} from '@capture/client-api/src/orval'
import { DigitalLegacyPrompt } from './DigitalLegacyContactPrompt'

type DigitalLegacyOption =
    | {
          id: DigitalLegacyChoiceDTO.only_provide_content_explicitly_detailed_in_my_will
      }
    | {
          id: DigitalLegacyChoiceDTO.do_not_provide_content_to_anybody
      }
    | {
          id: DigitalLegacyChoiceDTO.provide_content_to_contact
          contactInfo: DigitalLegacyContactDTO | undefined
      }

function getDigitalLegacyOptionFromChoice(
    choice: DigitalLegacyChoiceDTO,
    contactInfo?: DigitalLegacyContactDTO,
): DigitalLegacyOption {
    switch (choice) {
        case DigitalLegacyChoiceDTO.only_provide_content_explicitly_detailed_in_my_will:
        case DigitalLegacyChoiceDTO.do_not_provide_content_to_anybody: {
            return { id: choice }
        }
        case DigitalLegacyChoiceDTO.provide_content_to_contact: {
            return {
                id: choice,
                contactInfo,
            }
        }
    }
}

const DigitalLegacyContactInfo = styled.address`
    display: flex;
    flex-direction: column;
    padding: 0 1.5rem;
    gap: 0.5rem;

    font-style: normal;
`

const DigitalLegacyContactName = styled(Typography.Paragraph)`
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    margin-bottom: -0.5rem;
`

export function DigitalLegacySection() {
    const [isContactPromptOpen, setIsContactPromptOpen] = useState(false)
    const [isEditContactPromptOpen, setIsEditContactPromptOpen] =
        useState(false)
    const [localDigitalLegacyOption, setLocalDigitalLegacyOption] =
        useState<DigitalLegacyOption>({
            id: DigitalLegacyChoiceDTO.only_provide_content_explicitly_detailed_in_my_will,
        })
    const [remoteDigitalLegacyOption, setRemoteDigitalLegacyOption] = useState<
        DigitalLegacyOption | undefined
    >(undefined)
    const userId = useSelector(getCurrentUserUUID)

    const onDigitalLegacyValueChange = async (
        optionId: DigitalLegacyChoiceDTO,
    ) => {
        const option = getDigitalLegacyOptionFromChoice(optionId)
        switch (option.id) {
            case DigitalLegacyChoiceDTO.only_provide_content_explicitly_detailed_in_my_will:
            case DigitalLegacyChoiceDTO.do_not_provide_content_to_anybody: {
                setLocalDigitalLegacyOption(option)
                const service =
                    getServiceProvider().getAppServiceForLoggedInUserDefaults()
                await service.updateDigitalLegacyOption(option.id)
                setRemoteDigitalLegacyOption(option)
                break
            }
            case DigitalLegacyChoiceDTO.provide_content_to_contact: {
                setLocalDigitalLegacyOption(option)
                setIsContactPromptOpen(true)
            }
        }
    }

    const updateDigitalLegacyOptionWithContact = async (
        contact: DigitalLegacyContactDTO,
    ) => {
        const service =
            getServiceProvider().getAppServiceForLoggedInUserDefaults()
        await service.updateDigitalLegacyOption(
            DigitalLegacyChoiceDTO.provide_content_to_contact,
            contact,
        )
        const option = getDigitalLegacyOptionFromChoice(
            DigitalLegacyChoiceDTO.provide_content_to_contact,
            contact,
        )
        setRemoteDigitalLegacyOption(option)
        setLocalDigitalLegacyOption(option)
    }

    const onContactPromptCancel = () => {
        setIsContactPromptOpen(false)
        if (remoteDigitalLegacyOption) {
            setLocalDigitalLegacyOption(remoteDigitalLegacyOption)
        }
    }
    const onContactPromptSubmit = async (contact: DigitalLegacyContactDTO) => {
        await updateDigitalLegacyOptionWithContact(contact)
        setIsContactPromptOpen(false)
    }

    const onEditContactPromptCancel = () => {
        setIsEditContactPromptOpen(false)
    }
    const onEditContactPromptSubmit = async (
        contact: DigitalLegacyContactDTO,
    ) => {
        await updateDigitalLegacyOptionWithContact(contact)
        setIsEditContactPromptOpen(false)
    }

    useEffect(() => {
        const getDigitalLegacyValue = async () => {
            if (userId) {
                const service =
                    getServiceProvider().getAppServiceForLoggedInUserDefaults()
                const { choice, digital_legacy_data } =
                    await service.getDigitalLegacyOption(userId)
                const option = getDigitalLegacyOptionFromChoice(
                    choice,
                    digital_legacy_data?.contact_information[0],
                )
                setRemoteDigitalLegacyOption(option)
                setLocalDigitalLegacyOption(option)
            }
        }

        getDigitalLegacyValue()
    }, [userId])

    return (
        <AccordionItem title={_('digital_legacy')}>
            <Typography.Paragraph>
                {_('digital_legacy_select_an_option_below')}
            </Typography.Paragraph>
            <Typography.Paragraph>
                {_('digital_legacy_default_and_alternative')}
            </Typography.Paragraph>
            <Typography.Paragraph>
                <Typography.Bold>
                    {_('digital_legacy_comply_with_legal_obligation')}
                </Typography.Bold>
            </Typography.Paragraph>
            <DigitalLegacyRadioGroup.Root
                value={localDigitalLegacyOption.id}
                onValueChange={onDigitalLegacyValueChange}>
                <DigitalLegacyRadioGroup.Item
                    label={_('digital_legacy_only_provide_explicitly_in_will')}
                    id={
                        DigitalLegacyChoiceDTO.only_provide_content_explicitly_detailed_in_my_will
                    }
                />
                <DigitalLegacyRadioGroup.Item
                    label={_('digital_legacy_do_not_provide_content')}
                    id={
                        DigitalLegacyChoiceDTO.do_not_provide_content_to_anybody
                    }
                />
                <Prompt.Root
                    open={isContactPromptOpen}
                    onOpenChange={(open) => {
                        if (!open) {
                            onContactPromptCancel()
                        } else {
                            setIsContactPromptOpen(true)
                        }
                    }}>
                    <Prompt.Trigger asChild>
                        <DigitalLegacyRadioGroup.Item
                            label={_('digital_legacy_provide_to_contact')}
                            id={
                                DigitalLegacyChoiceDTO.provide_content_to_contact
                            }
                        />
                    </Prompt.Trigger>
                    {isContactPromptOpen && (
                        <DigitalLegacyPrompt
                            onSubmit={onContactPromptSubmit}
                            onCancel={onContactPromptCancel}
                        />
                    )}
                </Prompt.Root>
                {remoteDigitalLegacyOption !== undefined &&
                    remoteDigitalLegacyOption.id ===
                        DigitalLegacyChoiceDTO.provide_content_to_contact &&
                    remoteDigitalLegacyOption.contactInfo !== undefined && (
                        <DigitalLegacyContactInfo>
                            <DigitalLegacyContactName>
                                {remoteDigitalLegacyOption.contactInfo?.name}
                                <Prompt.Root
                                    open={isEditContactPromptOpen}
                                    onOpenChange={(open) => {
                                        if (!open) {
                                            onEditContactPromptCancel()
                                        } else {
                                            setIsEditContactPromptOpen(true)
                                        }
                                    }}>
                                    <Prompt.Trigger asChild>
                                        <DigitalLegacyRadioGroup.EditButton
                                            text={_('edit')}
                                        />
                                    </Prompt.Trigger>
                                    {isEditContactPromptOpen && (
                                        <DigitalLegacyPrompt
                                            contactInfo={
                                                remoteDigitalLegacyOption.contactInfo
                                            }
                                            onSubmit={onEditContactPromptSubmit}
                                            onCancel={onEditContactPromptCancel}
                                        />
                                    )}
                                </Prompt.Root>
                            </DigitalLegacyContactName>
                            <Typography.Paragraph>
                                {
                                    remoteDigitalLegacyOption.contactInfo
                                        .phone_number
                                }
                            </Typography.Paragraph>
                            <Typography.Paragraph>
                                {
                                    remoteDigitalLegacyOption.contactInfo
                                        .email_address
                                }
                            </Typography.Paragraph>
                            <Typography.Paragraph>
                                {remoteDigitalLegacyOption.contactInfo.address}
                            </Typography.Paragraph>
                        </DigitalLegacyContactInfo>
                    )}
            </DigitalLegacyRadioGroup.Root>
        </AccordionItem>
    )
}
