import { connect } from 'react-redux'
import type { NavigateFunction } from 'react-router-dom'
import { useNavigate, useParams } from 'react-router-dom'
import type { Dispatch } from '~/state/common/actions'
import { _ } from '~/assets/localization/util'
import { copyMultipleFilesToJob } from '~/API/job'
import { trackEventInternal, trackEvent } from '~/analytics/eventTracking'
import { Pages } from '~/routing'
import type { ExtendedJobFile } from '~/state/files/reducer'
import {
    FilesCopiedToAlbum,
    FilesCopiedToAlbumFailed,
} from '~/state/job/actions'
import { JobInfoStatus } from '~/state/jobInfo/reducer'
import { getStatusOfJob } from '~/state/jobInfo/selectors'
import { getConnectedInstance } from '~/state/uploader/uploadQueue'
import { goBack } from '~/utilities/navigation'
import { b64ToUuid } from '~/utilities/uuid'
import { SelectFromTimelinePage } from '../Timeline/SelectFromTimelinePage'

type OwnProps = {
    albumID: JobID
    navToAlbum?: boolean
    doTrackEvent: (files: number) => void
    navigate: NavigateFunction
}

type StateProps = {
    isRecentlyDeleted: boolean
}

type DispatchProps = {
    doHandleFiles: (files: ExtendedJobFile[]) => void
    doHandleLocalUpload: (files: File[]) => void
}

const mapStateToProps = (
    state: StateOfSelector<typeof getStatusOfJob>,
    ownProps: OwnProps,
): StateProps => ({
    isRecentlyDeleted:
        getStatusOfJob(state, ownProps.albumID) ===
        JobInfoStatus.RECENTLY_DELETED,
})

const mapDispatchToProps = (
    dispatch: Dispatch,
    ownProps: OwnProps,
): DispatchProps => ({
    doHandleFiles: async (files: ExtendedJobFile[]) => {
        ownProps.doTrackEvent(files.length)
        if (ownProps.navToAlbum) {
            ownProps.navigate(Pages.Album(ownProps.albumID).url)
        } else {
            goBack()
        }
        const { succeeded, failed, errors } = await copyMultipleFilesToJob(
            dispatch,
            ownProps.albumID,
            files,
        )

        // Handling the case where the album has been deleted before all files are added to the album
        const jobDeletedHint = 'Job has been deleted'
        if (errors && errors?.length > 0) {
            if (
                errors.some(async (e) => {
                    const cause = e.cause as Response
                    const body = await cause.text()
                    return body.includes(jobDeletedHint)
                })
            ) {
                return
            }
        } else if (succeeded.length > 0) {
            dispatch(
                FilesCopiedToAlbum({
                    jobID: ownProps.albumID,
                    files: succeeded,
                }),
            )
        } else if (failed.length > 0) {
            dispatch(
                FilesCopiedToAlbumFailed({
                    jobID: ownProps.albumID,
                    files: failed,
                }),
            )
        }
    },
    doHandleLocalUpload: (files: File[]) => {
        trackEvent('AddToAlbum', 'LocalUploadClicked')
        getConnectedInstance().addFiles(files, ownProps.albumID, '', true)
        goBack()
    },
})

const AddPhotosToAlbumPage = connect(
    mapStateToProps,
    mapDispatchToProps,
)(SelectFromTimelinePage)

export const MorePhotosSelectionPage: React.FunctionComponent = () => {
    const { albumIDB64 } = useParams<{ albumIDB64: string }>()
    const navigate = useNavigate()

    if (albumIDB64 === undefined) return null

    return (
        <AddPhotosToAlbumPage
            albumID={b64ToUuid(albumIDB64)}
            nextButtonLabel={_('add_photos')}
            doTrackEvent={() => {
                trackEvent('CreateAlbum', 'AddPhotosFromCapture')
                trackEventInternal('album_create_add_photos_from_capture')
            }}
            navigate={navigate}
        />
    )
}

export const AddToAlbumSelectionPage: React.FunctionComponent = () => {
    const { albumIDB64 } = useParams<{ albumIDB64: string }>()
    const navigate = useNavigate()
    if (!albumIDB64) {
        throw Error('Must have params for albumIDB64')
    }

    const trackEvents = (fileCount: number) => {
        trackEvent('AlbumPage', 'AddPhotosFromCapture')
        trackEventInternal('album_add_finish', {
            count: fileCount,
            source: 'account',
        })
    }
    return (
        <AddPhotosToAlbumPage
            albumID={b64ToUuid(albumIDB64)}
            navToAlbum={true}
            nextButtonLabel={_('add_photos')}
            doTrackEvent={trackEvents}
            navigate={navigate}
        />
    )
}
