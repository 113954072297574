import type { AxiosRequestOptions } from '@capture/client-api/src/orval/customInstance'
import type { APIKeyDTO } from '@capture/client-api/src/orval'
import type { RequiredCommonQueryParams } from '~/@types/backend-types'
import { getDeviceProps } from '~/config/constants'
import { responseInterceptor } from '../toolbox'

export class APIService {
    protected commonQueryParams: RequiredCommonQueryParams & {
        /**
         * Foreign auth
         * ---------------
         * Foreign auth is used when we fetch an job that doesnt belong to the logged in user
         * In those cases, the Auth token becomes foreign-auth
         * If the job is password protected, the job password is sent in place of the auth token
         *
         * @example Accessing a job with user auth:
         * Authorization: `Basic ${btoa(`USER:${authToken}`)}`
         *
         * @example Accessing a password protected job with foreign auth:
         * Authorization: `Basic ${btoa(`FOREIGN+PUBLIC:${authToken} ${password}`)}`
         */
        // TODO CAPWEB-3123: inject foreign auth only where needed, we will be able to do this when we get app service per session rather than per job
        'foreign-auth'?: string
    }
    public authToken: string
    public axiosRequestOptions: AxiosRequestOptions

    constructor(host: string, authToken: string, foreignAuth?: string) {
        this.authToken = authToken
        this.commonQueryParams = {
            key: (import.meta.env?.VITE_API_KEY || 'testing') as APIKeyDTO,
            client_v: (import.meta.env?.VITE_VERSION || 'testing') as string,
            device_id: getDeviceProps().device_id,
            'foreign-auth': foreignAuth,
        }
        this.axiosRequestOptions = {
            baseURL: `https://${host}/st/4`,
            headers: {
                Authorization: `Basic ${btoa(`USER:${this.authToken}`)}`,
            },
            responseInterceptors: [responseInterceptor],
        }
    }
}
