import type { Store } from '@reduxjs/toolkit'
import type { NavigateFunction } from 'react-router-dom'
import type { AxiosProgressEvent, GenericAbortSignal } from 'axios'
import {
    AsyncUploadStateDTO,
    FileLifetimeStatusDTO,
} from '@capture/client-api/src/orval'
import { createAutoGeneratedAlbum } from '~/API/album'
import { uploadFile, uploaderWithTimelineMirroring } from '~/API/job'
import { whenNetworkGoesOffline } from '~/API/toolbox'
import { FileTarget, getFileTargetFromName } from '~/utilities/fileTarget'
import type { UploadResponse } from '~/@types/backend-types'
import { AbortablePromise } from '~/utilities/promises'
import type { StateWithTimeline } from '~/state/timeline/reducers'
import type { Dispatch } from '../common/actions'
import type { StateWithCurrentUser } from '../currentUser/reducer'
import { getUsedStorage } from '../currentUser/selectors'
import { isStoryJob } from '../jobInfo/selectors'
import { getTimelineJobID } from '../timeline/selectors'
import { uploaderWithAlbumCreationSupport } from './AlbumAutoCreator'
import type { FileWasAddedPayload } from './actions'
import {
    AddedMoreFilesThanAvailableStorage,
    FileUploadBackendSucceeded,
    FileUploadFailed,
    FileUploadProgress,
    FileUploadRetry,
    FileUploadStarted,
    FileUploadSucceeded,
    FileWasAcceptedToUploadQueue,
    FileWasAddedToUploadQueue,
    FileWasRejected,
    FileWasRemovedFromUploadQueue,
    UploaderFinished,
    UploaderPaused,
    UploaderResumed,
    UploaderStatusBoxShown,
    UploaderStopped,
} from './actions'
import type { FileInformation, StateWithUploader } from './reducer'
import { RejectReason } from './reducer'
import {
    getAvailableStorage,
    getNextPossibleEnqueuedFile,
    getSucceededFiles,
    isCurrentlyUploading,
    isOffline,
    isPaused,
    isStopPrompted,
    isUploaderDone,
} from './selectors'

declare global {
    interface Window {
        uploadQueueInstance?: UploadQueue
    }
}

export type UploadFunction = (
    f: File,
    i: FileInformation,
) => AbortablePromise<UploadResponse>
export type UploadCheck = (file: File, targetJob: JobID) => Promise<unknown>
export type MultiUploadCheck = (
    files: File[],
    targetJob: JobID,
    targetFolder?: string,
) => Promise<void>

// Combining the state required for the uploader to work
export type UploadQueueCompatibleState = StateWithUploader &
    StateWithCurrentUser &
    StateWithTimeline

export const checkFolder: UploadCheck = (file: File) => {
    /* Folder-check:
     * FileReader will not be able to read a folder (while it will be ok with any file).
     * Therefore; try to read the first 64 bytes of data from the file - if that fails the user most likely
     * added a folder (or something else that will fail to upload) so reject it from the upload queue.
     * If FileReader does not exist (legacy browsers) - or for some other reason causes a real Exception - accept
     * the file as we failed to assert if it was a folder (and most likely is a legitimate file anyway)
     */
    return new Promise<void>((resolve, reject) => {
        try {
            const fr = new FileReader()
            fr.onload = () => {
                resolve()
            }
            fr.onerror = () => {
                reject(RejectReason.FileIsFolder)
            }
            fr.readAsBinaryString(file.slice(0, 64))
        } catch (_e) {
            // No file-reader? We accept the upload by default [to actually function in these cases]
            resolve()
        }
    })
}
export const makeFileTypeCheck =
    (
        allowedFileTypes: FileTarget[] = [
            FileTarget.Pictures,
            FileTarget.Movies,
        ],
    ): UploadCheck =>
    (file: File) => {
        return new Promise<void>((resolve, reject) => {
            const fileType = getFileTargetFromName(file.name)
            if (allowedFileTypes.some((type) => fileType === type)) {
                resolve()
            } else {
                reject(RejectReason.UnSupported)
            }
        })
    }

export const fileMaxSizeCheck: UploadCheck = async (file: File) => {
    if (file.size > 200 * 2 ** 30) {
        // 200 GiB limit
        throw RejectReason.TooLargeFile
    }
}

type EnforceAvailableStorageCheckState = StateOfSelector<typeof isStoryJob> &
    StateOfSelector<typeof getAvailableStorage>

export const makeEnforceAvailableStorageCheck =
    (store: Store<EnforceAvailableStorageCheckState>): MultiUploadCheck =>
    async (files: File[], jobID: JobID) => {
        if (isStoryJob(store.getState(), jobID)) {
            return // We dont check storage for albums, refs: CAPWEB-3172
        }
        const availableStorage = getAvailableStorage(store.getState())
        const totalSize = files.reduce((sum, file) => sum + file.size, 0)
        if (totalSize > availableStorage) {
            store.dispatch(AddedMoreFilesThanAvailableStorage())
            throw new Error(
                'Rejecting file additions: There is not enough space',
            )
        }
    }

export enum UploadFailedReason {
    NetworkError = 'NetworkError',
    LocalFileUnavailable = 'LocalFileUnavailable',
    FileError = 'FileError',
    OutOfStorage = 'OutOfStorage',
}
export class UploadError extends Error {
    constructor(
        public reason: UploadFailedReason,
        public message: string,
    ) {
        super(message)
    }
}

class UploadQueue {
    private files: File[] = []
    private previewThumbs: DictionaryOf<string> = {}
    private currentlyUploadingToBackend?: FileInformation
    private abortCurrent?: () => void

    constructor(
        private store: Store<UploadQueueCompatibleState>,
        private uploadFunction: UploadFunction,
        private uploadChecks: UploadCheck[],
        private multiUploadChecks: MultiUploadCheck[],
    ) {}

    private handleEnquedUploading = async (fileToUpload: FileInformation) => {
        this.currentlyUploadingToBackend = fileToUpload
        this.store.dispatch(FileUploadStarted({ fileID: fileToUpload.id }))
        this.store.dispatch(UploaderStatusBoxShown())
        const call = this.uploadFunction(
            this.files[fileToUpload.id],
            fileToUpload,
        )
        this.abortCurrent = call.abort.bind(call)

        await call.getPromise().then(
            (resolved: UploadResponse) => {
                this.abortCurrent = undefined
                this.currentlyUploadingToBackend = undefined

                if (
                    resolved.content.status === AsyncUploadStateDTO.processing
                ) {
                    this.store.dispatch(
                        FileUploadBackendSucceeded({
                            fileID: fileToUpload.id,
                            fileUUID: resolved.content.uuid,
                        }),
                    )
                } else {
                    const state = this.store.getState()
                    if (
                        resolved.content.status === FileLifetimeStatusDTO.exists
                    ) {
                        this.store.dispatch(
                            FileUploadSucceeded({
                                fileID: fileToUpload.id,
                                fileUUID: resolved.content.uuid,
                                usedStorage:
                                    resolved.content.estimated_used_space ||
                                    getUsedStorage(state),
                            }),
                        )
                    } else if (
                        resolved.content.status ===
                            FileLifetimeStatusDTO.deleted ||
                        resolved.content.status ===
                            FileLifetimeStatusDTO.trashed
                    ) {
                        this.store.dispatch(
                            FileWasRejected({
                                fileID: fileToUpload.id,
                                reason: RejectReason.FileWasDeleted,
                            }),
                        )
                    }

                    if (isUploaderDone(state)) {
                        this.store.dispatch(
                            UploaderFinished({
                                filesCount: getSucceededFiles(state).length,
                            }),
                        )
                    }
                }
            },
            (error?: UploadError) => {
                this.abortCurrent = undefined
                this.currentlyUploadingToBackend = undefined
                switch (error?.reason) {
                    case UploadFailedReason.FileError:
                        this.store.dispatch(
                            FileWasRejected({
                                fileID: fileToUpload.id,
                                reason: RejectReason.UnSupported,
                            }),
                        )
                        break
                    case UploadFailedReason.LocalFileUnavailable:
                        this.store.dispatch(
                            FileWasRejected({
                                fileID: fileToUpload.id,
                                reason: RejectReason.LocalFileUnavailable,
                            }),
                        )
                        break
                    case UploadFailedReason.OutOfStorage:
                        this.store.dispatch(
                            FileWasRejected({
                                fileID: fileToUpload.id,
                                reason: RejectReason.NoStorage,
                            }),
                        )
                        break
                    default:
                        this.store.dispatch(
                            FileUploadFailed({
                                fileID: fileToUpload.id,
                                message: error?.message,
                            }),
                        )
                }
            },
        )
    }

    public digestNewState(newState?: UploadQueueCompatibleState) {
        if (newState === undefined) {
            return
        }

        // if the user ran out of storage we skip to the next file
        const nextFile = getNextPossibleEnqueuedFile(newState)

        if (
            !isPaused(newState) &&
            !isStopPrompted(newState) &&
            !isOffline(newState) &&
            !isCurrentlyUploading(newState) &&
            nextFile
        ) {
            this.handleEnquedUploading(nextFile)
        }
    }

    public addFiles(
        files: File[],
        targetJob: JobID,
        targetFolder?: string,
        copyToTimeline?: boolean,
    ): Promise<void> {
        return Promise.all(
            this.multiUploadChecks.map((check) =>
                check(files, targetJob, targetFolder),
            ),
        ).then(
            () =>
                files.forEach((file) =>
                    this._addFile(
                        file,
                        targetJob,
                        targetFolder,
                        copyToTimeline,
                    ),
                ),
            () => {
                /* If the checks failed, the user should get notified by the UI-changes, swallow error here */
            },
        )
    }

    private async _addFile(
        file: File,
        targetJob: JobID,
        targetFolder?: string,
        copyToTimeline?: boolean,
    ): Promise<void> {
        const id = this.files.length
        this.files[id] = file

        const checksInSerial = (checks: UploadCheck[]): Promise<unknown> =>
            checks.reduce(
                (prev: Promise<unknown>, c) =>
                    prev.then(() => c(file, targetJob)),
                Promise.resolve(),
            )

        const newFile: FileWasAddedPayload = {
            id,
            targetJob,
            targetFolder,
            alsoTargetTimeline: copyToTimeline,
            name: file.name,
            size: file.size,
        }

        this.store.dispatch(FileWasAddedToUploadQueue(newFile))
        return checksInSerial(this.uploadChecks)
            .then(() => {
                this.store.dispatch(
                    FileWasAcceptedToUploadQueue({ fileID: id }),
                )
            })
            .catch((reason: RejectReason) => {
                this.store.dispatch(FileWasRejected({ fileID: id, reason }))
            })
    }

    public async getUploadFilePreviewThumb(id: number): Promise<string> {
        const file = this.files[id]
        if (file) {
            if (this.previewThumbs[id] === undefined) {
                this.previewThumbs[id] = window.URL.createObjectURL(file)
            }

            return this.previewThumbs[id]
        }

        return Promise.reject('file does not exist')
    }

    public clearUploadFiles() {
        Object.keys(this.previewThumbs).forEach((id) => {
            const thumb = this.previewThumbs[id]
            window.URL.revokeObjectURL(thumb)
        })

        this.previewThumbs = {}
        this.files = []
    }

    public retryUpload() {
        this.store.dispatch(FileUploadRetry())
    }

    public pause() {
        this.store.dispatch(UploaderPaused())
    }
    public resume() {
        this.store.dispatch(UploaderResumed())
    }

    public stop(navigate?: NavigateFunction) {
        if (this.abortCurrent) {
            this.abortCurrent()
            this.abortCurrent = undefined
        }
        this.store.dispatch(UploaderStopped({ navigate }))
    }

    public removeFile(fileID: number) {
        this.store.dispatch(FileWasRemovedFromUploadQueue({ fileID }))
        if (
            this.currentlyUploadingToBackend &&
            this.currentlyUploadingToBackend.id === fileID &&
            this.abortCurrent
        ) {
            this.abortCurrent()
        }
    }
}

export const getConnectedInstance = (): UploadQueue => {
    if (!window.uploadQueueInstance) {
        throw new Error(
            'Must connectUploadQueue before fetching the connectedInstance',
        )
    }
    return window.uploadQueueInstance
}

export function connectUploadQueue(
    store: Store<UploadQueueCompatibleState>,
    uploadFunction: UploadFunction,
    checks: UploadCheck[] = [],
    multiChecks: MultiUploadCheck[] = [],
): UploadQueue {
    const q = new UploadQueue(store, uploadFunction, checks, multiChecks)
    store.subscribe(() => q.digestNewState(store.getState()))
    if (typeof window !== 'undefined') {
        window.uploadQueueInstance = q
    }
    return q
}

export function connectUploader(store: Store) {
    const doUpload = [
        uploaderWithTimelineMirroring(store, store.dispatch, () =>
            getTimelineJobID(store.getState()),
        ),
        uploaderWithAlbumCreationSupport((name, tempID) =>
            createAutoGeneratedAlbum(store.dispatch, name, tempID),
        ),
    ].reduce((p, m) => m(p), uploadFile)

    connectUploadQueue(
        store,
        makeUploadFunction(store.dispatch, doUpload),
        [checkFolder, makeFileTypeCheck(), fileMaxSizeCheck],
        [makeEnforceAvailableStorageCheck(store)],
    )
}

/**
 * For an UploadFunction that accepts the request as an argument and uses that for the upload:
 * Make the Promise of an UploadFunctionResolveValue into an abortable and progress-tracking Promise
 */
export type UploadMethod = (
    f: File,
    i: FileInformation,
    onUploadProgress: (event: AxiosProgressEvent) => void,
    signal: GenericAbortSignal,
) => Promise<UploadResponse>
export type UploadDecorator = (u: UploadMethod) => UploadMethod
export const makeUploadFunction = (
    dispatch: Dispatch,
    uploadFunc: UploadMethod,
): UploadFunction => {
    let abortableInstance: AbortablePromise<UploadResponse> | undefined

    whenNetworkGoesOffline(() => {
        if (abortableInstance) {
            abortableInstance.abort()
            abortableInstance = undefined
        }
    })

    return (f: File, i: FileInformation) => {
        let isTimeBlocked = false // Avoid triggering too many actions within the same timeframe
        let lastDispatchedDoneRatio = 0 // ... or when the change doesn't matter

        abortableInstance = new AbortablePromise((signal) => {
            // If other requests detects that the network is missing, abort the uploader one.
            // The request may still be hanging for some time before it is aborted (as the connection have been made and the efforts to keep it may leave it hanging for several minutes)

            /** callback passed to axios request to allow keeping track of the upload progress */
            const onUploadProgress = (event: AxiosProgressEvent) => {
                // early return if there is no progress to report
                if (!event.loaded || event.total === undefined) {
                    return
                }

                /** normalised value between 0 and 1 */
                const doneRatio = event.loaded / event.total

                // early return to throttle update frequency
                if (
                    isTimeBlocked ||
                    doneRatio - lastDispatchedDoneRatio < 0.01
                ) {
                    return
                }

                lastDispatchedDoneRatio = doneRatio
                isTimeBlocked = true
                setTimeout(() => (isTimeBlocked = false), 3)
                dispatch(
                    FileUploadProgress({
                        fileID: i.id,
                        percentComplete: doneRatio,
                    }),
                )
            }

            return uploadFunc(f, i, onUploadProgress, signal).then(
                (resp) => {
                    abortableInstance = undefined
                    return resp
                },
                (err) => {
                    abortableInstance = undefined
                    throw err
                },
            )
        })
        return abortableInstance
    }
}
