import type { DeviceWithAuthDTO } from '@capture/client-api/src/orval'
import { getAuthToken } from '~/API/externals'
import type { Action } from '../common/actions'
import { isType } from '../common/actions'
import {
    FilesDeletionSucceeded,
    FilesRestorationSucceeded,
} from '../job/actions'
import { FileUploadSucceeded } from '../uploader/actions'
import {
    ProfileNameChanged,
    ProfilePictureChangeFailed,
    ProfilePictureChangeStarted,
} from '../users/actions'
import type { RequireUserInfoReason, UserInformation } from './actions'
import {
    ConnectedDeviceWasDeleted,
    ConnectedDevicesWasFetched,
    DetectedThatUserIsUnauthenticated,
    FetchedAccountInfo,
    FetchedFileCount,
    HideMissingNameModal,
    InvalidLoginStateDetected,
    JobSubscriptionsDetected,
    MissingNameModalContext,
    ShowMissingNameModal,
    StartFetchingAccountInfo,
    TOSTermsVerified,
    UnableToFetchAccountInfo,
    UnverifiedTermsDetected,
    UserLoggedOut,
    UserSubscribedToAlbum,
    UserUnsubscribedFromAlbum,
    ValidUserInfoRequestDeniedByUser,
    ValidUserInfoRequired,
} from './actions'

export enum LoginProgressStep {
    NOT_STARTED = 'NOT_STARTED',
    FETCHING = 'FETCHING',
    AUTHENTICATED = 'AUTHENTICATED',
    FAILED = 'FAILED',
    UNAUTHENTICATED = 'UNAUTHENTICATED',
}

export type CurrentUserState = {
    loginStatus: LoginProgressStep
    haveFetchedAlbumList: boolean
    userInfoRequired: { isRequired: boolean; reason?: RequireUserInfoReason }
    accountInfo?: UserInformation
    isUploadingProfilePhoto: boolean
    subscribedJobs: JobID[]
    connectedDevices: DeviceWithAuthDTO[]
    missingNameModal: {
        isVisible: boolean
        context: MissingNameModalContext
    }
}

// Gotcha: When loading the app, there is some time before the login-actions are dispatched.
// Anytime there is a stored authToken a login-process will be triggered. Respect and reflect that.
const isLoggingIn = getAuthToken() !== ''

const initialState: CurrentUserState = {
    loginStatus: isLoggingIn
        ? LoginProgressStep.FETCHING
        : LoginProgressStep.NOT_STARTED,
    haveFetchedAlbumList: false,
    userInfoRequired: { isRequired: false },
    subscribedJobs: [],
    connectedDevices: [],
    missingNameModal: {
        isVisible: false,
        context: MissingNameModalContext.NoContext,
    },
    isUploadingProfilePhoto: false,
}

export function currentUserReducer(
    state: CurrentUserState = initialState,
    action: Action,
): CurrentUserState {
    if (isType(action, StartFetchingAccountInfo)) {
        return {
            ...state,
            loginStatus: LoginProgressStep.FETCHING,
        }
    }
    if (isType(action, UnableToFetchAccountInfo)) {
        return { ...state, loginStatus: LoginProgressStep.FAILED }
    }
    if (isType(action, FetchedAccountInfo)) {
        return {
            ...state,
            loginStatus: LoginProgressStep.AUTHENTICATED,
            accountInfo: action.payload,
            isUploadingProfilePhoto: false,
        }
    }
    if (isType(action, DetectedThatUserIsUnauthenticated)) {
        return {
            ...state,
            loginStatus: LoginProgressStep.UNAUTHENTICATED,
            accountInfo: undefined,
        }
    }
    if (isType(action, ProfileNameChanged)) {
        if (state.accountInfo) {
            return {
                ...state,
                accountInfo: {
                    ...state.accountInfo,
                    name: action.payload.name,
                },
            }
        }
    }
    if (isType(action, ProfilePictureChangeStarted)) {
        return {
            ...state,
            isUploadingProfilePhoto: true,
        }
    }
    if (isType(action, ProfilePictureChangeFailed)) {
        return {
            ...state,
            isUploadingProfilePhoto: false,
        }
    }
    if (isType(action, UnverifiedTermsDetected) && state.accountInfo) {
        return {
            ...state,
            accountInfo: {
                ...state.accountInfo,
                tos_to_approve: action.payload,
            },
        }
    }
    if (isType(action, TOSTermsVerified) && state.accountInfo) {
        return {
            ...state,
            accountInfo: { ...state.accountInfo, tos_to_approve: null },
        }
    }
    if (
        isType(action, InvalidLoginStateDetected) ||
        isType(action, UserLoggedOut)
    ) {
        return {
            ...state,
            loginStatus: LoginProgressStep.FAILED,
            accountInfo: undefined,
        }
    }
    if (isType(action, ValidUserInfoRequired)) {
        return {
            ...state,
            userInfoRequired: { isRequired: true, reason: action.payload },
        }
    }
    if (isType(action, ValidUserInfoRequestDeniedByUser)) {
        return { ...state, userInfoRequired: { isRequired: false } }
    }

    if (isType(action, JobSubscriptionsDetected)) {
        return {
            ...state,
            subscribedJobs: action.payload,
            haveFetchedAlbumList: true,
        }
    }
    if (isType(action, UserSubscribedToAlbum)) {
        return {
            ...state,
            subscribedJobs: state.subscribedJobs.concat([action.payload]),
        }
    }
    if (isType(action, UserUnsubscribedFromAlbum)) {
        return {
            ...state,
            subscribedJobs: state.subscribedJobs.filter(
                (id) => id !== action.payload,
            ),
        }
    }

    if (isType(action, FileUploadSucceeded)) {
        let accInfo = state.accountInfo
        if (accInfo) {
            accInfo = { ...accInfo, used_space: action.payload.usedStorage }
        }
        return {
            ...state,
            accountInfo: accInfo,
        }
    }

    if (isType(action, ConnectedDevicesWasFetched)) {
        const devices = action.payload.devices.filter(
            (d) => d.id !== action.payload.currentDeviceID,
        )
        return {
            ...state,
            connectedDevices: devices,
        }
    }
    if (isType(action, ConnectedDeviceWasDeleted)) {
        const newState = { ...state }
        const devices = newState.connectedDevices.filter(
            (d) => d.id !== action.payload.deviceID,
        )
        return {
            ...newState,
            connectedDevices: devices,
        }
    }

    if (
        isType(action, FilesDeletionSucceeded) ||
        isType(action, FilesRestorationSucceeded)
    ) {
        if (state.accountInfo && action.payload.usedSpace) {
            return {
                ...state,
                accountInfo: {
                    ...state.accountInfo,
                    used_space: action.payload.usedSpace,
                },
            }
        }
    }

    if (isType(action, FetchedFileCount)) {
        const accInfo = state.accountInfo
        if (accInfo) {
            return {
                ...state,
                accountInfo: {
                    ...accInfo,
                    ...action.payload,
                },
            }
        }
    }

    if (isType(action, ShowMissingNameModal)) {
        return {
            ...state,
            missingNameModal: {
                isVisible: true,
                context: action.payload,
            },
        }
    }

    if (isType(action, HideMissingNameModal)) {
        return {
            ...state,
            missingNameModal: {
                isVisible: false,
                context: MissingNameModalContext.NoContext,
            },
        }
    }

    return state
}

export const currentUserReducerMapObj = {
    currentUser: currentUserReducer,
}

export type StateWithCurrentUser = StateOfReducerMapObj<
    typeof currentUserReducerMapObj
>
