/**
 * Generated by orval v7.7.0 🍺
 * Do not edit manually.
 * FastAPI
 * OpenAPI spec version: 0.1.0
 */

export enum UploadPolicyDTO {
    allow_duplicates = 'allow_duplicates',
    no_duplicates = 'no_duplicates',
    once_only = 'once_only',
}
