import { createActionCreator, createVoidActionCreator } from '../common/actions'

export enum UserActionTypes {
    DELETE_ALBUM = 'DELETE_ALBUM',
    UNSUBSCRIBE_ALBUM = 'UNSUBSCRIBE_ALBUM',
    DELETE_FILE = 'DELETE_FILE',
    DELETE_DEVICE = 'DELETE_DEVICE',
    FILE_COUNT_EXCEEDS_DOWNLOAD_LIMIT = 'FILE_COUNT_EXCEEDS_DOWNLOAD_LIMIT',
    SIZE_EXCEEDS_DOWNLOAD_LIMIT = 'SIZE_EXCEEDS_DOWNLOAD_LIMIT',
    ALBUM_FILE_COUNT_EXCEEDS_DOWNLOAD_LIMIT = 'ALBUM_FILE_COUNT_EXCEEDS_DOWNLOAD_LIMIT',
    ALBUM_SIZE_EXCEEDS_DOWNLOAD_LIMIT = 'ALBUM_SIZE_EXCEEDS_DOWNLOAD_LIMIT',
}

export type PendingUserAction = {
    type: UserActionTypes
    target: string
}

export const UserActionInitiated = createActionCreator<PendingUserAction>(
    'USER_ACTION_INITIATED',
)
export const UserAlertInitiated = createActionCreator<PendingUserAction>(
    'USER_ALERT_INITIATED',
)
export const UserActionConfirmHandled = createVoidActionCreator(
    'USER_ACTION_CONFIRM_HANDLED',
)
export const UserActionAlertHandled = createVoidActionCreator(
    'USER_ACTION_ALERT_HANDLED',
)

export const AlbumFileCountExceedsDownloadLimit = createActionCreator<JobID>(
    'ALBUM_FILE_COUNT_EXCEEDS_DOWNLOAD_LIMIT',
)

export const AlbumSizeExceedsDownloadLimit = createActionCreator<JobID>(
    'ALBUM_SIZE_EXCEEDS_DOWNLOAD_LIMIT',
)

export const FileCountExceedsDownloadLimit = createActionCreator<JobID>(
    'FILE_COUNT_EXCEEDS_DOWNLOAD_LIMIT',
)

export const SizeExceedsDownloadLimit = createActionCreator<JobID>(
    'SIZE_EXCEEDS_DOWNLOAD_LIMIT',
)

export const FileDeletionInitiated = (target: FileID) =>
    UserActionInitiated({ target, type: UserActionTypes.DELETE_FILE })

export const AlbumDeletionInitiated = (target: JobID) =>
    UserActionInitiated({ target, type: UserActionTypes.DELETE_ALBUM })
export const AlbumUnsubscriptionInitiated = (target: JobID) =>
    UserActionInitiated({ target, type: UserActionTypes.UNSUBSCRIBE_ALBUM })

export const DeleteConnectedDeviceInitiated = (target: string) =>
    UserActionInitiated({ target, type: UserActionTypes.DELETE_DEVICE })
