/**
 * Generated by orval v7.7.0 🍺
 * Do not edit manually.
 * FastAPI
 * OpenAPI spec version: 0.1.0
 */

/**
 * Type that replicates the ASYNC_UPLOAD_STATE enum in postgres
 */
export enum AsyncUploadStateDTO {
    uploading = 'uploading',
    resumable = 'resumable',
    ready_for_processing = 'ready_for_processing',
    processing = 'processing',
    ready_for_transcoding = 'ready_for_transcoding',
    failed = 'failed',
    done = 'done',
}
