import type { UserStatsEventModelDTO } from '@capture/client-api/src/orval'
import { sendClientStats, sendUserStatistics } from '~/API/currentUser'
import { getDeviceProps, getSessionID } from '~/config/constants'
import {
    AlbumCreationConfirmed,
    AlbumWasCreated,
    AutoGeneratedAlbumFinished,
} from '~/state/album/actions'
import { CarouselViewEntered } from '~/state/carouselViewer/actions'
import type { Action, ActionType } from '~/state/common/actions'
import { FilesDownloadSuccess } from '~/state/job/actions'
import { UploaderFinished } from '~/state/uploader/actions'

export type EventProps = {
    eventName: string
    eventValue?: Record<string, unknown>
}
interface InternalEventTracker {
    handleTrackingConsentGranted: () => void // Start by assuming no consent is given.
    trackAction: (action: Action, getState: () => unknown) => void
    trackEvents: (events: EventProps[]) => void
    trackEvent: (
        eventName: EventProps['eventName'],
        eventData: NonNullable<EventProps['eventValue']>,
    ) => void
}

class Tracker {
    private deviceID: string
    private sessionID: string
    private getUserID: () => string
    constructor(getCurrentUserID: () => string) {
        this.getUserID = getCurrentUserID
        this.deviceID = getDeviceProps().device_id
        this.sessionID = getSessionID()
    }

    public makeEventData = (
        eventName: EventProps['eventName'],
        eventData: EventProps['eventValue'],
    ): UserStatsEventModelDTO => {
        return {
            device_id: this.deviceID,
            session_id: this.sessionID,
            user_uuid: this.getUserID(),
            event_name: 'web_' + eventName,
            event_data: eventData || {},
            created_at: new Date().toISOString(),
        }
    }
    public sendEvent(eventData: EventProps[]) {
        const events = eventData.map((e) =>
            this.makeEventData(e.eventName, e.eventValue),
        )
        sendUserStatistics(events)
    }
}

export class InternalTracker implements InternalEventTracker {
    private tracker: Tracker
    private canTrack = false
    constructor(getCurrentUserID: () => string) {
        this.tracker = new Tracker(getCurrentUserID)
        this._initActionTracking()
    }
    public handleTrackingConsentGranted = () => {
        this.canTrack = true
    }
    private actionHandlers: Record<ActionType, (a: Action<any>) => void> = {}
    private _initActionTracking() {
        this.actionHandlers[FilesDownloadSuccess.type] = (a) =>
            this.trackEvent('file_download_finish', {
                count: a.payload.files.length,
            })
        this.actionHandlers[CarouselViewEntered.type] = (a) =>
            this.trackEvent('carousel_open', {
                is_timeline: a.payload.sourcePage === 'TimelinePage',
            })
        this.actionHandlers[AlbumWasCreated.type] = (a) =>
            this.trackEvent('albums_new_start', { shared: a.payload.shared })
        this.actionHandlers[AlbumCreationConfirmed.type] = (a) =>
            this.trackEvent('albums_new_finish', { shared: a.payload.shared })
        this.actionHandlers[AutoGeneratedAlbumFinished.type] = () =>
            this.trackEvent('albums_new_finish', { shared: false })
        this.actionHandlers[UploaderFinished.type] = (a) =>
            this.trackEvent('timeline_add_finish', {
                count: a.payload.filesCount,
                source: 'gallery',
            })
    }
    private engagedEvents = [
        'album_open',
        'albums_new_finish',
        'timeline_add_finish',
        'file_download_finish',
        'carousel_open',
        'carousel_fullscreen_open',
        'carousel_share_finish',
        'carousel_add_to_album_finish',
        'timeline_selection_share_finish',
        'timeline_selection_add_to_album_finish',
    ]
    private timeOfLastEngagedEvent = 0
    private cooldownPeriod = 60 * 60 // 1 hour
    private isEngagedEvent = (event: string) =>
        this.engagedEvents.indexOf(event) !== -1
    private maybeTrackEngagedUserEvent = (eventName: string) => {
        const justAMomentAgo = Date.now() / 1000
        if (
            this.isEngagedEvent(eventName) &&
            justAMomentAgo - this.timeOfLastEngagedEvent > this.cooldownPeriod
        ) {
            this.trackEvent('engaged_user', {
                last_engagement_event: eventName,
            })
            sendClientStats({
                last_foreground: justAMomentAgo,
                last_engaged: justAMomentAgo,
            })
            this.timeOfLastEngagedEvent = justAMomentAgo
        }
    }

    public trackEvents(events: EventProps[]) {
        if (this.canTrack) {
            this.tracker.sendEvent(events)
        }
    }
    public trackEvent(eventName: string, eventValue?: Record<string, unknown>) {
        if (this.canTrack) {
            this.tracker.sendEvent([{ eventName, eventValue }])
            this.maybeTrackEngagedUserEvent(eventName)
        }
    }
    public trackAction(action: Action) {
        if (this.canTrack && this.actionHandlers[action.type]) {
            this.actionHandlers[action.type](action)
        }
    }

    public trackClientStats = (
        timeOfLastForegroundEvent: number,
        coolDownPeriod: number,
    ) => {
        if (this.canTrack) {
            const now = Date.now() / 1000
            if (now - timeOfLastForegroundEvent > coolDownPeriod) {
                sendClientStats({ last_foreground: now, last_engaged: 0 })
                timeOfLastForegroundEvent = now
            }
        }
    }
}
