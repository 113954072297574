import {
    getRequestStripeCustomerExists,
    getRequestStripePaymentMethod,
    getRequestStripeProducts,
    getRequestStripeSubscriptions,
    postRequestStripeCreateCustomer,
    postRequestStripeCreateSetupIntent,
    postRequestStripeFinishSetupIntent,
    postRequestStripeV1Purchase,
    putRequestStripeV1Purchase,
} from '@capture/client-api/src/orval'
import type {
    StripePaymentInfo,
    StripeProductsMode,
    StripeProductsResponse,
} from '~/@types/backend-types'
import { getAuthToken, getStoredServiceDict } from '../externals'
import { APIService } from './APIService'

export class StripeService extends APIService {
    constructor(host: string, authToken: string) {
        super(host, authToken)
    }

    public async checkCustomerExists() {
        return getRequestStripeCustomerExists(
            this.commonQueryParams,
            this.axiosRequestOptions,
        )
    }

    public async createCustomer({
        name,
        email,
    }: {
        name: string
        email: string
    }) {
        return postRequestStripeCreateCustomer(
            {
                ...this.commonQueryParams,
                name,
                email,
            },
            this.axiosRequestOptions,
        )
    }

    public async createSetupIntent() {
        return postRequestStripeCreateSetupIntent(
            this.commonQueryParams,
            this.axiosRequestOptions,
        )
    }

    public async finishSetupIntent(setupIntentId: string) {
        return postRequestStripeFinishSetupIntent(
            {
                ...this.commonQueryParams,
                setup_intent_id: setupIntentId,
            },
            this.axiosRequestOptions,
        )
    }

    public async getAllSubscriptions() {
        return getRequestStripeSubscriptions(
            this.commonQueryParams,
            this.axiosRequestOptions,
        )
    }

    public async getStripeProducts(
        mode: StripeProductsMode /* 'test'|'production'*/,
    ): Promise<StripeProductsResponse> {
        const response = await getRequestStripeProducts(
            {
                ...this.commonQueryParams,
                [mode]: true,
            },
            this.axiosRequestOptions,
        )

        return response.data as StripeProductsResponse
    }

    public async getStripePaymentMethodInfo() {
        const response = await getRequestStripePaymentMethod(
            this.commonQueryParams,
            this.axiosRequestOptions,
        )

        return response.data as StripePaymentInfo
    }

    public async purchaseStripePlan(plan: string) {
        return postRequestStripeV1Purchase(
            {
                ...this.commonQueryParams,
                plan,
            },
            this.axiosRequestOptions,
        )
    }

    public async updateStripePlan(plan: string) {
        return putRequestStripeV1Purchase(
            {
                ...this.commonQueryParams,
                plan,
            },
            this.axiosRequestOptions,
        )
    }
}

let instance: StripeService | undefined

export function getStripeService() {
    if (instance) {
        return instance
    }

    const hosts = getStoredServiceDict()
    const token = getAuthToken()

    if (hosts === undefined || token === undefined) {
        throw new Error('Missing serviceDict or authToken')
    }

    instance = new StripeService(hosts.appHost, token)
    return instance
}
