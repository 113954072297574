import * as React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { _ } from '~/assets/localization/util'
import { trackEventInternal } from '~/analytics/eventTracking'
import { colors, fontSize } from '~/assets/styleConstants'
import { Pages } from '~/routing'
import { isLargerThanTablet } from '~/state/viewMode/selectors'
import type { PageDescription, WithRouterProps } from '~/utilities/navigation'
import { withRouter } from '~/utilities/navigation'
import { UserMenu } from '../Common/UserMenu'
import { CaptureLogoHorizontal } from '../Icons/CaptureLogoHorizontal'
import { ThreeAreasNavBar } from './ThreeAreasNavBar'

const NavElement = styled.div`
    font-size: ${fontSize.medium_18};
    font-weight: ${(props: { isHighlighted: boolean }) =>
        props.isHighlighted ? 'bold' : 'normal'};
    text-transform: uppercase;
    letter-spacing: 2px;
    color: ${(props) =>
        props.isHighlighted ? colors.captureBlue : colors.captureGrey800};
    padding-bottom: 14px;
    border-bottom: 4px solid
        ${(props) => (props.isHighlighted ? colors.captureBlue : 'transparent')};
    margin: 0 30px;
    display: inline-block;
    cursor: pointer;
`
const NavElementsWrapper = styled.div`
    margin-bottom: -10px;
`

const PageSpecificElement = styled.div`
    padding-left: 32px;
    display: inline-flex;
    cursor: pointer;
`

type StateProps = {
    showButtonText: boolean
}

type OwnProps = {
    currentPage?: PageDescription
    hideUserAvi?: boolean
    mainActionElements?: (showText: boolean) => React.ReactNode[]
}
type Props = OwnProps & WithRouterProps & StateProps

class MainTopNavBar extends React.Component<Props> {
    public render() {
        const makeNavButton = (
            label: string,
            page: PageDescription,
            cy?: string,
        ) => ({
            label,
            page,
            onClick: () => {
                if (this.props.currentPage) {
                    trackEventInternal(
                        `${this.props.location.pathname
                            .substring(1)
                            .replaceAll('/', '_')}_navbar_${
                            page.url === Pages.Timeline.url
                                ? 'photos_clicked'
                                : 'albums_clicked'
                        }`,
                    )
                }
                this.props.navigate(page.url)
            },
            cy,
        })
        const navButtons = [
            makeNavButton(_('timeline'), Pages.Timeline, 'photos__nav'),
            makeNavButton(_('albums'), Pages.Albums, 'albums__nav'),
        ]

        const navElements = navButtons.map((link) => {
            return (
                <NavElement
                    key={link.label}
                    data-cy={link.cy}
                    onClick={link.onClick}
                    onKeyUp={link.onClick}
                    role="button"
                    tabIndex={0}
                    isHighlighted={link.page === this.props.currentPage}>
                    <div>{link.label}</div>
                </NavElement>
            )
        })

        let pageSpecificElements: React.ReactNode[] = []
        if (this.props.mainActionElements) {
            pageSpecificElements = this.props
                .mainActionElements(this.props.showButtonText)
                .map((el, i) => (
                    <PageSpecificElement key={`mainActionElement${i}`}>
                        {el}
                    </PageSpecificElement>
                ))
        }

        if (!this.props.hideUserAvi) {
            pageSpecificElements.push(
                <UserMenu
                    key="avi_area"
                    showSeparator={pageSpecificElements.length > 0}
                    size={32}
                />,
            )
        }

        const leftElements: React.ReactNode[] = [
            <CaptureLogoHorizontal key={'logo'} />,
        ]

        return (
            <ThreeAreasNavBar
                left={leftElements}
                middle={<NavElementsWrapper>{navElements}</NavElementsWrapper>}
                right={pageSpecificElements}
            />
        )
    }
}

const mapStateToProps = (
    state: StateOfSelector<typeof isLargerThanTablet>,
): StateProps => ({
    showButtonText: isLargerThanTablet(state),
})

const ConnectedMainNavBarDesktop = connect(mapStateToProps)(MainTopNavBar)

export const MainNavBarDesktop = withRouter(ConnectedMainNavBarDesktop)
