/**
 * Generated by orval v7.7.0 🍺
 * Do not edit manually.
 * FastAPI
 * OpenAPI spec version: 0.1.0
 */
import { useMutation, useQuery } from '@tanstack/react-query'
import type {
    DataTag,
    DefinedInitialDataOptions,
    DefinedUseQueryResult,
    MutationFunction,
    QueryFunction,
    QueryKey,
    UndefinedInitialDataOptions,
    UseMutationOptions,
    UseMutationResult,
    UseQueryOptions,
    UseQueryResult,
} from '@tanstack/react-query'

import type {
    AccountAttributeResponseDTO,
    AccountDeletionRequestResultDTO,
    AccountInfoDTO,
    AlbumGroupDTO,
    AlbumListModelDTO,
    AndroidStoreResponseDTO,
    AppStoreServerNotificationsDTO,
    AppleStoreResponseDTO,
    AsyncUploadStatusAndChangesResponseDTO,
    AsyncUploadStatusAndChangesV2ResponseDTO,
    AsyncUploadStatusByChecksumResponseDTO,
    AsyncUploadStatusByExternalIdResponseDTO,
    AuthVerificationRespDTO,
    BodyCreatePrivateShareTestPrivateSharePostDTO,
    CancelAccountDeletionDTO,
    CardsDTO,
    ClientParamsDTO,
    CollectionDTO,
    CollectionTypeDTO,
    CommentResponseDTO,
    ConsentResponsev2DTO,
    ConsentsResponseDTO,
    DataProtectionRequestResultDTO,
    DataProtectionUpdateRequestResultDTO,
    DefaultJobDTO,
    DeleteAlbumsResponseDTO,
    DeleteFilesRespDTO,
    DeleteRequestAccountAttributeParams,
    DeleteRequestAlbumGroupsMembersByGroupUuid200,
    DeleteRequestClientEventMemoryDeletionParams,
    DeleteRequestDeleteAlbumsFromTrashCanParams,
    DeleteRequestDevicesByDeviceIdParams,
    DeleteRequestJobsByJobUuidParams,
    DeleteRequestJobsCoverByJobUuidParams,
    DeleteRequestJobsDeleteAlbumFromTrashCanByJobUuidParams,
    DeleteRequestJobsDeleteFilesFromTrashCanByJobUuidParams,
    DeleteRequestJobsFilesByIdByJobUuidAndFileUuid200,
    DeleteRequestJobsFilesByIdByJobUuidAndFileUuidParams,
    DeleteRequestJobsFilesByIdCommentsByJobUuidAndFileUuidAndCommentUuidParams,
    DeleteRequestJobsFilesByIdReactionByJobUuidAndFileUuidParams,
    DeleteRequestMemoryCollectionsByCollectionUuidParams,
    DeleteRequestOptionsByNameParams,
    DeleteRequestProfilePictureParams,
    DeleteRequestRemoteVarsParams,
    DeleteRequestRemoteVarsRegistryParams,
    DeleteRequestStripeUserCreditCardParams,
    DeleteRequestTestEraseStoreSubParams,
    DeleteRequestTestRemoveNonDefaultGrants200,
    DeleteRequestTestThrowawayUserTemplateParams,
    DeleteTemplateRespDTO,
    DeletedFilesInfoResponseDTO,
    DevicesWithAuthDTO,
    DigitalLegacyContactDTO,
    DigitalLegacyInfoDTO,
    DummyPoliceJwtRespDTO,
    EmailConsentIDDTO,
    ErrorLogDataResultDTO,
    FeatureFlagsDTO,
    FeedbackDataDTO,
    FileObjectResponseDTO,
    FilePropResponseDTO,
    FileUUIDListDTO,
    FileUUIDRollbackListDTO,
    FullScreenCardsResponseDTO,
    FullScreenCardsResponseV2DTO,
    GetRequest3ByFileUuidParams,
    GetRequest3ProfilePictureByUserUuidParams,
    GetRequest3TrashByJobUuidAndFileUuidParams,
    GetRequestAccountAttributeParams,
    GetRequestAccountInfoParams,
    GetRequestAndroidStoreProductsParams,
    GetRequestAppleStoreProductsParams,
    GetRequestCardsParams,
    GetRequestCoverAlbumParams,
    GetRequestDataProtectionConsentValuesParams,
    GetRequestDataProtectionRequestDataAccessAvailabilityParams,
    GetRequestDataProtectionRequestDataAccessParams,
    GetRequestDevicesParams,
    GetRequestDigitalLegacyParams,
    GetRequestEmptyTrashCanParams,
    GetRequestExternalUrls200,
    GetRequestFeatureFlagsParams,
    GetRequestFullScreenCardsParams,
    GetRequestJobs200Item,
    GetRequestJobsAsyncUploadByJobUuidAndChecksumParams,
    GetRequestJobsAsyncUploadWithUploadIdByJobUuidAndUploadIdParams,
    GetRequestJobsChangesAndAsyncUploadStatusByJobUuidParams,
    GetRequestJobsChangesByJobUuidParams,
    GetRequestJobsClassificationByJobUuid200,
    GetRequestJobsClassificationByJobUuidParams,
    GetRequestJobsDefaultParams,
    GetRequestJobsDeletedFilesInfoByJobUuidParams,
    GetRequestJobsEventsByJobUuidParams,
    GetRequestJobsFileExistsByChecksumByJobUuidAndChecksum200,
    GetRequestJobsFileExistsByChecksumByJobUuidAndChecksumParams,
    GetRequestJobsFilesAsArchiveByJobUuidParams,
    GetRequestJobsFilesByIdByJobUuidAndFileUuidAndNameBodies,
    GetRequestJobsFilesByIdByJobUuidAndFileUuidAndNameParams,
    GetRequestJobsFilesByIdByJobUuidAndFileUuidAndPathParams,
    GetRequestJobsFilesByIdByJobUuidAndFileUuidBodies,
    GetRequestJobsFilesByIdByJobUuidAndFileUuidParams,
    GetRequestJobsFilesByJobUuidParams,
    GetRequestJobsFilesDedupByJobUuidParams,
    GetRequestJobsFilesExistsByChecksumByJobUuid200,
    GetRequestJobsFilesExistsByChecksumByJobUuidParams,
    GetRequestJobsFilesFromTrashAsArchiveByJobUuidParams,
    GetRequestJobsFilesPropertiesByJobUuidAndFileUuidParams,
    GetRequestJobsInfoByJobUuid200,
    GetRequestJobsInfoByJobUuidParams,
    GetRequestJobsInstantsByJobUuidAndDateRangeParams,
    GetRequestJobsLightParams,
    GetRequestJobsMetadataByJobUuidAndFileUuid200,
    GetRequestJobsMetadataByJobUuidAndFileUuidParams,
    GetRequestJobsParams,
    GetRequestJobsPermissionsByJobUuidParams,
    GetRequestJobsPrivacyModeByJobUuidParams,
    GetRequestJobsReactionsByJobUuidParams,
    GetRequestJobsTimelineDaysByJobUuidParams,
    GetRequestJobsTimelineMonthsByJobUuidParams,
    GetRequestJobsUsersByJobUuidParams,
    GetRequestLogoutParams,
    GetRequestMemoryCollectionsByCollectionUuidParams,
    GetRequestMetatagsByJobUuidParams,
    GetRequestMittTelenorNoUserInfoByTelenorIdParams,
    GetRequestOptionsByName200,
    GetRequestOptionsByNameParams,
    GetRequestOptionsParams,
    GetRequestProfileAlbumParams,
    GetRequestProfilePictureParams,
    GetRequestRemoteVarsParams,
    GetRequestRemoteVarsRegistryParams,
    GetRequestSearchVerifyByJobUuidParams,
    GetRequestSearchVerifyUserAccessParams,
    GetRequestShouldSendUserStatisticsEventsParams,
    GetRequestStripeCustomerExistsParams,
    GetRequestStripePaymentMethodParams,
    GetRequestStripeProductsParams,
    GetRequestStripePurchaseValidatePurchaseBySubscriptionIdParams,
    GetRequestStripeSubscriptionsParams,
    GetRequestTelenoridPostflightOauth2callback200,
    GetRequestTelenoridPostflightOauth2callbackParams,
    GetRequestTelenoridPreflightOauth2loginParams,
    GetRequestTestCreateDummySignedJwtParams,
    GetRequestTestEmailConsentIdParams,
    GetRequestTestLoginIdentifierParams,
    GetRequestTrashCanAlbumsParams,
    GetRequestTrashCanParams,
    GetRequestUserEmailConsentParams,
    GetRequestUserEventsParams,
    GetRequestUserGrantsParams,
    GetRequestUserShouldUploadErrorLogsParams,
    GetRequestV2FullScreenCardsParams,
    GetRequestV2JobsChangesAndAsyncUploadStatusByJobUuidParams,
    GetRequestVerifyAuthParams,
    GrantInfoResponseDTO,
    HTTPValidationErrorDTO,
    InsertAlbumSeenEventDTO,
    InstantDTO,
    JobEventsRespDTO,
    JobListLightDTO,
    JobPermissionsResponseDTO,
    JobPrivacyModeResponseDTO,
    JobServiceRespDTO,
    JobSetPermissionsResponseDTO,
    JobUUIDListDTO,
    JwkRespDTO,
    KeepFilesQuotaExceededDTO,
    LocaleRespDTO,
    LoginIdentifierRespDTO,
    LoginTypeRespDTO,
    LogoutResponseDTO,
    MessageAndResultResponseDTO,
    MessageResponseDTO,
    NotificationModelDTO,
    PaymentInfoResponseDTO,
    PictureJobResponseDTO,
    PostCumulusEventsResponseDTO,
    PostRequestAccountAttributeBodies,
    PostRequestAccountAttributeParams,
    PostRequestAlbumGroupNameByGroupUuidParams,
    PostRequestAlbumGroupsParams,
    PostRequestAndroidValidatePurchaseParams,
    PostRequestAppleStoreReceiptParams,
    PostRequestApproveTosParams,
    PostRequestClientEventParams,
    PostRequestClientStatsBodies,
    PostRequestClientStatsParams,
    PostRequestConnectLoginTypeParams,
    PostRequestConnectRegisterLoginSuccessByUnused200,
    PostRequestDataProtectionConsentValuesBodies,
    PostRequestDataProtectionConsentValuesParams,
    PostRequestDataProtectionRequestAccountDeletionParams,
    PostRequestDebugEventsParams,
    PostRequestDevicesDeleteByDeviceIdParams,
    PostRequestDigitalLegacyParams,
    PostRequestEmptyTrashCanParams,
    PostRequestFullScreenCardsDismissByCardIdParams,
    PostRequestJobsAsyncUploadByJobUuidAndChecksum200,
    PostRequestJobsAsyncUploadByJobUuidAndChecksumBodiesTwo,
    PostRequestJobsAsyncUploadByJobUuidAndChecksumParams,
    PostRequestJobsAsyncUploadWithUploadIdByJobUuidAndUploadId200,
    PostRequestJobsAsyncUploadWithUploadIdByJobUuidAndUploadIdBodiesTwo,
    PostRequestJobsAsyncUploadWithUploadIdByJobUuidAndUploadIdParams,
    PostRequestJobsCoverByJobUuidParams,
    PostRequestJobsDeleteByJobUuidParams,
    PostRequestJobsExpirationDateByJobUuidParams,
    PostRequestJobsFilesAsArchiveByJobUuidParams,
    PostRequestJobsFilesByIdCommentsByJobUuidAndFileUuidParams,
    PostRequestJobsFilesByIdDeleteByJobUuidAndFileUuid200,
    PostRequestJobsFilesByIdDeleteByJobUuidAndFileUuidParams,
    PostRequestJobsFilesByIdReactionByJobUuidAndFileUuidParams,
    PostRequestJobsFilesByJobUuidBodiesTwo,
    PostRequestJobsFilesByJobUuidParams,
    PostRequestJobsFilesByUuidByJobUuidParams,
    PostRequestJobsFilesDedupByJobUuidParams,
    PostRequestJobsFilesFromTrashAsArchiveByJobUuidParams,
    PostRequestJobsFilesPropertiesByJobUuidAndFileUuidParams,
    PostRequestJobsKeepAllFilesByJobUuidParams,
    PostRequestJobsKeepFilesByJobUuid200,
    PostRequestJobsKeepFilesByJobUuidParams,
    PostRequestJobsMultiRollbackByJobUuidParams,
    PostRequestJobsNameByJobUuidParams,
    PostRequestJobsParams,
    PostRequestJobsPasswordByJobUuidParams,
    PostRequestJobsPermissionsByJobUuidParams,
    PostRequestJobsPrivacyModeByJobUuidParams,
    PostRequestJobsPublishByJobUuidParams,
    PostRequestJobsRecipientsByJobUuidParams,
    PostRequestJobsRollbackByJobUuidParams,
    PostRequestJobsRollbackJobByJobUuidParams,
    PostRequestJobsStorePasswordByJobUuidParams,
    PostRequestJobsSubscribeByJobUuidParams,
    PostRequestJobsUnsubscribeByJobUuidParams,
    PostRequestJobsUploadByJobUuid200,
    PostRequestJobsUploadByJobUuidBodiesTwo,
    PostRequestJobsUploadByJobUuidParams,
    PostRequestLogoutBackchannelParams,
    PostRequestLogoutParams,
    PostRequestNameParams,
    PostRequestProfilePictureBodiesTwo,
    PostRequestProfilePictureParams,
    PostRequestRollbackAlbumsParams,
    PostRequestRunTimeStatisticsParams,
    PostRequestSetTosReviewedParams,
    PostRequestStripeCreateCustomerParams,
    PostRequestStripeCreateSetupIntentParams,
    PostRequestStripeFinishSetupIntentParams,
    PostRequestStripePaymentMethodParams,
    PostRequestStripePurchase200,
    PostRequestStripePurchaseCancelBySubscriptionIdParams,
    PostRequestStripePurchaseParams,
    PostRequestStripePurchaseReactivateBySubscriptionIdParams,
    PostRequestStripeV1Purchase200,
    PostRequestStripeV1PurchaseParams,
    PostRequestTestAddGrantParams,
    PostRequestTestClientParamsParams,
    PostRequestTestDeleteStripeDataParams,
    PostRequestTestDeleteUserWithoutTelenorIdComsParams,
    PostRequestTestDigitalLegacyComsParams,
    PostRequestTestFullScreenCardParams,
    PostRequestTestLocaleParams,
    PostRequestTestResetTempFakeStipeCountryParams,
    PostRequestTestSearchRolloutByUsernameParams,
    PostRequestTestSendCollectionPushNotificationBodies,
    PostRequestTestSendCollectionPushNotificationParams,
    PostRequestTestSendPushNotificationBodies,
    PostRequestTestSendPushNotificationParams,
    PostRequestTestSunsetComsParams,
    PostRequestTestSunsetDeleteParams,
    PostRequestTestThrowawayUserBodies,
    PostRequestTestThrowawayUserParams,
    PostRequestTestThrowawayUserTemplateByJobUuidParams,
    PostRequestTestUserEventParams,
    PostRequestUpdateStripePurchaseParams,
    PostRequestUserErrorLogParams,
    PostRequestUserEventsAlbumSeenParams,
    PostRequestUserFeedbackParams,
    PostRequestUserShouldUploadErrorLogsParams,
    PostRequestUserStatisticsEventsParams,
    PostRequestWebClientStatsParams,
    PreFlightOauthStateDTO,
    PrivateShareRespDTO,
    ProductListResponseDTO,
    ProfilePictureUploadResponseDTO,
    PublishShareResponseDTO,
    PutRequestJobsAttributeByJobUuidAndAttributeParams,
    PutRequestJobsByJobUuidParams,
    PutRequestJobsFilesByIdCommentsByJobUuidAndFileUuidAndCommentUuidParams,
    PutRequestOptionsByNameParams,
    PutRequestRemoteVarsParams,
    PutRequestRemoteVarsRegistryParams,
    PutRequestStripeV1PurchaseParams,
    ReactionsResponseDTO,
    RecipientListDTO,
    RemoteVarDataDTO,
    RemoteVarDefinitionDataDTO,
    RestoreAlbumsResponseDTO,
    RollbackMultiResponseDTO,
    RunTimeStatsModelDTO,
    SaveTemplateRespDTO,
    SearchUserAttrsDTO,
    SendCsamRespDTO,
    SetEmailConsentDTO,
    SetJobDataResponseDTO,
    SetJobRecipientResponseDTO,
    SetReactionResponseDTO,
    ShareExpirationDTO,
    ShouldSendUserStatsEventsResponseDTO,
    StripeDeleteCCResponseDTO,
    StripeProductListResponseDTO,
    StripeSetupIntentCreateResponseDTO,
    StripeSubscriptionResponseDTO,
    SubFileStatusResponseDTO,
    SupportedExtensionsResponseDTO,
    ThrowawayAccountRespDTO,
    TimelineDaysDTO,
    TimelineMonthsDTO,
    TrashcanAlbumItemDTO,
    TrashcanFileItemDTO,
    UndoDeletionRequestRespDTO,
    UpdateStripePurchaseResponseDTO,
    UploadErrorLogResultDTO,
    UploadResponseDTO,
    UsageAndQuotaResponseDTO,
    UserEventsRespDTO,
    UserGroupCreationResponseDTO,
    UserGroupDefinitionDTO,
    UserGroupEnrollmentResponseDTO,
    UserGroupWithdrawalResponseDTO,
    UserGroupsDTO,
    UserInfoDTO,
    UserStatsEventsModelDTO,
    UsersResponseDTO,
    WebClientStatsDTO,
} from '../model'

import { customInstance } from '../../customInstance'
import type { ErrorType, BodyType } from '../../customInstance'
type SecondParameter<T extends (...args: never) => unknown> = Parameters<T>[1]

/**
 * This call allows the clients to send in the locale value. The backend
performs the check and fix locale operation. The client returns the
value after check and fix locale operation has been completed.

 * @summary Test Locale
 */
export const postRequestTestLocale = (
    params: PostRequestTestLocaleParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<LocaleRespDTO>(
        { url: `/test/locale`, method: 'POST', params, signal },
        options,
    )
}

export const getPostRequestTestLocaleMutationOptions = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestTestLocale>>,
        TError,
        { params: PostRequestTestLocaleParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestTestLocale>>,
    TError,
    { params: PostRequestTestLocaleParams },
    TContext
> => {
    const mutationKey = ['postRequestTestLocale']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postRequestTestLocale>>,
        { params: PostRequestTestLocaleParams }
    > = (props) => {
        const { params } = props ?? {}

        return postRequestTestLocale(params, requestOptions)
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestTestLocaleMutationResult = NonNullable<
    Awaited<ReturnType<typeof postRequestTestLocale>>
>

export type PostRequestTestLocaleMutationError =
    ErrorType<HTTPValidationErrorDTO>

/**
 * @summary Test Locale
 */
export const usePostRequestTestLocale = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestTestLocale>>,
        TError,
        { params: PostRequestTestLocaleParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestTestLocale>>,
    TError,
    { params: PostRequestTestLocaleParams },
    TContext
> => {
    const mutationOptions = getPostRequestTestLocaleMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * This test function deletes the original file in the cache + thumbnails
size 256-80 and -1280 For video it does not remove the encoded video
from cache

 * @summary Test Cache Remove
 */
export const postRequestTestCacheRemoveByJobUuidAndFileUuid = (
    jobUuid: string,
    fileUuid: string,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<unknown>(
        {
            url: `/test/cache-remove/${encodeURIComponent(String(jobUuid))}/${encodeURIComponent(String(fileUuid))}`,
            method: 'POST',
            signal,
        },
        options,
    )
}

export const getPostRequestTestCacheRemoveByJobUuidAndFileUuidMutationOptions =
    <TError = ErrorType<HTTPValidationErrorDTO>, TContext = unknown>(options?: {
        mutation?: UseMutationOptions<
            Awaited<
                ReturnType<
                    typeof postRequestTestCacheRemoveByJobUuidAndFileUuid
                >
            >,
            TError,
            { jobUuid: string; fileUuid: string },
            TContext
        >
        request?: SecondParameter<typeof customInstance>
    }): UseMutationOptions<
        Awaited<
            ReturnType<typeof postRequestTestCacheRemoveByJobUuidAndFileUuid>
        >,
        TError,
        { jobUuid: string; fileUuid: string },
        TContext
    > => {
        const mutationKey = ['postRequestTestCacheRemoveByJobUuidAndFileUuid']
        const { mutation: mutationOptions, request: requestOptions } = options
            ? options.mutation &&
              'mutationKey' in options.mutation &&
              options.mutation.mutationKey
                ? options
                : { ...options, mutation: { ...options.mutation, mutationKey } }
            : { mutation: { mutationKey }, request: undefined }

        const mutationFn: MutationFunction<
            Awaited<
                ReturnType<
                    typeof postRequestTestCacheRemoveByJobUuidAndFileUuid
                >
            >,
            { jobUuid: string; fileUuid: string }
        > = (props) => {
            const { jobUuid, fileUuid } = props ?? {}

            return postRequestTestCacheRemoveByJobUuidAndFileUuid(
                jobUuid,
                fileUuid,
                requestOptions,
            )
        }

        return { mutationFn, ...mutationOptions }
    }

export type PostRequestTestCacheRemoveByJobUuidAndFileUuidMutationResult =
    NonNullable<
        Awaited<
            ReturnType<typeof postRequestTestCacheRemoveByJobUuidAndFileUuid>
        >
    >

export type PostRequestTestCacheRemoveByJobUuidAndFileUuidMutationError =
    ErrorType<HTTPValidationErrorDTO>

/**
 * @summary Test Cache Remove
 */
export const usePostRequestTestCacheRemoveByJobUuidAndFileUuid = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<typeof postRequestTestCacheRemoveByJobUuidAndFileUuid>
        >,
        TError,
        { jobUuid: string; fileUuid: string },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestTestCacheRemoveByJobUuidAndFileUuid>>,
    TError,
    { jobUuid: string; fileUuid: string },
    TContext
> => {
    const mutationOptions =
        getPostRequestTestCacheRemoveByJobUuidAndFileUuidMutationOptions(
            options,
        )

    return useMutation(mutationOptions)
}

/**
 * TODO: Add information
  The meaning of this call is to check if a thumbnail is in cache and
  storage. But now we only test if the thumbnail is in storage.

 * @summary Verify Thumbs
 */
export const postRequestTestVerifyThumbsByJobUuidAndFileUuid = (
    jobUuid: string,
    fileUuid: string,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<unknown>(
        {
            url: `/test/verify-thumbs/${encodeURIComponent(String(jobUuid))}/${encodeURIComponent(String(fileUuid))}`,
            method: 'POST',
            signal,
        },
        options,
    )
}

export const getPostRequestTestVerifyThumbsByJobUuidAndFileUuidMutationOptions =
    <TError = ErrorType<HTTPValidationErrorDTO>, TContext = unknown>(options?: {
        mutation?: UseMutationOptions<
            Awaited<
                ReturnType<
                    typeof postRequestTestVerifyThumbsByJobUuidAndFileUuid
                >
            >,
            TError,
            { jobUuid: string; fileUuid: string },
            TContext
        >
        request?: SecondParameter<typeof customInstance>
    }): UseMutationOptions<
        Awaited<
            ReturnType<typeof postRequestTestVerifyThumbsByJobUuidAndFileUuid>
        >,
        TError,
        { jobUuid: string; fileUuid: string },
        TContext
    > => {
        const mutationKey = ['postRequestTestVerifyThumbsByJobUuidAndFileUuid']
        const { mutation: mutationOptions, request: requestOptions } = options
            ? options.mutation &&
              'mutationKey' in options.mutation &&
              options.mutation.mutationKey
                ? options
                : { ...options, mutation: { ...options.mutation, mutationKey } }
            : { mutation: { mutationKey }, request: undefined }

        const mutationFn: MutationFunction<
            Awaited<
                ReturnType<
                    typeof postRequestTestVerifyThumbsByJobUuidAndFileUuid
                >
            >,
            { jobUuid: string; fileUuid: string }
        > = (props) => {
            const { jobUuid, fileUuid } = props ?? {}

            return postRequestTestVerifyThumbsByJobUuidAndFileUuid(
                jobUuid,
                fileUuid,
                requestOptions,
            )
        }

        return { mutationFn, ...mutationOptions }
    }

export type PostRequestTestVerifyThumbsByJobUuidAndFileUuidMutationResult =
    NonNullable<
        Awaited<
            ReturnType<typeof postRequestTestVerifyThumbsByJobUuidAndFileUuid>
        >
    >

export type PostRequestTestVerifyThumbsByJobUuidAndFileUuidMutationError =
    ErrorType<HTTPValidationErrorDTO>

/**
 * @summary Verify Thumbs
 */
export const usePostRequestTestVerifyThumbsByJobUuidAndFileUuid = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<typeof postRequestTestVerifyThumbsByJobUuidAndFileUuid>
        >,
        TError,
        { jobUuid: string; fileUuid: string },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestTestVerifyThumbsByJobUuidAndFileUuid>>,
    TError,
    { jobUuid: string; fileUuid: string },
    TContext
> => {
    const mutationOptions =
        getPostRequestTestVerifyThumbsByJobUuidAndFileUuidMutationOptions(
            options,
        )

    return useMutation(mutationOptions)
}

/**
 * @summary Verify Thumbs Cache
 */
export const postRequestTestVerifyThumbsCacheByJobUuidAndFileUuid = (
    jobUuid: string,
    fileUuid: string,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<unknown>(
        {
            url: `/test/verify-thumbs-cache/${encodeURIComponent(String(jobUuid))}/${encodeURIComponent(String(fileUuid))}`,
            method: 'POST',
            signal,
        },
        options,
    )
}

export const getPostRequestTestVerifyThumbsCacheByJobUuidAndFileUuidMutationOptions =
    <TError = ErrorType<HTTPValidationErrorDTO>, TContext = unknown>(options?: {
        mutation?: UseMutationOptions<
            Awaited<
                ReturnType<
                    typeof postRequestTestVerifyThumbsCacheByJobUuidAndFileUuid
                >
            >,
            TError,
            { jobUuid: string; fileUuid: string },
            TContext
        >
        request?: SecondParameter<typeof customInstance>
    }): UseMutationOptions<
        Awaited<
            ReturnType<
                typeof postRequestTestVerifyThumbsCacheByJobUuidAndFileUuid
            >
        >,
        TError,
        { jobUuid: string; fileUuid: string },
        TContext
    > => {
        const mutationKey = [
            'postRequestTestVerifyThumbsCacheByJobUuidAndFileUuid',
        ]
        const { mutation: mutationOptions, request: requestOptions } = options
            ? options.mutation &&
              'mutationKey' in options.mutation &&
              options.mutation.mutationKey
                ? options
                : { ...options, mutation: { ...options.mutation, mutationKey } }
            : { mutation: { mutationKey }, request: undefined }

        const mutationFn: MutationFunction<
            Awaited<
                ReturnType<
                    typeof postRequestTestVerifyThumbsCacheByJobUuidAndFileUuid
                >
            >,
            { jobUuid: string; fileUuid: string }
        > = (props) => {
            const { jobUuid, fileUuid } = props ?? {}

            return postRequestTestVerifyThumbsCacheByJobUuidAndFileUuid(
                jobUuid,
                fileUuid,
                requestOptions,
            )
        }

        return { mutationFn, ...mutationOptions }
    }

export type PostRequestTestVerifyThumbsCacheByJobUuidAndFileUuidMutationResult =
    NonNullable<
        Awaited<
            ReturnType<
                typeof postRequestTestVerifyThumbsCacheByJobUuidAndFileUuid
            >
        >
    >

export type PostRequestTestVerifyThumbsCacheByJobUuidAndFileUuidMutationError =
    ErrorType<HTTPValidationErrorDTO>

/**
 * @summary Verify Thumbs Cache
 */
export const usePostRequestTestVerifyThumbsCacheByJobUuidAndFileUuid = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<
                typeof postRequestTestVerifyThumbsCacheByJobUuidAndFileUuid
            >
        >,
        TError,
        { jobUuid: string; fileUuid: string },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<
        ReturnType<typeof postRequestTestVerifyThumbsCacheByJobUuidAndFileUuid>
    >,
    TError,
    { jobUuid: string; fileUuid: string },
    TContext
> => {
    const mutationOptions =
        getPostRequestTestVerifyThumbsCacheByJobUuidAndFileUuidMutationOptions(
            options,
        )

    return useMutation(mutationOptions)
}

/**
 * @summary Verify Thumbs Storage
 */
export const postRequestTestVerifyThumbsStorageByJobUuidAndFileUuid = (
    jobUuid: string,
    fileUuid: string,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<unknown>(
        {
            url: `/test/verify-thumbs-storage/${encodeURIComponent(String(jobUuid))}/${encodeURIComponent(String(fileUuid))}`,
            method: 'POST',
            signal,
        },
        options,
    )
}

export const getPostRequestTestVerifyThumbsStorageByJobUuidAndFileUuidMutationOptions =
    <TError = ErrorType<HTTPValidationErrorDTO>, TContext = unknown>(options?: {
        mutation?: UseMutationOptions<
            Awaited<
                ReturnType<
                    typeof postRequestTestVerifyThumbsStorageByJobUuidAndFileUuid
                >
            >,
            TError,
            { jobUuid: string; fileUuid: string },
            TContext
        >
        request?: SecondParameter<typeof customInstance>
    }): UseMutationOptions<
        Awaited<
            ReturnType<
                typeof postRequestTestVerifyThumbsStorageByJobUuidAndFileUuid
            >
        >,
        TError,
        { jobUuid: string; fileUuid: string },
        TContext
    > => {
        const mutationKey = [
            'postRequestTestVerifyThumbsStorageByJobUuidAndFileUuid',
        ]
        const { mutation: mutationOptions, request: requestOptions } = options
            ? options.mutation &&
              'mutationKey' in options.mutation &&
              options.mutation.mutationKey
                ? options
                : { ...options, mutation: { ...options.mutation, mutationKey } }
            : { mutation: { mutationKey }, request: undefined }

        const mutationFn: MutationFunction<
            Awaited<
                ReturnType<
                    typeof postRequestTestVerifyThumbsStorageByJobUuidAndFileUuid
                >
            >,
            { jobUuid: string; fileUuid: string }
        > = (props) => {
            const { jobUuid, fileUuid } = props ?? {}

            return postRequestTestVerifyThumbsStorageByJobUuidAndFileUuid(
                jobUuid,
                fileUuid,
                requestOptions,
            )
        }

        return { mutationFn, ...mutationOptions }
    }

export type PostRequestTestVerifyThumbsStorageByJobUuidAndFileUuidMutationResult =
    NonNullable<
        Awaited<
            ReturnType<
                typeof postRequestTestVerifyThumbsStorageByJobUuidAndFileUuid
            >
        >
    >

export type PostRequestTestVerifyThumbsStorageByJobUuidAndFileUuidMutationError =
    ErrorType<HTTPValidationErrorDTO>

/**
 * @summary Verify Thumbs Storage
 */
export const usePostRequestTestVerifyThumbsStorageByJobUuidAndFileUuid = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<
                typeof postRequestTestVerifyThumbsStorageByJobUuidAndFileUuid
            >
        >,
        TError,
        { jobUuid: string; fileUuid: string },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<
        ReturnType<
            typeof postRequestTestVerifyThumbsStorageByJobUuidAndFileUuid
        >
    >,
    TError,
    { jobUuid: string; fileUuid: string },
    TContext
> => {
    const mutationOptions =
        getPostRequestTestVerifyThumbsStorageByJobUuidAndFileUuidMutationOptions(
            options,
        )

    return useMutation(mutationOptions)
}

/**
 * @summary Test Delete All Files
 */
export const postRequestTestJobDeleteAllFilesByJobUuid = (
    jobUuid: string,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<DeleteFilesRespDTO>(
        {
            url: `/test/job/${encodeURIComponent(String(jobUuid))}/delete-all-files`,
            method: 'POST',
            signal,
        },
        options,
    )
}

export const getPostRequestTestJobDeleteAllFilesByJobUuidMutationOptions = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestTestJobDeleteAllFilesByJobUuid>>,
        TError,
        { jobUuid: string },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestTestJobDeleteAllFilesByJobUuid>>,
    TError,
    { jobUuid: string },
    TContext
> => {
    const mutationKey = ['postRequestTestJobDeleteAllFilesByJobUuid']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postRequestTestJobDeleteAllFilesByJobUuid>>,
        { jobUuid: string }
    > = (props) => {
        const { jobUuid } = props ?? {}

        return postRequestTestJobDeleteAllFilesByJobUuid(
            jobUuid,
            requestOptions,
        )
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestTestJobDeleteAllFilesByJobUuidMutationResult =
    NonNullable<
        Awaited<ReturnType<typeof postRequestTestJobDeleteAllFilesByJobUuid>>
    >

export type PostRequestTestJobDeleteAllFilesByJobUuidMutationError =
    ErrorType<HTTPValidationErrorDTO>

/**
 * @summary Test Delete All Files
 */
export const usePostRequestTestJobDeleteAllFilesByJobUuid = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestTestJobDeleteAllFilesByJobUuid>>,
        TError,
        { jobUuid: string },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestTestJobDeleteAllFilesByJobUuid>>,
    TError,
    { jobUuid: string },
    TContext
> => {
    const mutationOptions =
        getPostRequestTestJobDeleteAllFilesByJobUuidMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary Make Throwaway User
 */
export const postRequestTestThrowawayUser = (
    postRequestTestThrowawayUserBodies: BodyType<PostRequestTestThrowawayUserBodies>,
    params: PostRequestTestThrowawayUserParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<ThrowawayAccountRespDTO>(
        {
            url: `/test/throwaway_user`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: postRequestTestThrowawayUserBodies,
            params,
            signal,
        },
        options,
    )
}

export const getPostRequestTestThrowawayUserMutationOptions = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestTestThrowawayUser>>,
        TError,
        {
            data: BodyType<PostRequestTestThrowawayUserBodies>
            params: PostRequestTestThrowawayUserParams
        },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestTestThrowawayUser>>,
    TError,
    {
        data: BodyType<PostRequestTestThrowawayUserBodies>
        params: PostRequestTestThrowawayUserParams
    },
    TContext
> => {
    const mutationKey = ['postRequestTestThrowawayUser']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postRequestTestThrowawayUser>>,
        {
            data: BodyType<PostRequestTestThrowawayUserBodies>
            params: PostRequestTestThrowawayUserParams
        }
    > = (props) => {
        const { data, params } = props ?? {}

        return postRequestTestThrowawayUser(data, params, requestOptions)
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestTestThrowawayUserMutationResult = NonNullable<
    Awaited<ReturnType<typeof postRequestTestThrowawayUser>>
>
export type PostRequestTestThrowawayUserMutationBody =
    BodyType<PostRequestTestThrowawayUserBodies>
export type PostRequestTestThrowawayUserMutationError =
    ErrorType<HTTPValidationErrorDTO>

/**
 * @summary Make Throwaway User
 */
export const usePostRequestTestThrowawayUser = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestTestThrowawayUser>>,
        TError,
        {
            data: BodyType<PostRequestTestThrowawayUserBodies>
            params: PostRequestTestThrowawayUserParams
        },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestTestThrowawayUser>>,
    TError,
    {
        data: BodyType<PostRequestTestThrowawayUserBodies>
        params: PostRequestTestThrowawayUserParams
    },
    TContext
> => {
    const mutationOptions =
        getPostRequestTestThrowawayUserMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary Save Throwaway Template
 */
export const postRequestTestThrowawayUserTemplateByJobUuid = (
    jobUuid: string,
    params: PostRequestTestThrowawayUserTemplateByJobUuidParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<SaveTemplateRespDTO>(
        {
            url: `/test/throwaway_user_template/${encodeURIComponent(String(jobUuid))}`,
            method: 'POST',
            params,
            signal,
        },
        options,
    )
}

export const getPostRequestTestThrowawayUserTemplateByJobUuidMutationOptions = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<typeof postRequestTestThrowawayUserTemplateByJobUuid>
        >,
        TError,
        {
            jobUuid: string
            params: PostRequestTestThrowawayUserTemplateByJobUuidParams
        },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestTestThrowawayUserTemplateByJobUuid>>,
    TError,
    {
        jobUuid: string
        params: PostRequestTestThrowawayUserTemplateByJobUuidParams
    },
    TContext
> => {
    const mutationKey = ['postRequestTestThrowawayUserTemplateByJobUuid']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<
            ReturnType<typeof postRequestTestThrowawayUserTemplateByJobUuid>
        >,
        {
            jobUuid: string
            params: PostRequestTestThrowawayUserTemplateByJobUuidParams
        }
    > = (props) => {
        const { jobUuid, params } = props ?? {}

        return postRequestTestThrowawayUserTemplateByJobUuid(
            jobUuid,
            params,
            requestOptions,
        )
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestTestThrowawayUserTemplateByJobUuidMutationResult =
    NonNullable<
        Awaited<
            ReturnType<typeof postRequestTestThrowawayUserTemplateByJobUuid>
        >
    >

export type PostRequestTestThrowawayUserTemplateByJobUuidMutationError =
    ErrorType<HTTPValidationErrorDTO>

/**
 * @summary Save Throwaway Template
 */
export const usePostRequestTestThrowawayUserTemplateByJobUuid = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<typeof postRequestTestThrowawayUserTemplateByJobUuid>
        >,
        TError,
        {
            jobUuid: string
            params: PostRequestTestThrowawayUserTemplateByJobUuidParams
        },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestTestThrowawayUserTemplateByJobUuid>>,
    TError,
    {
        jobUuid: string
        params: PostRequestTestThrowawayUserTemplateByJobUuidParams
    },
    TContext
> => {
    const mutationOptions =
        getPostRequestTestThrowawayUserTemplateByJobUuidMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary Delete Throwaway Template
 */
export const deleteRequestTestThrowawayUserTemplate = (
    params: DeleteRequestTestThrowawayUserTemplateParams,
    options?: SecondParameter<typeof customInstance>,
) => {
    return customInstance<DeleteTemplateRespDTO>(
        { url: `/test/throwaway_user_template`, method: 'DELETE', params },
        options,
    )
}

export const getDeleteRequestTestThrowawayUserTemplateMutationOptions = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof deleteRequestTestThrowawayUserTemplate>>,
        TError,
        { params: DeleteRequestTestThrowawayUserTemplateParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof deleteRequestTestThrowawayUserTemplate>>,
    TError,
    { params: DeleteRequestTestThrowawayUserTemplateParams },
    TContext
> => {
    const mutationKey = ['deleteRequestTestThrowawayUserTemplate']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof deleteRequestTestThrowawayUserTemplate>>,
        { params: DeleteRequestTestThrowawayUserTemplateParams }
    > = (props) => {
        const { params } = props ?? {}

        return deleteRequestTestThrowawayUserTemplate(params, requestOptions)
    }

    return { mutationFn, ...mutationOptions }
}

export type DeleteRequestTestThrowawayUserTemplateMutationResult = NonNullable<
    Awaited<ReturnType<typeof deleteRequestTestThrowawayUserTemplate>>
>

export type DeleteRequestTestThrowawayUserTemplateMutationError =
    ErrorType<HTTPValidationErrorDTO>

/**
 * @summary Delete Throwaway Template
 */
export const useDeleteRequestTestThrowawayUserTemplate = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof deleteRequestTestThrowawayUserTemplate>>,
        TError,
        { params: DeleteRequestTestThrowawayUserTemplateParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof deleteRequestTestThrowawayUserTemplate>>,
    TError,
    { params: DeleteRequestTestThrowawayUserTemplateParams },
    TContext
> => {
    const mutationOptions =
        getDeleteRequestTestThrowawayUserTemplateMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary Rollout User To Search
 */
export const postRequestTestSearchRolloutByUsername = (
    username: string,
    params?: PostRequestTestSearchRolloutByUsernameParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<unknown>(
        {
            url: `/test/search_rollout/${encodeURIComponent(String(username))}`,
            method: 'POST',
            params,
            signal,
        },
        options,
    )
}

export const getPostRequestTestSearchRolloutByUsernameMutationOptions = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestTestSearchRolloutByUsername>>,
        TError,
        {
            username: string
            params?: PostRequestTestSearchRolloutByUsernameParams
        },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestTestSearchRolloutByUsername>>,
    TError,
    { username: string; params?: PostRequestTestSearchRolloutByUsernameParams },
    TContext
> => {
    const mutationKey = ['postRequestTestSearchRolloutByUsername']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postRequestTestSearchRolloutByUsername>>,
        {
            username: string
            params?: PostRequestTestSearchRolloutByUsernameParams
        }
    > = (props) => {
        const { username, params } = props ?? {}

        return postRequestTestSearchRolloutByUsername(
            username,
            params,
            requestOptions,
        )
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestTestSearchRolloutByUsernameMutationResult = NonNullable<
    Awaited<ReturnType<typeof postRequestTestSearchRolloutByUsername>>
>

export type PostRequestTestSearchRolloutByUsernameMutationError =
    ErrorType<HTTPValidationErrorDTO>

/**
 * @summary Rollout User To Search
 */
export const usePostRequestTestSearchRolloutByUsername = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestTestSearchRolloutByUsername>>,
        TError,
        {
            username: string
            params?: PostRequestTestSearchRolloutByUsernameParams
        },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestTestSearchRolloutByUsername>>,
    TError,
    { username: string; params?: PostRequestTestSearchRolloutByUsernameParams },
    TContext
> => {
    const mutationOptions =
        getPostRequestTestSearchRolloutByUsernameMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * Allow developers to send push notification to device via the backend

 * @summary Send Push Notification
 */
export const postRequestTestSendPushNotification = (
    postRequestTestSendPushNotificationBodies: BodyType<PostRequestTestSendPushNotificationBodies>,
    params?: PostRequestTestSendPushNotificationParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<unknown>(
        {
            url: `/test/send_push_notification`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: postRequestTestSendPushNotificationBodies,
            params,
            signal,
        },
        options,
    )
}

export const getPostRequestTestSendPushNotificationMutationOptions = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestTestSendPushNotification>>,
        TError,
        {
            data: BodyType<PostRequestTestSendPushNotificationBodies>
            params?: PostRequestTestSendPushNotificationParams
        },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestTestSendPushNotification>>,
    TError,
    {
        data: BodyType<PostRequestTestSendPushNotificationBodies>
        params?: PostRequestTestSendPushNotificationParams
    },
    TContext
> => {
    const mutationKey = ['postRequestTestSendPushNotification']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postRequestTestSendPushNotification>>,
        {
            data: BodyType<PostRequestTestSendPushNotificationBodies>
            params?: PostRequestTestSendPushNotificationParams
        }
    > = (props) => {
        const { data, params } = props ?? {}

        return postRequestTestSendPushNotification(data, params, requestOptions)
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestTestSendPushNotificationMutationResult = NonNullable<
    Awaited<ReturnType<typeof postRequestTestSendPushNotification>>
>
export type PostRequestTestSendPushNotificationMutationBody =
    BodyType<PostRequestTestSendPushNotificationBodies>
export type PostRequestTestSendPushNotificationMutationError =
    ErrorType<HTTPValidationErrorDTO>

/**
 * @summary Send Push Notification
 */
export const usePostRequestTestSendPushNotification = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestTestSendPushNotification>>,
        TError,
        {
            data: BodyType<PostRequestTestSendPushNotificationBodies>
            params?: PostRequestTestSendPushNotificationParams
        },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestTestSendPushNotification>>,
    TError,
    {
        data: BodyType<PostRequestTestSendPushNotificationBodies>
        params?: PostRequestTestSendPushNotificationParams
    },
    TContext
> => {
    const mutationOptions =
        getPostRequestTestSendPushNotificationMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * Allow developers to send push notification to device for different collections via the backend

 * @summary Send Collection Push
 */
export const postRequestTestSendCollectionPushNotification = (
    postRequestTestSendCollectionPushNotificationBodies: BodyType<PostRequestTestSendCollectionPushNotificationBodies>,
    params?: PostRequestTestSendCollectionPushNotificationParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<unknown>(
        {
            url: `/test/send_collection_push_notification`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: postRequestTestSendCollectionPushNotificationBodies,
            params,
            signal,
        },
        options,
    )
}

export const getPostRequestTestSendCollectionPushNotificationMutationOptions = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<typeof postRequestTestSendCollectionPushNotification>
        >,
        TError,
        {
            data: BodyType<PostRequestTestSendCollectionPushNotificationBodies>
            params?: PostRequestTestSendCollectionPushNotificationParams
        },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestTestSendCollectionPushNotification>>,
    TError,
    {
        data: BodyType<PostRequestTestSendCollectionPushNotificationBodies>
        params?: PostRequestTestSendCollectionPushNotificationParams
    },
    TContext
> => {
    const mutationKey = ['postRequestTestSendCollectionPushNotification']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<
            ReturnType<typeof postRequestTestSendCollectionPushNotification>
        >,
        {
            data: BodyType<PostRequestTestSendCollectionPushNotificationBodies>
            params?: PostRequestTestSendCollectionPushNotificationParams
        }
    > = (props) => {
        const { data, params } = props ?? {}

        return postRequestTestSendCollectionPushNotification(
            data,
            params,
            requestOptions,
        )
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestTestSendCollectionPushNotificationMutationResult =
    NonNullable<
        Awaited<
            ReturnType<typeof postRequestTestSendCollectionPushNotification>
        >
    >
export type PostRequestTestSendCollectionPushNotificationMutationBody =
    BodyType<PostRequestTestSendCollectionPushNotificationBodies>
export type PostRequestTestSendCollectionPushNotificationMutationError =
    ErrorType<HTTPValidationErrorDTO>

/**
 * @summary Send Collection Push
 */
export const usePostRequestTestSendCollectionPushNotification = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<typeof postRequestTestSendCollectionPushNotification>
        >,
        TError,
        {
            data: BodyType<PostRequestTestSendCollectionPushNotificationBodies>
            params?: PostRequestTestSendCollectionPushNotificationParams
        },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestTestSendCollectionPushNotification>>,
    TError,
    {
        data: BodyType<PostRequestTestSendCollectionPushNotificationBodies>
        params?: PostRequestTestSendCollectionPushNotificationParams
    },
    TContext
> => {
    const mutationOptions =
        getPostRequestTestSendCollectionPushNotificationMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary Erase Store Sub
 */
export const deleteRequestTestEraseStoreSub = (
    params: DeleteRequestTestEraseStoreSubParams,
    options?: SecondParameter<typeof customInstance>,
) => {
    return customInstance<unknown>(
        { url: `/test/erase_store_sub`, method: 'DELETE', params },
        options,
    )
}

export const getDeleteRequestTestEraseStoreSubMutationOptions = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof deleteRequestTestEraseStoreSub>>,
        TError,
        { params: DeleteRequestTestEraseStoreSubParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof deleteRequestTestEraseStoreSub>>,
    TError,
    { params: DeleteRequestTestEraseStoreSubParams },
    TContext
> => {
    const mutationKey = ['deleteRequestTestEraseStoreSub']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof deleteRequestTestEraseStoreSub>>,
        { params: DeleteRequestTestEraseStoreSubParams }
    > = (props) => {
        const { params } = props ?? {}

        return deleteRequestTestEraseStoreSub(params, requestOptions)
    }

    return { mutationFn, ...mutationOptions }
}

export type DeleteRequestTestEraseStoreSubMutationResult = NonNullable<
    Awaited<ReturnType<typeof deleteRequestTestEraseStoreSub>>
>

export type DeleteRequestTestEraseStoreSubMutationError =
    ErrorType<HTTPValidationErrorDTO>

/**
 * @summary Erase Store Sub
 */
export const useDeleteRequestTestEraseStoreSub = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof deleteRequestTestEraseStoreSub>>,
        TError,
        { params: DeleteRequestTestEraseStoreSubParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof deleteRequestTestEraseStoreSub>>,
    TError,
    { params: DeleteRequestTestEraseStoreSubParams },
    TContext
> => {
    const mutationOptions =
        getDeleteRequestTestEraseStoreSubMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary Remove Non Default Grants
 */
export const deleteRequestTestRemoveNonDefaultGrants = (
    options?: SecondParameter<typeof customInstance>,
) => {
    return customInstance<DeleteRequestTestRemoveNonDefaultGrants200>(
        { url: `/test/remove_non_default_grants`, method: 'DELETE' },
        options,
    )
}

export const getDeleteRequestTestRemoveNonDefaultGrantsMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof deleteRequestTestRemoveNonDefaultGrants>>,
        TError,
        void,
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof deleteRequestTestRemoveNonDefaultGrants>>,
    TError,
    void,
    TContext
> => {
    const mutationKey = ['deleteRequestTestRemoveNonDefaultGrants']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof deleteRequestTestRemoveNonDefaultGrants>>,
        void
    > = () => {
        return deleteRequestTestRemoveNonDefaultGrants(requestOptions)
    }

    return { mutationFn, ...mutationOptions }
}

export type DeleteRequestTestRemoveNonDefaultGrantsMutationResult = NonNullable<
    Awaited<ReturnType<typeof deleteRequestTestRemoveNonDefaultGrants>>
>

export type DeleteRequestTestRemoveNonDefaultGrantsMutationError =
    ErrorType<unknown>

/**
 * @summary Remove Non Default Grants
 */
export const useDeleteRequestTestRemoveNonDefaultGrants = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof deleteRequestTestRemoveNonDefaultGrants>>,
        TError,
        void,
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof deleteRequestTestRemoveNonDefaultGrants>>,
    TError,
    void,
    TContext
> => {
    const mutationOptions =
        getDeleteRequestTestRemoveNonDefaultGrantsMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary Add Grant
 */
export const postRequestTestAddGrant = (
    params: PostRequestTestAddGrantParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<MessageResponseDTO>(
        { url: `/test/add_grant`, method: 'POST', params, signal },
        options,
    )
}

export const getPostRequestTestAddGrantMutationOptions = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestTestAddGrant>>,
        TError,
        { params: PostRequestTestAddGrantParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestTestAddGrant>>,
    TError,
    { params: PostRequestTestAddGrantParams },
    TContext
> => {
    const mutationKey = ['postRequestTestAddGrant']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postRequestTestAddGrant>>,
        { params: PostRequestTestAddGrantParams }
    > = (props) => {
        const { params } = props ?? {}

        return postRequestTestAddGrant(params, requestOptions)
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestTestAddGrantMutationResult = NonNullable<
    Awaited<ReturnType<typeof postRequestTestAddGrant>>
>

export type PostRequestTestAddGrantMutationError =
    ErrorType<HTTPValidationErrorDTO>

/**
 * @summary Add Grant
 */
export const usePostRequestTestAddGrant = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestTestAddGrant>>,
        TError,
        { params: PostRequestTestAddGrantParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestTestAddGrant>>,
    TError,
    { params: PostRequestTestAddGrantParams },
    TContext
> => {
    const mutationOptions = getPostRequestTestAddGrantMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary Get Login Identifier
 */
export const getRequestTestLoginIdentifier = (
    params: GetRequestTestLoginIdentifierParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<LoginIdentifierRespDTO>(
        { url: `/test/login_identifier`, method: 'GET', params, signal },
        options,
    )
}

export const getGetRequestTestLoginIdentifierQueryKey = (
    params: GetRequestTestLoginIdentifierParams,
) => {
    return [`/test/login_identifier`, ...(params ? [params] : [])] as const
}

export const getGetRequestTestLoginIdentifierQueryOptions = <
    TData = Awaited<ReturnType<typeof getRequestTestLoginIdentifier>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestTestLoginIdentifierParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestTestLoginIdentifier>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {}

    const queryKey =
        queryOptions?.queryKey ??
        getGetRequestTestLoginIdentifierQueryKey(params)

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getRequestTestLoginIdentifier>>
    > = ({ signal }) =>
        getRequestTestLoginIdentifier(params, requestOptions, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof getRequestTestLoginIdentifier>>,
        TError,
        TData
    > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRequestTestLoginIdentifierQueryResult = NonNullable<
    Awaited<ReturnType<typeof getRequestTestLoginIdentifier>>
>
export type GetRequestTestLoginIdentifierQueryError =
    ErrorType<HTTPValidationErrorDTO>

export function useGetRequestTestLoginIdentifier<
    TData = Awaited<ReturnType<typeof getRequestTestLoginIdentifier>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestTestLoginIdentifierParams,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestTestLoginIdentifier>>,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestTestLoginIdentifier>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestTestLoginIdentifier>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestTestLoginIdentifier<
    TData = Awaited<ReturnType<typeof getRequestTestLoginIdentifier>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestTestLoginIdentifierParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestTestLoginIdentifier>>,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestTestLoginIdentifier>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestTestLoginIdentifier>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestTestLoginIdentifier<
    TData = Awaited<ReturnType<typeof getRequestTestLoginIdentifier>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestTestLoginIdentifierParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestTestLoginIdentifier>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get Login Identifier
 */

export function useGetRequestTestLoginIdentifier<
    TData = Awaited<ReturnType<typeof getRequestTestLoginIdentifier>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestTestLoginIdentifierParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestTestLoginIdentifier>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions = getGetRequestTestLoginIdentifierQueryOptions(
        params,
        options,
    )

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * @summary Create Private Share
 */
export const postRequestTestPrivateShare = (
    bodyCreatePrivateShareTestPrivateSharePostDTO: BodyType<BodyCreatePrivateShareTestPrivateSharePostDTO>,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<PrivateShareRespDTO>(
        {
            url: `/test/private_share`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: bodyCreatePrivateShareTestPrivateSharePostDTO,
            signal,
        },
        options,
    )
}

export const getPostRequestTestPrivateShareMutationOptions = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestTestPrivateShare>>,
        TError,
        { data: BodyType<BodyCreatePrivateShareTestPrivateSharePostDTO> },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestTestPrivateShare>>,
    TError,
    { data: BodyType<BodyCreatePrivateShareTestPrivateSharePostDTO> },
    TContext
> => {
    const mutationKey = ['postRequestTestPrivateShare']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postRequestTestPrivateShare>>,
        { data: BodyType<BodyCreatePrivateShareTestPrivateSharePostDTO> }
    > = (props) => {
        const { data } = props ?? {}

        return postRequestTestPrivateShare(data, requestOptions)
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestTestPrivateShareMutationResult = NonNullable<
    Awaited<ReturnType<typeof postRequestTestPrivateShare>>
>
export type PostRequestTestPrivateShareMutationBody =
    BodyType<BodyCreatePrivateShareTestPrivateSharePostDTO>
export type PostRequestTestPrivateShareMutationError =
    ErrorType<HTTPValidationErrorDTO>

/**
 * @summary Create Private Share
 */
export const usePostRequestTestPrivateShare = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestTestPrivateShare>>,
        TError,
        { data: BodyType<BodyCreatePrivateShareTestPrivateSharePostDTO> },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestTestPrivateShare>>,
    TError,
    { data: BodyType<BodyCreatePrivateShareTestPrivateSharePostDTO> },
    TContext
> => {
    const mutationOptions =
        getPostRequestTestPrivateShareMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary Undo Requested Delete
 */
export const postRequestTestUndoRequestedDeleteByUsername = (
    username: string,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<UndoDeletionRequestRespDTO>(
        {
            url: `/test/undo_requested_delete/${encodeURIComponent(String(username))}`,
            method: 'POST',
            signal,
        },
        options,
    )
}

export const getPostRequestTestUndoRequestedDeleteByUsernameMutationOptions = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<typeof postRequestTestUndoRequestedDeleteByUsername>
        >,
        TError,
        { username: string },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestTestUndoRequestedDeleteByUsername>>,
    TError,
    { username: string },
    TContext
> => {
    const mutationKey = ['postRequestTestUndoRequestedDeleteByUsername']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<
            ReturnType<typeof postRequestTestUndoRequestedDeleteByUsername>
        >,
        { username: string }
    > = (props) => {
        const { username } = props ?? {}

        return postRequestTestUndoRequestedDeleteByUsername(
            username,
            requestOptions,
        )
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestTestUndoRequestedDeleteByUsernameMutationResult =
    NonNullable<
        Awaited<ReturnType<typeof postRequestTestUndoRequestedDeleteByUsername>>
    >

export type PostRequestTestUndoRequestedDeleteByUsernameMutationError =
    ErrorType<HTTPValidationErrorDTO>

/**
 * @summary Undo Requested Delete
 */
export const usePostRequestTestUndoRequestedDeleteByUsername = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<typeof postRequestTestUndoRequestedDeleteByUsername>
        >,
        TError,
        { username: string },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestTestUndoRequestedDeleteByUsername>>,
    TError,
    { username: string },
    TContext
> => {
    const mutationOptions =
        getPostRequestTestUndoRequestedDeleteByUsernameMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary Create Dummy Signed Jwt
 */
export const getRequestTestCreateDummySignedJwt = (
    params: GetRequestTestCreateDummySignedJwtParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<DummyPoliceJwtRespDTO>(
        { url: `/test/create_dummy_signed_jwt`, method: 'GET', params, signal },
        options,
    )
}

export const getGetRequestTestCreateDummySignedJwtQueryKey = (
    params: GetRequestTestCreateDummySignedJwtParams,
) => {
    return [
        `/test/create_dummy_signed_jwt`,
        ...(params ? [params] : []),
    ] as const
}

export const getGetRequestTestCreateDummySignedJwtQueryOptions = <
    TData = Awaited<ReturnType<typeof getRequestTestCreateDummySignedJwt>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestTestCreateDummySignedJwtParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestTestCreateDummySignedJwt>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {}

    const queryKey =
        queryOptions?.queryKey ??
        getGetRequestTestCreateDummySignedJwtQueryKey(params)

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getRequestTestCreateDummySignedJwt>>
    > = ({ signal }) =>
        getRequestTestCreateDummySignedJwt(params, requestOptions, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof getRequestTestCreateDummySignedJwt>>,
        TError,
        TData
    > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRequestTestCreateDummySignedJwtQueryResult = NonNullable<
    Awaited<ReturnType<typeof getRequestTestCreateDummySignedJwt>>
>
export type GetRequestTestCreateDummySignedJwtQueryError =
    ErrorType<HTTPValidationErrorDTO>

export function useGetRequestTestCreateDummySignedJwt<
    TData = Awaited<ReturnType<typeof getRequestTestCreateDummySignedJwt>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestTestCreateDummySignedJwtParams,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestTestCreateDummySignedJwt>>,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<
                        ReturnType<typeof getRequestTestCreateDummySignedJwt>
                    >,
                    TError,
                    Awaited<
                        ReturnType<typeof getRequestTestCreateDummySignedJwt>
                    >
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestTestCreateDummySignedJwt<
    TData = Awaited<ReturnType<typeof getRequestTestCreateDummySignedJwt>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestTestCreateDummySignedJwtParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestTestCreateDummySignedJwt>>,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<
                        ReturnType<typeof getRequestTestCreateDummySignedJwt>
                    >,
                    TError,
                    Awaited<
                        ReturnType<typeof getRequestTestCreateDummySignedJwt>
                    >
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestTestCreateDummySignedJwt<
    TData = Awaited<ReturnType<typeof getRequestTestCreateDummySignedJwt>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestTestCreateDummySignedJwtParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestTestCreateDummySignedJwt>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Create Dummy Signed Jwt
 */

export function useGetRequestTestCreateDummySignedJwt<
    TData = Awaited<ReturnType<typeof getRequestTestCreateDummySignedJwt>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestTestCreateDummySignedJwtParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestTestCreateDummySignedJwt>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions = getGetRequestTestCreateDummySignedJwtQueryOptions(
        params,
        options,
    )

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * @summary Send Csam Report
 */
export const postRequestTestSendCsamReport = (
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<SendCsamRespDTO>(
        { url: `/test/send_csam_report`, method: 'POST', signal },
        options,
    )
}

export const getPostRequestTestSendCsamReportMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestTestSendCsamReport>>,
        TError,
        void,
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestTestSendCsamReport>>,
    TError,
    void,
    TContext
> => {
    const mutationKey = ['postRequestTestSendCsamReport']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postRequestTestSendCsamReport>>,
        void
    > = () => {
        return postRequestTestSendCsamReport(requestOptions)
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestTestSendCsamReportMutationResult = NonNullable<
    Awaited<ReturnType<typeof postRequestTestSendCsamReport>>
>

export type PostRequestTestSendCsamReportMutationError = ErrorType<unknown>

/**
 * @summary Send Csam Report
 */
export const usePostRequestTestSendCsamReport = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestTestSendCsamReport>>,
        TError,
        void,
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestTestSendCsamReport>>,
    TError,
    void,
    TContext
> => {
    const mutationOptions =
        getPostRequestTestSendCsamReportMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary Delete Stripe Data
 */
export const postRequestTestDeleteStripeData = (
    params: PostRequestTestDeleteStripeDataParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<MessageResponseDTO>(
        { url: `/test/delete_stripe_data`, method: 'POST', params, signal },
        options,
    )
}

export const getPostRequestTestDeleteStripeDataMutationOptions = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestTestDeleteStripeData>>,
        TError,
        { params: PostRequestTestDeleteStripeDataParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestTestDeleteStripeData>>,
    TError,
    { params: PostRequestTestDeleteStripeDataParams },
    TContext
> => {
    const mutationKey = ['postRequestTestDeleteStripeData']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postRequestTestDeleteStripeData>>,
        { params: PostRequestTestDeleteStripeDataParams }
    > = (props) => {
        const { params } = props ?? {}

        return postRequestTestDeleteStripeData(params, requestOptions)
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestTestDeleteStripeDataMutationResult = NonNullable<
    Awaited<ReturnType<typeof postRequestTestDeleteStripeData>>
>

export type PostRequestTestDeleteStripeDataMutationError =
    ErrorType<HTTPValidationErrorDTO>

/**
 * @summary Delete Stripe Data
 */
export const usePostRequestTestDeleteStripeData = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestTestDeleteStripeData>>,
        TError,
        { params: PostRequestTestDeleteStripeDataParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestTestDeleteStripeData>>,
    TError,
    { params: PostRequestTestDeleteStripeDataParams },
    TContext
> => {
    const mutationOptions =
        getPostRequestTestDeleteStripeDataMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary Add Full Screen Card
 */
export const postRequestTestFullScreenCard = (
    params: PostRequestTestFullScreenCardParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<MessageResponseDTO>(
        { url: `/test/full_screen_card`, method: 'POST', params, signal },
        options,
    )
}

export const getPostRequestTestFullScreenCardMutationOptions = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestTestFullScreenCard>>,
        TError,
        { params: PostRequestTestFullScreenCardParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestTestFullScreenCard>>,
    TError,
    { params: PostRequestTestFullScreenCardParams },
    TContext
> => {
    const mutationKey = ['postRequestTestFullScreenCard']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postRequestTestFullScreenCard>>,
        { params: PostRequestTestFullScreenCardParams }
    > = (props) => {
        const { params } = props ?? {}

        return postRequestTestFullScreenCard(params, requestOptions)
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestTestFullScreenCardMutationResult = NonNullable<
    Awaited<ReturnType<typeof postRequestTestFullScreenCard>>
>

export type PostRequestTestFullScreenCardMutationError =
    ErrorType<HTTPValidationErrorDTO>

/**
 * @summary Add Full Screen Card
 */
export const usePostRequestTestFullScreenCard = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestTestFullScreenCard>>,
        TError,
        { params: PostRequestTestFullScreenCardParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestTestFullScreenCard>>,
    TError,
    { params: PostRequestTestFullScreenCardParams },
    TContext
> => {
    const mutationOptions =
        getPostRequestTestFullScreenCardMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary Create Memory Collection
 */
export const postRequestTestJobsMemoryCollectionsByJobUuidAndCollectionType = (
    jobUuid: string,
    collectionType: CollectionTypeDTO,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<unknown>(
        {
            url: `/test/jobs/${encodeURIComponent(String(jobUuid))}/memory_collections/${encodeURIComponent(String(collectionType))}`,
            method: 'POST',
            signal,
        },
        options,
    )
}

export const getPostRequestTestJobsMemoryCollectionsByJobUuidAndCollectionTypeMutationOptions =
    <TError = ErrorType<HTTPValidationErrorDTO>, TContext = unknown>(options?: {
        mutation?: UseMutationOptions<
            Awaited<
                ReturnType<
                    typeof postRequestTestJobsMemoryCollectionsByJobUuidAndCollectionType
                >
            >,
            TError,
            { jobUuid: string; collectionType: CollectionTypeDTO },
            TContext
        >
        request?: SecondParameter<typeof customInstance>
    }): UseMutationOptions<
        Awaited<
            ReturnType<
                typeof postRequestTestJobsMemoryCollectionsByJobUuidAndCollectionType
            >
        >,
        TError,
        { jobUuid: string; collectionType: CollectionTypeDTO },
        TContext
    > => {
        const mutationKey = [
            'postRequestTestJobsMemoryCollectionsByJobUuidAndCollectionType',
        ]
        const { mutation: mutationOptions, request: requestOptions } = options
            ? options.mutation &&
              'mutationKey' in options.mutation &&
              options.mutation.mutationKey
                ? options
                : { ...options, mutation: { ...options.mutation, mutationKey } }
            : { mutation: { mutationKey }, request: undefined }

        const mutationFn: MutationFunction<
            Awaited<
                ReturnType<
                    typeof postRequestTestJobsMemoryCollectionsByJobUuidAndCollectionType
                >
            >,
            { jobUuid: string; collectionType: CollectionTypeDTO }
        > = (props) => {
            const { jobUuid, collectionType } = props ?? {}

            return postRequestTestJobsMemoryCollectionsByJobUuidAndCollectionType(
                jobUuid,
                collectionType,
                requestOptions,
            )
        }

        return { mutationFn, ...mutationOptions }
    }

export type PostRequestTestJobsMemoryCollectionsByJobUuidAndCollectionTypeMutationResult =
    NonNullable<
        Awaited<
            ReturnType<
                typeof postRequestTestJobsMemoryCollectionsByJobUuidAndCollectionType
            >
        >
    >

export type PostRequestTestJobsMemoryCollectionsByJobUuidAndCollectionTypeMutationError =
    ErrorType<HTTPValidationErrorDTO>

/**
 * @summary Create Memory Collection
 */
export const usePostRequestTestJobsMemoryCollectionsByJobUuidAndCollectionType =
    <TError = ErrorType<HTTPValidationErrorDTO>, TContext = unknown>(options?: {
        mutation?: UseMutationOptions<
            Awaited<
                ReturnType<
                    typeof postRequestTestJobsMemoryCollectionsByJobUuidAndCollectionType
                >
            >,
            TError,
            { jobUuid: string; collectionType: CollectionTypeDTO },
            TContext
        >
        request?: SecondParameter<typeof customInstance>
    }): UseMutationResult<
        Awaited<
            ReturnType<
                typeof postRequestTestJobsMemoryCollectionsByJobUuidAndCollectionType
            >
        >,
        TError,
        { jobUuid: string; collectionType: CollectionTypeDTO },
        TContext
    > => {
        const mutationOptions =
            getPostRequestTestJobsMemoryCollectionsByJobUuidAndCollectionTypeMutationOptions(
                options,
            )

        return useMutation(mutationOptions)
    }

/**
 * @summary Get Memory Collection
 */
export const getRequestTestMemoryCollectionsByCollectionUuid = (
    collectionUuid: string,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<CollectionDTO>(
        {
            url: `/test/memory_collections/${encodeURIComponent(String(collectionUuid))}`,
            method: 'GET',
            signal,
        },
        options,
    )
}

export const getGetRequestTestMemoryCollectionsByCollectionUuidQueryKey = (
    collectionUuid: string,
) => {
    return [`/test/memory_collections/${collectionUuid}`] as const
}

export const getGetRequestTestMemoryCollectionsByCollectionUuidQueryOptions = <
    TData = Awaited<
        ReturnType<typeof getRequestTestMemoryCollectionsByCollectionUuid>
    >,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    collectionUuid: string,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestTestMemoryCollectionsByCollectionUuid
                    >
                >,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {}

    const queryKey =
        queryOptions?.queryKey ??
        getGetRequestTestMemoryCollectionsByCollectionUuidQueryKey(
            collectionUuid,
        )

    const queryFn: QueryFunction<
        Awaited<
            ReturnType<typeof getRequestTestMemoryCollectionsByCollectionUuid>
        >
    > = ({ signal }) =>
        getRequestTestMemoryCollectionsByCollectionUuid(
            collectionUuid,
            requestOptions,
            signal,
        )

    return {
        queryKey,
        queryFn,
        enabled: !!collectionUuid,
        ...queryOptions,
    } as UseQueryOptions<
        Awaited<
            ReturnType<typeof getRequestTestMemoryCollectionsByCollectionUuid>
        >,
        TError,
        TData
    > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRequestTestMemoryCollectionsByCollectionUuidQueryResult =
    NonNullable<
        Awaited<
            ReturnType<typeof getRequestTestMemoryCollectionsByCollectionUuid>
        >
    >
export type GetRequestTestMemoryCollectionsByCollectionUuidQueryError =
    ErrorType<HTTPValidationErrorDTO>

export function useGetRequestTestMemoryCollectionsByCollectionUuid<
    TData = Awaited<
        ReturnType<typeof getRequestTestMemoryCollectionsByCollectionUuid>
    >,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    collectionUuid: string,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestTestMemoryCollectionsByCollectionUuid
                    >
                >,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<
                        ReturnType<
                            typeof getRequestTestMemoryCollectionsByCollectionUuid
                        >
                    >,
                    TError,
                    Awaited<
                        ReturnType<
                            typeof getRequestTestMemoryCollectionsByCollectionUuid
                        >
                    >
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestTestMemoryCollectionsByCollectionUuid<
    TData = Awaited<
        ReturnType<typeof getRequestTestMemoryCollectionsByCollectionUuid>
    >,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    collectionUuid: string,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestTestMemoryCollectionsByCollectionUuid
                    >
                >,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<
                        ReturnType<
                            typeof getRequestTestMemoryCollectionsByCollectionUuid
                        >
                    >,
                    TError,
                    Awaited<
                        ReturnType<
                            typeof getRequestTestMemoryCollectionsByCollectionUuid
                        >
                    >
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestTestMemoryCollectionsByCollectionUuid<
    TData = Awaited<
        ReturnType<typeof getRequestTestMemoryCollectionsByCollectionUuid>
    >,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    collectionUuid: string,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestTestMemoryCollectionsByCollectionUuid
                    >
                >,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get Memory Collection
 */

export function useGetRequestTestMemoryCollectionsByCollectionUuid<
    TData = Awaited<
        ReturnType<typeof getRequestTestMemoryCollectionsByCollectionUuid>
    >,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    collectionUuid: string,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestTestMemoryCollectionsByCollectionUuid
                    >
                >,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions =
        getGetRequestTestMemoryCollectionsByCollectionUuidQueryOptions(
            collectionUuid,
            options,
        )

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * @summary Sunset Coms
 */
export const postRequestTestSunsetComs = (
    params: PostRequestTestSunsetComsParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<MessageResponseDTO>(
        { url: `/test/sunset_coms`, method: 'POST', params, signal },
        options,
    )
}

export const getPostRequestTestSunsetComsMutationOptions = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestTestSunsetComs>>,
        TError,
        { params: PostRequestTestSunsetComsParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestTestSunsetComs>>,
    TError,
    { params: PostRequestTestSunsetComsParams },
    TContext
> => {
    const mutationKey = ['postRequestTestSunsetComs']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postRequestTestSunsetComs>>,
        { params: PostRequestTestSunsetComsParams }
    > = (props) => {
        const { params } = props ?? {}

        return postRequestTestSunsetComs(params, requestOptions)
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestTestSunsetComsMutationResult = NonNullable<
    Awaited<ReturnType<typeof postRequestTestSunsetComs>>
>

export type PostRequestTestSunsetComsMutationError =
    ErrorType<HTTPValidationErrorDTO>

/**
 * @summary Sunset Coms
 */
export const usePostRequestTestSunsetComs = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestTestSunsetComs>>,
        TError,
        { params: PostRequestTestSunsetComsParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestTestSunsetComs>>,
    TError,
    { params: PostRequestTestSunsetComsParams },
    TContext
> => {
    const mutationOptions = getPostRequestTestSunsetComsMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary Sunset Delete User Post
 */
export const postRequestTestSunsetDelete = (
    params: PostRequestTestSunsetDeleteParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<MessageResponseDTO>(
        { url: `/test/sunset_delete`, method: 'POST', params, signal },
        options,
    )
}

export const getPostRequestTestSunsetDeleteMutationOptions = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestTestSunsetDelete>>,
        TError,
        { params: PostRequestTestSunsetDeleteParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestTestSunsetDelete>>,
    TError,
    { params: PostRequestTestSunsetDeleteParams },
    TContext
> => {
    const mutationKey = ['postRequestTestSunsetDelete']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postRequestTestSunsetDelete>>,
        { params: PostRequestTestSunsetDeleteParams }
    > = (props) => {
        const { params } = props ?? {}

        return postRequestTestSunsetDelete(params, requestOptions)
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestTestSunsetDeleteMutationResult = NonNullable<
    Awaited<ReturnType<typeof postRequestTestSunsetDelete>>
>

export type PostRequestTestSunsetDeleteMutationError =
    ErrorType<HTTPValidationErrorDTO>

/**
 * @summary Sunset Delete User Post
 */
export const usePostRequestTestSunsetDelete = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestTestSunsetDelete>>,
        TError,
        { params: PostRequestTestSunsetDeleteParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestTestSunsetDelete>>,
    TError,
    { params: PostRequestTestSunsetDeleteParams },
    TContext
> => {
    const mutationOptions =
        getPostRequestTestSunsetDeleteMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary Delete User Without Telenor Id Coms
 */
export const postRequestTestDeleteUserWithoutTelenorIdComs = (
    params: PostRequestTestDeleteUserWithoutTelenorIdComsParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<MessageResponseDTO>(
        {
            url: `/test/delete_user_without_telenor_id_coms`,
            method: 'POST',
            params,
            signal,
        },
        options,
    )
}

export const getPostRequestTestDeleteUserWithoutTelenorIdComsMutationOptions = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<typeof postRequestTestDeleteUserWithoutTelenorIdComs>
        >,
        TError,
        { params: PostRequestTestDeleteUserWithoutTelenorIdComsParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestTestDeleteUserWithoutTelenorIdComs>>,
    TError,
    { params: PostRequestTestDeleteUserWithoutTelenorIdComsParams },
    TContext
> => {
    const mutationKey = ['postRequestTestDeleteUserWithoutTelenorIdComs']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<
            ReturnType<typeof postRequestTestDeleteUserWithoutTelenorIdComs>
        >,
        { params: PostRequestTestDeleteUserWithoutTelenorIdComsParams }
    > = (props) => {
        const { params } = props ?? {}

        return postRequestTestDeleteUserWithoutTelenorIdComs(
            params,
            requestOptions,
        )
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestTestDeleteUserWithoutTelenorIdComsMutationResult =
    NonNullable<
        Awaited<
            ReturnType<typeof postRequestTestDeleteUserWithoutTelenorIdComs>
        >
    >

export type PostRequestTestDeleteUserWithoutTelenorIdComsMutationError =
    ErrorType<HTTPValidationErrorDTO>

/**
 * @summary Delete User Without Telenor Id Coms
 */
export const usePostRequestTestDeleteUserWithoutTelenorIdComs = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<typeof postRequestTestDeleteUserWithoutTelenorIdComs>
        >,
        TError,
        { params: PostRequestTestDeleteUserWithoutTelenorIdComsParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestTestDeleteUserWithoutTelenorIdComs>>,
    TError,
    { params: PostRequestTestDeleteUserWithoutTelenorIdComsParams },
    TContext
> => {
    const mutationOptions =
        getPostRequestTestDeleteUserWithoutTelenorIdComsMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary Set Read Only Grant
 */
export const postRequestTestSetReadOnlyGrant = (
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<MessageResponseDTO>(
        { url: `/test/set_read_only_grant`, method: 'POST', signal },
        options,
    )
}

export const getPostRequestTestSetReadOnlyGrantMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestTestSetReadOnlyGrant>>,
        TError,
        void,
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestTestSetReadOnlyGrant>>,
    TError,
    void,
    TContext
> => {
    const mutationKey = ['postRequestTestSetReadOnlyGrant']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postRequestTestSetReadOnlyGrant>>,
        void
    > = () => {
        return postRequestTestSetReadOnlyGrant(requestOptions)
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestTestSetReadOnlyGrantMutationResult = NonNullable<
    Awaited<ReturnType<typeof postRequestTestSetReadOnlyGrant>>
>

export type PostRequestTestSetReadOnlyGrantMutationError = ErrorType<unknown>

/**
 * @summary Set Read Only Grant
 */
export const usePostRequestTestSetReadOnlyGrant = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestTestSetReadOnlyGrant>>,
        TError,
        void,
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestTestSetReadOnlyGrant>>,
    TError,
    void,
    TContext
> => {
    const mutationOptions =
        getPostRequestTestSetReadOnlyGrantMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary Set Freemium Grant
 */
export const postRequestTestSetFreemiumGrant = (
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<MessageResponseDTO>(
        { url: `/test/set_freemium_grant`, method: 'POST', signal },
        options,
    )
}

export const getPostRequestTestSetFreemiumGrantMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestTestSetFreemiumGrant>>,
        TError,
        void,
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestTestSetFreemiumGrant>>,
    TError,
    void,
    TContext
> => {
    const mutationKey = ['postRequestTestSetFreemiumGrant']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postRequestTestSetFreemiumGrant>>,
        void
    > = () => {
        return postRequestTestSetFreemiumGrant(requestOptions)
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestTestSetFreemiumGrantMutationResult = NonNullable<
    Awaited<ReturnType<typeof postRequestTestSetFreemiumGrant>>
>

export type PostRequestTestSetFreemiumGrantMutationError = ErrorType<unknown>

/**
 * @summary Set Freemium Grant
 */
export const usePostRequestTestSetFreemiumGrant = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestTestSetFreemiumGrant>>,
        TError,
        void,
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestTestSetFreemiumGrant>>,
    TError,
    void,
    TContext
> => {
    const mutationOptions =
        getPostRequestTestSetFreemiumGrantMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary Reset Temp Fake Stipe Country
 */
export const postRequestTestResetTempFakeStipeCountry = (
    params: PostRequestTestResetTempFakeStipeCountryParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<MessageResponseDTO>(
        {
            url: `/test/reset_temp_fake_stipe_country`,
            method: 'POST',
            params,
            signal,
        },
        options,
    )
}

export const getPostRequestTestResetTempFakeStipeCountryMutationOptions = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestTestResetTempFakeStipeCountry>>,
        TError,
        { params: PostRequestTestResetTempFakeStipeCountryParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestTestResetTempFakeStipeCountry>>,
    TError,
    { params: PostRequestTestResetTempFakeStipeCountryParams },
    TContext
> => {
    const mutationKey = ['postRequestTestResetTempFakeStipeCountry']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postRequestTestResetTempFakeStipeCountry>>,
        { params: PostRequestTestResetTempFakeStipeCountryParams }
    > = (props) => {
        const { params } = props ?? {}

        return postRequestTestResetTempFakeStipeCountry(params, requestOptions)
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestTestResetTempFakeStipeCountryMutationResult =
    NonNullable<
        Awaited<ReturnType<typeof postRequestTestResetTempFakeStipeCountry>>
    >

export type PostRequestTestResetTempFakeStipeCountryMutationError =
    ErrorType<HTTPValidationErrorDTO>

/**
 * @summary Reset Temp Fake Stipe Country
 */
export const usePostRequestTestResetTempFakeStipeCountry = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestTestResetTempFakeStipeCountry>>,
        TError,
        { params: PostRequestTestResetTempFakeStipeCountryParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestTestResetTempFakeStipeCountry>>,
    TError,
    { params: PostRequestTestResetTempFakeStipeCountryParams },
    TContext
> => {
    const mutationOptions =
        getPostRequestTestResetTempFakeStipeCountryMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * For now only force sync event is supported
 * @summary Add User Event
 */
export const postRequestTestUserEvent = (
    params: PostRequestTestUserEventParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<MessageResponseDTO>(
        { url: `/test/user_event`, method: 'POST', params, signal },
        options,
    )
}

export const getPostRequestTestUserEventMutationOptions = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestTestUserEvent>>,
        TError,
        { params: PostRequestTestUserEventParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestTestUserEvent>>,
    TError,
    { params: PostRequestTestUserEventParams },
    TContext
> => {
    const mutationKey = ['postRequestTestUserEvent']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postRequestTestUserEvent>>,
        { params: PostRequestTestUserEventParams }
    > = (props) => {
        const { params } = props ?? {}

        return postRequestTestUserEvent(params, requestOptions)
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestTestUserEventMutationResult = NonNullable<
    Awaited<ReturnType<typeof postRequestTestUserEvent>>
>

export type PostRequestTestUserEventMutationError =
    ErrorType<HTTPValidationErrorDTO>

/**
 * @summary Add User Event
 */
export const usePostRequestTestUserEvent = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestTestUserEvent>>,
        TError,
        { params: PostRequestTestUserEventParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestTestUserEvent>>,
    TError,
    { params: PostRequestTestUserEventParams },
    TContext
> => {
    const mutationOptions = getPostRequestTestUserEventMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary Remove User
 */
export const deleteRequestTestRemoveUser = (
    options?: SecondParameter<typeof customInstance>,
) => {
    return customInstance<MessageResponseDTO>(
        { url: `/test/remove_user`, method: 'DELETE' },
        options,
    )
}

export const getDeleteRequestTestRemoveUserMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof deleteRequestTestRemoveUser>>,
        TError,
        void,
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof deleteRequestTestRemoveUser>>,
    TError,
    void,
    TContext
> => {
    const mutationKey = ['deleteRequestTestRemoveUser']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof deleteRequestTestRemoveUser>>,
        void
    > = () => {
        return deleteRequestTestRemoveUser(requestOptions)
    }

    return { mutationFn, ...mutationOptions }
}

export type DeleteRequestTestRemoveUserMutationResult = NonNullable<
    Awaited<ReturnType<typeof deleteRequestTestRemoveUser>>
>

export type DeleteRequestTestRemoveUserMutationError = ErrorType<unknown>

/**
 * @summary Remove User
 */
export const useDeleteRequestTestRemoveUser = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof deleteRequestTestRemoveUser>>,
        TError,
        void,
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof deleteRequestTestRemoveUser>>,
    TError,
    void,
    TContext
> => {
    const mutationOptions =
        getDeleteRequestTestRemoveUserMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary Post Client Params
 */
export const postRequestTestClientParams = (
    params: PostRequestTestClientParamsParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<ClientParamsDTO>(
        { url: `/test/client_params`, method: 'POST', params, signal },
        options,
    )
}

export const getPostRequestTestClientParamsMutationOptions = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestTestClientParams>>,
        TError,
        { params: PostRequestTestClientParamsParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestTestClientParams>>,
    TError,
    { params: PostRequestTestClientParamsParams },
    TContext
> => {
    const mutationKey = ['postRequestTestClientParams']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postRequestTestClientParams>>,
        { params: PostRequestTestClientParamsParams }
    > = (props) => {
        const { params } = props ?? {}

        return postRequestTestClientParams(params, requestOptions)
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestTestClientParamsMutationResult = NonNullable<
    Awaited<ReturnType<typeof postRequestTestClientParams>>
>

export type PostRequestTestClientParamsMutationError =
    ErrorType<HTTPValidationErrorDTO>

/**
 * @summary Post Client Params
 */
export const usePostRequestTestClientParams = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestTestClientParams>>,
        TError,
        { params: PostRequestTestClientParamsParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestTestClientParams>>,
    TError,
    { params: PostRequestTestClientParamsParams },
    TContext
> => {
    const mutationOptions =
        getPostRequestTestClientParamsMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary Digital Legacy Coms
 */
export const postRequestTestDigitalLegacyComs = (
    params: PostRequestTestDigitalLegacyComsParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<MessageResponseDTO>(
        { url: `/test/digital_legacy_coms`, method: 'POST', params, signal },
        options,
    )
}

export const getPostRequestTestDigitalLegacyComsMutationOptions = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestTestDigitalLegacyComs>>,
        TError,
        { params: PostRequestTestDigitalLegacyComsParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestTestDigitalLegacyComs>>,
    TError,
    { params: PostRequestTestDigitalLegacyComsParams },
    TContext
> => {
    const mutationKey = ['postRequestTestDigitalLegacyComs']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postRequestTestDigitalLegacyComs>>,
        { params: PostRequestTestDigitalLegacyComsParams }
    > = (props) => {
        const { params } = props ?? {}

        return postRequestTestDigitalLegacyComs(params, requestOptions)
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestTestDigitalLegacyComsMutationResult = NonNullable<
    Awaited<ReturnType<typeof postRequestTestDigitalLegacyComs>>
>

export type PostRequestTestDigitalLegacyComsMutationError =
    ErrorType<HTTPValidationErrorDTO>

/**
 * @summary Digital Legacy Coms
 */
export const usePostRequestTestDigitalLegacyComs = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestTestDigitalLegacyComs>>,
        TError,
        { params: PostRequestTestDigitalLegacyComsParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestTestDigitalLegacyComs>>,
    TError,
    { params: PostRequestTestDigitalLegacyComsParams },
    TContext
> => {
    const mutationOptions =
        getPostRequestTestDigitalLegacyComsMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary Get Email Consent Id
 */
export const getRequestTestEmailConsentId = (
    params: GetRequestTestEmailConsentIdParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<EmailConsentIDDTO>(
        { url: `/test/email_consent_id`, method: 'GET', params, signal },
        options,
    )
}

export const getGetRequestTestEmailConsentIdQueryKey = (
    params: GetRequestTestEmailConsentIdParams,
) => {
    return [`/test/email_consent_id`, ...(params ? [params] : [])] as const
}

export const getGetRequestTestEmailConsentIdQueryOptions = <
    TData = Awaited<ReturnType<typeof getRequestTestEmailConsentId>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestTestEmailConsentIdParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestTestEmailConsentId>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {}

    const queryKey =
        queryOptions?.queryKey ??
        getGetRequestTestEmailConsentIdQueryKey(params)

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getRequestTestEmailConsentId>>
    > = ({ signal }) =>
        getRequestTestEmailConsentId(params, requestOptions, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof getRequestTestEmailConsentId>>,
        TError,
        TData
    > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRequestTestEmailConsentIdQueryResult = NonNullable<
    Awaited<ReturnType<typeof getRequestTestEmailConsentId>>
>
export type GetRequestTestEmailConsentIdQueryError =
    ErrorType<HTTPValidationErrorDTO>

export function useGetRequestTestEmailConsentId<
    TData = Awaited<ReturnType<typeof getRequestTestEmailConsentId>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestTestEmailConsentIdParams,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestTestEmailConsentId>>,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestTestEmailConsentId>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestTestEmailConsentId>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestTestEmailConsentId<
    TData = Awaited<ReturnType<typeof getRequestTestEmailConsentId>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestTestEmailConsentIdParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestTestEmailConsentId>>,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestTestEmailConsentId>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestTestEmailConsentId>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestTestEmailConsentId<
    TData = Awaited<ReturnType<typeof getRequestTestEmailConsentId>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestTestEmailConsentIdParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestTestEmailConsentId>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get Email Consent Id
 */

export function useGetRequestTestEmailConsentId<
    TData = Awaited<ReturnType<typeof getRequestTestEmailConsentId>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestTestEmailConsentIdParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestTestEmailConsentId>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions = getGetRequestTestEmailConsentIdQueryOptions(
        params,
        options,
    )

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * @summary Read Error Log
 */
export const getRequestConnectUserErrorLogsByUsername = (
    username: string,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<ErrorLogDataResultDTO[]>(
        {
            url: `/connect_user/${encodeURIComponent(String(username))}/error_logs`,
            method: 'GET',
            signal,
        },
        options,
    )
}

export const getGetRequestConnectUserErrorLogsByUsernameQueryKey = (
    username: string,
) => {
    return [`/connect_user/${username}/error_logs`] as const
}

export const getGetRequestConnectUserErrorLogsByUsernameQueryOptions = <
    TData = Awaited<
        ReturnType<typeof getRequestConnectUserErrorLogsByUsername>
    >,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    username: string,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<typeof getRequestConnectUserErrorLogsByUsername>
                >,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {}

    const queryKey =
        queryOptions?.queryKey ??
        getGetRequestConnectUserErrorLogsByUsernameQueryKey(username)

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getRequestConnectUserErrorLogsByUsername>>
    > = ({ signal }) =>
        getRequestConnectUserErrorLogsByUsername(
            username,
            requestOptions,
            signal,
        )

    return {
        queryKey,
        queryFn,
        enabled: !!username,
        ...queryOptions,
    } as UseQueryOptions<
        Awaited<ReturnType<typeof getRequestConnectUserErrorLogsByUsername>>,
        TError,
        TData
    > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRequestConnectUserErrorLogsByUsernameQueryResult = NonNullable<
    Awaited<ReturnType<typeof getRequestConnectUserErrorLogsByUsername>>
>
export type GetRequestConnectUserErrorLogsByUsernameQueryError =
    ErrorType<HTTPValidationErrorDTO>

export function useGetRequestConnectUserErrorLogsByUsername<
    TData = Awaited<
        ReturnType<typeof getRequestConnectUserErrorLogsByUsername>
    >,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    username: string,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<typeof getRequestConnectUserErrorLogsByUsername>
                >,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<
                        ReturnType<
                            typeof getRequestConnectUserErrorLogsByUsername
                        >
                    >,
                    TError,
                    Awaited<
                        ReturnType<
                            typeof getRequestConnectUserErrorLogsByUsername
                        >
                    >
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestConnectUserErrorLogsByUsername<
    TData = Awaited<
        ReturnType<typeof getRequestConnectUserErrorLogsByUsername>
    >,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    username: string,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<typeof getRequestConnectUserErrorLogsByUsername>
                >,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<
                        ReturnType<
                            typeof getRequestConnectUserErrorLogsByUsername
                        >
                    >,
                    TError,
                    Awaited<
                        ReturnType<
                            typeof getRequestConnectUserErrorLogsByUsername
                        >
                    >
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestConnectUserErrorLogsByUsername<
    TData = Awaited<
        ReturnType<typeof getRequestConnectUserErrorLogsByUsername>
    >,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    username: string,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<typeof getRequestConnectUserErrorLogsByUsername>
                >,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Read Error Log
 */

export function useGetRequestConnectUserErrorLogsByUsername<
    TData = Awaited<
        ReturnType<typeof getRequestConnectUserErrorLogsByUsername>
    >,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    username: string,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<typeof getRequestConnectUserErrorLogsByUsername>
                >,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions =
        getGetRequestConnectUserErrorLogsByUsernameQueryOptions(
            username,
            options,
        )

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * @summary Request Error Logs
 */
export const postRequestConnectUserRequestErrorLogsByUsername = (
    username: string,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<MessageResponseDTO>(
        {
            url: `/connect_user/${encodeURIComponent(String(username))}/request_error_logs`,
            method: 'POST',
            signal,
        },
        options,
    )
}

export const getPostRequestConnectUserRequestErrorLogsByUsernameMutationOptions =
    <TError = ErrorType<HTTPValidationErrorDTO>, TContext = unknown>(options?: {
        mutation?: UseMutationOptions<
            Awaited<
                ReturnType<
                    typeof postRequestConnectUserRequestErrorLogsByUsername
                >
            >,
            TError,
            { username: string },
            TContext
        >
        request?: SecondParameter<typeof customInstance>
    }): UseMutationOptions<
        Awaited<
            ReturnType<typeof postRequestConnectUserRequestErrorLogsByUsername>
        >,
        TError,
        { username: string },
        TContext
    > => {
        const mutationKey = ['postRequestConnectUserRequestErrorLogsByUsername']
        const { mutation: mutationOptions, request: requestOptions } = options
            ? options.mutation &&
              'mutationKey' in options.mutation &&
              options.mutation.mutationKey
                ? options
                : { ...options, mutation: { ...options.mutation, mutationKey } }
            : { mutation: { mutationKey }, request: undefined }

        const mutationFn: MutationFunction<
            Awaited<
                ReturnType<
                    typeof postRequestConnectUserRequestErrorLogsByUsername
                >
            >,
            { username: string }
        > = (props) => {
            const { username } = props ?? {}

            return postRequestConnectUserRequestErrorLogsByUsername(
                username,
                requestOptions,
            )
        }

        return { mutationFn, ...mutationOptions }
    }

export type PostRequestConnectUserRequestErrorLogsByUsernameMutationResult =
    NonNullable<
        Awaited<
            ReturnType<typeof postRequestConnectUserRequestErrorLogsByUsername>
        >
    >

export type PostRequestConnectUserRequestErrorLogsByUsernameMutationError =
    ErrorType<HTTPValidationErrorDTO>

/**
 * @summary Request Error Logs
 */
export const usePostRequestConnectUserRequestErrorLogsByUsername = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<typeof postRequestConnectUserRequestErrorLogsByUsername>
        >,
        TError,
        { username: string },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<
        ReturnType<typeof postRequestConnectUserRequestErrorLogsByUsername>
    >,
    TError,
    { username: string },
    TContext
> => {
    const mutationOptions =
        getPostRequestConnectUserRequestErrorLogsByUsernameMutationOptions(
            options,
        )

    return useMutation(mutationOptions)
}

/**
 * Signals to the client if it should upload error logs

 * @summary Get Should Upload Error Logs
 */
export const getRequestUserShouldUploadErrorLogs = (
    params: GetRequestUserShouldUploadErrorLogsParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<UploadErrorLogResultDTO>(
        {
            url: `/user/should_upload_error_logs`,
            method: 'GET',
            params,
            signal,
        },
        options,
    )
}

export const getGetRequestUserShouldUploadErrorLogsQueryKey = (
    params: GetRequestUserShouldUploadErrorLogsParams,
) => {
    return [
        `/user/should_upload_error_logs`,
        ...(params ? [params] : []),
    ] as const
}

export const getGetRequestUserShouldUploadErrorLogsQueryOptions = <
    TData = Awaited<ReturnType<typeof getRequestUserShouldUploadErrorLogs>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestUserShouldUploadErrorLogsParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestUserShouldUploadErrorLogs>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {}

    const queryKey =
        queryOptions?.queryKey ??
        getGetRequestUserShouldUploadErrorLogsQueryKey(params)

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getRequestUserShouldUploadErrorLogs>>
    > = ({ signal }) =>
        getRequestUserShouldUploadErrorLogs(params, requestOptions, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof getRequestUserShouldUploadErrorLogs>>,
        TError,
        TData
    > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRequestUserShouldUploadErrorLogsQueryResult = NonNullable<
    Awaited<ReturnType<typeof getRequestUserShouldUploadErrorLogs>>
>
export type GetRequestUserShouldUploadErrorLogsQueryError =
    ErrorType<HTTPValidationErrorDTO>

export function useGetRequestUserShouldUploadErrorLogs<
    TData = Awaited<ReturnType<typeof getRequestUserShouldUploadErrorLogs>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestUserShouldUploadErrorLogsParams,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestUserShouldUploadErrorLogs>>,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<
                        ReturnType<typeof getRequestUserShouldUploadErrorLogs>
                    >,
                    TError,
                    Awaited<
                        ReturnType<typeof getRequestUserShouldUploadErrorLogs>
                    >
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestUserShouldUploadErrorLogs<
    TData = Awaited<ReturnType<typeof getRequestUserShouldUploadErrorLogs>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestUserShouldUploadErrorLogsParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestUserShouldUploadErrorLogs>>,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<
                        ReturnType<typeof getRequestUserShouldUploadErrorLogs>
                    >,
                    TError,
                    Awaited<
                        ReturnType<typeof getRequestUserShouldUploadErrorLogs>
                    >
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestUserShouldUploadErrorLogs<
    TData = Awaited<ReturnType<typeof getRequestUserShouldUploadErrorLogs>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestUserShouldUploadErrorLogsParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestUserShouldUploadErrorLogs>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get Should Upload Error Logs
 */

export function useGetRequestUserShouldUploadErrorLogs<
    TData = Awaited<ReturnType<typeof getRequestUserShouldUploadErrorLogs>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestUserShouldUploadErrorLogsParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestUserShouldUploadErrorLogs>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions = getGetRequestUserShouldUploadErrorLogsQueryOptions(
        params,
        options,
    )

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * @summary Post Should Upload Error Logs
 */
export const postRequestUserShouldUploadErrorLogs = (
    params: PostRequestUserShouldUploadErrorLogsParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<UploadErrorLogResultDTO>(
        {
            url: `/user/should_upload_error_logs`,
            method: 'POST',
            params,
            signal,
        },
        options,
    )
}

export const getPostRequestUserShouldUploadErrorLogsMutationOptions = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestUserShouldUploadErrorLogs>>,
        TError,
        { params: PostRequestUserShouldUploadErrorLogsParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestUserShouldUploadErrorLogs>>,
    TError,
    { params: PostRequestUserShouldUploadErrorLogsParams },
    TContext
> => {
    const mutationKey = ['postRequestUserShouldUploadErrorLogs']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postRequestUserShouldUploadErrorLogs>>,
        { params: PostRequestUserShouldUploadErrorLogsParams }
    > = (props) => {
        const { params } = props ?? {}

        return postRequestUserShouldUploadErrorLogs(params, requestOptions)
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestUserShouldUploadErrorLogsMutationResult = NonNullable<
    Awaited<ReturnType<typeof postRequestUserShouldUploadErrorLogs>>
>

export type PostRequestUserShouldUploadErrorLogsMutationError =
    ErrorType<HTTPValidationErrorDTO>

/**
 * @summary Post Should Upload Error Logs
 */
export const usePostRequestUserShouldUploadErrorLogs = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestUserShouldUploadErrorLogs>>,
        TError,
        { params: PostRequestUserShouldUploadErrorLogsParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestUserShouldUploadErrorLogs>>,
    TError,
    { params: PostRequestUserShouldUploadErrorLogsParams },
    TContext
> => {
    const mutationOptions =
        getPostRequestUserShouldUploadErrorLogsMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * Read content from request body
 * @summary Upload Error Log
 */
export const postRequestUserErrorLog = (
    params: PostRequestUserErrorLogParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<UploadErrorLogResultDTO>(
        { url: `/user/error_log`, method: 'POST', params, signal },
        options,
    )
}

export const getPostRequestUserErrorLogMutationOptions = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestUserErrorLog>>,
        TError,
        { params: PostRequestUserErrorLogParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestUserErrorLog>>,
    TError,
    { params: PostRequestUserErrorLogParams },
    TContext
> => {
    const mutationKey = ['postRequestUserErrorLog']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postRequestUserErrorLog>>,
        { params: PostRequestUserErrorLogParams }
    > = (props) => {
        const { params } = props ?? {}

        return postRequestUserErrorLog(params, requestOptions)
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestUserErrorLogMutationResult = NonNullable<
    Awaited<ReturnType<typeof postRequestUserErrorLog>>
>

export type PostRequestUserErrorLogMutationError =
    ErrorType<HTTPValidationErrorDTO>

/**
 * @summary Upload Error Log
 */
export const usePostRequestUserErrorLog = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestUserErrorLog>>,
        TError,
        { params: PostRequestUserErrorLogParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestUserErrorLog>>,
    TError,
    { params: PostRequestUserErrorLogParams },
    TContext
> => {
    const mutationOptions = getPostRequestUserErrorLogMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * Get me page cards from the backend
 * @summary Get Cards
 */
export const getRequestCards = (
    params: GetRequestCardsParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<CardsDTO>(
        { url: `/cards`, method: 'GET', params, signal },
        options,
    )
}

export const getGetRequestCardsQueryKey = (params: GetRequestCardsParams) => {
    return [`/cards`, ...(params ? [params] : [])] as const
}

export const getGetRequestCardsQueryOptions = <
    TData = Awaited<ReturnType<typeof getRequestCards>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestCardsParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestCards>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {}

    const queryKey =
        queryOptions?.queryKey ?? getGetRequestCardsQueryKey(params)

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getRequestCards>>
    > = ({ signal }) => getRequestCards(params, requestOptions, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof getRequestCards>>,
        TError,
        TData
    > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRequestCardsQueryResult = NonNullable<
    Awaited<ReturnType<typeof getRequestCards>>
>
export type GetRequestCardsQueryError = ErrorType<HTTPValidationErrorDTO>

export function useGetRequestCards<
    TData = Awaited<ReturnType<typeof getRequestCards>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestCardsParams,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestCards>>,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestCards>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestCards>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestCards<
    TData = Awaited<ReturnType<typeof getRequestCards>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestCardsParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestCards>>,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestCards>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestCards>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestCards<
    TData = Awaited<ReturnType<typeof getRequestCards>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestCardsParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestCards>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get Cards
 */

export function useGetRequestCards<
    TData = Awaited<ReturnType<typeof getRequestCards>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestCardsParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestCards>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions = getGetRequestCardsQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * Dismiss full screen card

 * @summary Dismiss Full Screen Card
 */
export const postRequestFullScreenCardsDismissByCardId = (
    cardId: string,
    params: PostRequestFullScreenCardsDismissByCardIdParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<MessageResponseDTO>(
        {
            url: `/full_screen_cards/${encodeURIComponent(String(cardId))}/dismiss`,
            method: 'POST',
            params,
            signal,
        },
        options,
    )
}

export const getPostRequestFullScreenCardsDismissByCardIdMutationOptions = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestFullScreenCardsDismissByCardId>>,
        TError,
        {
            cardId: string
            params: PostRequestFullScreenCardsDismissByCardIdParams
        },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestFullScreenCardsDismissByCardId>>,
    TError,
    { cardId: string; params: PostRequestFullScreenCardsDismissByCardIdParams },
    TContext
> => {
    const mutationKey = ['postRequestFullScreenCardsDismissByCardId']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postRequestFullScreenCardsDismissByCardId>>,
        {
            cardId: string
            params: PostRequestFullScreenCardsDismissByCardIdParams
        }
    > = (props) => {
        const { cardId, params } = props ?? {}

        return postRequestFullScreenCardsDismissByCardId(
            cardId,
            params,
            requestOptions,
        )
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestFullScreenCardsDismissByCardIdMutationResult =
    NonNullable<
        Awaited<ReturnType<typeof postRequestFullScreenCardsDismissByCardId>>
    >

export type PostRequestFullScreenCardsDismissByCardIdMutationError =
    ErrorType<void | HTTPValidationErrorDTO>

/**
 * @summary Dismiss Full Screen Card
 */
export const usePostRequestFullScreenCardsDismissByCardId = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestFullScreenCardsDismissByCardId>>,
        TError,
        {
            cardId: string
            params: PostRequestFullScreenCardsDismissByCardIdParams
        },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestFullScreenCardsDismissByCardId>>,
    TError,
    { cardId: string; params: PostRequestFullScreenCardsDismissByCardIdParams },
    TContext
> => {
    const mutationOptions =
        getPostRequestFullScreenCardsDismissByCardIdMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * For a user get all the full screen cards that a user should see

 * @summary Get Full Screen Cards
 */
export const getRequestFullScreenCards = (
    params: GetRequestFullScreenCardsParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<FullScreenCardsResponseDTO>(
        { url: `/full_screen_cards`, method: 'GET', params, signal },
        options,
    )
}

export const getGetRequestFullScreenCardsQueryKey = (
    params: GetRequestFullScreenCardsParams,
) => {
    return [`/full_screen_cards`, ...(params ? [params] : [])] as const
}

export const getGetRequestFullScreenCardsQueryOptions = <
    TData = Awaited<ReturnType<typeof getRequestFullScreenCards>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestFullScreenCardsParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestFullScreenCards>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {}

    const queryKey =
        queryOptions?.queryKey ?? getGetRequestFullScreenCardsQueryKey(params)

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getRequestFullScreenCards>>
    > = ({ signal }) =>
        getRequestFullScreenCards(params, requestOptions, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof getRequestFullScreenCards>>,
        TError,
        TData
    > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRequestFullScreenCardsQueryResult = NonNullable<
    Awaited<ReturnType<typeof getRequestFullScreenCards>>
>
export type GetRequestFullScreenCardsQueryError =
    ErrorType<HTTPValidationErrorDTO>

export function useGetRequestFullScreenCards<
    TData = Awaited<ReturnType<typeof getRequestFullScreenCards>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestFullScreenCardsParams,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestFullScreenCards>>,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestFullScreenCards>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestFullScreenCards>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestFullScreenCards<
    TData = Awaited<ReturnType<typeof getRequestFullScreenCards>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestFullScreenCardsParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestFullScreenCards>>,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestFullScreenCards>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestFullScreenCards>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestFullScreenCards<
    TData = Awaited<ReturnType<typeof getRequestFullScreenCards>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestFullScreenCardsParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestFullScreenCards>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get Full Screen Cards
 */

export function useGetRequestFullScreenCards<
    TData = Awaited<ReturnType<typeof getRequestFullScreenCards>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestFullScreenCardsParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestFullScreenCards>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions = getGetRequestFullScreenCardsQueryOptions(
        params,
        options,
    )

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * @summary Get Full Screen Cards V2
 */
export const getRequestV2FullScreenCards = (
    params: GetRequestV2FullScreenCardsParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<FullScreenCardsResponseV2DTO>(
        { url: `/v2/full_screen_cards`, method: 'GET', params, signal },
        options,
    )
}

export const getGetRequestV2FullScreenCardsQueryKey = (
    params: GetRequestV2FullScreenCardsParams,
) => {
    return [`/v2/full_screen_cards`, ...(params ? [params] : [])] as const
}

export const getGetRequestV2FullScreenCardsQueryOptions = <
    TData = Awaited<ReturnType<typeof getRequestV2FullScreenCards>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestV2FullScreenCardsParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestV2FullScreenCards>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {}

    const queryKey =
        queryOptions?.queryKey ?? getGetRequestV2FullScreenCardsQueryKey(params)

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getRequestV2FullScreenCards>>
    > = ({ signal }) =>
        getRequestV2FullScreenCards(params, requestOptions, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof getRequestV2FullScreenCards>>,
        TError,
        TData
    > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRequestV2FullScreenCardsQueryResult = NonNullable<
    Awaited<ReturnType<typeof getRequestV2FullScreenCards>>
>
export type GetRequestV2FullScreenCardsQueryError =
    ErrorType<HTTPValidationErrorDTO>

export function useGetRequestV2FullScreenCards<
    TData = Awaited<ReturnType<typeof getRequestV2FullScreenCards>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestV2FullScreenCardsParams,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestV2FullScreenCards>>,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestV2FullScreenCards>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestV2FullScreenCards>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestV2FullScreenCards<
    TData = Awaited<ReturnType<typeof getRequestV2FullScreenCards>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestV2FullScreenCardsParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestV2FullScreenCards>>,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestV2FullScreenCards>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestV2FullScreenCards>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestV2FullScreenCards<
    TData = Awaited<ReturnType<typeof getRequestV2FullScreenCards>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestV2FullScreenCardsParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestV2FullScreenCards>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get Full Screen Cards V2
 */

export function useGetRequestV2FullScreenCards<
    TData = Awaited<ReturnType<typeof getRequestV2FullScreenCards>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestV2FullScreenCardsParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestV2FullScreenCards>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions = getGetRequestV2FullScreenCardsQueryOptions(
        params,
        options,
    )

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * This endpoint is used to signal that ToS have been reviewed by the user

 * @summary Set Tos Reviewed
 */
export const postRequestSetTosReviewed = (
    params: PostRequestSetTosReviewedParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<unknown>(
        { url: `/set_tos_reviewed`, method: 'POST', params, signal },
        options,
    )
}

export const getPostRequestSetTosReviewedMutationOptions = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestSetTosReviewed>>,
        TError,
        { params: PostRequestSetTosReviewedParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestSetTosReviewed>>,
    TError,
    { params: PostRequestSetTosReviewedParams },
    TContext
> => {
    const mutationKey = ['postRequestSetTosReviewed']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postRequestSetTosReviewed>>,
        { params: PostRequestSetTosReviewedParams }
    > = (props) => {
        const { params } = props ?? {}

        return postRequestSetTosReviewed(params, requestOptions)
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestSetTosReviewedMutationResult = NonNullable<
    Awaited<ReturnType<typeof postRequestSetTosReviewed>>
>

export type PostRequestSetTosReviewedMutationError =
    ErrorType<HTTPValidationErrorDTO>

/**
 * @summary Set Tos Reviewed
 */
export const usePostRequestSetTosReviewed = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestSetTosReviewed>>,
        TError,
        { params: PostRequestSetTosReviewedParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestSetTosReviewed>>,
    TError,
    { params: PostRequestSetTosReviewedParams },
    TContext
> => {
    const mutationOptions = getPostRequestSetTosReviewedMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * This is the method to create a new job.
If the job is of type story,
it is possible to specify the permission settings

 * @summary Post Job
 */
export const postRequestJobs = (
    params: PostRequestJobsParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<SetJobDataResponseDTO>(
        { url: `/jobs`, method: 'POST', params, signal },
        options,
    )
}

export const getPostRequestJobsMutationOptions = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestJobs>>,
        TError,
        { params: PostRequestJobsParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestJobs>>,
    TError,
    { params: PostRequestJobsParams },
    TContext
> => {
    const mutationKey = ['postRequestJobs']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postRequestJobs>>,
        { params: PostRequestJobsParams }
    > = (props) => {
        const { params } = props ?? {}

        return postRequestJobs(params, requestOptions)
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestJobsMutationResult = NonNullable<
    Awaited<ReturnType<typeof postRequestJobs>>
>

export type PostRequestJobsMutationError =
    ErrorType<void | HTTPValidationErrorDTO>

/**
 * @summary Post Job
 */
export const usePostRequestJobs = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestJobs>>,
        TError,
        { params: PostRequestJobsParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestJobs>>,
    TError,
    { params: PostRequestJobsParams },
    TContext
> => {
    const mutationOptions = getPostRequestJobsMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * The list of all jobs, shares, albums owned or shared with the user. Timeline jobs and shares
are of the type `JobResponse`, albums, cover and profile jobs are of the type
`JobListStoryResponse`. If `include_details` is `True`, albums will be of this type
 * @summary Get Job List
 */
export const getRequestJobs = (
    params: GetRequestJobsParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<GetRequestJobs200Item[]>(
        { url: `/jobs`, method: 'GET', params, signal },
        options,
    )
}

export const getGetRequestJobsQueryKey = (params: GetRequestJobsParams) => {
    return [`/jobs`, ...(params ? [params] : [])] as const
}

export const getGetRequestJobsQueryOptions = <
    TData = Awaited<ReturnType<typeof getRequestJobs>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestJobsParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestJobs>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetRequestJobsQueryKey(params)

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getRequestJobs>>
    > = ({ signal }) => getRequestJobs(params, requestOptions, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof getRequestJobs>>,
        TError,
        TData
    > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRequestJobsQueryResult = NonNullable<
    Awaited<ReturnType<typeof getRequestJobs>>
>
export type GetRequestJobsQueryError = ErrorType<HTTPValidationErrorDTO>

export function useGetRequestJobs<
    TData = Awaited<ReturnType<typeof getRequestJobs>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestJobsParams,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestJobs>>,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestJobs>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestJobs>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestJobs<
    TData = Awaited<ReturnType<typeof getRequestJobs>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestJobsParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestJobs>>,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestJobs>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestJobs>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestJobs<
    TData = Awaited<ReturnType<typeof getRequestJobs>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestJobsParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestJobs>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get Job List
 */

export function useGetRequestJobs<
    TData = Awaited<ReturnType<typeof getRequestJobs>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestJobsParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestJobs>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions = getGetRequestJobsQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * A lighter version of the job list, only containing the job id, serial and last update time
for each job. Also includes the feature flag hash and the max and used space for the user.
 * @summary Get Job List Light
 */
export const getRequestJobsLight = (
    params: GetRequestJobsLightParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<JobListLightDTO>(
        { url: `/jobs_light`, method: 'GET', params, signal },
        options,
    )
}

export const getGetRequestJobsLightQueryKey = (
    params: GetRequestJobsLightParams,
) => {
    return [`/jobs_light`, ...(params ? [params] : [])] as const
}

export const getGetRequestJobsLightQueryOptions = <
    TData = Awaited<ReturnType<typeof getRequestJobsLight>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestJobsLightParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestJobsLight>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {}

    const queryKey =
        queryOptions?.queryKey ?? getGetRequestJobsLightQueryKey(params)

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getRequestJobsLight>>
    > = ({ signal }) => getRequestJobsLight(params, requestOptions, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof getRequestJobsLight>>,
        TError,
        TData
    > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRequestJobsLightQueryResult = NonNullable<
    Awaited<ReturnType<typeof getRequestJobsLight>>
>
export type GetRequestJobsLightQueryError = ErrorType<HTTPValidationErrorDTO>

export function useGetRequestJobsLight<
    TData = Awaited<ReturnType<typeof getRequestJobsLight>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestJobsLightParams,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestJobsLight>>,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestJobsLight>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestJobsLight>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestJobsLight<
    TData = Awaited<ReturnType<typeof getRequestJobsLight>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestJobsLightParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestJobsLight>>,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestJobsLight>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestJobsLight>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestJobsLight<
    TData = Awaited<ReturnType<typeof getRequestJobsLight>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestJobsLightParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestJobsLight>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get Job List Light
 */

export function useGetRequestJobsLight<
    TData = Awaited<ReturnType<typeof getRequestJobsLight>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestJobsLightParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestJobsLight>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions = getGetRequestJobsLightQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * Returns information on a specific job
 * @summary Get Job Info
 */
export const getRequestJobsInfoByJobUuid = (
    jobUuid: string,
    params: GetRequestJobsInfoByJobUuidParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<GetRequestJobsInfoByJobUuid200>(
        {
            url: `/jobs/${encodeURIComponent(String(jobUuid))}/info`,
            method: 'GET',
            params,
            signal,
        },
        options,
    )
}

export const getGetRequestJobsInfoByJobUuidQueryKey = (
    jobUuid: string,
    params: GetRequestJobsInfoByJobUuidParams,
) => {
    return [`/jobs/${jobUuid}/info`, ...(params ? [params] : [])] as const
}

export const getGetRequestJobsInfoByJobUuidQueryOptions = <
    TData = Awaited<ReturnType<typeof getRequestJobsInfoByJobUuid>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestJobsInfoByJobUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestJobsInfoByJobUuid>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {}

    const queryKey =
        queryOptions?.queryKey ??
        getGetRequestJobsInfoByJobUuidQueryKey(jobUuid, params)

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getRequestJobsInfoByJobUuid>>
    > = ({ signal }) =>
        getRequestJobsInfoByJobUuid(jobUuid, params, requestOptions, signal)

    return {
        queryKey,
        queryFn,
        enabled: !!jobUuid,
        ...queryOptions,
    } as UseQueryOptions<
        Awaited<ReturnType<typeof getRequestJobsInfoByJobUuid>>,
        TError,
        TData
    > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRequestJobsInfoByJobUuidQueryResult = NonNullable<
    Awaited<ReturnType<typeof getRequestJobsInfoByJobUuid>>
>
export type GetRequestJobsInfoByJobUuidQueryError =
    ErrorType<void | HTTPValidationErrorDTO>

export function useGetRequestJobsInfoByJobUuid<
    TData = Awaited<ReturnType<typeof getRequestJobsInfoByJobUuid>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestJobsInfoByJobUuidParams,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestJobsInfoByJobUuid>>,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestJobsInfoByJobUuid>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestJobsInfoByJobUuid>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestJobsInfoByJobUuid<
    TData = Awaited<ReturnType<typeof getRequestJobsInfoByJobUuid>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestJobsInfoByJobUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestJobsInfoByJobUuid>>,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestJobsInfoByJobUuid>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestJobsInfoByJobUuid>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestJobsInfoByJobUuid<
    TData = Awaited<ReturnType<typeof getRequestJobsInfoByJobUuid>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestJobsInfoByJobUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestJobsInfoByJobUuid>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get Job Info
 */

export function useGetRequestJobsInfoByJobUuid<
    TData = Awaited<ReturnType<typeof getRequestJobsInfoByJobUuid>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestJobsInfoByJobUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestJobsInfoByJobUuid>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions = getGetRequestJobsInfoByJobUuidQueryOptions(
        jobUuid,
        params,
        options,
    )

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * This api call returns the service for the job_uuid.
Note that the poll-host might be different for https (see description)

 * @summary Job Service Get Handler
 */
export const getRequestJobsServiceByJobUuid = (
    jobUuid: string,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<JobServiceRespDTO>(
        {
            url: `/jobs/${encodeURIComponent(String(jobUuid))}/service`,
            method: 'GET',
            signal,
        },
        options,
    )
}

export const getGetRequestJobsServiceByJobUuidQueryKey = (jobUuid: string) => {
    return [`/jobs/${jobUuid}/service`] as const
}

export const getGetRequestJobsServiceByJobUuidQueryOptions = <
    TData = Awaited<ReturnType<typeof getRequestJobsServiceByJobUuid>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestJobsServiceByJobUuid>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {}

    const queryKey =
        queryOptions?.queryKey ??
        getGetRequestJobsServiceByJobUuidQueryKey(jobUuid)

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getRequestJobsServiceByJobUuid>>
    > = ({ signal }) =>
        getRequestJobsServiceByJobUuid(jobUuid, requestOptions, signal)

    return {
        queryKey,
        queryFn,
        enabled: !!jobUuid,
        ...queryOptions,
    } as UseQueryOptions<
        Awaited<ReturnType<typeof getRequestJobsServiceByJobUuid>>,
        TError,
        TData
    > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRequestJobsServiceByJobUuidQueryResult = NonNullable<
    Awaited<ReturnType<typeof getRequestJobsServiceByJobUuid>>
>
export type GetRequestJobsServiceByJobUuidQueryError =
    ErrorType<void | HTTPValidationErrorDTO>

export function useGetRequestJobsServiceByJobUuid<
    TData = Awaited<ReturnType<typeof getRequestJobsServiceByJobUuid>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestJobsServiceByJobUuid>>,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestJobsServiceByJobUuid>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestJobsServiceByJobUuid>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestJobsServiceByJobUuid<
    TData = Awaited<ReturnType<typeof getRequestJobsServiceByJobUuid>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestJobsServiceByJobUuid>>,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestJobsServiceByJobUuid>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestJobsServiceByJobUuid>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestJobsServiceByJobUuid<
    TData = Awaited<ReturnType<typeof getRequestJobsServiceByJobUuid>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestJobsServiceByJobUuid>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Job Service Get Handler
 */

export function useGetRequestJobsServiceByJobUuid<
    TData = Awaited<ReturnType<typeof getRequestJobsServiceByJobUuid>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestJobsServiceByJobUuid>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions = getGetRequestJobsServiceByJobUuidQueryOptions(
        jobUuid,
        options,
    )

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * Returns users that are subscribers, or have previously been subscribers to an album
 * @summary Get Album Users
 */
export const getRequestJobsUsersByJobUuid = (
    jobUuid: string,
    params: GetRequestJobsUsersByJobUuidParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<UsersResponseDTO>(
        {
            url: `/jobs/${encodeURIComponent(String(jobUuid))}/users`,
            method: 'GET',
            params,
            signal,
        },
        options,
    )
}

export const getGetRequestJobsUsersByJobUuidQueryKey = (
    jobUuid: string,
    params: GetRequestJobsUsersByJobUuidParams,
) => {
    return [`/jobs/${jobUuid}/users`, ...(params ? [params] : [])] as const
}

export const getGetRequestJobsUsersByJobUuidQueryOptions = <
    TData = Awaited<ReturnType<typeof getRequestJobsUsersByJobUuid>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestJobsUsersByJobUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestJobsUsersByJobUuid>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {}

    const queryKey =
        queryOptions?.queryKey ??
        getGetRequestJobsUsersByJobUuidQueryKey(jobUuid, params)

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getRequestJobsUsersByJobUuid>>
    > = ({ signal }) =>
        getRequestJobsUsersByJobUuid(jobUuid, params, requestOptions, signal)

    return {
        queryKey,
        queryFn,
        enabled: !!jobUuid,
        ...queryOptions,
    } as UseQueryOptions<
        Awaited<ReturnType<typeof getRequestJobsUsersByJobUuid>>,
        TError,
        TData
    > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRequestJobsUsersByJobUuidQueryResult = NonNullable<
    Awaited<ReturnType<typeof getRequestJobsUsersByJobUuid>>
>
export type GetRequestJobsUsersByJobUuidQueryError =
    ErrorType<void | HTTPValidationErrorDTO>

export function useGetRequestJobsUsersByJobUuid<
    TData = Awaited<ReturnType<typeof getRequestJobsUsersByJobUuid>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestJobsUsersByJobUuidParams,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestJobsUsersByJobUuid>>,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestJobsUsersByJobUuid>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestJobsUsersByJobUuid>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestJobsUsersByJobUuid<
    TData = Awaited<ReturnType<typeof getRequestJobsUsersByJobUuid>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestJobsUsersByJobUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestJobsUsersByJobUuid>>,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestJobsUsersByJobUuid>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestJobsUsersByJobUuid>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestJobsUsersByJobUuid<
    TData = Awaited<ReturnType<typeof getRequestJobsUsersByJobUuid>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestJobsUsersByJobUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestJobsUsersByJobUuid>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get Album Users
 */

export function useGetRequestJobsUsersByJobUuid<
    TData = Awaited<ReturnType<typeof getRequestJobsUsersByJobUuid>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestJobsUsersByJobUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestJobsUsersByJobUuid>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions = getGetRequestJobsUsersByJobUuidQueryOptions(
        jobUuid,
        params,
        options,
    )

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * This api call returns the amount of images per day in the timeline.

 * @summary Timeline Days
 */
export const getRequestJobsTimelineDaysByJobUuid = (
    jobUuid: string,
    params: GetRequestJobsTimelineDaysByJobUuidParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<TimelineDaysDTO>(
        {
            url: `/jobs/${encodeURIComponent(String(jobUuid))}/timeline/days`,
            method: 'GET',
            params,
            signal,
        },
        options,
    )
}

export const getGetRequestJobsTimelineDaysByJobUuidQueryKey = (
    jobUuid: string,
    params: GetRequestJobsTimelineDaysByJobUuidParams,
) => {
    return [
        `/jobs/${jobUuid}/timeline/days`,
        ...(params ? [params] : []),
    ] as const
}

export const getGetRequestJobsTimelineDaysByJobUuidQueryOptions = <
    TData = Awaited<ReturnType<typeof getRequestJobsTimelineDaysByJobUuid>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestJobsTimelineDaysByJobUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestJobsTimelineDaysByJobUuid>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {}

    const queryKey =
        queryOptions?.queryKey ??
        getGetRequestJobsTimelineDaysByJobUuidQueryKey(jobUuid, params)

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getRequestJobsTimelineDaysByJobUuid>>
    > = ({ signal }) =>
        getRequestJobsTimelineDaysByJobUuid(
            jobUuid,
            params,
            requestOptions,
            signal,
        )

    return {
        queryKey,
        queryFn,
        enabled: !!jobUuid,
        ...queryOptions,
    } as UseQueryOptions<
        Awaited<ReturnType<typeof getRequestJobsTimelineDaysByJobUuid>>,
        TError,
        TData
    > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRequestJobsTimelineDaysByJobUuidQueryResult = NonNullable<
    Awaited<ReturnType<typeof getRequestJobsTimelineDaysByJobUuid>>
>
export type GetRequestJobsTimelineDaysByJobUuidQueryError =
    ErrorType<void | HTTPValidationErrorDTO>

export function useGetRequestJobsTimelineDaysByJobUuid<
    TData = Awaited<ReturnType<typeof getRequestJobsTimelineDaysByJobUuid>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestJobsTimelineDaysByJobUuidParams,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestJobsTimelineDaysByJobUuid>>,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<
                        ReturnType<typeof getRequestJobsTimelineDaysByJobUuid>
                    >,
                    TError,
                    Awaited<
                        ReturnType<typeof getRequestJobsTimelineDaysByJobUuid>
                    >
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestJobsTimelineDaysByJobUuid<
    TData = Awaited<ReturnType<typeof getRequestJobsTimelineDaysByJobUuid>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestJobsTimelineDaysByJobUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestJobsTimelineDaysByJobUuid>>,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<
                        ReturnType<typeof getRequestJobsTimelineDaysByJobUuid>
                    >,
                    TError,
                    Awaited<
                        ReturnType<typeof getRequestJobsTimelineDaysByJobUuid>
                    >
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestJobsTimelineDaysByJobUuid<
    TData = Awaited<ReturnType<typeof getRequestJobsTimelineDaysByJobUuid>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestJobsTimelineDaysByJobUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestJobsTimelineDaysByJobUuid>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Timeline Days
 */

export function useGetRequestJobsTimelineDaysByJobUuid<
    TData = Awaited<ReturnType<typeof getRequestJobsTimelineDaysByJobUuid>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestJobsTimelineDaysByJobUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestJobsTimelineDaysByJobUuid>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions = getGetRequestJobsTimelineDaysByJobUuidQueryOptions(
        jobUuid,
        params,
        options,
    )

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * This api call returns the amount of images per months in the timeline.

 * @summary Timeline Months
 */
export const getRequestJobsTimelineMonthsByJobUuid = (
    jobUuid: string,
    params: GetRequestJobsTimelineMonthsByJobUuidParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<TimelineMonthsDTO>(
        {
            url: `/jobs/${encodeURIComponent(String(jobUuid))}/timeline/months`,
            method: 'GET',
            params,
            signal,
        },
        options,
    )
}

export const getGetRequestJobsTimelineMonthsByJobUuidQueryKey = (
    jobUuid: string,
    params: GetRequestJobsTimelineMonthsByJobUuidParams,
) => {
    return [
        `/jobs/${jobUuid}/timeline/months`,
        ...(params ? [params] : []),
    ] as const
}

export const getGetRequestJobsTimelineMonthsByJobUuidQueryOptions = <
    TData = Awaited<ReturnType<typeof getRequestJobsTimelineMonthsByJobUuid>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestJobsTimelineMonthsByJobUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<typeof getRequestJobsTimelineMonthsByJobUuid>
                >,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {}

    const queryKey =
        queryOptions?.queryKey ??
        getGetRequestJobsTimelineMonthsByJobUuidQueryKey(jobUuid, params)

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getRequestJobsTimelineMonthsByJobUuid>>
    > = ({ signal }) =>
        getRequestJobsTimelineMonthsByJobUuid(
            jobUuid,
            params,
            requestOptions,
            signal,
        )

    return {
        queryKey,
        queryFn,
        enabled: !!jobUuid,
        ...queryOptions,
    } as UseQueryOptions<
        Awaited<ReturnType<typeof getRequestJobsTimelineMonthsByJobUuid>>,
        TError,
        TData
    > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRequestJobsTimelineMonthsByJobUuidQueryResult = NonNullable<
    Awaited<ReturnType<typeof getRequestJobsTimelineMonthsByJobUuid>>
>
export type GetRequestJobsTimelineMonthsByJobUuidQueryError =
    ErrorType<void | HTTPValidationErrorDTO>

export function useGetRequestJobsTimelineMonthsByJobUuid<
    TData = Awaited<ReturnType<typeof getRequestJobsTimelineMonthsByJobUuid>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestJobsTimelineMonthsByJobUuidParams,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<typeof getRequestJobsTimelineMonthsByJobUuid>
                >,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<
                        ReturnType<typeof getRequestJobsTimelineMonthsByJobUuid>
                    >,
                    TError,
                    Awaited<
                        ReturnType<typeof getRequestJobsTimelineMonthsByJobUuid>
                    >
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestJobsTimelineMonthsByJobUuid<
    TData = Awaited<ReturnType<typeof getRequestJobsTimelineMonthsByJobUuid>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestJobsTimelineMonthsByJobUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<typeof getRequestJobsTimelineMonthsByJobUuid>
                >,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<
                        ReturnType<typeof getRequestJobsTimelineMonthsByJobUuid>
                    >,
                    TError,
                    Awaited<
                        ReturnType<typeof getRequestJobsTimelineMonthsByJobUuid>
                    >
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestJobsTimelineMonthsByJobUuid<
    TData = Awaited<ReturnType<typeof getRequestJobsTimelineMonthsByJobUuid>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestJobsTimelineMonthsByJobUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<typeof getRequestJobsTimelineMonthsByJobUuid>
                >,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Timeline Months
 */

export function useGetRequestJobsTimelineMonthsByJobUuid<
    TData = Awaited<ReturnType<typeof getRequestJobsTimelineMonthsByJobUuid>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestJobsTimelineMonthsByJobUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<typeof getRequestJobsTimelineMonthsByJobUuid>
                >,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions = getGetRequestJobsTimelineMonthsByJobUuidQueryOptions(
        jobUuid,
        params,
        options,
    )

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * Add an attribute to a job. If attributes for a job is set, it will be part of the job object

 * @summary Put Attribute On Job
 */
export const putRequestJobsAttributeByJobUuidAndAttribute = (
    jobUuid: string,
    attribute: string,
    params: PutRequestJobsAttributeByJobUuidAndAttributeParams,
    options?: SecondParameter<typeof customInstance>,
) => {
    return customInstance<unknown | void>(
        {
            url: `/jobs/${encodeURIComponent(String(jobUuid))}/attribute/${encodeURIComponent(String(attribute))}`,
            method: 'PUT',
            params,
        },
        options,
    )
}

export const getPutRequestJobsAttributeByJobUuidAndAttributeMutationOptions = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<typeof putRequestJobsAttributeByJobUuidAndAttribute>
        >,
        TError,
        {
            jobUuid: string
            attribute: string
            params: PutRequestJobsAttributeByJobUuidAndAttributeParams
        },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof putRequestJobsAttributeByJobUuidAndAttribute>>,
    TError,
    {
        jobUuid: string
        attribute: string
        params: PutRequestJobsAttributeByJobUuidAndAttributeParams
    },
    TContext
> => {
    const mutationKey = ['putRequestJobsAttributeByJobUuidAndAttribute']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<
            ReturnType<typeof putRequestJobsAttributeByJobUuidAndAttribute>
        >,
        {
            jobUuid: string
            attribute: string
            params: PutRequestJobsAttributeByJobUuidAndAttributeParams
        }
    > = (props) => {
        const { jobUuid, attribute, params } = props ?? {}

        return putRequestJobsAttributeByJobUuidAndAttribute(
            jobUuid,
            attribute,
            params,
            requestOptions,
        )
    }

    return { mutationFn, ...mutationOptions }
}

export type PutRequestJobsAttributeByJobUuidAndAttributeMutationResult =
    NonNullable<
        Awaited<ReturnType<typeof putRequestJobsAttributeByJobUuidAndAttribute>>
    >

export type PutRequestJobsAttributeByJobUuidAndAttributeMutationError =
    ErrorType<void | HTTPValidationErrorDTO>

/**
 * @summary Put Attribute On Job
 */
export const usePutRequestJobsAttributeByJobUuidAndAttribute = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<typeof putRequestJobsAttributeByJobUuidAndAttribute>
        >,
        TError,
        {
            jobUuid: string
            attribute: string
            params: PutRequestJobsAttributeByJobUuidAndAttributeParams
        },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof putRequestJobsAttributeByJobUuidAndAttribute>>,
    TError,
    {
        jobUuid: string
        attribute: string
        params: PutRequestJobsAttributeByJobUuidAndAttributeParams
    },
    TContext
> => {
    const mutationOptions =
        getPutRequestJobsAttributeByJobUuidAndAttributeMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * This call returns the metadata of the specified file. The returned
json object includes the standard Photo/Image exif fields of the image
without the entire namespace tree.
If it is called without the optional *full* argument it returns an
object which may include the following tags

* Make
* Model
* ApertureValue
* ExposureTime
* FocalLength
* ISOSpeedRatings
* Capture.GpsPosition
* Capture.Country
* Capture.Municipality

There are capturized custom tags which are created for convenience
reasons. These are added into *Capture* namespace and are the
following

* Capture.Parser
* Capture.OrigWidth
* Capture.OrigHeight
* Capture.GpsPosition

The *Capture.GpsPosition* is provided as a three-elements float list
where indexes represent the *longitude*, *latitude* and *altitude*
coordinates correspondingly.

*NOTE* that this call could take long if the metadata is not in cache
or if it hasn't been acquired yet (e.g. files already uploaded) since
the for grabbing the metadata the original file must be fetched and
processed.

 * @summary Get File Metadata
 */
export const getRequestJobsMetadataByJobUuidAndFileUuid = (
    jobUuid: string,
    fileUuid: string,
    params: GetRequestJobsMetadataByJobUuidAndFileUuidParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<GetRequestJobsMetadataByJobUuidAndFileUuid200>(
        {
            url: `/jobs/${encodeURIComponent(String(jobUuid))}/metadata/${encodeURIComponent(String(fileUuid))}`,
            method: 'GET',
            params,
            signal,
        },
        options,
    )
}

export const getGetRequestJobsMetadataByJobUuidAndFileUuidQueryKey = (
    jobUuid: string,
    fileUuid: string,
    params: GetRequestJobsMetadataByJobUuidAndFileUuidParams,
) => {
    return [
        `/jobs/${jobUuid}/metadata/${fileUuid}`,
        ...(params ? [params] : []),
    ] as const
}

export const getGetRequestJobsMetadataByJobUuidAndFileUuidQueryOptions = <
    TData = Awaited<
        ReturnType<typeof getRequestJobsMetadataByJobUuidAndFileUuid>
    >,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    fileUuid: string,
    params: GetRequestJobsMetadataByJobUuidAndFileUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestJobsMetadataByJobUuidAndFileUuid
                    >
                >,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {}

    const queryKey =
        queryOptions?.queryKey ??
        getGetRequestJobsMetadataByJobUuidAndFileUuidQueryKey(
            jobUuid,
            fileUuid,
            params,
        )

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getRequestJobsMetadataByJobUuidAndFileUuid>>
    > = ({ signal }) =>
        getRequestJobsMetadataByJobUuidAndFileUuid(
            jobUuid,
            fileUuid,
            params,
            requestOptions,
            signal,
        )

    return {
        queryKey,
        queryFn,
        enabled: !!(jobUuid && fileUuid),
        ...queryOptions,
    } as UseQueryOptions<
        Awaited<ReturnType<typeof getRequestJobsMetadataByJobUuidAndFileUuid>>,
        TError,
        TData
    > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRequestJobsMetadataByJobUuidAndFileUuidQueryResult = NonNullable<
    Awaited<ReturnType<typeof getRequestJobsMetadataByJobUuidAndFileUuid>>
>
export type GetRequestJobsMetadataByJobUuidAndFileUuidQueryError =
    ErrorType<void | HTTPValidationErrorDTO>

export function useGetRequestJobsMetadataByJobUuidAndFileUuid<
    TData = Awaited<
        ReturnType<typeof getRequestJobsMetadataByJobUuidAndFileUuid>
    >,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    fileUuid: string,
    params: GetRequestJobsMetadataByJobUuidAndFileUuidParams,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestJobsMetadataByJobUuidAndFileUuid
                    >
                >,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<
                        ReturnType<
                            typeof getRequestJobsMetadataByJobUuidAndFileUuid
                        >
                    >,
                    TError,
                    Awaited<
                        ReturnType<
                            typeof getRequestJobsMetadataByJobUuidAndFileUuid
                        >
                    >
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestJobsMetadataByJobUuidAndFileUuid<
    TData = Awaited<
        ReturnType<typeof getRequestJobsMetadataByJobUuidAndFileUuid>
    >,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    fileUuid: string,
    params: GetRequestJobsMetadataByJobUuidAndFileUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestJobsMetadataByJobUuidAndFileUuid
                    >
                >,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<
                        ReturnType<
                            typeof getRequestJobsMetadataByJobUuidAndFileUuid
                        >
                    >,
                    TError,
                    Awaited<
                        ReturnType<
                            typeof getRequestJobsMetadataByJobUuidAndFileUuid
                        >
                    >
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestJobsMetadataByJobUuidAndFileUuid<
    TData = Awaited<
        ReturnType<typeof getRequestJobsMetadataByJobUuidAndFileUuid>
    >,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    fileUuid: string,
    params: GetRequestJobsMetadataByJobUuidAndFileUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestJobsMetadataByJobUuidAndFileUuid
                    >
                >,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get File Metadata
 */

export function useGetRequestJobsMetadataByJobUuidAndFileUuid<
    TData = Awaited<
        ReturnType<typeof getRequestJobsMetadataByJobUuidAndFileUuid>
    >,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    fileUuid: string,
    params: GetRequestJobsMetadataByJobUuidAndFileUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestJobsMetadataByJobUuidAndFileUuid
                    >
                >,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions =
        getGetRequestJobsMetadataByJobUuidAndFileUuidQueryOptions(
            jobUuid,
            fileUuid,
            params,
            options,
        )

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * Upload a file and connect it to the specified job.
- Note when using a mobile API key and uploading a file with a name that
already exists it will have file_name+checksum as filename.
When using a PC client API key the existing file will be overwritten.
- Note when uploading a file that would overwrite an existing path
no FileEvent will be created for the deleted file.

 * @summary Post Sync Upload
 */
export const postRequestJobsFilesByJobUuid = (
    jobUuid: string,
    postRequestJobsFilesByJobUuidBodiesTwo: BodyType<PostRequestJobsFilesByJobUuidBodiesTwo>,
    params: PostRequestJobsFilesByJobUuidParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    const formData = new FormData()
    if (postRequestJobsFilesByJobUuidBodiesTwo.file !== undefined) {
        formData.append('file', postRequestJobsFilesByJobUuidBodiesTwo.file)
    }

    return customInstance<UploadResponseDTO | void>(
        {
            url: `/jobs/${encodeURIComponent(String(jobUuid))}/files`,
            method: 'POST',
            headers: { 'Content-Type': 'multipart/form-data' },
            data: formData,
            params,
            signal,
        },
        options,
    )
}

export const getPostRequestJobsFilesByJobUuidMutationOptions = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestJobsFilesByJobUuid>>,
        TError,
        {
            jobUuid: string
            data: BodyType<PostRequestJobsFilesByJobUuidBodiesTwo>
            params: PostRequestJobsFilesByJobUuidParams
        },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestJobsFilesByJobUuid>>,
    TError,
    {
        jobUuid: string
        data: BodyType<PostRequestJobsFilesByJobUuidBodiesTwo>
        params: PostRequestJobsFilesByJobUuidParams
    },
    TContext
> => {
    const mutationKey = ['postRequestJobsFilesByJobUuid']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postRequestJobsFilesByJobUuid>>,
        {
            jobUuid: string
            data: BodyType<PostRequestJobsFilesByJobUuidBodiesTwo>
            params: PostRequestJobsFilesByJobUuidParams
        }
    > = (props) => {
        const { jobUuid, data, params } = props ?? {}

        return postRequestJobsFilesByJobUuid(
            jobUuid,
            data,
            params,
            requestOptions,
        )
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestJobsFilesByJobUuidMutationResult = NonNullable<
    Awaited<ReturnType<typeof postRequestJobsFilesByJobUuid>>
>
export type PostRequestJobsFilesByJobUuidMutationBody =
    BodyType<PostRequestJobsFilesByJobUuidBodiesTwo>
export type PostRequestJobsFilesByJobUuidMutationError =
    ErrorType<void | HTTPValidationErrorDTO>

/**
 * @summary Post Sync Upload
 */
export const usePostRequestJobsFilesByJobUuid = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestJobsFilesByJobUuid>>,
        TError,
        {
            jobUuid: string
            data: BodyType<PostRequestJobsFilesByJobUuidBodiesTwo>
            params: PostRequestJobsFilesByJobUuidParams
        },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestJobsFilesByJobUuid>>,
    TError,
    {
        jobUuid: string
        data: BodyType<PostRequestJobsFilesByJobUuidBodiesTwo>
        params: PostRequestJobsFilesByJobUuidParams
    },
    TContext
> => {
    const mutationOptions =
        getPostRequestJobsFilesByJobUuidMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * Lists files for a specific job that can be used in following ways:
- If _path_ parameter specified every files in the directory will be returned (excluding paths from subdirectories) for the job. The query can be combined with _dirs_only_, _recursive_ or file filters such as  _file_type_filters_, __uploaded_since__ or __reaction__ parameters to reduce or extend the files to be returned. If _dirs_only_ specified  any value in file filters will be ignored.
- If _path_ is not provided it returns all files belonging to the job. The returned files may be limited with _dirs_only_, _start_, _end_ or file filters query arguments. The _recursive_ parameter will be ignored if provided.

 * @summary Get Job Files
 */
export const getRequestJobsFilesByJobUuid = (
    jobUuid: string,
    params: GetRequestJobsFilesByJobUuidParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<FileObjectResponseDTO[]>(
        {
            url: `/jobs/${encodeURIComponent(String(jobUuid))}/files`,
            method: 'GET',
            params,
            signal,
        },
        options,
    )
}

export const getGetRequestJobsFilesByJobUuidQueryKey = (
    jobUuid: string,
    params: GetRequestJobsFilesByJobUuidParams,
) => {
    return [`/jobs/${jobUuid}/files`, ...(params ? [params] : [])] as const
}

export const getGetRequestJobsFilesByJobUuidQueryOptions = <
    TData = Awaited<ReturnType<typeof getRequestJobsFilesByJobUuid>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestJobsFilesByJobUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestJobsFilesByJobUuid>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {}

    const queryKey =
        queryOptions?.queryKey ??
        getGetRequestJobsFilesByJobUuidQueryKey(jobUuid, params)

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getRequestJobsFilesByJobUuid>>
    > = ({ signal }) =>
        getRequestJobsFilesByJobUuid(jobUuid, params, requestOptions, signal)

    return {
        queryKey,
        queryFn,
        enabled: !!jobUuid,
        ...queryOptions,
    } as UseQueryOptions<
        Awaited<ReturnType<typeof getRequestJobsFilesByJobUuid>>,
        TError,
        TData
    > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRequestJobsFilesByJobUuidQueryResult = NonNullable<
    Awaited<ReturnType<typeof getRequestJobsFilesByJobUuid>>
>
export type GetRequestJobsFilesByJobUuidQueryError =
    ErrorType<void | HTTPValidationErrorDTO>

export function useGetRequestJobsFilesByJobUuid<
    TData = Awaited<ReturnType<typeof getRequestJobsFilesByJobUuid>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestJobsFilesByJobUuidParams,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestJobsFilesByJobUuid>>,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestJobsFilesByJobUuid>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestJobsFilesByJobUuid>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestJobsFilesByJobUuid<
    TData = Awaited<ReturnType<typeof getRequestJobsFilesByJobUuid>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestJobsFilesByJobUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestJobsFilesByJobUuid>>,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestJobsFilesByJobUuid>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestJobsFilesByJobUuid>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestJobsFilesByJobUuid<
    TData = Awaited<ReturnType<typeof getRequestJobsFilesByJobUuid>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestJobsFilesByJobUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestJobsFilesByJobUuid>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get Job Files
 */

export function useGetRequestJobsFilesByJobUuid<
    TData = Awaited<ReturnType<typeof getRequestJobsFilesByJobUuid>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestJobsFilesByJobUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestJobsFilesByJobUuid>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions = getGetRequestJobsFilesByJobUuidQueryOptions(
        jobUuid,
        params,
        options,
    )

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * Upload a file to cache and schedule it for asynchronous processing. The call returns the file uuid. The endpoint validates user inputs and checksum if it is provided. If the transfer has been interrupted either by the client or due to unstable network, upload should be restarted from beginning.
 * @summary Post Sync Upload With Async Processing
 */
export const postRequestJobsUploadByJobUuid = (
    jobUuid: string,
    postRequestJobsUploadByJobUuidBodiesTwo: BodyType<PostRequestJobsUploadByJobUuidBodiesTwo>,
    params: PostRequestJobsUploadByJobUuidParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    const formData = new FormData()
    if (postRequestJobsUploadByJobUuidBodiesTwo.file !== undefined) {
        formData.append('file', postRequestJobsUploadByJobUuidBodiesTwo.file)
    }

    return customInstance<PostRequestJobsUploadByJobUuid200>(
        {
            url: `/jobs/${encodeURIComponent(String(jobUuid))}/upload`,
            method: 'POST',
            headers: { 'Content-Type': 'multipart/form-data' },
            data: formData,
            params,
            signal,
        },
        options,
    )
}

export const getPostRequestJobsUploadByJobUuidMutationOptions = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestJobsUploadByJobUuid>>,
        TError,
        {
            jobUuid: string
            data: BodyType<PostRequestJobsUploadByJobUuidBodiesTwo>
            params: PostRequestJobsUploadByJobUuidParams
        },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestJobsUploadByJobUuid>>,
    TError,
    {
        jobUuid: string
        data: BodyType<PostRequestJobsUploadByJobUuidBodiesTwo>
        params: PostRequestJobsUploadByJobUuidParams
    },
    TContext
> => {
    const mutationKey = ['postRequestJobsUploadByJobUuid']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postRequestJobsUploadByJobUuid>>,
        {
            jobUuid: string
            data: BodyType<PostRequestJobsUploadByJobUuidBodiesTwo>
            params: PostRequestJobsUploadByJobUuidParams
        }
    > = (props) => {
        const { jobUuid, data, params } = props ?? {}

        return postRequestJobsUploadByJobUuid(
            jobUuid,
            data,
            params,
            requestOptions,
        )
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestJobsUploadByJobUuidMutationResult = NonNullable<
    Awaited<ReturnType<typeof postRequestJobsUploadByJobUuid>>
>
export type PostRequestJobsUploadByJobUuidMutationBody =
    BodyType<PostRequestJobsUploadByJobUuidBodiesTwo>
export type PostRequestJobsUploadByJobUuidMutationError =
    ErrorType<void | HTTPValidationErrorDTO>

/**
 * @summary Post Sync Upload With Async Processing
 */
export const usePostRequestJobsUploadByJobUuid = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestJobsUploadByJobUuid>>,
        TError,
        {
            jobUuid: string
            data: BodyType<PostRequestJobsUploadByJobUuidBodiesTwo>
            params: PostRequestJobsUploadByJobUuidParams
        },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestJobsUploadByJobUuid>>,
    TError,
    {
        jobUuid: string
        data: BodyType<PostRequestJobsUploadByJobUuidBodiesTwo>
        params: PostRequestJobsUploadByJobUuidParams
    },
    TContext
> => {
    const mutationOptions =
        getPostRequestJobsUploadByJobUuidMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * this api call is used to download a file.

 * @summary Get File
 */
export const getRequestJobsFilesByIdByJobUuidAndFileUuid = (
    jobUuid: string,
    fileUuid: string,
    _getRequestJobsFilesByIdByJobUuidAndFileUuidBodies: BodyType<GetRequestJobsFilesByIdByJobUuidAndFileUuidBodies>,
    params: GetRequestJobsFilesByIdByJobUuidAndFileUuidParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<SubFileStatusResponseDTO>(
        {
            url: `/jobs/${encodeURIComponent(String(jobUuid))}/files_by_id/${encodeURIComponent(String(fileUuid))}`,
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            params,
            signal,
        },
        options,
    )
}

export const getGetRequestJobsFilesByIdByJobUuidAndFileUuidQueryKey = (
    jobUuid: string,
    fileUuid: string,
    getRequestJobsFilesByIdByJobUuidAndFileUuidBodies: GetRequestJobsFilesByIdByJobUuidAndFileUuidBodies,
    params: GetRequestJobsFilesByIdByJobUuidAndFileUuidParams,
) => {
    return [
        `/jobs/${jobUuid}/files_by_id/${fileUuid}`,
        ...(params ? [params] : []),
        getRequestJobsFilesByIdByJobUuidAndFileUuidBodies,
    ] as const
}

export const getGetRequestJobsFilesByIdByJobUuidAndFileUuidQueryOptions = <
    TData = Awaited<
        ReturnType<typeof getRequestJobsFilesByIdByJobUuidAndFileUuid>
    >,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    fileUuid: string,
    getRequestJobsFilesByIdByJobUuidAndFileUuidBodies: GetRequestJobsFilesByIdByJobUuidAndFileUuidBodies,
    params: GetRequestJobsFilesByIdByJobUuidAndFileUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestJobsFilesByIdByJobUuidAndFileUuid
                    >
                >,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {}

    const queryKey =
        queryOptions?.queryKey ??
        getGetRequestJobsFilesByIdByJobUuidAndFileUuidQueryKey(
            jobUuid,
            fileUuid,
            getRequestJobsFilesByIdByJobUuidAndFileUuidBodies,
            params,
        )

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getRequestJobsFilesByIdByJobUuidAndFileUuid>>
    > = ({ signal }) =>
        getRequestJobsFilesByIdByJobUuidAndFileUuid(
            jobUuid,
            fileUuid,
            getRequestJobsFilesByIdByJobUuidAndFileUuidBodies,
            params,
            requestOptions,
            signal,
        )

    return {
        queryKey,
        queryFn,
        enabled: !!(jobUuid && fileUuid),
        ...queryOptions,
    } as UseQueryOptions<
        Awaited<ReturnType<typeof getRequestJobsFilesByIdByJobUuidAndFileUuid>>,
        TError,
        TData
    > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRequestJobsFilesByIdByJobUuidAndFileUuidQueryResult =
    NonNullable<
        Awaited<ReturnType<typeof getRequestJobsFilesByIdByJobUuidAndFileUuid>>
    >
export type GetRequestJobsFilesByIdByJobUuidAndFileUuidQueryError =
    ErrorType<void | HTTPValidationErrorDTO>

export function useGetRequestJobsFilesByIdByJobUuidAndFileUuid<
    TData = Awaited<
        ReturnType<typeof getRequestJobsFilesByIdByJobUuidAndFileUuid>
    >,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    fileUuid: string,
    getRequestJobsFilesByIdByJobUuidAndFileUuidBodies: GetRequestJobsFilesByIdByJobUuidAndFileUuidBodies,
    params: GetRequestJobsFilesByIdByJobUuidAndFileUuidParams,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestJobsFilesByIdByJobUuidAndFileUuid
                    >
                >,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<
                        ReturnType<
                            typeof getRequestJobsFilesByIdByJobUuidAndFileUuid
                        >
                    >,
                    TError,
                    Awaited<
                        ReturnType<
                            typeof getRequestJobsFilesByIdByJobUuidAndFileUuid
                        >
                    >
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestJobsFilesByIdByJobUuidAndFileUuid<
    TData = Awaited<
        ReturnType<typeof getRequestJobsFilesByIdByJobUuidAndFileUuid>
    >,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    fileUuid: string,
    getRequestJobsFilesByIdByJobUuidAndFileUuidBodies: GetRequestJobsFilesByIdByJobUuidAndFileUuidBodies,
    params: GetRequestJobsFilesByIdByJobUuidAndFileUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestJobsFilesByIdByJobUuidAndFileUuid
                    >
                >,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<
                        ReturnType<
                            typeof getRequestJobsFilesByIdByJobUuidAndFileUuid
                        >
                    >,
                    TError,
                    Awaited<
                        ReturnType<
                            typeof getRequestJobsFilesByIdByJobUuidAndFileUuid
                        >
                    >
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestJobsFilesByIdByJobUuidAndFileUuid<
    TData = Awaited<
        ReturnType<typeof getRequestJobsFilesByIdByJobUuidAndFileUuid>
    >,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    fileUuid: string,
    getRequestJobsFilesByIdByJobUuidAndFileUuidBodies: GetRequestJobsFilesByIdByJobUuidAndFileUuidBodies,
    params: GetRequestJobsFilesByIdByJobUuidAndFileUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestJobsFilesByIdByJobUuidAndFileUuid
                    >
                >,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get File
 */

export function useGetRequestJobsFilesByIdByJobUuidAndFileUuid<
    TData = Awaited<
        ReturnType<typeof getRequestJobsFilesByIdByJobUuidAndFileUuid>
    >,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    fileUuid: string,
    getRequestJobsFilesByIdByJobUuidAndFileUuidBodies: GetRequestJobsFilesByIdByJobUuidAndFileUuidBodies,
    params: GetRequestJobsFilesByIdByJobUuidAndFileUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestJobsFilesByIdByJobUuidAndFileUuid
                    >
                >,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions =
        getGetRequestJobsFilesByIdByJobUuidAndFileUuidQueryOptions(
            jobUuid,
            fileUuid,
            getRequestJobsFilesByIdByJobUuidAndFileUuidBodies,
            params,
            options,
        )

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * this call archive the files for a job
 * @summary Delete File Delete
 */
export const deleteRequestJobsFilesByIdByJobUuidAndFileUuid = (
    jobUuid: string,
    fileUuid: string,
    params: DeleteRequestJobsFilesByIdByJobUuidAndFileUuidParams,
    options?: SecondParameter<typeof customInstance>,
) => {
    return customInstance<DeleteRequestJobsFilesByIdByJobUuidAndFileUuid200>(
        {
            url: `/jobs/${encodeURIComponent(String(jobUuid))}/files_by_id/${encodeURIComponent(String(fileUuid))}`,
            method: 'DELETE',
            params,
        },
        options,
    )
}

export const getDeleteRequestJobsFilesByIdByJobUuidAndFileUuidMutationOptions =
    <
        TError = ErrorType<void | HTTPValidationErrorDTO>,
        TContext = unknown,
    >(options?: {
        mutation?: UseMutationOptions<
            Awaited<
                ReturnType<
                    typeof deleteRequestJobsFilesByIdByJobUuidAndFileUuid
                >
            >,
            TError,
            {
                jobUuid: string
                fileUuid: string
                params: DeleteRequestJobsFilesByIdByJobUuidAndFileUuidParams
            },
            TContext
        >
        request?: SecondParameter<typeof customInstance>
    }): UseMutationOptions<
        Awaited<
            ReturnType<typeof deleteRequestJobsFilesByIdByJobUuidAndFileUuid>
        >,
        TError,
        {
            jobUuid: string
            fileUuid: string
            params: DeleteRequestJobsFilesByIdByJobUuidAndFileUuidParams
        },
        TContext
    > => {
        const mutationKey = ['deleteRequestJobsFilesByIdByJobUuidAndFileUuid']
        const { mutation: mutationOptions, request: requestOptions } = options
            ? options.mutation &&
              'mutationKey' in options.mutation &&
              options.mutation.mutationKey
                ? options
                : { ...options, mutation: { ...options.mutation, mutationKey } }
            : { mutation: { mutationKey }, request: undefined }

        const mutationFn: MutationFunction<
            Awaited<
                ReturnType<
                    typeof deleteRequestJobsFilesByIdByJobUuidAndFileUuid
                >
            >,
            {
                jobUuid: string
                fileUuid: string
                params: DeleteRequestJobsFilesByIdByJobUuidAndFileUuidParams
            }
        > = (props) => {
            const { jobUuid, fileUuid, params } = props ?? {}

            return deleteRequestJobsFilesByIdByJobUuidAndFileUuid(
                jobUuid,
                fileUuid,
                params,
                requestOptions,
            )
        }

        return { mutationFn, ...mutationOptions }
    }

export type DeleteRequestJobsFilesByIdByJobUuidAndFileUuidMutationResult =
    NonNullable<
        Awaited<
            ReturnType<typeof deleteRequestJobsFilesByIdByJobUuidAndFileUuid>
        >
    >

export type DeleteRequestJobsFilesByIdByJobUuidAndFileUuidMutationError =
    ErrorType<void | HTTPValidationErrorDTO>

/**
 * @summary Delete File Delete
 */
export const useDeleteRequestJobsFilesByIdByJobUuidAndFileUuid = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<typeof deleteRequestJobsFilesByIdByJobUuidAndFileUuid>
        >,
        TError,
        {
            jobUuid: string
            fileUuid: string
            params: DeleteRequestJobsFilesByIdByJobUuidAndFileUuidParams
        },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof deleteRequestJobsFilesByIdByJobUuidAndFileUuid>>,
    TError,
    {
        jobUuid: string
        fileUuid: string
        params: DeleteRequestJobsFilesByIdByJobUuidAndFileUuidParams
    },
    TContext
> => {
    const mutationOptions =
        getDeleteRequestJobsFilesByIdByJobUuidAndFileUuidMutationOptions(
            options,
        )

    return useMutation(mutationOptions)
}

/**
 * @summary Dedup File
 */
export const getRequestJobsFilesDedupByJobUuid = (
    jobUuid: string,
    params: GetRequestJobsFilesDedupByJobUuidParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<UploadResponseDTO>(
        {
            url: `/jobs/${encodeURIComponent(String(jobUuid))}/files_dedup`,
            method: 'GET',
            params,
            signal,
        },
        options,
    )
}

export const getGetRequestJobsFilesDedupByJobUuidQueryKey = (
    jobUuid: string,
    params: GetRequestJobsFilesDedupByJobUuidParams,
) => {
    return [
        `/jobs/${jobUuid}/files_dedup`,
        ...(params ? [params] : []),
    ] as const
}

export const getGetRequestJobsFilesDedupByJobUuidQueryOptions = <
    TData = Awaited<ReturnType<typeof getRequestJobsFilesDedupByJobUuid>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestJobsFilesDedupByJobUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestJobsFilesDedupByJobUuid>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {}

    const queryKey =
        queryOptions?.queryKey ??
        getGetRequestJobsFilesDedupByJobUuidQueryKey(jobUuid, params)

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getRequestJobsFilesDedupByJobUuid>>
    > = ({ signal }) =>
        getRequestJobsFilesDedupByJobUuid(
            jobUuid,
            params,
            requestOptions,
            signal,
        )

    return {
        queryKey,
        queryFn,
        enabled: !!jobUuid,
        ...queryOptions,
    } as UseQueryOptions<
        Awaited<ReturnType<typeof getRequestJobsFilesDedupByJobUuid>>,
        TError,
        TData
    > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRequestJobsFilesDedupByJobUuidQueryResult = NonNullable<
    Awaited<ReturnType<typeof getRequestJobsFilesDedupByJobUuid>>
>
export type GetRequestJobsFilesDedupByJobUuidQueryError =
    ErrorType<HTTPValidationErrorDTO>

export function useGetRequestJobsFilesDedupByJobUuid<
    TData = Awaited<ReturnType<typeof getRequestJobsFilesDedupByJobUuid>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestJobsFilesDedupByJobUuidParams,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestJobsFilesDedupByJobUuid>>,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<
                        ReturnType<typeof getRequestJobsFilesDedupByJobUuid>
                    >,
                    TError,
                    Awaited<
                        ReturnType<typeof getRequestJobsFilesDedupByJobUuid>
                    >
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestJobsFilesDedupByJobUuid<
    TData = Awaited<ReturnType<typeof getRequestJobsFilesDedupByJobUuid>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestJobsFilesDedupByJobUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestJobsFilesDedupByJobUuid>>,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<
                        ReturnType<typeof getRequestJobsFilesDedupByJobUuid>
                    >,
                    TError,
                    Awaited<
                        ReturnType<typeof getRequestJobsFilesDedupByJobUuid>
                    >
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestJobsFilesDedupByJobUuid<
    TData = Awaited<ReturnType<typeof getRequestJobsFilesDedupByJobUuid>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestJobsFilesDedupByJobUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestJobsFilesDedupByJobUuid>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Dedup File
 */

export function useGetRequestJobsFilesDedupByJobUuid<
    TData = Awaited<ReturnType<typeof getRequestJobsFilesDedupByJobUuid>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestJobsFilesDedupByJobUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestJobsFilesDedupByJobUuid>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions = getGetRequestJobsFilesDedupByJobUuidQueryOptions(
        jobUuid,
        params,
        options,
    )

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * Add files to a job with no need to perform the actual upload of the file.
Using the checksum of the file to link the job to an already uploaded
file if Capture contains the file checksum.
- Note when using a mobile API key and uploading a file with a name that
already exists, it will have file_name+checksum as filename.
When using a PC client API key the existing file will be overwritten.
- Note when uploading a file that would overwrite an existing path
no FileEvent will be created for the deleted file.
- Due to some clients that are unable to POST with no data
we also have a GET endpoint but it is not supported.
- If *group_id* is specified, the file will be added to a group.
  One must also provide the *group_type*, and optionally the *master*
  flag, which defaults to 0.

 * @summary Dedup File
 */
export const postRequestJobsFilesDedupByJobUuid = (
    jobUuid: string,
    params: PostRequestJobsFilesDedupByJobUuidParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<UploadResponseDTO>(
        {
            url: `/jobs/${encodeURIComponent(String(jobUuid))}/files_dedup`,
            method: 'POST',
            params,
            signal,
        },
        options,
    )
}

export const getPostRequestJobsFilesDedupByJobUuidMutationOptions = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestJobsFilesDedupByJobUuid>>,
        TError,
        { jobUuid: string; params: PostRequestJobsFilesDedupByJobUuidParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestJobsFilesDedupByJobUuid>>,
    TError,
    { jobUuid: string; params: PostRequestJobsFilesDedupByJobUuidParams },
    TContext
> => {
    const mutationKey = ['postRequestJobsFilesDedupByJobUuid']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postRequestJobsFilesDedupByJobUuid>>,
        { jobUuid: string; params: PostRequestJobsFilesDedupByJobUuidParams }
    > = (props) => {
        const { jobUuid, params } = props ?? {}

        return postRequestJobsFilesDedupByJobUuid(
            jobUuid,
            params,
            requestOptions,
        )
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestJobsFilesDedupByJobUuidMutationResult = NonNullable<
    Awaited<ReturnType<typeof postRequestJobsFilesDedupByJobUuid>>
>

export type PostRequestJobsFilesDedupByJobUuidMutationError =
    ErrorType<void | HTTPValidationErrorDTO>

/**
 * @summary Dedup File
 */
export const usePostRequestJobsFilesDedupByJobUuid = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestJobsFilesDedupByJobUuid>>,
        TError,
        { jobUuid: string; params: PostRequestJobsFilesDedupByJobUuidParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestJobsFilesDedupByJobUuid>>,
    TError,
    { jobUuid: string; params: PostRequestJobsFilesDedupByJobUuidParams },
    TContext
> => {
    const mutationOptions =
        getPostRequestJobsFilesDedupByJobUuidMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * Get the status of a not yet complete upload
 * @summary Async Upload Check
 */
export const getRequestJobsAsyncUploadByJobUuidAndChecksum = (
    jobUuid: string,
    checksum: string,
    params: GetRequestJobsAsyncUploadByJobUuidAndChecksumParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<AsyncUploadStatusByChecksumResponseDTO>(
        {
            url: `/jobs/${encodeURIComponent(String(jobUuid))}/async_upload/${encodeURIComponent(String(checksum))}`,
            method: 'GET',
            params,
            signal,
        },
        options,
    )
}

export const getGetRequestJobsAsyncUploadByJobUuidAndChecksumQueryKey = (
    jobUuid: string,
    checksum: string,
    params: GetRequestJobsAsyncUploadByJobUuidAndChecksumParams,
) => {
    return [
        `/jobs/${jobUuid}/async_upload/${checksum}`,
        ...(params ? [params] : []),
    ] as const
}

export const getGetRequestJobsAsyncUploadByJobUuidAndChecksumQueryOptions = <
    TData = Awaited<
        ReturnType<typeof getRequestJobsAsyncUploadByJobUuidAndChecksum>
    >,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    checksum: string,
    params: GetRequestJobsAsyncUploadByJobUuidAndChecksumParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestJobsAsyncUploadByJobUuidAndChecksum
                    >
                >,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {}

    const queryKey =
        queryOptions?.queryKey ??
        getGetRequestJobsAsyncUploadByJobUuidAndChecksumQueryKey(
            jobUuid,
            checksum,
            params,
        )

    const queryFn: QueryFunction<
        Awaited<
            ReturnType<typeof getRequestJobsAsyncUploadByJobUuidAndChecksum>
        >
    > = ({ signal }) =>
        getRequestJobsAsyncUploadByJobUuidAndChecksum(
            jobUuid,
            checksum,
            params,
            requestOptions,
            signal,
        )

    return {
        queryKey,
        queryFn,
        enabled: !!(jobUuid && checksum),
        ...queryOptions,
    } as UseQueryOptions<
        Awaited<
            ReturnType<typeof getRequestJobsAsyncUploadByJobUuidAndChecksum>
        >,
        TError,
        TData
    > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRequestJobsAsyncUploadByJobUuidAndChecksumQueryResult =
    NonNullable<
        Awaited<
            ReturnType<typeof getRequestJobsAsyncUploadByJobUuidAndChecksum>
        >
    >
export type GetRequestJobsAsyncUploadByJobUuidAndChecksumQueryError =
    ErrorType<void | HTTPValidationErrorDTO>

export function useGetRequestJobsAsyncUploadByJobUuidAndChecksum<
    TData = Awaited<
        ReturnType<typeof getRequestJobsAsyncUploadByJobUuidAndChecksum>
    >,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    checksum: string,
    params: GetRequestJobsAsyncUploadByJobUuidAndChecksumParams,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestJobsAsyncUploadByJobUuidAndChecksum
                    >
                >,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<
                        ReturnType<
                            typeof getRequestJobsAsyncUploadByJobUuidAndChecksum
                        >
                    >,
                    TError,
                    Awaited<
                        ReturnType<
                            typeof getRequestJobsAsyncUploadByJobUuidAndChecksum
                        >
                    >
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestJobsAsyncUploadByJobUuidAndChecksum<
    TData = Awaited<
        ReturnType<typeof getRequestJobsAsyncUploadByJobUuidAndChecksum>
    >,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    checksum: string,
    params: GetRequestJobsAsyncUploadByJobUuidAndChecksumParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestJobsAsyncUploadByJobUuidAndChecksum
                    >
                >,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<
                        ReturnType<
                            typeof getRequestJobsAsyncUploadByJobUuidAndChecksum
                        >
                    >,
                    TError,
                    Awaited<
                        ReturnType<
                            typeof getRequestJobsAsyncUploadByJobUuidAndChecksum
                        >
                    >
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestJobsAsyncUploadByJobUuidAndChecksum<
    TData = Awaited<
        ReturnType<typeof getRequestJobsAsyncUploadByJobUuidAndChecksum>
    >,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    checksum: string,
    params: GetRequestJobsAsyncUploadByJobUuidAndChecksumParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestJobsAsyncUploadByJobUuidAndChecksum
                    >
                >,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Async Upload Check
 */

export function useGetRequestJobsAsyncUploadByJobUuidAndChecksum<
    TData = Awaited<
        ReturnType<typeof getRequestJobsAsyncUploadByJobUuidAndChecksum>
    >,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    checksum: string,
    params: GetRequestJobsAsyncUploadByJobUuidAndChecksumParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestJobsAsyncUploadByJobUuidAndChecksum
                    >
                >,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions =
        getGetRequestJobsAsyncUploadByJobUuidAndChecksumQueryOptions(
            jobUuid,
            checksum,
            params,
            options,
        )

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * Upload a file and schedule it for asynchronous processing after the transfer has been completed.
The endpoint validates user inputs and verifies the received data against the provided checksum. If the transfer has been interrupted either by the client  or due to unstable network the partial content will still be stored together with the known byte offset. The client can continue to upload the rest of the data by providing the correct offset. Note that the offset is not arbitrary. Discontinuity in the provided offset is not allowed and will be rejected. If the client offset differs the known offset will be returned in the error response.
 * @summary Async Upload File
 */
export const postRequestJobsAsyncUploadByJobUuidAndChecksum = (
    jobUuid: string,
    checksum: string,
    postRequestJobsAsyncUploadByJobUuidAndChecksumBodiesTwo: BodyType<PostRequestJobsAsyncUploadByJobUuidAndChecksumBodiesTwo>,
    params: PostRequestJobsAsyncUploadByJobUuidAndChecksumParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    const formData = new FormData()
    if (
        postRequestJobsAsyncUploadByJobUuidAndChecksumBodiesTwo.file !==
        undefined
    ) {
        formData.append(
            'file',
            postRequestJobsAsyncUploadByJobUuidAndChecksumBodiesTwo.file,
        )
    }

    return customInstance<PostRequestJobsAsyncUploadByJobUuidAndChecksum200>(
        {
            url: `/jobs/${encodeURIComponent(String(jobUuid))}/async_upload/${encodeURIComponent(String(checksum))}`,
            method: 'POST',
            headers: { 'Content-Type': 'multipart/form-data' },
            data: formData,
            params,
            signal,
        },
        options,
    )
}

export const getPostRequestJobsAsyncUploadByJobUuidAndChecksumMutationOptions =
    <
        TError = ErrorType<void | HTTPValidationErrorDTO>,
        TContext = unknown,
    >(options?: {
        mutation?: UseMutationOptions<
            Awaited<
                ReturnType<
                    typeof postRequestJobsAsyncUploadByJobUuidAndChecksum
                >
            >,
            TError,
            {
                jobUuid: string
                checksum: string
                data: BodyType<PostRequestJobsAsyncUploadByJobUuidAndChecksumBodiesTwo>
                params: PostRequestJobsAsyncUploadByJobUuidAndChecksumParams
            },
            TContext
        >
        request?: SecondParameter<typeof customInstance>
    }): UseMutationOptions<
        Awaited<
            ReturnType<typeof postRequestJobsAsyncUploadByJobUuidAndChecksum>
        >,
        TError,
        {
            jobUuid: string
            checksum: string
            data: BodyType<PostRequestJobsAsyncUploadByJobUuidAndChecksumBodiesTwo>
            params: PostRequestJobsAsyncUploadByJobUuidAndChecksumParams
        },
        TContext
    > => {
        const mutationKey = ['postRequestJobsAsyncUploadByJobUuidAndChecksum']
        const { mutation: mutationOptions, request: requestOptions } = options
            ? options.mutation &&
              'mutationKey' in options.mutation &&
              options.mutation.mutationKey
                ? options
                : { ...options, mutation: { ...options.mutation, mutationKey } }
            : { mutation: { mutationKey }, request: undefined }

        const mutationFn: MutationFunction<
            Awaited<
                ReturnType<
                    typeof postRequestJobsAsyncUploadByJobUuidAndChecksum
                >
            >,
            {
                jobUuid: string
                checksum: string
                data: BodyType<PostRequestJobsAsyncUploadByJobUuidAndChecksumBodiesTwo>
                params: PostRequestJobsAsyncUploadByJobUuidAndChecksumParams
            }
        > = (props) => {
            const { jobUuid, checksum, data, params } = props ?? {}

            return postRequestJobsAsyncUploadByJobUuidAndChecksum(
                jobUuid,
                checksum,
                data,
                params,
                requestOptions,
            )
        }

        return { mutationFn, ...mutationOptions }
    }

export type PostRequestJobsAsyncUploadByJobUuidAndChecksumMutationResult =
    NonNullable<
        Awaited<
            ReturnType<typeof postRequestJobsAsyncUploadByJobUuidAndChecksum>
        >
    >
export type PostRequestJobsAsyncUploadByJobUuidAndChecksumMutationBody =
    BodyType<PostRequestJobsAsyncUploadByJobUuidAndChecksumBodiesTwo>
export type PostRequestJobsAsyncUploadByJobUuidAndChecksumMutationError =
    ErrorType<void | HTTPValidationErrorDTO>

/**
 * @summary Async Upload File
 */
export const usePostRequestJobsAsyncUploadByJobUuidAndChecksum = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<typeof postRequestJobsAsyncUploadByJobUuidAndChecksum>
        >,
        TError,
        {
            jobUuid: string
            checksum: string
            data: BodyType<PostRequestJobsAsyncUploadByJobUuidAndChecksumBodiesTwo>
            params: PostRequestJobsAsyncUploadByJobUuidAndChecksumParams
        },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestJobsAsyncUploadByJobUuidAndChecksum>>,
    TError,
    {
        jobUuid: string
        checksum: string
        data: BodyType<PostRequestJobsAsyncUploadByJobUuidAndChecksumBodiesTwo>
        params: PostRequestJobsAsyncUploadByJobUuidAndChecksumParams
    },
    TContext
> => {
    const mutationOptions =
        getPostRequestJobsAsyncUploadByJobUuidAndChecksumMutationOptions(
            options,
        )

    return useMutation(mutationOptions)
}

/**
 * Get the status of a not yet completed upload for the provided upload id
 * @summary Async Upload With Upload Id Check
 */
export const getRequestJobsAsyncUploadWithUploadIdByJobUuidAndUploadId = (
    jobUuid: string,
    uploadId: string,
    params: GetRequestJobsAsyncUploadWithUploadIdByJobUuidAndUploadIdParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<AsyncUploadStatusByExternalIdResponseDTO>(
        {
            url: `/jobs/${encodeURIComponent(String(jobUuid))}/async_upload_with_upload_id/${encodeURIComponent(String(uploadId))}`,
            method: 'GET',
            params,
            signal,
        },
        options,
    )
}

export const getGetRequestJobsAsyncUploadWithUploadIdByJobUuidAndUploadIdQueryKey =
    (
        jobUuid: string,
        uploadId: string,
        params: GetRequestJobsAsyncUploadWithUploadIdByJobUuidAndUploadIdParams,
    ) => {
        return [
            `/jobs/${jobUuid}/async_upload_with_upload_id/${uploadId}`,
            ...(params ? [params] : []),
        ] as const
    }

export const getGetRequestJobsAsyncUploadWithUploadIdByJobUuidAndUploadIdQueryOptions =
    <
        TData = Awaited<
            ReturnType<
                typeof getRequestJobsAsyncUploadWithUploadIdByJobUuidAndUploadId
            >
        >,
        TError = ErrorType<HTTPValidationErrorDTO>,
    >(
        jobUuid: string,
        uploadId: string,
        params: GetRequestJobsAsyncUploadWithUploadIdByJobUuidAndUploadIdParams,
        options?: {
            query?: Partial<
                UseQueryOptions<
                    Awaited<
                        ReturnType<
                            typeof getRequestJobsAsyncUploadWithUploadIdByJobUuidAndUploadId
                        >
                    >,
                    TError,
                    TData
                >
            >
            request?: SecondParameter<typeof customInstance>
        },
    ) => {
        const { query: queryOptions, request: requestOptions } = options ?? {}

        const queryKey =
            queryOptions?.queryKey ??
            getGetRequestJobsAsyncUploadWithUploadIdByJobUuidAndUploadIdQueryKey(
                jobUuid,
                uploadId,
                params,
            )

        const queryFn: QueryFunction<
            Awaited<
                ReturnType<
                    typeof getRequestJobsAsyncUploadWithUploadIdByJobUuidAndUploadId
                >
            >
        > = ({ signal }) =>
            getRequestJobsAsyncUploadWithUploadIdByJobUuidAndUploadId(
                jobUuid,
                uploadId,
                params,
                requestOptions,
                signal,
            )

        return {
            queryKey,
            queryFn,
            enabled: !!(jobUuid && uploadId),
            ...queryOptions,
        } as UseQueryOptions<
            Awaited<
                ReturnType<
                    typeof getRequestJobsAsyncUploadWithUploadIdByJobUuidAndUploadId
                >
            >,
            TError,
            TData
        > & { queryKey: DataTag<QueryKey, TData, TError> }
    }

export type GetRequestJobsAsyncUploadWithUploadIdByJobUuidAndUploadIdQueryResult =
    NonNullable<
        Awaited<
            ReturnType<
                typeof getRequestJobsAsyncUploadWithUploadIdByJobUuidAndUploadId
            >
        >
    >
export type GetRequestJobsAsyncUploadWithUploadIdByJobUuidAndUploadIdQueryError =
    ErrorType<HTTPValidationErrorDTO>

export function useGetRequestJobsAsyncUploadWithUploadIdByJobUuidAndUploadId<
    TData = Awaited<
        ReturnType<
            typeof getRequestJobsAsyncUploadWithUploadIdByJobUuidAndUploadId
        >
    >,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    uploadId: string,
    params: GetRequestJobsAsyncUploadWithUploadIdByJobUuidAndUploadIdParams,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestJobsAsyncUploadWithUploadIdByJobUuidAndUploadId
                    >
                >,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<
                        ReturnType<
                            typeof getRequestJobsAsyncUploadWithUploadIdByJobUuidAndUploadId
                        >
                    >,
                    TError,
                    Awaited<
                        ReturnType<
                            typeof getRequestJobsAsyncUploadWithUploadIdByJobUuidAndUploadId
                        >
                    >
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestJobsAsyncUploadWithUploadIdByJobUuidAndUploadId<
    TData = Awaited<
        ReturnType<
            typeof getRequestJobsAsyncUploadWithUploadIdByJobUuidAndUploadId
        >
    >,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    uploadId: string,
    params: GetRequestJobsAsyncUploadWithUploadIdByJobUuidAndUploadIdParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestJobsAsyncUploadWithUploadIdByJobUuidAndUploadId
                    >
                >,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<
                        ReturnType<
                            typeof getRequestJobsAsyncUploadWithUploadIdByJobUuidAndUploadId
                        >
                    >,
                    TError,
                    Awaited<
                        ReturnType<
                            typeof getRequestJobsAsyncUploadWithUploadIdByJobUuidAndUploadId
                        >
                    >
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestJobsAsyncUploadWithUploadIdByJobUuidAndUploadId<
    TData = Awaited<
        ReturnType<
            typeof getRequestJobsAsyncUploadWithUploadIdByJobUuidAndUploadId
        >
    >,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    uploadId: string,
    params: GetRequestJobsAsyncUploadWithUploadIdByJobUuidAndUploadIdParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestJobsAsyncUploadWithUploadIdByJobUuidAndUploadId
                    >
                >,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Async Upload With Upload Id Check
 */

export function useGetRequestJobsAsyncUploadWithUploadIdByJobUuidAndUploadId<
    TData = Awaited<
        ReturnType<
            typeof getRequestJobsAsyncUploadWithUploadIdByJobUuidAndUploadId
        >
    >,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    uploadId: string,
    params: GetRequestJobsAsyncUploadWithUploadIdByJobUuidAndUploadIdParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestJobsAsyncUploadWithUploadIdByJobUuidAndUploadId
                    >
                >,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions =
        getGetRequestJobsAsyncUploadWithUploadIdByJobUuidAndUploadIdQueryOptions(
            jobUuid,
            uploadId,
            params,
            options,
        )

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * Upload a file and schedule it for asynchronous processing after the transfer has been completed.
If the transfer has been interrupted either by the client or due to unstable network the partial
content will still be stored together with the known byte offset. The client can continue to
upload the rest of the data by providing the correct offset. Note that the offset is not
arbitrary. Discontinuity in the provided offset is not allowed and will be rejected. If the
client offset differs the known offset will be returned in the error response.
 * @summary Async Upload File With Upload Id
 */
export const postRequestJobsAsyncUploadWithUploadIdByJobUuidAndUploadId = (
    jobUuid: string,
    uploadId: string,
    postRequestJobsAsyncUploadWithUploadIdByJobUuidAndUploadIdBodiesTwo: BodyType<PostRequestJobsAsyncUploadWithUploadIdByJobUuidAndUploadIdBodiesTwo>,
    params: PostRequestJobsAsyncUploadWithUploadIdByJobUuidAndUploadIdParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    const formData = new FormData()
    if (
        postRequestJobsAsyncUploadWithUploadIdByJobUuidAndUploadIdBodiesTwo.file !==
        undefined
    ) {
        formData.append(
            'file',
            postRequestJobsAsyncUploadWithUploadIdByJobUuidAndUploadIdBodiesTwo.file,
        )
    }

    return customInstance<PostRequestJobsAsyncUploadWithUploadIdByJobUuidAndUploadId200>(
        {
            url: `/jobs/${encodeURIComponent(String(jobUuid))}/async_upload_with_upload_id/${encodeURIComponent(String(uploadId))}`,
            method: 'POST',
            headers: { 'Content-Type': 'multipart/form-data' },
            data: formData,
            params,
            signal,
        },
        options,
    )
}

export const getPostRequestJobsAsyncUploadWithUploadIdByJobUuidAndUploadIdMutationOptions =
    <TError = ErrorType<HTTPValidationErrorDTO>, TContext = unknown>(options?: {
        mutation?: UseMutationOptions<
            Awaited<
                ReturnType<
                    typeof postRequestJobsAsyncUploadWithUploadIdByJobUuidAndUploadId
                >
            >,
            TError,
            {
                jobUuid: string
                uploadId: string
                data: BodyType<PostRequestJobsAsyncUploadWithUploadIdByJobUuidAndUploadIdBodiesTwo>
                params: PostRequestJobsAsyncUploadWithUploadIdByJobUuidAndUploadIdParams
            },
            TContext
        >
        request?: SecondParameter<typeof customInstance>
    }): UseMutationOptions<
        Awaited<
            ReturnType<
                typeof postRequestJobsAsyncUploadWithUploadIdByJobUuidAndUploadId
            >
        >,
        TError,
        {
            jobUuid: string
            uploadId: string
            data: BodyType<PostRequestJobsAsyncUploadWithUploadIdByJobUuidAndUploadIdBodiesTwo>
            params: PostRequestJobsAsyncUploadWithUploadIdByJobUuidAndUploadIdParams
        },
        TContext
    > => {
        const mutationKey = [
            'postRequestJobsAsyncUploadWithUploadIdByJobUuidAndUploadId',
        ]
        const { mutation: mutationOptions, request: requestOptions } = options
            ? options.mutation &&
              'mutationKey' in options.mutation &&
              options.mutation.mutationKey
                ? options
                : { ...options, mutation: { ...options.mutation, mutationKey } }
            : { mutation: { mutationKey }, request: undefined }

        const mutationFn: MutationFunction<
            Awaited<
                ReturnType<
                    typeof postRequestJobsAsyncUploadWithUploadIdByJobUuidAndUploadId
                >
            >,
            {
                jobUuid: string
                uploadId: string
                data: BodyType<PostRequestJobsAsyncUploadWithUploadIdByJobUuidAndUploadIdBodiesTwo>
                params: PostRequestJobsAsyncUploadWithUploadIdByJobUuidAndUploadIdParams
            }
        > = (props) => {
            const { jobUuid, uploadId, data, params } = props ?? {}

            return postRequestJobsAsyncUploadWithUploadIdByJobUuidAndUploadId(
                jobUuid,
                uploadId,
                data,
                params,
                requestOptions,
            )
        }

        return { mutationFn, ...mutationOptions }
    }

export type PostRequestJobsAsyncUploadWithUploadIdByJobUuidAndUploadIdMutationResult =
    NonNullable<
        Awaited<
            ReturnType<
                typeof postRequestJobsAsyncUploadWithUploadIdByJobUuidAndUploadId
            >
        >
    >
export type PostRequestJobsAsyncUploadWithUploadIdByJobUuidAndUploadIdMutationBody =
    BodyType<PostRequestJobsAsyncUploadWithUploadIdByJobUuidAndUploadIdBodiesTwo>
export type PostRequestJobsAsyncUploadWithUploadIdByJobUuidAndUploadIdMutationError =
    ErrorType<HTTPValidationErrorDTO>

/**
 * @summary Async Upload File With Upload Id
 */
export const usePostRequestJobsAsyncUploadWithUploadIdByJobUuidAndUploadId = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<
                typeof postRequestJobsAsyncUploadWithUploadIdByJobUuidAndUploadId
            >
        >,
        TError,
        {
            jobUuid: string
            uploadId: string
            data: BodyType<PostRequestJobsAsyncUploadWithUploadIdByJobUuidAndUploadIdBodiesTwo>
            params: PostRequestJobsAsyncUploadWithUploadIdByJobUuidAndUploadIdParams
        },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<
        ReturnType<
            typeof postRequestJobsAsyncUploadWithUploadIdByJobUuidAndUploadId
        >
    >,
    TError,
    {
        jobUuid: string
        uploadId: string
        data: BodyType<PostRequestJobsAsyncUploadWithUploadIdByJobUuidAndUploadIdBodiesTwo>
        params: PostRequestJobsAsyncUploadWithUploadIdByJobUuidAndUploadIdParams
    },
    TContext
> => {
    const mutationOptions =
        getPostRequestJobsAsyncUploadWithUploadIdByJobUuidAndUploadIdMutationOptions(
            options,
        )

    return useMutation(mutationOptions)
}

/**
 * Get the status of a not yet complete uploads together with changes

 * @summary Changes And Async Upload Status
 */
export const getRequestJobsChangesAndAsyncUploadStatusByJobUuid = (
    jobUuid: string,
    params: GetRequestJobsChangesAndAsyncUploadStatusByJobUuidParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<AsyncUploadStatusAndChangesResponseDTO>(
        {
            url: `/jobs/${encodeURIComponent(String(jobUuid))}/changes_and_async_upload_status`,
            method: 'GET',
            params,
            signal,
        },
        options,
    )
}

export const getGetRequestJobsChangesAndAsyncUploadStatusByJobUuidQueryKey = (
    jobUuid: string,
    params: GetRequestJobsChangesAndAsyncUploadStatusByJobUuidParams,
) => {
    return [
        `/jobs/${jobUuid}/changes_and_async_upload_status`,
        ...(params ? [params] : []),
    ] as const
}

export const getGetRequestJobsChangesAndAsyncUploadStatusByJobUuidQueryOptions =
    <
        TData = Awaited<
            ReturnType<
                typeof getRequestJobsChangesAndAsyncUploadStatusByJobUuid
            >
        >,
        TError = ErrorType<HTTPValidationErrorDTO>,
    >(
        jobUuid: string,
        params: GetRequestJobsChangesAndAsyncUploadStatusByJobUuidParams,
        options?: {
            query?: Partial<
                UseQueryOptions<
                    Awaited<
                        ReturnType<
                            typeof getRequestJobsChangesAndAsyncUploadStatusByJobUuid
                        >
                    >,
                    TError,
                    TData
                >
            >
            request?: SecondParameter<typeof customInstance>
        },
    ) => {
        const { query: queryOptions, request: requestOptions } = options ?? {}

        const queryKey =
            queryOptions?.queryKey ??
            getGetRequestJobsChangesAndAsyncUploadStatusByJobUuidQueryKey(
                jobUuid,
                params,
            )

        const queryFn: QueryFunction<
            Awaited<
                ReturnType<
                    typeof getRequestJobsChangesAndAsyncUploadStatusByJobUuid
                >
            >
        > = ({ signal }) =>
            getRequestJobsChangesAndAsyncUploadStatusByJobUuid(
                jobUuid,
                params,
                requestOptions,
                signal,
            )

        return {
            queryKey,
            queryFn,
            enabled: !!jobUuid,
            ...queryOptions,
        } as UseQueryOptions<
            Awaited<
                ReturnType<
                    typeof getRequestJobsChangesAndAsyncUploadStatusByJobUuid
                >
            >,
            TError,
            TData
        > & { queryKey: DataTag<QueryKey, TData, TError> }
    }

export type GetRequestJobsChangesAndAsyncUploadStatusByJobUuidQueryResult =
    NonNullable<
        Awaited<
            ReturnType<
                typeof getRequestJobsChangesAndAsyncUploadStatusByJobUuid
            >
        >
    >
export type GetRequestJobsChangesAndAsyncUploadStatusByJobUuidQueryError =
    ErrorType<HTTPValidationErrorDTO>

export function useGetRequestJobsChangesAndAsyncUploadStatusByJobUuid<
    TData = Awaited<
        ReturnType<typeof getRequestJobsChangesAndAsyncUploadStatusByJobUuid>
    >,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestJobsChangesAndAsyncUploadStatusByJobUuidParams,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestJobsChangesAndAsyncUploadStatusByJobUuid
                    >
                >,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<
                        ReturnType<
                            typeof getRequestJobsChangesAndAsyncUploadStatusByJobUuid
                        >
                    >,
                    TError,
                    Awaited<
                        ReturnType<
                            typeof getRequestJobsChangesAndAsyncUploadStatusByJobUuid
                        >
                    >
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestJobsChangesAndAsyncUploadStatusByJobUuid<
    TData = Awaited<
        ReturnType<typeof getRequestJobsChangesAndAsyncUploadStatusByJobUuid>
    >,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestJobsChangesAndAsyncUploadStatusByJobUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestJobsChangesAndAsyncUploadStatusByJobUuid
                    >
                >,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<
                        ReturnType<
                            typeof getRequestJobsChangesAndAsyncUploadStatusByJobUuid
                        >
                    >,
                    TError,
                    Awaited<
                        ReturnType<
                            typeof getRequestJobsChangesAndAsyncUploadStatusByJobUuid
                        >
                    >
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestJobsChangesAndAsyncUploadStatusByJobUuid<
    TData = Awaited<
        ReturnType<typeof getRequestJobsChangesAndAsyncUploadStatusByJobUuid>
    >,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestJobsChangesAndAsyncUploadStatusByJobUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestJobsChangesAndAsyncUploadStatusByJobUuid
                    >
                >,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Changes And Async Upload Status
 */

export function useGetRequestJobsChangesAndAsyncUploadStatusByJobUuid<
    TData = Awaited<
        ReturnType<typeof getRequestJobsChangesAndAsyncUploadStatusByJobUuid>
    >,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestJobsChangesAndAsyncUploadStatusByJobUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestJobsChangesAndAsyncUploadStatusByJobUuid
                    >
                >,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions =
        getGetRequestJobsChangesAndAsyncUploadStatusByJobUuidQueryOptions(
            jobUuid,
            params,
            options,
        )

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * Get the changes and the status of the asynchronous uploads for the job.

The response contains the changes since the provided serial `since` together with the
statuses of the not yet completed asynchronous uploads.
 * @summary Changes And Async Upload Status V2
 */
export const getRequestV2JobsChangesAndAsyncUploadStatusByJobUuid = (
    jobUuid: string,
    params: GetRequestV2JobsChangesAndAsyncUploadStatusByJobUuidParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<AsyncUploadStatusAndChangesV2ResponseDTO>(
        {
            url: `/v2/jobs/${encodeURIComponent(String(jobUuid))}/changes_and_async_upload_status`,
            method: 'GET',
            params,
            signal,
        },
        options,
    )
}

export const getGetRequestV2JobsChangesAndAsyncUploadStatusByJobUuidQueryKey = (
    jobUuid: string,
    params: GetRequestV2JobsChangesAndAsyncUploadStatusByJobUuidParams,
) => {
    return [
        `/v2/jobs/${jobUuid}/changes_and_async_upload_status`,
        ...(params ? [params] : []),
    ] as const
}

export const getGetRequestV2JobsChangesAndAsyncUploadStatusByJobUuidQueryOptions =
    <
        TData = Awaited<
            ReturnType<
                typeof getRequestV2JobsChangesAndAsyncUploadStatusByJobUuid
            >
        >,
        TError = ErrorType<HTTPValidationErrorDTO>,
    >(
        jobUuid: string,
        params: GetRequestV2JobsChangesAndAsyncUploadStatusByJobUuidParams,
        options?: {
            query?: Partial<
                UseQueryOptions<
                    Awaited<
                        ReturnType<
                            typeof getRequestV2JobsChangesAndAsyncUploadStatusByJobUuid
                        >
                    >,
                    TError,
                    TData
                >
            >
            request?: SecondParameter<typeof customInstance>
        },
    ) => {
        const { query: queryOptions, request: requestOptions } = options ?? {}

        const queryKey =
            queryOptions?.queryKey ??
            getGetRequestV2JobsChangesAndAsyncUploadStatusByJobUuidQueryKey(
                jobUuid,
                params,
            )

        const queryFn: QueryFunction<
            Awaited<
                ReturnType<
                    typeof getRequestV2JobsChangesAndAsyncUploadStatusByJobUuid
                >
            >
        > = ({ signal }) =>
            getRequestV2JobsChangesAndAsyncUploadStatusByJobUuid(
                jobUuid,
                params,
                requestOptions,
                signal,
            )

        return {
            queryKey,
            queryFn,
            enabled: !!jobUuid,
            ...queryOptions,
        } as UseQueryOptions<
            Awaited<
                ReturnType<
                    typeof getRequestV2JobsChangesAndAsyncUploadStatusByJobUuid
                >
            >,
            TError,
            TData
        > & { queryKey: DataTag<QueryKey, TData, TError> }
    }

export type GetRequestV2JobsChangesAndAsyncUploadStatusByJobUuidQueryResult =
    NonNullable<
        Awaited<
            ReturnType<
                typeof getRequestV2JobsChangesAndAsyncUploadStatusByJobUuid
            >
        >
    >
export type GetRequestV2JobsChangesAndAsyncUploadStatusByJobUuidQueryError =
    ErrorType<HTTPValidationErrorDTO>

export function useGetRequestV2JobsChangesAndAsyncUploadStatusByJobUuid<
    TData = Awaited<
        ReturnType<typeof getRequestV2JobsChangesAndAsyncUploadStatusByJobUuid>
    >,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestV2JobsChangesAndAsyncUploadStatusByJobUuidParams,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestV2JobsChangesAndAsyncUploadStatusByJobUuid
                    >
                >,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<
                        ReturnType<
                            typeof getRequestV2JobsChangesAndAsyncUploadStatusByJobUuid
                        >
                    >,
                    TError,
                    Awaited<
                        ReturnType<
                            typeof getRequestV2JobsChangesAndAsyncUploadStatusByJobUuid
                        >
                    >
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestV2JobsChangesAndAsyncUploadStatusByJobUuid<
    TData = Awaited<
        ReturnType<typeof getRequestV2JobsChangesAndAsyncUploadStatusByJobUuid>
    >,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestV2JobsChangesAndAsyncUploadStatusByJobUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestV2JobsChangesAndAsyncUploadStatusByJobUuid
                    >
                >,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<
                        ReturnType<
                            typeof getRequestV2JobsChangesAndAsyncUploadStatusByJobUuid
                        >
                    >,
                    TError,
                    Awaited<
                        ReturnType<
                            typeof getRequestV2JobsChangesAndAsyncUploadStatusByJobUuid
                        >
                    >
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestV2JobsChangesAndAsyncUploadStatusByJobUuid<
    TData = Awaited<
        ReturnType<typeof getRequestV2JobsChangesAndAsyncUploadStatusByJobUuid>
    >,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestV2JobsChangesAndAsyncUploadStatusByJobUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestV2JobsChangesAndAsyncUploadStatusByJobUuid
                    >
                >,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Changes And Async Upload Status V2
 */

export function useGetRequestV2JobsChangesAndAsyncUploadStatusByJobUuid<
    TData = Awaited<
        ReturnType<typeof getRequestV2JobsChangesAndAsyncUploadStatusByJobUuid>
    >,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestV2JobsChangesAndAsyncUploadStatusByJobUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestV2JobsChangesAndAsyncUploadStatusByJobUuid
                    >
                >,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions =
        getGetRequestV2JobsChangesAndAsyncUploadStatusByJobUuidQueryOptions(
            jobUuid,
            params,
            options,
        )

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * This call is deprecated use the same call minus name
This api call is used to download a file.

The name parameter is mandatory but it is not used to get file, since
the file uuid is distinct so no name is needed
 * @summary Get File With Name
 */
export const getRequestJobsFilesByIdByJobUuidAndFileUuidAndName = (
    jobUuid: string,
    fileUuid: string,
    name: string,
    _getRequestJobsFilesByIdByJobUuidAndFileUuidAndNameBodies: BodyType<GetRequestJobsFilesByIdByJobUuidAndFileUuidAndNameBodies>,
    params: GetRequestJobsFilesByIdByJobUuidAndFileUuidAndNameParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<SubFileStatusResponseDTO>(
        {
            url: `/jobs/${encodeURIComponent(String(jobUuid))}/files_by_id/${encodeURIComponent(String(fileUuid))}/${encodeURIComponent(String(name))}`,
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            params,
            signal,
        },
        options,
    )
}

export const getGetRequestJobsFilesByIdByJobUuidAndFileUuidAndNameQueryKey = (
    jobUuid: string,
    fileUuid: string,
    name: string,
    getRequestJobsFilesByIdByJobUuidAndFileUuidAndNameBodies: GetRequestJobsFilesByIdByJobUuidAndFileUuidAndNameBodies,
    params: GetRequestJobsFilesByIdByJobUuidAndFileUuidAndNameParams,
) => {
    return [
        `/jobs/${jobUuid}/files_by_id/${fileUuid}/${name}`,
        ...(params ? [params] : []),
        getRequestJobsFilesByIdByJobUuidAndFileUuidAndNameBodies,
    ] as const
}

export const getGetRequestJobsFilesByIdByJobUuidAndFileUuidAndNameQueryOptions =
    <
        TData = Awaited<
            ReturnType<
                typeof getRequestJobsFilesByIdByJobUuidAndFileUuidAndName
            >
        >,
        TError = ErrorType<void | HTTPValidationErrorDTO>,
    >(
        jobUuid: string,
        fileUuid: string,
        name: string,
        getRequestJobsFilesByIdByJobUuidAndFileUuidAndNameBodies: GetRequestJobsFilesByIdByJobUuidAndFileUuidAndNameBodies,
        params: GetRequestJobsFilesByIdByJobUuidAndFileUuidAndNameParams,
        options?: {
            query?: Partial<
                UseQueryOptions<
                    Awaited<
                        ReturnType<
                            typeof getRequestJobsFilesByIdByJobUuidAndFileUuidAndName
                        >
                    >,
                    TError,
                    TData
                >
            >
            request?: SecondParameter<typeof customInstance>
        },
    ) => {
        const { query: queryOptions, request: requestOptions } = options ?? {}

        const queryKey =
            queryOptions?.queryKey ??
            getGetRequestJobsFilesByIdByJobUuidAndFileUuidAndNameQueryKey(
                jobUuid,
                fileUuid,
                name,
                getRequestJobsFilesByIdByJobUuidAndFileUuidAndNameBodies,
                params,
            )

        const queryFn: QueryFunction<
            Awaited<
                ReturnType<
                    typeof getRequestJobsFilesByIdByJobUuidAndFileUuidAndName
                >
            >
        > = ({ signal }) =>
            getRequestJobsFilesByIdByJobUuidAndFileUuidAndName(
                jobUuid,
                fileUuid,
                name,
                getRequestJobsFilesByIdByJobUuidAndFileUuidAndNameBodies,
                params,
                requestOptions,
                signal,
            )

        return {
            queryKey,
            queryFn,
            enabled: !!(jobUuid && fileUuid && name),
            ...queryOptions,
        } as UseQueryOptions<
            Awaited<
                ReturnType<
                    typeof getRequestJobsFilesByIdByJobUuidAndFileUuidAndName
                >
            >,
            TError,
            TData
        > & { queryKey: DataTag<QueryKey, TData, TError> }
    }

export type GetRequestJobsFilesByIdByJobUuidAndFileUuidAndNameQueryResult =
    NonNullable<
        Awaited<
            ReturnType<
                typeof getRequestJobsFilesByIdByJobUuidAndFileUuidAndName
            >
        >
    >
export type GetRequestJobsFilesByIdByJobUuidAndFileUuidAndNameQueryError =
    ErrorType<void | HTTPValidationErrorDTO>

export function useGetRequestJobsFilesByIdByJobUuidAndFileUuidAndName<
    TData = Awaited<
        ReturnType<typeof getRequestJobsFilesByIdByJobUuidAndFileUuidAndName>
    >,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    fileUuid: string,
    name: string,
    getRequestJobsFilesByIdByJobUuidAndFileUuidAndNameBodies: GetRequestJobsFilesByIdByJobUuidAndFileUuidAndNameBodies,
    params: GetRequestJobsFilesByIdByJobUuidAndFileUuidAndNameParams,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestJobsFilesByIdByJobUuidAndFileUuidAndName
                    >
                >,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<
                        ReturnType<
                            typeof getRequestJobsFilesByIdByJobUuidAndFileUuidAndName
                        >
                    >,
                    TError,
                    Awaited<
                        ReturnType<
                            typeof getRequestJobsFilesByIdByJobUuidAndFileUuidAndName
                        >
                    >
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestJobsFilesByIdByJobUuidAndFileUuidAndName<
    TData = Awaited<
        ReturnType<typeof getRequestJobsFilesByIdByJobUuidAndFileUuidAndName>
    >,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    fileUuid: string,
    name: string,
    getRequestJobsFilesByIdByJobUuidAndFileUuidAndNameBodies: GetRequestJobsFilesByIdByJobUuidAndFileUuidAndNameBodies,
    params: GetRequestJobsFilesByIdByJobUuidAndFileUuidAndNameParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestJobsFilesByIdByJobUuidAndFileUuidAndName
                    >
                >,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<
                        ReturnType<
                            typeof getRequestJobsFilesByIdByJobUuidAndFileUuidAndName
                        >
                    >,
                    TError,
                    Awaited<
                        ReturnType<
                            typeof getRequestJobsFilesByIdByJobUuidAndFileUuidAndName
                        >
                    >
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestJobsFilesByIdByJobUuidAndFileUuidAndName<
    TData = Awaited<
        ReturnType<typeof getRequestJobsFilesByIdByJobUuidAndFileUuidAndName>
    >,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    fileUuid: string,
    name: string,
    getRequestJobsFilesByIdByJobUuidAndFileUuidAndNameBodies: GetRequestJobsFilesByIdByJobUuidAndFileUuidAndNameBodies,
    params: GetRequestJobsFilesByIdByJobUuidAndFileUuidAndNameParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestJobsFilesByIdByJobUuidAndFileUuidAndName
                    >
                >,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get File With Name
 */

export function useGetRequestJobsFilesByIdByJobUuidAndFileUuidAndName<
    TData = Awaited<
        ReturnType<typeof getRequestJobsFilesByIdByJobUuidAndFileUuidAndName>
    >,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    fileUuid: string,
    name: string,
    getRequestJobsFilesByIdByJobUuidAndFileUuidAndNameBodies: GetRequestJobsFilesByIdByJobUuidAndFileUuidAndNameBodies,
    params: GetRequestJobsFilesByIdByJobUuidAndFileUuidAndNameParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestJobsFilesByIdByJobUuidAndFileUuidAndName
                    >
                >,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions =
        getGetRequestJobsFilesByIdByJobUuidAndFileUuidAndNameQueryOptions(
            jobUuid,
            fileUuid,
            name,
            getRequestJobsFilesByIdByJobUuidAndFileUuidAndNameBodies,
            params,
            options,
        )

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * @summary Get File By Path
 */
export const getRequestJobsFilesByIdByJobUuidAndFileUuidAndPath = (
    jobUuid: string,
    fileUuid: string,
    path: string,
    params: GetRequestJobsFilesByIdByJobUuidAndFileUuidAndPathParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<SubFileStatusResponseDTO>(
        {
            url: `/jobs/${encodeURIComponent(String(jobUuid))}/files_by_id/${encodeURIComponent(String(fileUuid))}/${encodeURIComponent(String(path))}`,
            method: 'GET',
            params,
            signal,
        },
        options,
    )
}

export const getGetRequestJobsFilesByIdByJobUuidAndFileUuidAndPathQueryKey = (
    jobUuid: string,
    fileUuid: string,
    path: string,
    params: GetRequestJobsFilesByIdByJobUuidAndFileUuidAndPathParams,
) => {
    return [
        `/jobs/${jobUuid}/files_by_id/${fileUuid}/${path}`,
        ...(params ? [params] : []),
    ] as const
}

export const getGetRequestJobsFilesByIdByJobUuidAndFileUuidAndPathQueryOptions =
    <
        TData = Awaited<
            ReturnType<
                typeof getRequestJobsFilesByIdByJobUuidAndFileUuidAndPath
            >
        >,
        TError = ErrorType<HTTPValidationErrorDTO>,
    >(
        jobUuid: string,
        fileUuid: string,
        path: string,
        params: GetRequestJobsFilesByIdByJobUuidAndFileUuidAndPathParams,
        options?: {
            query?: Partial<
                UseQueryOptions<
                    Awaited<
                        ReturnType<
                            typeof getRequestJobsFilesByIdByJobUuidAndFileUuidAndPath
                        >
                    >,
                    TError,
                    TData
                >
            >
            request?: SecondParameter<typeof customInstance>
        },
    ) => {
        const { query: queryOptions, request: requestOptions } = options ?? {}

        const queryKey =
            queryOptions?.queryKey ??
            getGetRequestJobsFilesByIdByJobUuidAndFileUuidAndPathQueryKey(
                jobUuid,
                fileUuid,
                path,
                params,
            )

        const queryFn: QueryFunction<
            Awaited<
                ReturnType<
                    typeof getRequestJobsFilesByIdByJobUuidAndFileUuidAndPath
                >
            >
        > = ({ signal }) =>
            getRequestJobsFilesByIdByJobUuidAndFileUuidAndPath(
                jobUuid,
                fileUuid,
                path,
                params,
                requestOptions,
                signal,
            )

        return {
            queryKey,
            queryFn,
            enabled: !!(jobUuid && fileUuid && path),
            ...queryOptions,
        } as UseQueryOptions<
            Awaited<
                ReturnType<
                    typeof getRequestJobsFilesByIdByJobUuidAndFileUuidAndPath
                >
            >,
            TError,
            TData
        > & { queryKey: DataTag<QueryKey, TData, TError> }
    }

export type GetRequestJobsFilesByIdByJobUuidAndFileUuidAndPathQueryResult =
    NonNullable<
        Awaited<
            ReturnType<
                typeof getRequestJobsFilesByIdByJobUuidAndFileUuidAndPath
            >
        >
    >
export type GetRequestJobsFilesByIdByJobUuidAndFileUuidAndPathQueryError =
    ErrorType<HTTPValidationErrorDTO>

export function useGetRequestJobsFilesByIdByJobUuidAndFileUuidAndPath<
    TData = Awaited<
        ReturnType<typeof getRequestJobsFilesByIdByJobUuidAndFileUuidAndPath>
    >,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    fileUuid: string,
    path: string,
    params: GetRequestJobsFilesByIdByJobUuidAndFileUuidAndPathParams,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestJobsFilesByIdByJobUuidAndFileUuidAndPath
                    >
                >,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<
                        ReturnType<
                            typeof getRequestJobsFilesByIdByJobUuidAndFileUuidAndPath
                        >
                    >,
                    TError,
                    Awaited<
                        ReturnType<
                            typeof getRequestJobsFilesByIdByJobUuidAndFileUuidAndPath
                        >
                    >
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestJobsFilesByIdByJobUuidAndFileUuidAndPath<
    TData = Awaited<
        ReturnType<typeof getRequestJobsFilesByIdByJobUuidAndFileUuidAndPath>
    >,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    fileUuid: string,
    path: string,
    params: GetRequestJobsFilesByIdByJobUuidAndFileUuidAndPathParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestJobsFilesByIdByJobUuidAndFileUuidAndPath
                    >
                >,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<
                        ReturnType<
                            typeof getRequestJobsFilesByIdByJobUuidAndFileUuidAndPath
                        >
                    >,
                    TError,
                    Awaited<
                        ReturnType<
                            typeof getRequestJobsFilesByIdByJobUuidAndFileUuidAndPath
                        >
                    >
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestJobsFilesByIdByJobUuidAndFileUuidAndPath<
    TData = Awaited<
        ReturnType<typeof getRequestJobsFilesByIdByJobUuidAndFileUuidAndPath>
    >,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    fileUuid: string,
    path: string,
    params: GetRequestJobsFilesByIdByJobUuidAndFileUuidAndPathParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestJobsFilesByIdByJobUuidAndFileUuidAndPath
                    >
                >,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get File By Path
 */

export function useGetRequestJobsFilesByIdByJobUuidAndFileUuidAndPath<
    TData = Awaited<
        ReturnType<typeof getRequestJobsFilesByIdByJobUuidAndFileUuidAndPath>
    >,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    fileUuid: string,
    path: string,
    params: GetRequestJobsFilesByIdByJobUuidAndFileUuidAndPathParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestJobsFilesByIdByJobUuidAndFileUuidAndPath
                    >
                >,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions =
        getGetRequestJobsFilesByIdByJobUuidAndFileUuidAndPathQueryOptions(
            jobUuid,
            fileUuid,
            path,
            params,
            options,
        )

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * This is a method to download all files from a job.

 * @summary Get Files As Archive
 */
export const getRequestJobsFilesAsArchiveByJobUuid = (
    jobUuid: string,
    params: GetRequestJobsFilesAsArchiveByJobUuidParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<unknown>(
        {
            url: `/jobs/${encodeURIComponent(String(jobUuid))}/files_as_archive`,
            method: 'GET',
            params,
            signal,
        },
        options,
    )
}

export const getGetRequestJobsFilesAsArchiveByJobUuidQueryKey = (
    jobUuid: string,
    params: GetRequestJobsFilesAsArchiveByJobUuidParams,
) => {
    return [
        `/jobs/${jobUuid}/files_as_archive`,
        ...(params ? [params] : []),
    ] as const
}

export const getGetRequestJobsFilesAsArchiveByJobUuidQueryOptions = <
    TData = Awaited<ReturnType<typeof getRequestJobsFilesAsArchiveByJobUuid>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestJobsFilesAsArchiveByJobUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<typeof getRequestJobsFilesAsArchiveByJobUuid>
                >,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {}

    const queryKey =
        queryOptions?.queryKey ??
        getGetRequestJobsFilesAsArchiveByJobUuidQueryKey(jobUuid, params)

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getRequestJobsFilesAsArchiveByJobUuid>>
    > = ({ signal }) =>
        getRequestJobsFilesAsArchiveByJobUuid(
            jobUuid,
            params,
            requestOptions,
            signal,
        )

    return {
        queryKey,
        queryFn,
        enabled: !!jobUuid,
        ...queryOptions,
    } as UseQueryOptions<
        Awaited<ReturnType<typeof getRequestJobsFilesAsArchiveByJobUuid>>,
        TError,
        TData
    > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRequestJobsFilesAsArchiveByJobUuidQueryResult = NonNullable<
    Awaited<ReturnType<typeof getRequestJobsFilesAsArchiveByJobUuid>>
>
export type GetRequestJobsFilesAsArchiveByJobUuidQueryError =
    ErrorType<void | HTTPValidationErrorDTO>

export function useGetRequestJobsFilesAsArchiveByJobUuid<
    TData = Awaited<ReturnType<typeof getRequestJobsFilesAsArchiveByJobUuid>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestJobsFilesAsArchiveByJobUuidParams,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<typeof getRequestJobsFilesAsArchiveByJobUuid>
                >,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<
                        ReturnType<typeof getRequestJobsFilesAsArchiveByJobUuid>
                    >,
                    TError,
                    Awaited<
                        ReturnType<typeof getRequestJobsFilesAsArchiveByJobUuid>
                    >
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestJobsFilesAsArchiveByJobUuid<
    TData = Awaited<ReturnType<typeof getRequestJobsFilesAsArchiveByJobUuid>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestJobsFilesAsArchiveByJobUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<typeof getRequestJobsFilesAsArchiveByJobUuid>
                >,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<
                        ReturnType<typeof getRequestJobsFilesAsArchiveByJobUuid>
                    >,
                    TError,
                    Awaited<
                        ReturnType<typeof getRequestJobsFilesAsArchiveByJobUuid>
                    >
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestJobsFilesAsArchiveByJobUuid<
    TData = Awaited<ReturnType<typeof getRequestJobsFilesAsArchiveByJobUuid>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestJobsFilesAsArchiveByJobUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<typeof getRequestJobsFilesAsArchiveByJobUuid>
                >,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get Files As Archive
 */

export function useGetRequestJobsFilesAsArchiveByJobUuid<
    TData = Awaited<ReturnType<typeof getRequestJobsFilesAsArchiveByJobUuid>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestJobsFilesAsArchiveByJobUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<typeof getRequestJobsFilesAsArchiveByJobUuid>
                >,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions = getGetRequestJobsFilesAsArchiveByJobUuidQueryOptions(
        jobUuid,
        params,
        options,
    )

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * This is a method to get files as archived file.

 * @summary Post Files As Archive
 */
export const postRequestJobsFilesAsArchiveByJobUuid = (
    jobUuid: string,
    postRequestJobsFilesAsArchiveByJobUuidBodies: BodyType<string[]>,
    params: PostRequestJobsFilesAsArchiveByJobUuidParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<unknown>(
        {
            url: `/jobs/${encodeURIComponent(String(jobUuid))}/files_as_archive`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: postRequestJobsFilesAsArchiveByJobUuidBodies,
            params,
            signal,
        },
        options,
    )
}

export const getPostRequestJobsFilesAsArchiveByJobUuidMutationOptions = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestJobsFilesAsArchiveByJobUuid>>,
        TError,
        {
            jobUuid: string
            data: BodyType<string[]>
            params: PostRequestJobsFilesAsArchiveByJobUuidParams
        },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestJobsFilesAsArchiveByJobUuid>>,
    TError,
    {
        jobUuid: string
        data: BodyType<string[]>
        params: PostRequestJobsFilesAsArchiveByJobUuidParams
    },
    TContext
> => {
    const mutationKey = ['postRequestJobsFilesAsArchiveByJobUuid']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postRequestJobsFilesAsArchiveByJobUuid>>,
        {
            jobUuid: string
            data: BodyType<string[]>
            params: PostRequestJobsFilesAsArchiveByJobUuidParams
        }
    > = (props) => {
        const { jobUuid, data, params } = props ?? {}

        return postRequestJobsFilesAsArchiveByJobUuid(
            jobUuid,
            data,
            params,
            requestOptions,
        )
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestJobsFilesAsArchiveByJobUuidMutationResult = NonNullable<
    Awaited<ReturnType<typeof postRequestJobsFilesAsArchiveByJobUuid>>
>
export type PostRequestJobsFilesAsArchiveByJobUuidMutationBody = BodyType<
    string[]
>
export type PostRequestJobsFilesAsArchiveByJobUuidMutationError =
    ErrorType<void | HTTPValidationErrorDTO>

/**
 * @summary Post Files As Archive
 */
export const usePostRequestJobsFilesAsArchiveByJobUuid = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestJobsFilesAsArchiveByJobUuid>>,
        TError,
        {
            jobUuid: string
            data: BodyType<string[]>
            params: PostRequestJobsFilesAsArchiveByJobUuidParams
        },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestJobsFilesAsArchiveByJobUuid>>,
    TError,
    {
        jobUuid: string
        data: BodyType<string[]>
        params: PostRequestJobsFilesAsArchiveByJobUuidParams
    },
    TContext
> => {
    const mutationOptions =
        getPostRequestJobsFilesAsArchiveByJobUuidMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * This is a method to download all deleted files from trash can for a job.

 * @summary Get Files From Trash As Archive
 */
export const getRequestJobsFilesFromTrashAsArchiveByJobUuid = (
    jobUuid: string,
    params: GetRequestJobsFilesFromTrashAsArchiveByJobUuidParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<unknown>(
        {
            url: `/jobs/${encodeURIComponent(String(jobUuid))}/files_from_trash_as_archive`,
            method: 'GET',
            params,
            signal,
        },
        options,
    )
}

export const getGetRequestJobsFilesFromTrashAsArchiveByJobUuidQueryKey = (
    jobUuid: string,
    params: GetRequestJobsFilesFromTrashAsArchiveByJobUuidParams,
) => {
    return [
        `/jobs/${jobUuid}/files_from_trash_as_archive`,
        ...(params ? [params] : []),
    ] as const
}

export const getGetRequestJobsFilesFromTrashAsArchiveByJobUuidQueryOptions = <
    TData = Awaited<
        ReturnType<typeof getRequestJobsFilesFromTrashAsArchiveByJobUuid>
    >,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestJobsFilesFromTrashAsArchiveByJobUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestJobsFilesFromTrashAsArchiveByJobUuid
                    >
                >,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {}

    const queryKey =
        queryOptions?.queryKey ??
        getGetRequestJobsFilesFromTrashAsArchiveByJobUuidQueryKey(
            jobUuid,
            params,
        )

    const queryFn: QueryFunction<
        Awaited<
            ReturnType<typeof getRequestJobsFilesFromTrashAsArchiveByJobUuid>
        >
    > = ({ signal }) =>
        getRequestJobsFilesFromTrashAsArchiveByJobUuid(
            jobUuid,
            params,
            requestOptions,
            signal,
        )

    return {
        queryKey,
        queryFn,
        enabled: !!jobUuid,
        ...queryOptions,
    } as UseQueryOptions<
        Awaited<
            ReturnType<typeof getRequestJobsFilesFromTrashAsArchiveByJobUuid>
        >,
        TError,
        TData
    > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRequestJobsFilesFromTrashAsArchiveByJobUuidQueryResult =
    NonNullable<
        Awaited<
            ReturnType<typeof getRequestJobsFilesFromTrashAsArchiveByJobUuid>
        >
    >
export type GetRequestJobsFilesFromTrashAsArchiveByJobUuidQueryError =
    ErrorType<void | HTTPValidationErrorDTO>

export function useGetRequestJobsFilesFromTrashAsArchiveByJobUuid<
    TData = Awaited<
        ReturnType<typeof getRequestJobsFilesFromTrashAsArchiveByJobUuid>
    >,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestJobsFilesFromTrashAsArchiveByJobUuidParams,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestJobsFilesFromTrashAsArchiveByJobUuid
                    >
                >,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<
                        ReturnType<
                            typeof getRequestJobsFilesFromTrashAsArchiveByJobUuid
                        >
                    >,
                    TError,
                    Awaited<
                        ReturnType<
                            typeof getRequestJobsFilesFromTrashAsArchiveByJobUuid
                        >
                    >
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestJobsFilesFromTrashAsArchiveByJobUuid<
    TData = Awaited<
        ReturnType<typeof getRequestJobsFilesFromTrashAsArchiveByJobUuid>
    >,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestJobsFilesFromTrashAsArchiveByJobUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestJobsFilesFromTrashAsArchiveByJobUuid
                    >
                >,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<
                        ReturnType<
                            typeof getRequestJobsFilesFromTrashAsArchiveByJobUuid
                        >
                    >,
                    TError,
                    Awaited<
                        ReturnType<
                            typeof getRequestJobsFilesFromTrashAsArchiveByJobUuid
                        >
                    >
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestJobsFilesFromTrashAsArchiveByJobUuid<
    TData = Awaited<
        ReturnType<typeof getRequestJobsFilesFromTrashAsArchiveByJobUuid>
    >,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestJobsFilesFromTrashAsArchiveByJobUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestJobsFilesFromTrashAsArchiveByJobUuid
                    >
                >,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get Files From Trash As Archive
 */

export function useGetRequestJobsFilesFromTrashAsArchiveByJobUuid<
    TData = Awaited<
        ReturnType<typeof getRequestJobsFilesFromTrashAsArchiveByJobUuid>
    >,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestJobsFilesFromTrashAsArchiveByJobUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestJobsFilesFromTrashAsArchiveByJobUuid
                    >
                >,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions =
        getGetRequestJobsFilesFromTrashAsArchiveByJobUuidQueryOptions(
            jobUuid,
            params,
            options,
        )

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * This is a method to get deleted files from trash can as archived file.

 * @summary Post Files From Trash As Archive
 */
export const postRequestJobsFilesFromTrashAsArchiveByJobUuid = (
    jobUuid: string,
    postRequestJobsFilesFromTrashAsArchiveByJobUuidBodies: BodyType<string[]>,
    params: PostRequestJobsFilesFromTrashAsArchiveByJobUuidParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<unknown>(
        {
            url: `/jobs/${encodeURIComponent(String(jobUuid))}/files_from_trash_as_archive`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: postRequestJobsFilesFromTrashAsArchiveByJobUuidBodies,
            params,
            signal,
        },
        options,
    )
}

export const getPostRequestJobsFilesFromTrashAsArchiveByJobUuidMutationOptions =
    <
        TError = ErrorType<void | HTTPValidationErrorDTO>,
        TContext = unknown,
    >(options?: {
        mutation?: UseMutationOptions<
            Awaited<
                ReturnType<
                    typeof postRequestJobsFilesFromTrashAsArchiveByJobUuid
                >
            >,
            TError,
            {
                jobUuid: string
                data: BodyType<string[]>
                params: PostRequestJobsFilesFromTrashAsArchiveByJobUuidParams
            },
            TContext
        >
        request?: SecondParameter<typeof customInstance>
    }): UseMutationOptions<
        Awaited<
            ReturnType<typeof postRequestJobsFilesFromTrashAsArchiveByJobUuid>
        >,
        TError,
        {
            jobUuid: string
            data: BodyType<string[]>
            params: PostRequestJobsFilesFromTrashAsArchiveByJobUuidParams
        },
        TContext
    > => {
        const mutationKey = ['postRequestJobsFilesFromTrashAsArchiveByJobUuid']
        const { mutation: mutationOptions, request: requestOptions } = options
            ? options.mutation &&
              'mutationKey' in options.mutation &&
              options.mutation.mutationKey
                ? options
                : { ...options, mutation: { ...options.mutation, mutationKey } }
            : { mutation: { mutationKey }, request: undefined }

        const mutationFn: MutationFunction<
            Awaited<
                ReturnType<
                    typeof postRequestJobsFilesFromTrashAsArchiveByJobUuid
                >
            >,
            {
                jobUuid: string
                data: BodyType<string[]>
                params: PostRequestJobsFilesFromTrashAsArchiveByJobUuidParams
            }
        > = (props) => {
            const { jobUuid, data, params } = props ?? {}

            return postRequestJobsFilesFromTrashAsArchiveByJobUuid(
                jobUuid,
                data,
                params,
                requestOptions,
            )
        }

        return { mutationFn, ...mutationOptions }
    }

export type PostRequestJobsFilesFromTrashAsArchiveByJobUuidMutationResult =
    NonNullable<
        Awaited<
            ReturnType<typeof postRequestJobsFilesFromTrashAsArchiveByJobUuid>
        >
    >
export type PostRequestJobsFilesFromTrashAsArchiveByJobUuidMutationBody =
    BodyType<string[]>
export type PostRequestJobsFilesFromTrashAsArchiveByJobUuidMutationError =
    ErrorType<void | HTTPValidationErrorDTO>

/**
 * @summary Post Files From Trash As Archive
 */
export const usePostRequestJobsFilesFromTrashAsArchiveByJobUuid = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<typeof postRequestJobsFilesFromTrashAsArchiveByJobUuid>
        >,
        TError,
        {
            jobUuid: string
            data: BodyType<string[]>
            params: PostRequestJobsFilesFromTrashAsArchiveByJobUuidParams
        },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestJobsFilesFromTrashAsArchiveByJobUuid>>,
    TError,
    {
        jobUuid: string
        data: BodyType<string[]>
        params: PostRequestJobsFilesFromTrashAsArchiveByJobUuidParams
    },
    TContext
> => {
    const mutationOptions =
        getPostRequestJobsFilesFromTrashAsArchiveByJobUuidMutationOptions(
            options,
        )

    return useMutation(mutationOptions)
}

/**
 * This endpoint is used to send share email and/or
get the share url for a job

 * @summary Publish Share
 */
export const postRequestJobsPublishByJobUuid = (
    jobUuid: string,
    params: PostRequestJobsPublishByJobUuidParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<PublishShareResponseDTO>(
        {
            url: `/jobs/${encodeURIComponent(String(jobUuid))}/publish`,
            method: 'POST',
            params,
            signal,
        },
        options,
    )
}

export const getPostRequestJobsPublishByJobUuidMutationOptions = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestJobsPublishByJobUuid>>,
        TError,
        { jobUuid: string; params: PostRequestJobsPublishByJobUuidParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestJobsPublishByJobUuid>>,
    TError,
    { jobUuid: string; params: PostRequestJobsPublishByJobUuidParams },
    TContext
> => {
    const mutationKey = ['postRequestJobsPublishByJobUuid']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postRequestJobsPublishByJobUuid>>,
        { jobUuid: string; params: PostRequestJobsPublishByJobUuidParams }
    > = (props) => {
        const { jobUuid, params } = props ?? {}

        return postRequestJobsPublishByJobUuid(jobUuid, params, requestOptions)
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestJobsPublishByJobUuidMutationResult = NonNullable<
    Awaited<ReturnType<typeof postRequestJobsPublishByJobUuid>>
>

export type PostRequestJobsPublishByJobUuidMutationError =
    ErrorType<void | HTTPValidationErrorDTO>

/**
 * @summary Publish Share
 */
export const usePostRequestJobsPublishByJobUuid = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestJobsPublishByJobUuid>>,
        TError,
        { jobUuid: string; params: PostRequestJobsPublishByJobUuidParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestJobsPublishByJobUuid>>,
    TError,
    { jobUuid: string; params: PostRequestJobsPublishByJobUuidParams },
    TContext
> => {
    const mutationOptions =
        getPostRequestJobsPublishByJobUuidMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * This method is used to update the job title and password

 * @summary Put Job
 */
export const putRequestJobsByJobUuid = (
    jobUuid: string,
    params: PutRequestJobsByJobUuidParams,
    options?: SecondParameter<typeof customInstance>,
) => {
    return customInstance<SetJobDataResponseDTO>(
        {
            url: `/jobs/${encodeURIComponent(String(jobUuid))}`,
            method: 'PUT',
            params,
        },
        options,
    )
}

export const getPutRequestJobsByJobUuidMutationOptions = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof putRequestJobsByJobUuid>>,
        TError,
        { jobUuid: string; params: PutRequestJobsByJobUuidParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof putRequestJobsByJobUuid>>,
    TError,
    { jobUuid: string; params: PutRequestJobsByJobUuidParams },
    TContext
> => {
    const mutationKey = ['putRequestJobsByJobUuid']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof putRequestJobsByJobUuid>>,
        { jobUuid: string; params: PutRequestJobsByJobUuidParams }
    > = (props) => {
        const { jobUuid, params } = props ?? {}

        return putRequestJobsByJobUuid(jobUuid, params, requestOptions)
    }

    return { mutationFn, ...mutationOptions }
}

export type PutRequestJobsByJobUuidMutationResult = NonNullable<
    Awaited<ReturnType<typeof putRequestJobsByJobUuid>>
>

export type PutRequestJobsByJobUuidMutationError =
    ErrorType<void | HTTPValidationErrorDTO>

/**
 * @summary Put Job
 */
export const usePutRequestJobsByJobUuid = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof putRequestJobsByJobUuid>>,
        TError,
        { jobUuid: string; params: PutRequestJobsByJobUuidParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof putRequestJobsByJobUuid>>,
    TError,
    { jobUuid: string; params: PutRequestJobsByJobUuidParams },
    TContext
> => {
    const mutationOptions = getPutRequestJobsByJobUuidMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * Delete a job

 * @summary Delete Job Delete
 */
export const deleteRequestJobsByJobUuid = (
    jobUuid: string,
    params: DeleteRequestJobsByJobUuidParams,
    options?: SecondParameter<typeof customInstance>,
) => {
    return customInstance<unknown>(
        {
            url: `/jobs/${encodeURIComponent(String(jobUuid))}`,
            method: 'DELETE',
            params,
        },
        options,
    )
}

export const getDeleteRequestJobsByJobUuidMutationOptions = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof deleteRequestJobsByJobUuid>>,
        TError,
        { jobUuid: string; params: DeleteRequestJobsByJobUuidParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof deleteRequestJobsByJobUuid>>,
    TError,
    { jobUuid: string; params: DeleteRequestJobsByJobUuidParams },
    TContext
> => {
    const mutationKey = ['deleteRequestJobsByJobUuid']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof deleteRequestJobsByJobUuid>>,
        { jobUuid: string; params: DeleteRequestJobsByJobUuidParams }
    > = (props) => {
        const { jobUuid, params } = props ?? {}

        return deleteRequestJobsByJobUuid(jobUuid, params, requestOptions)
    }

    return { mutationFn, ...mutationOptions }
}

export type DeleteRequestJobsByJobUuidMutationResult = NonNullable<
    Awaited<ReturnType<typeof deleteRequestJobsByJobUuid>>
>

export type DeleteRequestJobsByJobUuidMutationError =
    ErrorType<void | HTTPValidationErrorDTO>

/**
 * @summary Delete Job Delete
 */
export const useDeleteRequestJobsByJobUuid = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof deleteRequestJobsByJobUuid>>,
        TError,
        { jobUuid: string; params: DeleteRequestJobsByJobUuidParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof deleteRequestJobsByJobUuid>>,
    TError,
    { jobUuid: string; params: DeleteRequestJobsByJobUuidParams },
    TContext
> => {
    const mutationOptions =
        getDeleteRequestJobsByJobUuidMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * It extracts and returns changes occurred since the given file event id.

 * @summary Get Changes
 */
export const getRequestJobsChangesByJobUuid = (
    jobUuid: string,
    params: GetRequestJobsChangesByJobUuidParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<unknown>(
        {
            url: `/jobs/${encodeURIComponent(String(jobUuid))}/changes`,
            method: 'GET',
            params,
            signal,
        },
        options,
    )
}

export const getGetRequestJobsChangesByJobUuidQueryKey = (
    jobUuid: string,
    params: GetRequestJobsChangesByJobUuidParams,
) => {
    return [`/jobs/${jobUuid}/changes`, ...(params ? [params] : [])] as const
}

export const getGetRequestJobsChangesByJobUuidQueryOptions = <
    TData = Awaited<ReturnType<typeof getRequestJobsChangesByJobUuid>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestJobsChangesByJobUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestJobsChangesByJobUuid>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {}

    const queryKey =
        queryOptions?.queryKey ??
        getGetRequestJobsChangesByJobUuidQueryKey(jobUuid, params)

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getRequestJobsChangesByJobUuid>>
    > = ({ signal }) =>
        getRequestJobsChangesByJobUuid(jobUuid, params, requestOptions, signal)

    return {
        queryKey,
        queryFn,
        enabled: !!jobUuid,
        ...queryOptions,
    } as UseQueryOptions<
        Awaited<ReturnType<typeof getRequestJobsChangesByJobUuid>>,
        TError,
        TData
    > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRequestJobsChangesByJobUuidQueryResult = NonNullable<
    Awaited<ReturnType<typeof getRequestJobsChangesByJobUuid>>
>
export type GetRequestJobsChangesByJobUuidQueryError =
    ErrorType<void | HTTPValidationErrorDTO>

export function useGetRequestJobsChangesByJobUuid<
    TData = Awaited<ReturnType<typeof getRequestJobsChangesByJobUuid>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestJobsChangesByJobUuidParams,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestJobsChangesByJobUuid>>,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestJobsChangesByJobUuid>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestJobsChangesByJobUuid>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestJobsChangesByJobUuid<
    TData = Awaited<ReturnType<typeof getRequestJobsChangesByJobUuid>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestJobsChangesByJobUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestJobsChangesByJobUuid>>,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestJobsChangesByJobUuid>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestJobsChangesByJobUuid>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestJobsChangesByJobUuid<
    TData = Awaited<ReturnType<typeof getRequestJobsChangesByJobUuid>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestJobsChangesByJobUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestJobsChangesByJobUuid>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get Changes
 */

export function useGetRequestJobsChangesByJobUuid<
    TData = Awaited<ReturnType<typeof getRequestJobsChangesByJobUuid>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestJobsChangesByJobUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestJobsChangesByJobUuid>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions = getGetRequestJobsChangesByJobUuidQueryOptions(
        jobUuid,
        params,
        options,
    )

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * Returns the default job for a user,
if the default job does not exists it will be created

 * @summary Get Default Job
 */
export const getRequestJobsDefault = (
    params: GetRequestJobsDefaultParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<DefaultJobDTO>(
        { url: `/jobs/default`, method: 'GET', params, signal },
        options,
    )
}

export const getGetRequestJobsDefaultQueryKey = (
    params: GetRequestJobsDefaultParams,
) => {
    return [`/jobs/default`, ...(params ? [params] : [])] as const
}

export const getGetRequestJobsDefaultQueryOptions = <
    TData = Awaited<ReturnType<typeof getRequestJobsDefault>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestJobsDefaultParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestJobsDefault>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {}

    const queryKey =
        queryOptions?.queryKey ?? getGetRequestJobsDefaultQueryKey(params)

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getRequestJobsDefault>>
    > = ({ signal }) => getRequestJobsDefault(params, requestOptions, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof getRequestJobsDefault>>,
        TError,
        TData
    > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRequestJobsDefaultQueryResult = NonNullable<
    Awaited<ReturnType<typeof getRequestJobsDefault>>
>
export type GetRequestJobsDefaultQueryError = ErrorType<HTTPValidationErrorDTO>

export function useGetRequestJobsDefault<
    TData = Awaited<ReturnType<typeof getRequestJobsDefault>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestJobsDefaultParams,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestJobsDefault>>,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestJobsDefault>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestJobsDefault>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestJobsDefault<
    TData = Awaited<ReturnType<typeof getRequestJobsDefault>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestJobsDefaultParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestJobsDefault>>,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestJobsDefault>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestJobsDefault>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestJobsDefault<
    TData = Awaited<ReturnType<typeof getRequestJobsDefault>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestJobsDefaultParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestJobsDefault>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get Default Job
 */

export function useGetRequestJobsDefault<
    TData = Awaited<ReturnType<typeof getRequestJobsDefault>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestJobsDefaultParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestJobsDefault>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions = getGetRequestJobsDefaultQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * Get feature list supported by the backend

 * @summary Get Feature Flags
 */
export const getRequestFeatureFlags = (
    params: GetRequestFeatureFlagsParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<FeatureFlagsDTO>(
        { url: `/feature_flags`, method: 'GET', params, signal },
        options,
    )
}

export const getGetRequestFeatureFlagsQueryKey = (
    params: GetRequestFeatureFlagsParams,
) => {
    return [`/feature_flags`, ...(params ? [params] : [])] as const
}

export const getGetRequestFeatureFlagsQueryOptions = <
    TData = Awaited<ReturnType<typeof getRequestFeatureFlags>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestFeatureFlagsParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestFeatureFlags>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {}

    const queryKey =
        queryOptions?.queryKey ?? getGetRequestFeatureFlagsQueryKey(params)

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getRequestFeatureFlags>>
    > = ({ signal }) => getRequestFeatureFlags(params, requestOptions, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof getRequestFeatureFlags>>,
        TError,
        TData
    > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRequestFeatureFlagsQueryResult = NonNullable<
    Awaited<ReturnType<typeof getRequestFeatureFlags>>
>
export type GetRequestFeatureFlagsQueryError = ErrorType<HTTPValidationErrorDTO>

export function useGetRequestFeatureFlags<
    TData = Awaited<ReturnType<typeof getRequestFeatureFlags>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestFeatureFlagsParams,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestFeatureFlags>>,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestFeatureFlags>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestFeatureFlags>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestFeatureFlags<
    TData = Awaited<ReturnType<typeof getRequestFeatureFlags>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestFeatureFlagsParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestFeatureFlags>>,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestFeatureFlags>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestFeatureFlags>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestFeatureFlags<
    TData = Awaited<ReturnType<typeof getRequestFeatureFlags>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestFeatureFlagsParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestFeatureFlags>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get Feature Flags
 */

export function useGetRequestFeatureFlags<
    TData = Awaited<ReturnType<typeof getRequestFeatureFlags>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestFeatureFlagsParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestFeatureFlags>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions = getGetRequestFeatureFlagsQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * this call will list all files in the trash can

 * @summary Get Trash Can Files
 */
export const getRequestTrashCan = (
    params: GetRequestTrashCanParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<TrashcanFileItemDTO[]>(
        { url: `/trash_can`, method: 'GET', params, signal },
        options,
    )
}

export const getGetRequestTrashCanQueryKey = (
    params: GetRequestTrashCanParams,
) => {
    return [`/trash_can`, ...(params ? [params] : [])] as const
}

export const getGetRequestTrashCanQueryOptions = <
    TData = Awaited<ReturnType<typeof getRequestTrashCan>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestTrashCanParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestTrashCan>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {}

    const queryKey =
        queryOptions?.queryKey ?? getGetRequestTrashCanQueryKey(params)

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getRequestTrashCan>>
    > = ({ signal }) => getRequestTrashCan(params, requestOptions, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof getRequestTrashCan>>,
        TError,
        TData
    > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRequestTrashCanQueryResult = NonNullable<
    Awaited<ReturnType<typeof getRequestTrashCan>>
>
export type GetRequestTrashCanQueryError = ErrorType<HTTPValidationErrorDTO>

export function useGetRequestTrashCan<
    TData = Awaited<ReturnType<typeof getRequestTrashCan>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestTrashCanParams,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestTrashCan>>,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestTrashCan>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestTrashCan>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestTrashCan<
    TData = Awaited<ReturnType<typeof getRequestTrashCan>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestTrashCanParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestTrashCan>>,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestTrashCan>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestTrashCan>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestTrashCan<
    TData = Awaited<ReturnType<typeof getRequestTrashCan>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestTrashCanParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestTrashCan>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get Trash Can Files
 */

export function useGetRequestTrashCan<
    TData = Awaited<ReturnType<typeof getRequestTrashCan>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestTrashCanParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestTrashCan>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions = getGetRequestTrashCanQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * this call will list all albums in the trash can

 * @summary Get Trash Can Albums
 */
export const getRequestTrashCanAlbums = (
    params: GetRequestTrashCanAlbumsParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<TrashcanAlbumItemDTO[]>(
        { url: `/trash_can_albums`, method: 'GET', params, signal },
        options,
    )
}

export const getGetRequestTrashCanAlbumsQueryKey = (
    params: GetRequestTrashCanAlbumsParams,
) => {
    return [`/trash_can_albums`, ...(params ? [params] : [])] as const
}

export const getGetRequestTrashCanAlbumsQueryOptions = <
    TData = Awaited<ReturnType<typeof getRequestTrashCanAlbums>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestTrashCanAlbumsParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestTrashCanAlbums>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {}

    const queryKey =
        queryOptions?.queryKey ?? getGetRequestTrashCanAlbumsQueryKey(params)

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getRequestTrashCanAlbums>>
    > = ({ signal }) => getRequestTrashCanAlbums(params, requestOptions, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof getRequestTrashCanAlbums>>,
        TError,
        TData
    > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRequestTrashCanAlbumsQueryResult = NonNullable<
    Awaited<ReturnType<typeof getRequestTrashCanAlbums>>
>
export type GetRequestTrashCanAlbumsQueryError =
    ErrorType<HTTPValidationErrorDTO>

export function useGetRequestTrashCanAlbums<
    TData = Awaited<ReturnType<typeof getRequestTrashCanAlbums>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestTrashCanAlbumsParams,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestTrashCanAlbums>>,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestTrashCanAlbums>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestTrashCanAlbums>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestTrashCanAlbums<
    TData = Awaited<ReturnType<typeof getRequestTrashCanAlbums>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestTrashCanAlbumsParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestTrashCanAlbums>>,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestTrashCanAlbums>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestTrashCanAlbums>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestTrashCanAlbums<
    TData = Awaited<ReturnType<typeof getRequestTrashCanAlbums>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestTrashCanAlbumsParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestTrashCanAlbums>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get Trash Can Albums
 */

export function useGetRequestTrashCanAlbums<
    TData = Awaited<ReturnType<typeof getRequestTrashCanAlbums>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestTrashCanAlbumsParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestTrashCanAlbums>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions = getGetRequestTrashCanAlbumsQueryOptions(
        params,
        options,
    )

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * empty_trash_can for a given file

 * @summary Get Empty Trash Can
 */
export const getRequestEmptyTrashCan = (
    params: GetRequestEmptyTrashCanParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<unknown>(
        { url: `/empty_trash_can`, method: 'GET', params, signal },
        options,
    )
}

export const getGetRequestEmptyTrashCanQueryKey = (
    params: GetRequestEmptyTrashCanParams,
) => {
    return [`/empty_trash_can`, ...(params ? [params] : [])] as const
}

export const getGetRequestEmptyTrashCanQueryOptions = <
    TData = Awaited<ReturnType<typeof getRequestEmptyTrashCan>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestEmptyTrashCanParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestEmptyTrashCan>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {}

    const queryKey =
        queryOptions?.queryKey ?? getGetRequestEmptyTrashCanQueryKey(params)

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getRequestEmptyTrashCan>>
    > = ({ signal }) => getRequestEmptyTrashCan(params, requestOptions, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof getRequestEmptyTrashCan>>,
        TError,
        TData
    > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRequestEmptyTrashCanQueryResult = NonNullable<
    Awaited<ReturnType<typeof getRequestEmptyTrashCan>>
>
export type GetRequestEmptyTrashCanQueryError =
    ErrorType<HTTPValidationErrorDTO>

export function useGetRequestEmptyTrashCan<
    TData = Awaited<ReturnType<typeof getRequestEmptyTrashCan>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestEmptyTrashCanParams,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestEmptyTrashCan>>,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestEmptyTrashCan>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestEmptyTrashCan>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestEmptyTrashCan<
    TData = Awaited<ReturnType<typeof getRequestEmptyTrashCan>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestEmptyTrashCanParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestEmptyTrashCan>>,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestEmptyTrashCan>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestEmptyTrashCan>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestEmptyTrashCan<
    TData = Awaited<ReturnType<typeof getRequestEmptyTrashCan>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestEmptyTrashCanParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestEmptyTrashCan>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get Empty Trash Can
 */

export function useGetRequestEmptyTrashCan<
    TData = Awaited<ReturnType<typeof getRequestEmptyTrashCan>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestEmptyTrashCanParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestEmptyTrashCan>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions = getGetRequestEmptyTrashCanQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * empty_trash_can for a given file

 * @summary Post Empty Trash Can
 */
export const postRequestEmptyTrashCan = (
    params: PostRequestEmptyTrashCanParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<unknown>(
        { url: `/empty_trash_can`, method: 'POST', params, signal },
        options,
    )
}

export const getPostRequestEmptyTrashCanMutationOptions = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestEmptyTrashCan>>,
        TError,
        { params: PostRequestEmptyTrashCanParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestEmptyTrashCan>>,
    TError,
    { params: PostRequestEmptyTrashCanParams },
    TContext
> => {
    const mutationKey = ['postRequestEmptyTrashCan']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postRequestEmptyTrashCan>>,
        { params: PostRequestEmptyTrashCanParams }
    > = (props) => {
        const { params } = props ?? {}

        return postRequestEmptyTrashCan(params, requestOptions)
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestEmptyTrashCanMutationResult = NonNullable<
    Awaited<ReturnType<typeof postRequestEmptyTrashCan>>
>

export type PostRequestEmptyTrashCanMutationError =
    ErrorType<HTTPValidationErrorDTO>

/**
 * @summary Post Empty Trash Can
 */
export const usePostRequestEmptyTrashCan = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestEmptyTrashCan>>,
        TError,
        { params: PostRequestEmptyTrashCanParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestEmptyTrashCan>>,
    TError,
    { params: PostRequestEmptyTrashCanParams },
    TContext
> => {
    const mutationOptions = getPostRequestEmptyTrashCanMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * this call is used to permanently delete files from trash can.

 * @summary Delete Files From Trash Can
 */
export const deleteRequestJobsDeleteFilesFromTrashCanByJobUuid = (
    jobUuid: string,
    fileUUIDListDTO: BodyType<FileUUIDListDTO>,
    params: DeleteRequestJobsDeleteFilesFromTrashCanByJobUuidParams,
    options?: SecondParameter<typeof customInstance>,
) => {
    return customInstance<unknown>(
        {
            url: `/jobs/${encodeURIComponent(String(jobUuid))}/delete_files_from_trash_can`,
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
            data: fileUUIDListDTO,
            params,
        },
        options,
    )
}

export const getDeleteRequestJobsDeleteFilesFromTrashCanByJobUuidMutationOptions =
    <
        TError = ErrorType<void | HTTPValidationErrorDTO>,
        TContext = unknown,
    >(options?: {
        mutation?: UseMutationOptions<
            Awaited<
                ReturnType<
                    typeof deleteRequestJobsDeleteFilesFromTrashCanByJobUuid
                >
            >,
            TError,
            {
                jobUuid: string
                data: BodyType<FileUUIDListDTO>
                params: DeleteRequestJobsDeleteFilesFromTrashCanByJobUuidParams
            },
            TContext
        >
        request?: SecondParameter<typeof customInstance>
    }): UseMutationOptions<
        Awaited<
            ReturnType<typeof deleteRequestJobsDeleteFilesFromTrashCanByJobUuid>
        >,
        TError,
        {
            jobUuid: string
            data: BodyType<FileUUIDListDTO>
            params: DeleteRequestJobsDeleteFilesFromTrashCanByJobUuidParams
        },
        TContext
    > => {
        const mutationKey = [
            'deleteRequestJobsDeleteFilesFromTrashCanByJobUuid',
        ]
        const { mutation: mutationOptions, request: requestOptions } = options
            ? options.mutation &&
              'mutationKey' in options.mutation &&
              options.mutation.mutationKey
                ? options
                : { ...options, mutation: { ...options.mutation, mutationKey } }
            : { mutation: { mutationKey }, request: undefined }

        const mutationFn: MutationFunction<
            Awaited<
                ReturnType<
                    typeof deleteRequestJobsDeleteFilesFromTrashCanByJobUuid
                >
            >,
            {
                jobUuid: string
                data: BodyType<FileUUIDListDTO>
                params: DeleteRequestJobsDeleteFilesFromTrashCanByJobUuidParams
            }
        > = (props) => {
            const { jobUuid, data, params } = props ?? {}

            return deleteRequestJobsDeleteFilesFromTrashCanByJobUuid(
                jobUuid,
                data,
                params,
                requestOptions,
            )
        }

        return { mutationFn, ...mutationOptions }
    }

export type DeleteRequestJobsDeleteFilesFromTrashCanByJobUuidMutationResult =
    NonNullable<
        Awaited<
            ReturnType<typeof deleteRequestJobsDeleteFilesFromTrashCanByJobUuid>
        >
    >
export type DeleteRequestJobsDeleteFilesFromTrashCanByJobUuidMutationBody =
    BodyType<FileUUIDListDTO>
export type DeleteRequestJobsDeleteFilesFromTrashCanByJobUuidMutationError =
    ErrorType<void | HTTPValidationErrorDTO>

/**
 * @summary Delete Files From Trash Can
 */
export const useDeleteRequestJobsDeleteFilesFromTrashCanByJobUuid = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<typeof deleteRequestJobsDeleteFilesFromTrashCanByJobUuid>
        >,
        TError,
        {
            jobUuid: string
            data: BodyType<FileUUIDListDTO>
            params: DeleteRequestJobsDeleteFilesFromTrashCanByJobUuidParams
        },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<
        ReturnType<typeof deleteRequestJobsDeleteFilesFromTrashCanByJobUuid>
    >,
    TError,
    {
        jobUuid: string
        data: BodyType<FileUUIDListDTO>
        params: DeleteRequestJobsDeleteFilesFromTrashCanByJobUuidParams
    },
    TContext
> => {
    const mutationOptions =
        getDeleteRequestJobsDeleteFilesFromTrashCanByJobUuidMutationOptions(
            options,
        )

    return useMutation(mutationOptions)
}

/**
 * this call is used to permanently delete album from trash can

 * @summary Delete Album From Trash Can
 */
export const deleteRequestJobsDeleteAlbumFromTrashCanByJobUuid = (
    jobUuid: string,
    params: DeleteRequestJobsDeleteAlbumFromTrashCanByJobUuidParams,
    options?: SecondParameter<typeof customInstance>,
) => {
    return customInstance<unknown>(
        {
            url: `/jobs/${encodeURIComponent(String(jobUuid))}/delete_album_from_trash_can`,
            method: 'DELETE',
            params,
        },
        options,
    )
}

export const getDeleteRequestJobsDeleteAlbumFromTrashCanByJobUuidMutationOptions =
    <
        TError = ErrorType<void | HTTPValidationErrorDTO>,
        TContext = unknown,
    >(options?: {
        mutation?: UseMutationOptions<
            Awaited<
                ReturnType<
                    typeof deleteRequestJobsDeleteAlbumFromTrashCanByJobUuid
                >
            >,
            TError,
            {
                jobUuid: string
                params: DeleteRequestJobsDeleteAlbumFromTrashCanByJobUuidParams
            },
            TContext
        >
        request?: SecondParameter<typeof customInstance>
    }): UseMutationOptions<
        Awaited<
            ReturnType<typeof deleteRequestJobsDeleteAlbumFromTrashCanByJobUuid>
        >,
        TError,
        {
            jobUuid: string
            params: DeleteRequestJobsDeleteAlbumFromTrashCanByJobUuidParams
        },
        TContext
    > => {
        const mutationKey = [
            'deleteRequestJobsDeleteAlbumFromTrashCanByJobUuid',
        ]
        const { mutation: mutationOptions, request: requestOptions } = options
            ? options.mutation &&
              'mutationKey' in options.mutation &&
              options.mutation.mutationKey
                ? options
                : { ...options, mutation: { ...options.mutation, mutationKey } }
            : { mutation: { mutationKey }, request: undefined }

        const mutationFn: MutationFunction<
            Awaited<
                ReturnType<
                    typeof deleteRequestJobsDeleteAlbumFromTrashCanByJobUuid
                >
            >,
            {
                jobUuid: string
                params: DeleteRequestJobsDeleteAlbumFromTrashCanByJobUuidParams
            }
        > = (props) => {
            const { jobUuid, params } = props ?? {}

            return deleteRequestJobsDeleteAlbumFromTrashCanByJobUuid(
                jobUuid,
                params,
                requestOptions,
            )
        }

        return { mutationFn, ...mutationOptions }
    }

export type DeleteRequestJobsDeleteAlbumFromTrashCanByJobUuidMutationResult =
    NonNullable<
        Awaited<
            ReturnType<typeof deleteRequestJobsDeleteAlbumFromTrashCanByJobUuid>
        >
    >

export type DeleteRequestJobsDeleteAlbumFromTrashCanByJobUuidMutationError =
    ErrorType<void | HTTPValidationErrorDTO>

/**
 * @summary Delete Album From Trash Can
 */
export const useDeleteRequestJobsDeleteAlbumFromTrashCanByJobUuid = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<typeof deleteRequestJobsDeleteAlbumFromTrashCanByJobUuid>
        >,
        TError,
        {
            jobUuid: string
            params: DeleteRequestJobsDeleteAlbumFromTrashCanByJobUuidParams
        },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<
        ReturnType<typeof deleteRequestJobsDeleteAlbumFromTrashCanByJobUuid>
    >,
    TError,
    {
        jobUuid: string
        params: DeleteRequestJobsDeleteAlbumFromTrashCanByJobUuidParams
    },
    TContext
> => {
    const mutationOptions =
        getDeleteRequestJobsDeleteAlbumFromTrashCanByJobUuidMutationOptions(
            options,
        )

    return useMutation(mutationOptions)
}

/**
 * this call is used to permanently delete albums from trash can

 * @summary Delete Albums From Trash Can
 */
export const deleteRequestDeleteAlbumsFromTrashCan = (
    jobUUIDListDTO: BodyType<JobUUIDListDTO>,
    params: DeleteRequestDeleteAlbumsFromTrashCanParams,
    options?: SecondParameter<typeof customInstance>,
) => {
    return customInstance<DeleteAlbumsResponseDTO>(
        {
            url: `/delete_albums_from_trash_can`,
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
            data: jobUUIDListDTO,
            params,
        },
        options,
    )
}

export const getDeleteRequestDeleteAlbumsFromTrashCanMutationOptions = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof deleteRequestDeleteAlbumsFromTrashCan>>,
        TError,
        {
            data: BodyType<JobUUIDListDTO>
            params: DeleteRequestDeleteAlbumsFromTrashCanParams
        },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof deleteRequestDeleteAlbumsFromTrashCan>>,
    TError,
    {
        data: BodyType<JobUUIDListDTO>
        params: DeleteRequestDeleteAlbumsFromTrashCanParams
    },
    TContext
> => {
    const mutationKey = ['deleteRequestDeleteAlbumsFromTrashCan']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof deleteRequestDeleteAlbumsFromTrashCan>>,
        {
            data: BodyType<JobUUIDListDTO>
            params: DeleteRequestDeleteAlbumsFromTrashCanParams
        }
    > = (props) => {
        const { data, params } = props ?? {}

        return deleteRequestDeleteAlbumsFromTrashCan(
            data,
            params,
            requestOptions,
        )
    }

    return { mutationFn, ...mutationOptions }
}

export type DeleteRequestDeleteAlbumsFromTrashCanMutationResult = NonNullable<
    Awaited<ReturnType<typeof deleteRequestDeleteAlbumsFromTrashCan>>
>
export type DeleteRequestDeleteAlbumsFromTrashCanMutationBody =
    BodyType<JobUUIDListDTO>
export type DeleteRequestDeleteAlbumsFromTrashCanMutationError =
    ErrorType<void | HTTPValidationErrorDTO>

/**
 * @summary Delete Albums From Trash Can
 */
export const useDeleteRequestDeleteAlbumsFromTrashCan = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof deleteRequestDeleteAlbumsFromTrashCan>>,
        TError,
        {
            data: BodyType<JobUUIDListDTO>
            params: DeleteRequestDeleteAlbumsFromTrashCanParams
        },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof deleteRequestDeleteAlbumsFromTrashCan>>,
    TError,
    {
        data: BodyType<JobUUIDListDTO>
        params: DeleteRequestDeleteAlbumsFromTrashCanParams
    },
    TContext
> => {
    const mutationOptions =
        getDeleteRequestDeleteAlbumsFromTrashCanMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * Preflight endpoint for login

 * @summary Login Preflight
 */
export const getRequestTelenoridPreflightOauth2login = (
    params: GetRequestTelenoridPreflightOauth2loginParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<PreFlightOauthStateDTO>(
        {
            url: `/telenorid/preflight_oauth2login`,
            method: 'GET',
            params,
            signal,
        },
        options,
    )
}

export const getGetRequestTelenoridPreflightOauth2loginQueryKey = (
    params: GetRequestTelenoridPreflightOauth2loginParams,
) => {
    return [
        `/telenorid/preflight_oauth2login`,
        ...(params ? [params] : []),
    ] as const
}

export const getGetRequestTelenoridPreflightOauth2loginQueryOptions = <
    TData = Awaited<ReturnType<typeof getRequestTelenoridPreflightOauth2login>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestTelenoridPreflightOauth2loginParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<typeof getRequestTelenoridPreflightOauth2login>
                >,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {}

    const queryKey =
        queryOptions?.queryKey ??
        getGetRequestTelenoridPreflightOauth2loginQueryKey(params)

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getRequestTelenoridPreflightOauth2login>>
    > = ({ signal }) =>
        getRequestTelenoridPreflightOauth2login(params, requestOptions, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof getRequestTelenoridPreflightOauth2login>>,
        TError,
        TData
    > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRequestTelenoridPreflightOauth2loginQueryResult = NonNullable<
    Awaited<ReturnType<typeof getRequestTelenoridPreflightOauth2login>>
>
export type GetRequestTelenoridPreflightOauth2loginQueryError =
    ErrorType<HTTPValidationErrorDTO>

export function useGetRequestTelenoridPreflightOauth2login<
    TData = Awaited<ReturnType<typeof getRequestTelenoridPreflightOauth2login>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestTelenoridPreflightOauth2loginParams,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<typeof getRequestTelenoridPreflightOauth2login>
                >,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<
                        ReturnType<
                            typeof getRequestTelenoridPreflightOauth2login
                        >
                    >,
                    TError,
                    Awaited<
                        ReturnType<
                            typeof getRequestTelenoridPreflightOauth2login
                        >
                    >
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestTelenoridPreflightOauth2login<
    TData = Awaited<ReturnType<typeof getRequestTelenoridPreflightOauth2login>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestTelenoridPreflightOauth2loginParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<typeof getRequestTelenoridPreflightOauth2login>
                >,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<
                        ReturnType<
                            typeof getRequestTelenoridPreflightOauth2login
                        >
                    >,
                    TError,
                    Awaited<
                        ReturnType<
                            typeof getRequestTelenoridPreflightOauth2login
                        >
                    >
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestTelenoridPreflightOauth2login<
    TData = Awaited<ReturnType<typeof getRequestTelenoridPreflightOauth2login>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestTelenoridPreflightOauth2loginParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<typeof getRequestTelenoridPreflightOauth2login>
                >,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Login Preflight
 */

export function useGetRequestTelenoridPreflightOauth2login<
    TData = Awaited<ReturnType<typeof getRequestTelenoridPreflightOauth2login>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestTelenoridPreflightOauth2loginParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<typeof getRequestTelenoridPreflightOauth2login>
                >,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions = getGetRequestTelenoridPreflightOauth2loginQueryOptions(
        params,
        options,
    )

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * Postfligth endpoint after getting authorization code from TelenorID.
Send all parameters provided by the oauth provider to this endpoint for future proofing.

 * @summary Login Postflight
 */
export const getRequestTelenoridPostflightOauth2callback = (
    params: GetRequestTelenoridPostflightOauth2callbackParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<GetRequestTelenoridPostflightOauth2callback200>(
        {
            url: `/telenorid/postflight_oauth2callback`,
            method: 'GET',
            params,
            signal,
        },
        options,
    )
}

export const getGetRequestTelenoridPostflightOauth2callbackQueryKey = (
    params: GetRequestTelenoridPostflightOauth2callbackParams,
) => {
    return [
        `/telenorid/postflight_oauth2callback`,
        ...(params ? [params] : []),
    ] as const
}

export const getGetRequestTelenoridPostflightOauth2callbackQueryOptions = <
    TData = Awaited<
        ReturnType<typeof getRequestTelenoridPostflightOauth2callback>
    >,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestTelenoridPostflightOauth2callbackParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestTelenoridPostflightOauth2callback
                    >
                >,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {}

    const queryKey =
        queryOptions?.queryKey ??
        getGetRequestTelenoridPostflightOauth2callbackQueryKey(params)

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getRequestTelenoridPostflightOauth2callback>>
    > = ({ signal }) =>
        getRequestTelenoridPostflightOauth2callback(
            params,
            requestOptions,
            signal,
        )

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof getRequestTelenoridPostflightOauth2callback>>,
        TError,
        TData
    > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRequestTelenoridPostflightOauth2callbackQueryResult =
    NonNullable<
        Awaited<ReturnType<typeof getRequestTelenoridPostflightOauth2callback>>
    >
export type GetRequestTelenoridPostflightOauth2callbackQueryError =
    ErrorType<HTTPValidationErrorDTO>

export function useGetRequestTelenoridPostflightOauth2callback<
    TData = Awaited<
        ReturnType<typeof getRequestTelenoridPostflightOauth2callback>
    >,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestTelenoridPostflightOauth2callbackParams,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestTelenoridPostflightOauth2callback
                    >
                >,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<
                        ReturnType<
                            typeof getRequestTelenoridPostflightOauth2callback
                        >
                    >,
                    TError,
                    Awaited<
                        ReturnType<
                            typeof getRequestTelenoridPostflightOauth2callback
                        >
                    >
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestTelenoridPostflightOauth2callback<
    TData = Awaited<
        ReturnType<typeof getRequestTelenoridPostflightOauth2callback>
    >,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestTelenoridPostflightOauth2callbackParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestTelenoridPostflightOauth2callback
                    >
                >,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<
                        ReturnType<
                            typeof getRequestTelenoridPostflightOauth2callback
                        >
                    >,
                    TError,
                    Awaited<
                        ReturnType<
                            typeof getRequestTelenoridPostflightOauth2callback
                        >
                    >
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestTelenoridPostflightOauth2callback<
    TData = Awaited<
        ReturnType<typeof getRequestTelenoridPostflightOauth2callback>
    >,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestTelenoridPostflightOauth2callbackParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestTelenoridPostflightOauth2callback
                    >
                >,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Login Postflight
 */

export function useGetRequestTelenoridPostflightOauth2callback<
    TData = Awaited<
        ReturnType<typeof getRequestTelenoridPostflightOauth2callback>
    >,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestTelenoridPostflightOauth2callbackParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestTelenoridPostflightOauth2callback
                    >
                >,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions =
        getGetRequestTelenoridPostflightOauth2callbackQueryOptions(
            params,
            options,
        )

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * As a temporary fix for any potential issues with TelenorID HTML5 login
devices can ask the backend for what login type it should use. This
functionality can only be used by devices that knows what user_agent
information will be used before calling oauth2login

 * @deprecated
 * @summary Login Type
 */
export const postRequestConnectLoginType = (
    params: PostRequestConnectLoginTypeParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<LoginTypeRespDTO>(
        { url: `/connect/login_type`, method: 'POST', params, signal },
        options,
    )
}

export const getPostRequestConnectLoginTypeMutationOptions = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestConnectLoginType>>,
        TError,
        { params: PostRequestConnectLoginTypeParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestConnectLoginType>>,
    TError,
    { params: PostRequestConnectLoginTypeParams },
    TContext
> => {
    const mutationKey = ['postRequestConnectLoginType']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postRequestConnectLoginType>>,
        { params: PostRequestConnectLoginTypeParams }
    > = (props) => {
        const { params } = props ?? {}

        return postRequestConnectLoginType(params, requestOptions)
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestConnectLoginTypeMutationResult = NonNullable<
    Awaited<ReturnType<typeof postRequestConnectLoginType>>
>

export type PostRequestConnectLoginTypeMutationError =
    ErrorType<HTTPValidationErrorDTO>

/**
 * @deprecated
 * @summary Login Type
 */
export const usePostRequestConnectLoginType = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestConnectLoginType>>,
        TError,
        { params: PostRequestConnectLoginTypeParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestConnectLoginType>>,
    TError,
    { params: PostRequestConnectLoginTypeParams },
    TContext
> => {
    const mutationOptions =
        getPostRequestConnectLoginTypeMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @deprecated
 * @summary Register Login Success
 */
export const postRequestConnectRegisterLoginSuccessByUnused = (
    unused: string,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<PostRequestConnectRegisterLoginSuccessByUnused200>(
        {
            url: `/connect/register_login_success/${encodeURIComponent(String(unused))}`,
            method: 'POST',
            signal,
        },
        options,
    )
}

export const getPostRequestConnectRegisterLoginSuccessByUnusedMutationOptions =
    <TError = ErrorType<HTTPValidationErrorDTO>, TContext = unknown>(options?: {
        mutation?: UseMutationOptions<
            Awaited<
                ReturnType<
                    typeof postRequestConnectRegisterLoginSuccessByUnused
                >
            >,
            TError,
            { unused: string },
            TContext
        >
        request?: SecondParameter<typeof customInstance>
    }): UseMutationOptions<
        Awaited<
            ReturnType<typeof postRequestConnectRegisterLoginSuccessByUnused>
        >,
        TError,
        { unused: string },
        TContext
    > => {
        const mutationKey = ['postRequestConnectRegisterLoginSuccessByUnused']
        const { mutation: mutationOptions, request: requestOptions } = options
            ? options.mutation &&
              'mutationKey' in options.mutation &&
              options.mutation.mutationKey
                ? options
                : { ...options, mutation: { ...options.mutation, mutationKey } }
            : { mutation: { mutationKey }, request: undefined }

        const mutationFn: MutationFunction<
            Awaited<
                ReturnType<
                    typeof postRequestConnectRegisterLoginSuccessByUnused
                >
            >,
            { unused: string }
        > = (props) => {
            const { unused } = props ?? {}

            return postRequestConnectRegisterLoginSuccessByUnused(
                unused,
                requestOptions,
            )
        }

        return { mutationFn, ...mutationOptions }
    }

export type PostRequestConnectRegisterLoginSuccessByUnusedMutationResult =
    NonNullable<
        Awaited<
            ReturnType<typeof postRequestConnectRegisterLoginSuccessByUnused>
        >
    >

export type PostRequestConnectRegisterLoginSuccessByUnusedMutationError =
    ErrorType<HTTPValidationErrorDTO>

/**
 * @deprecated
 * @summary Register Login Success
 */
export const usePostRequestConnectRegisterLoginSuccessByUnused = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<typeof postRequestConnectRegisterLoginSuccessByUnused>
        >,
        TError,
        { unused: string },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestConnectRegisterLoginSuccessByUnused>>,
    TError,
    { unused: string },
    TContext
> => {
    const mutationOptions =
        getPostRequestConnectRegisterLoginSuccessByUnusedMutationOptions(
            options,
        )

    return useMutation(mutationOptions)
}

/**
 * Clients can run this call to verify if auth token has been revoked.
So we can prevent the client logging out if they receive 401 from the backend,
but auth token is still valid.

 * @summary Verify Auth
 */
export const getRequestVerifyAuth = (
    params: GetRequestVerifyAuthParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<AuthVerificationRespDTO>(
        { url: `/verify_auth`, method: 'GET', params, signal },
        options,
    )
}

export const getGetRequestVerifyAuthQueryKey = (
    params: GetRequestVerifyAuthParams,
) => {
    return [`/verify_auth`, ...(params ? [params] : [])] as const
}

export const getGetRequestVerifyAuthQueryOptions = <
    TData = Awaited<ReturnType<typeof getRequestVerifyAuth>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestVerifyAuthParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestVerifyAuth>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {}

    const queryKey =
        queryOptions?.queryKey ?? getGetRequestVerifyAuthQueryKey(params)

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getRequestVerifyAuth>>
    > = ({ signal }) => getRequestVerifyAuth(params, requestOptions, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof getRequestVerifyAuth>>,
        TError,
        TData
    > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRequestVerifyAuthQueryResult = NonNullable<
    Awaited<ReturnType<typeof getRequestVerifyAuth>>
>
export type GetRequestVerifyAuthQueryError = ErrorType<HTTPValidationErrorDTO>

export function useGetRequestVerifyAuth<
    TData = Awaited<ReturnType<typeof getRequestVerifyAuth>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestVerifyAuthParams,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestVerifyAuth>>,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestVerifyAuth>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestVerifyAuth>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestVerifyAuth<
    TData = Awaited<ReturnType<typeof getRequestVerifyAuth>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestVerifyAuthParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestVerifyAuth>>,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestVerifyAuth>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestVerifyAuth>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestVerifyAuth<
    TData = Awaited<ReturnType<typeof getRequestVerifyAuth>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestVerifyAuthParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestVerifyAuth>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Verify Auth
 */

export function useGetRequestVerifyAuth<
    TData = Awaited<ReturnType<typeof getRequestVerifyAuth>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestVerifyAuthParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestVerifyAuth>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions = getGetRequestVerifyAuthQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * Logout and delete the Capture token used in the API call.
If the user has an active TelenorID session we also try to do a logout
from TelenorID

 * @summary Logout
 */
export const postRequestLogout = (
    params: PostRequestLogoutParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<LogoutResponseDTO>(
        { url: `/logout`, method: 'POST', params, signal },
        options,
    )
}

export const getPostRequestLogoutMutationOptions = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestLogout>>,
        TError,
        { params: PostRequestLogoutParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestLogout>>,
    TError,
    { params: PostRequestLogoutParams },
    TContext
> => {
    const mutationKey = ['postRequestLogout']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postRequestLogout>>,
        { params: PostRequestLogoutParams }
    > = (props) => {
        const { params } = props ?? {}

        return postRequestLogout(params, requestOptions)
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestLogoutMutationResult = NonNullable<
    Awaited<ReturnType<typeof postRequestLogout>>
>

export type PostRequestLogoutMutationError =
    ErrorType<void | HTTPValidationErrorDTO>

/**
 * @summary Logout
 */
export const usePostRequestLogout = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestLogout>>,
        TError,
        { params: PostRequestLogoutParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestLogout>>,
    TError,
    { params: PostRequestLogoutParams },
    TContext
> => {
    const mutationOptions = getPostRequestLogoutMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * Logout and delete the Capture token used in the API call.
If the user has an active TelenorID session we also try to do a logout
from TelenorID

 * @summary Logout
 */
export const getRequestLogout = (
    params: GetRequestLogoutParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<LogoutResponseDTO>(
        { url: `/logout`, method: 'GET', params, signal },
        options,
    )
}

export const getGetRequestLogoutQueryKey = (params: GetRequestLogoutParams) => {
    return [`/logout`, ...(params ? [params] : [])] as const
}

export const getGetRequestLogoutQueryOptions = <
    TData = Awaited<ReturnType<typeof getRequestLogout>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    params: GetRequestLogoutParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestLogout>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {}

    const queryKey =
        queryOptions?.queryKey ?? getGetRequestLogoutQueryKey(params)

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getRequestLogout>>
    > = ({ signal }) => getRequestLogout(params, requestOptions, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof getRequestLogout>>,
        TError,
        TData
    > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRequestLogoutQueryResult = NonNullable<
    Awaited<ReturnType<typeof getRequestLogout>>
>
export type GetRequestLogoutQueryError =
    ErrorType<void | HTTPValidationErrorDTO>

export function useGetRequestLogout<
    TData = Awaited<ReturnType<typeof getRequestLogout>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    params: GetRequestLogoutParams,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestLogout>>,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestLogout>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestLogout>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestLogout<
    TData = Awaited<ReturnType<typeof getRequestLogout>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    params: GetRequestLogoutParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestLogout>>,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestLogout>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestLogout>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestLogout<
    TData = Awaited<ReturnType<typeof getRequestLogout>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    params: GetRequestLogoutParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestLogout>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Logout
 */

export function useGetRequestLogout<
    TData = Awaited<ReturnType<typeof getRequestLogout>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    params: GetRequestLogoutParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestLogout>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions = getGetRequestLogoutQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * This call is used in the login. It retrieves the user account information In the inlogging we call this method with https://a0-test.univex.no/account_info?validity=86400&device_id=fae886eb-0be9-471a-a174-6ea95a002f86&device_name=Capture%20web%201.5&key=KVEz-WDMsj&auth=5xYX44S_Q5SF-KKBTxkvAAuV8yeZ8H8u8Bi6J0Berj&uniquets=1452154975416
Seems like we call this method with some parameters not used on the backend
 * @summary Account Info
 */
export const getRequestAccountInfo = (
    params: GetRequestAccountInfoParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<AccountInfoDTO>(
        { url: `/account_info`, method: 'GET', params, signal },
        options,
    )
}

export const getGetRequestAccountInfoQueryKey = (
    params: GetRequestAccountInfoParams,
) => {
    return [`/account_info`, ...(params ? [params] : [])] as const
}

export const getGetRequestAccountInfoQueryOptions = <
    TData = Awaited<ReturnType<typeof getRequestAccountInfo>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    params: GetRequestAccountInfoParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestAccountInfo>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {}

    const queryKey =
        queryOptions?.queryKey ?? getGetRequestAccountInfoQueryKey(params)

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getRequestAccountInfo>>
    > = ({ signal }) => getRequestAccountInfo(params, requestOptions, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof getRequestAccountInfo>>,
        TError,
        TData
    > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRequestAccountInfoQueryResult = NonNullable<
    Awaited<ReturnType<typeof getRequestAccountInfo>>
>
export type GetRequestAccountInfoQueryError =
    ErrorType<void | HTTPValidationErrorDTO>

export function useGetRequestAccountInfo<
    TData = Awaited<ReturnType<typeof getRequestAccountInfo>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    params: GetRequestAccountInfoParams,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestAccountInfo>>,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestAccountInfo>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestAccountInfo>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestAccountInfo<
    TData = Awaited<ReturnType<typeof getRequestAccountInfo>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    params: GetRequestAccountInfoParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestAccountInfo>>,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestAccountInfo>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestAccountInfo>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestAccountInfo<
    TData = Awaited<ReturnType<typeof getRequestAccountInfo>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    params: GetRequestAccountInfoParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestAccountInfo>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Account Info
 */

export function useGetRequestAccountInfo<
    TData = Awaited<ReturnType<typeof getRequestAccountInfo>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    params: GetRequestAccountInfoParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestAccountInfo>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions = getGetRequestAccountInfoQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * This api call sets and clears the name of the user.

 * @summary Set User Name
 */
export const postRequestName = (
    params: PostRequestNameParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<unknown>(
        { url: `/name`, method: 'POST', params, signal },
        options,
    )
}

export const getPostRequestNameMutationOptions = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestName>>,
        TError,
        { params: PostRequestNameParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestName>>,
    TError,
    { params: PostRequestNameParams },
    TContext
> => {
    const mutationKey = ['postRequestName']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postRequestName>>,
        { params: PostRequestNameParams }
    > = (props) => {
        const { params } = props ?? {}

        return postRequestName(params, requestOptions)
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestNameMutationResult = NonNullable<
    Awaited<ReturnType<typeof postRequestName>>
>

export type PostRequestNameMutationError = ErrorType<HTTPValidationErrorDTO>

/**
 * @summary Set User Name
 */
export const usePostRequestName = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestName>>,
        TError,
        { params: PostRequestNameParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestName>>,
    TError,
    { params: PostRequestNameParams },
    TContext
> => {
    const mutationOptions = getPostRequestNameMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * This call list out the different devices

 * @summary Get Devices
 */
export const getRequestDevices = (
    params: GetRequestDevicesParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<DevicesWithAuthDTO>(
        { url: `/devices`, method: 'GET', params, signal },
        options,
    )
}

export const getGetRequestDevicesQueryKey = (
    params: GetRequestDevicesParams,
) => {
    return [`/devices`, ...(params ? [params] : [])] as const
}

export const getGetRequestDevicesQueryOptions = <
    TData = Awaited<ReturnType<typeof getRequestDevices>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestDevicesParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestDevices>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {}

    const queryKey =
        queryOptions?.queryKey ?? getGetRequestDevicesQueryKey(params)

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getRequestDevices>>
    > = ({ signal }) => getRequestDevices(params, requestOptions, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof getRequestDevices>>,
        TError,
        TData
    > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRequestDevicesQueryResult = NonNullable<
    Awaited<ReturnType<typeof getRequestDevices>>
>
export type GetRequestDevicesQueryError = ErrorType<HTTPValidationErrorDTO>

export function useGetRequestDevices<
    TData = Awaited<ReturnType<typeof getRequestDevices>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestDevicesParams,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestDevices>>,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestDevices>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestDevices>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestDevices<
    TData = Awaited<ReturnType<typeof getRequestDevices>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestDevicesParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestDevices>>,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestDevices>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestDevices>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestDevices<
    TData = Awaited<ReturnType<typeof getRequestDevices>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestDevicesParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestDevices>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get Devices
 */

export function useGetRequestDevices<
    TData = Awaited<ReturnType<typeof getRequestDevices>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestDevicesParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestDevices>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions = getGetRequestDevicesQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * This call is used to get current valid grants for a user

 * @summary Get User Grants
 */
export const getRequestUserGrants = (
    params: GetRequestUserGrantsParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<GrantInfoResponseDTO>(
        { url: `/user_grants`, method: 'GET', params, signal },
        options,
    )
}

export const getGetRequestUserGrantsQueryKey = (
    params: GetRequestUserGrantsParams,
) => {
    return [`/user_grants`, ...(params ? [params] : [])] as const
}

export const getGetRequestUserGrantsQueryOptions = <
    TData = Awaited<ReturnType<typeof getRequestUserGrants>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestUserGrantsParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestUserGrants>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {}

    const queryKey =
        queryOptions?.queryKey ?? getGetRequestUserGrantsQueryKey(params)

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getRequestUserGrants>>
    > = ({ signal }) => getRequestUserGrants(params, requestOptions, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof getRequestUserGrants>>,
        TError,
        TData
    > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRequestUserGrantsQueryResult = NonNullable<
    Awaited<ReturnType<typeof getRequestUserGrants>>
>
export type GetRequestUserGrantsQueryError = ErrorType<HTTPValidationErrorDTO>

export function useGetRequestUserGrants<
    TData = Awaited<ReturnType<typeof getRequestUserGrants>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestUserGrantsParams,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestUserGrants>>,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestUserGrants>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestUserGrants>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestUserGrants<
    TData = Awaited<ReturnType<typeof getRequestUserGrants>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestUserGrantsParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestUserGrants>>,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestUserGrants>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestUserGrants>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestUserGrants<
    TData = Awaited<ReturnType<typeof getRequestUserGrants>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestUserGrantsParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestUserGrants>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get User Grants
 */

export function useGetRequestUserGrants<
    TData = Awaited<ReturnType<typeof getRequestUserGrants>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestUserGrantsParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestUserGrants>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions = getGetRequestUserGrantsQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * This api call is used to invalidate the auth token for the device

 * @summary Delete Devices
 */
export const deleteRequestDevicesByDeviceId = (
    deviceId: string,
    params: DeleteRequestDevicesByDeviceIdParams,
    options?: SecondParameter<typeof customInstance>,
) => {
    return customInstance<MessageResponseDTO>(
        {
            url: `/devices/${encodeURIComponent(String(deviceId))}`,
            method: 'DELETE',
            params,
        },
        options,
    )
}

export const getDeleteRequestDevicesByDeviceIdMutationOptions = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof deleteRequestDevicesByDeviceId>>,
        TError,
        { deviceId: string; params: DeleteRequestDevicesByDeviceIdParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof deleteRequestDevicesByDeviceId>>,
    TError,
    { deviceId: string; params: DeleteRequestDevicesByDeviceIdParams },
    TContext
> => {
    const mutationKey = ['deleteRequestDevicesByDeviceId']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof deleteRequestDevicesByDeviceId>>,
        { deviceId: string; params: DeleteRequestDevicesByDeviceIdParams }
    > = (props) => {
        const { deviceId, params } = props ?? {}

        return deleteRequestDevicesByDeviceId(deviceId, params, requestOptions)
    }

    return { mutationFn, ...mutationOptions }
}

export type DeleteRequestDevicesByDeviceIdMutationResult = NonNullable<
    Awaited<ReturnType<typeof deleteRequestDevicesByDeviceId>>
>

export type DeleteRequestDevicesByDeviceIdMutationError =
    ErrorType<HTTPValidationErrorDTO>

/**
 * @summary Delete Devices
 */
export const useDeleteRequestDevicesByDeviceId = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof deleteRequestDevicesByDeviceId>>,
        TError,
        { deviceId: string; params: DeleteRequestDevicesByDeviceIdParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof deleteRequestDevicesByDeviceId>>,
    TError,
    { deviceId: string; params: DeleteRequestDevicesByDeviceIdParams },
    TContext
> => {
    const mutationOptions =
        getDeleteRequestDevicesByDeviceIdMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * Use DELETE /devices/{device_id}
 * @deprecated
 * @summary Delete Devices
 */
export const postRequestDevicesDeleteByDeviceId = (
    deviceId: string,
    params: PostRequestDevicesDeleteByDeviceIdParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<MessageResponseDTO>(
        {
            url: `/devices/${encodeURIComponent(String(deviceId))}/delete`,
            method: 'POST',
            params,
            signal,
        },
        options,
    )
}

export const getPostRequestDevicesDeleteByDeviceIdMutationOptions = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestDevicesDeleteByDeviceId>>,
        TError,
        { deviceId: string; params: PostRequestDevicesDeleteByDeviceIdParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestDevicesDeleteByDeviceId>>,
    TError,
    { deviceId: string; params: PostRequestDevicesDeleteByDeviceIdParams },
    TContext
> => {
    const mutationKey = ['postRequestDevicesDeleteByDeviceId']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postRequestDevicesDeleteByDeviceId>>,
        { deviceId: string; params: PostRequestDevicesDeleteByDeviceIdParams }
    > = (props) => {
        const { deviceId, params } = props ?? {}

        return postRequestDevicesDeleteByDeviceId(
            deviceId,
            params,
            requestOptions,
        )
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestDevicesDeleteByDeviceIdMutationResult = NonNullable<
    Awaited<ReturnType<typeof postRequestDevicesDeleteByDeviceId>>
>

export type PostRequestDevicesDeleteByDeviceIdMutationError =
    ErrorType<HTTPValidationErrorDTO>

/**
 * @deprecated
 * @summary Delete Devices
 */
export const usePostRequestDevicesDeleteByDeviceId = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestDevicesDeleteByDeviceId>>,
        TError,
        { deviceId: string; params: PostRequestDevicesDeleteByDeviceIdParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestDevicesDeleteByDeviceId>>,
    TError,
    { deviceId: string; params: PostRequestDevicesDeleteByDeviceIdParams },
    TContext
> => {
    const mutationOptions =
        getPostRequestDevicesDeleteByDeviceIdMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * List all user groups the logged in user is a member of

 * @summary Get User Groups
 */
export const getRequestUserGroups = (
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<UserGroupsDTO>(
        { url: `/user_groups`, method: 'GET', signal },
        options,
    )
}

export const getGetRequestUserGroupsQueryKey = () => {
    return [`/user_groups`] as const
}

export const getGetRequestUserGroupsQueryOptions = <
    TData = Awaited<ReturnType<typeof getRequestUserGroups>>,
    TError = ErrorType<unknown>,
>(options?: {
    query?: Partial<
        UseQueryOptions<
            Awaited<ReturnType<typeof getRequestUserGroups>>,
            TError,
            TData
        >
    >
    request?: SecondParameter<typeof customInstance>
}) => {
    const { query: queryOptions, request: requestOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetRequestUserGroupsQueryKey()

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getRequestUserGroups>>
    > = ({ signal }) => getRequestUserGroups(requestOptions, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof getRequestUserGroups>>,
        TError,
        TData
    > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRequestUserGroupsQueryResult = NonNullable<
    Awaited<ReturnType<typeof getRequestUserGroups>>
>
export type GetRequestUserGroupsQueryError = ErrorType<unknown>

export function useGetRequestUserGroups<
    TData = Awaited<ReturnType<typeof getRequestUserGroups>>,
    TError = ErrorType<unknown>,
>(options: {
    query: Partial<
        UseQueryOptions<
            Awaited<ReturnType<typeof getRequestUserGroups>>,
            TError,
            TData
        >
    > &
        Pick<
            DefinedInitialDataOptions<
                Awaited<ReturnType<typeof getRequestUserGroups>>,
                TError,
                Awaited<ReturnType<typeof getRequestUserGroups>>
            >,
            'initialData'
        >
    request?: SecondParameter<typeof customInstance>
}): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestUserGroups<
    TData = Awaited<ReturnType<typeof getRequestUserGroups>>,
    TError = ErrorType<unknown>,
>(options?: {
    query?: Partial<
        UseQueryOptions<
            Awaited<ReturnType<typeof getRequestUserGroups>>,
            TError,
            TData
        >
    > &
        Pick<
            UndefinedInitialDataOptions<
                Awaited<ReturnType<typeof getRequestUserGroups>>,
                TError,
                Awaited<ReturnType<typeof getRequestUserGroups>>
            >,
            'initialData'
        >
    request?: SecondParameter<typeof customInstance>
}): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestUserGroups<
    TData = Awaited<ReturnType<typeof getRequestUserGroups>>,
    TError = ErrorType<unknown>,
>(options?: {
    query?: Partial<
        UseQueryOptions<
            Awaited<ReturnType<typeof getRequestUserGroups>>,
            TError,
            TData
        >
    >
    request?: SecondParameter<typeof customInstance>
}): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get User Groups
 */

export function useGetRequestUserGroups<
    TData = Awaited<ReturnType<typeof getRequestUserGroups>>,
    TError = ErrorType<unknown>,
>(options?: {
    query?: Partial<
        UseQueryOptions<
            Awaited<ReturnType<typeof getRequestUserGroups>>,
            TError,
            TData
        >
    >
    request?: SecondParameter<typeof customInstance>
}): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions = getGetRequestUserGroupsQueryOptions(options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * Add the logged in user to the given user group

 * @summary Put User Group Membership
 */
export const putRequestUserGroupsByUserGroupName = (
    userGroupName: string,
    options?: SecondParameter<typeof customInstance>,
) => {
    return customInstance<UserGroupEnrollmentResponseDTO>(
        {
            url: `/user_groups/${encodeURIComponent(String(userGroupName))}`,
            method: 'PUT',
        },
        options,
    )
}

export const getPutRequestUserGroupsByUserGroupNameMutationOptions = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof putRequestUserGroupsByUserGroupName>>,
        TError,
        { userGroupName: string },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof putRequestUserGroupsByUserGroupName>>,
    TError,
    { userGroupName: string },
    TContext
> => {
    const mutationKey = ['putRequestUserGroupsByUserGroupName']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof putRequestUserGroupsByUserGroupName>>,
        { userGroupName: string }
    > = (props) => {
        const { userGroupName } = props ?? {}

        return putRequestUserGroupsByUserGroupName(
            userGroupName,
            requestOptions,
        )
    }

    return { mutationFn, ...mutationOptions }
}

export type PutRequestUserGroupsByUserGroupNameMutationResult = NonNullable<
    Awaited<ReturnType<typeof putRequestUserGroupsByUserGroupName>>
>

export type PutRequestUserGroupsByUserGroupNameMutationError =
    ErrorType<void | HTTPValidationErrorDTO>

/**
 * @summary Put User Group Membership
 */
export const usePutRequestUserGroupsByUserGroupName = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof putRequestUserGroupsByUserGroupName>>,
        TError,
        { userGroupName: string },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof putRequestUserGroupsByUserGroupName>>,
    TError,
    { userGroupName: string },
    TContext
> => {
    const mutationOptions =
        getPutRequestUserGroupsByUserGroupNameMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * Remove the logged in user from the given user group

 * @summary Delete User Group Membership
 */
export const deleteRequestUserGroupsByUserGroupName = (
    userGroupName: string,
    options?: SecondParameter<typeof customInstance>,
) => {
    return customInstance<UserGroupWithdrawalResponseDTO>(
        {
            url: `/user_groups/${encodeURIComponent(String(userGroupName))}`,
            method: 'DELETE',
        },
        options,
    )
}

export const getDeleteRequestUserGroupsByUserGroupNameMutationOptions = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof deleteRequestUserGroupsByUserGroupName>>,
        TError,
        { userGroupName: string },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof deleteRequestUserGroupsByUserGroupName>>,
    TError,
    { userGroupName: string },
    TContext
> => {
    const mutationKey = ['deleteRequestUserGroupsByUserGroupName']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof deleteRequestUserGroupsByUserGroupName>>,
        { userGroupName: string }
    > = (props) => {
        const { userGroupName } = props ?? {}

        return deleteRequestUserGroupsByUserGroupName(
            userGroupName,
            requestOptions,
        )
    }

    return { mutationFn, ...mutationOptions }
}

export type DeleteRequestUserGroupsByUserGroupNameMutationResult = NonNullable<
    Awaited<ReturnType<typeof deleteRequestUserGroupsByUserGroupName>>
>

export type DeleteRequestUserGroupsByUserGroupNameMutationError =
    ErrorType<HTTPValidationErrorDTO>

/**
 * @summary Delete User Group Membership
 */
export const useDeleteRequestUserGroupsByUserGroupName = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof deleteRequestUserGroupsByUserGroupName>>,
        TError,
        { userGroupName: string },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof deleteRequestUserGroupsByUserGroupName>>,
    TError,
    { userGroupName: string },
    TContext
> => {
    const mutationOptions =
        getDeleteRequestUserGroupsByUserGroupNameMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * Admin endpoint to list all user groups
 * @summary Get All User Groups
 */
export const getRequestUserGroupAdmin = (
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<UserGroupDefinitionDTO[]>(
        { url: `/user_group_admin`, method: 'GET', signal },
        options,
    )
}

export const getGetRequestUserGroupAdminQueryKey = () => {
    return [`/user_group_admin`] as const
}

export const getGetRequestUserGroupAdminQueryOptions = <
    TData = Awaited<ReturnType<typeof getRequestUserGroupAdmin>>,
    TError = ErrorType<unknown>,
>(options?: {
    query?: Partial<
        UseQueryOptions<
            Awaited<ReturnType<typeof getRequestUserGroupAdmin>>,
            TError,
            TData
        >
    >
    request?: SecondParameter<typeof customInstance>
}) => {
    const { query: queryOptions, request: requestOptions } = options ?? {}

    const queryKey =
        queryOptions?.queryKey ?? getGetRequestUserGroupAdminQueryKey()

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getRequestUserGroupAdmin>>
    > = ({ signal }) => getRequestUserGroupAdmin(requestOptions, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof getRequestUserGroupAdmin>>,
        TError,
        TData
    > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRequestUserGroupAdminQueryResult = NonNullable<
    Awaited<ReturnType<typeof getRequestUserGroupAdmin>>
>
export type GetRequestUserGroupAdminQueryError = ErrorType<unknown>

export function useGetRequestUserGroupAdmin<
    TData = Awaited<ReturnType<typeof getRequestUserGroupAdmin>>,
    TError = ErrorType<unknown>,
>(options: {
    query: Partial<
        UseQueryOptions<
            Awaited<ReturnType<typeof getRequestUserGroupAdmin>>,
            TError,
            TData
        >
    > &
        Pick<
            DefinedInitialDataOptions<
                Awaited<ReturnType<typeof getRequestUserGroupAdmin>>,
                TError,
                Awaited<ReturnType<typeof getRequestUserGroupAdmin>>
            >,
            'initialData'
        >
    request?: SecondParameter<typeof customInstance>
}): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestUserGroupAdmin<
    TData = Awaited<ReturnType<typeof getRequestUserGroupAdmin>>,
    TError = ErrorType<unknown>,
>(options?: {
    query?: Partial<
        UseQueryOptions<
            Awaited<ReturnType<typeof getRequestUserGroupAdmin>>,
            TError,
            TData
        >
    > &
        Pick<
            UndefinedInitialDataOptions<
                Awaited<ReturnType<typeof getRequestUserGroupAdmin>>,
                TError,
                Awaited<ReturnType<typeof getRequestUserGroupAdmin>>
            >,
            'initialData'
        >
    request?: SecondParameter<typeof customInstance>
}): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestUserGroupAdmin<
    TData = Awaited<ReturnType<typeof getRequestUserGroupAdmin>>,
    TError = ErrorType<unknown>,
>(options?: {
    query?: Partial<
        UseQueryOptions<
            Awaited<ReturnType<typeof getRequestUserGroupAdmin>>,
            TError,
            TData
        >
    >
    request?: SecondParameter<typeof customInstance>
}): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get All User Groups
 */

export function useGetRequestUserGroupAdmin<
    TData = Awaited<ReturnType<typeof getRequestUserGroupAdmin>>,
    TError = ErrorType<unknown>,
>(options?: {
    query?: Partial<
        UseQueryOptions<
            Awaited<ReturnType<typeof getRequestUserGroupAdmin>>,
            TError,
            TData
        >
    >
    request?: SecondParameter<typeof customInstance>
}): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions = getGetRequestUserGroupAdminQueryOptions(options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * Admin endpoint to create a new user group
 * @summary Put User Group
 */
export const putRequestUserGroupAdminByUserGroupName = (
    userGroupName: string,
    options?: SecondParameter<typeof customInstance>,
) => {
    return customInstance<UserGroupCreationResponseDTO | void>(
        {
            url: `/user_group_admin/${encodeURIComponent(String(userGroupName))}`,
            method: 'PUT',
        },
        options,
    )
}

export const getPutRequestUserGroupAdminByUserGroupNameMutationOptions = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof putRequestUserGroupAdminByUserGroupName>>,
        TError,
        { userGroupName: string },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof putRequestUserGroupAdminByUserGroupName>>,
    TError,
    { userGroupName: string },
    TContext
> => {
    const mutationKey = ['putRequestUserGroupAdminByUserGroupName']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof putRequestUserGroupAdminByUserGroupName>>,
        { userGroupName: string }
    > = (props) => {
        const { userGroupName } = props ?? {}

        return putRequestUserGroupAdminByUserGroupName(
            userGroupName,
            requestOptions,
        )
    }

    return { mutationFn, ...mutationOptions }
}

export type PutRequestUserGroupAdminByUserGroupNameMutationResult = NonNullable<
    Awaited<ReturnType<typeof putRequestUserGroupAdminByUserGroupName>>
>

export type PutRequestUserGroupAdminByUserGroupNameMutationError =
    ErrorType<HTTPValidationErrorDTO>

/**
 * @summary Put User Group
 */
export const usePutRequestUserGroupAdminByUserGroupName = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof putRequestUserGroupAdminByUserGroupName>>,
        TError,
        { userGroupName: string },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof putRequestUserGroupAdminByUserGroupName>>,
    TError,
    { userGroupName: string },
    TContext
> => {
    const mutationOptions =
        getPutRequestUserGroupAdminByUserGroupNameMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * Return all registered remote variables.

 * @summary Get Registerered Remote Vars
 */
export const getRequestRemoteVarsRegistry = (
    params: GetRequestRemoteVarsRegistryParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<RemoteVarDefinitionDataDTO>(
        { url: `/remote_vars/registry`, method: 'GET', params, signal },
        options,
    )
}

export const getGetRequestRemoteVarsRegistryQueryKey = (
    params: GetRequestRemoteVarsRegistryParams,
) => {
    return [`/remote_vars/registry`, ...(params ? [params] : [])] as const
}

export const getGetRequestRemoteVarsRegistryQueryOptions = <
    TData = Awaited<ReturnType<typeof getRequestRemoteVarsRegistry>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    params: GetRequestRemoteVarsRegistryParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestRemoteVarsRegistry>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {}

    const queryKey =
        queryOptions?.queryKey ??
        getGetRequestRemoteVarsRegistryQueryKey(params)

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getRequestRemoteVarsRegistry>>
    > = ({ signal }) =>
        getRequestRemoteVarsRegistry(params, requestOptions, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof getRequestRemoteVarsRegistry>>,
        TError,
        TData
    > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRequestRemoteVarsRegistryQueryResult = NonNullable<
    Awaited<ReturnType<typeof getRequestRemoteVarsRegistry>>
>
export type GetRequestRemoteVarsRegistryQueryError =
    ErrorType<void | HTTPValidationErrorDTO>

export function useGetRequestRemoteVarsRegistry<
    TData = Awaited<ReturnType<typeof getRequestRemoteVarsRegistry>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    params: GetRequestRemoteVarsRegistryParams,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestRemoteVarsRegistry>>,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestRemoteVarsRegistry>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestRemoteVarsRegistry>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestRemoteVarsRegistry<
    TData = Awaited<ReturnType<typeof getRequestRemoteVarsRegistry>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    params: GetRequestRemoteVarsRegistryParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestRemoteVarsRegistry>>,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestRemoteVarsRegistry>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestRemoteVarsRegistry>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestRemoteVarsRegistry<
    TData = Awaited<ReturnType<typeof getRequestRemoteVarsRegistry>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    params: GetRequestRemoteVarsRegistryParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestRemoteVarsRegistry>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get Registerered Remote Vars
 */

export function useGetRequestRemoteVarsRegistry<
    TData = Awaited<ReturnType<typeof getRequestRemoteVarsRegistry>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    params: GetRequestRemoteVarsRegistryParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestRemoteVarsRegistry>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions = getGetRequestRemoteVarsRegistryQueryOptions(
        params,
        options,
    )

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * Registering new variable. If the variable exists for (name, api_key)
then the _required_version_ and _default_value_ will be overwritten.

 * @summary Put Register Remote Var
 */
export const putRequestRemoteVarsRegistry = (
    params: PutRequestRemoteVarsRegistryParams,
    options?: SecondParameter<typeof customInstance>,
) => {
    return customInstance<unknown>(
        { url: `/remote_vars/registry`, method: 'PUT', params },
        options,
    )
}

export const getPutRequestRemoteVarsRegistryMutationOptions = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof putRequestRemoteVarsRegistry>>,
        TError,
        { params: PutRequestRemoteVarsRegistryParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof putRequestRemoteVarsRegistry>>,
    TError,
    { params: PutRequestRemoteVarsRegistryParams },
    TContext
> => {
    const mutationKey = ['putRequestRemoteVarsRegistry']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof putRequestRemoteVarsRegistry>>,
        { params: PutRequestRemoteVarsRegistryParams }
    > = (props) => {
        const { params } = props ?? {}

        return putRequestRemoteVarsRegistry(params, requestOptions)
    }

    return { mutationFn, ...mutationOptions }
}

export type PutRequestRemoteVarsRegistryMutationResult = NonNullable<
    Awaited<ReturnType<typeof putRequestRemoteVarsRegistry>>
>

export type PutRequestRemoteVarsRegistryMutationError =
    ErrorType<void | HTTPValidationErrorDTO>

/**
 * @summary Put Register Remote Var
 */
export const usePutRequestRemoteVarsRegistry = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof putRequestRemoteVarsRegistry>>,
        TError,
        { params: PutRequestRemoteVarsRegistryParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof putRequestRemoteVarsRegistry>>,
    TError,
    { params: PutRequestRemoteVarsRegistryParams },
    TContext
> => {
    const mutationOptions =
        getPutRequestRemoteVarsRegistryMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * Unregister a remote variable. The method will remove remote variable
together with user overrides. Unregistering of not yet registered
variable will be silently ignored.

 * @summary Delete Registered Remote Var
 */
export const deleteRequestRemoteVarsRegistry = (
    params: DeleteRequestRemoteVarsRegistryParams,
    options?: SecondParameter<typeof customInstance>,
) => {
    return customInstance<unknown>(
        { url: `/remote_vars/registry`, method: 'DELETE', params },
        options,
    )
}

export const getDeleteRequestRemoteVarsRegistryMutationOptions = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof deleteRequestRemoteVarsRegistry>>,
        TError,
        { params: DeleteRequestRemoteVarsRegistryParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof deleteRequestRemoteVarsRegistry>>,
    TError,
    { params: DeleteRequestRemoteVarsRegistryParams },
    TContext
> => {
    const mutationKey = ['deleteRequestRemoteVarsRegistry']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof deleteRequestRemoteVarsRegistry>>,
        { params: DeleteRequestRemoteVarsRegistryParams }
    > = (props) => {
        const { params } = props ?? {}

        return deleteRequestRemoteVarsRegistry(params, requestOptions)
    }

    return { mutationFn, ...mutationOptions }
}

export type DeleteRequestRemoteVarsRegistryMutationResult = NonNullable<
    Awaited<ReturnType<typeof deleteRequestRemoteVarsRegistry>>
>

export type DeleteRequestRemoteVarsRegistryMutationError =
    ErrorType<void | HTTPValidationErrorDTO>

/**
 * @summary Delete Registered Remote Var
 */
export const useDeleteRequestRemoteVarsRegistry = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof deleteRequestRemoteVarsRegistry>>,
        TError,
        { params: DeleteRequestRemoteVarsRegistryParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof deleteRequestRemoteVarsRegistry>>,
    TError,
    { params: DeleteRequestRemoteVarsRegistryParams },
    TContext
> => {
    const mutationOptions =
        getDeleteRequestRemoteVarsRegistryMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * It will return all compatible remote variables for the client.
The method will apply user overrides on the value before returns.

 * @summary Get All Remote Vars
 */
export const getRequestRemoteVars = (
    params: GetRequestRemoteVarsParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<RemoteVarDataDTO>(
        { url: `/remote_vars`, method: 'GET', params, signal },
        options,
    )
}

export const getGetRequestRemoteVarsQueryKey = (
    params: GetRequestRemoteVarsParams,
) => {
    return [`/remote_vars`, ...(params ? [params] : [])] as const
}

export const getGetRequestRemoteVarsQueryOptions = <
    TData = Awaited<ReturnType<typeof getRequestRemoteVars>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    params: GetRequestRemoteVarsParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestRemoteVars>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {}

    const queryKey =
        queryOptions?.queryKey ?? getGetRequestRemoteVarsQueryKey(params)

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getRequestRemoteVars>>
    > = ({ signal }) => getRequestRemoteVars(params, requestOptions, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof getRequestRemoteVars>>,
        TError,
        TData
    > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRequestRemoteVarsQueryResult = NonNullable<
    Awaited<ReturnType<typeof getRequestRemoteVars>>
>
export type GetRequestRemoteVarsQueryError =
    ErrorType<void | HTTPValidationErrorDTO>

export function useGetRequestRemoteVars<
    TData = Awaited<ReturnType<typeof getRequestRemoteVars>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    params: GetRequestRemoteVarsParams,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestRemoteVars>>,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestRemoteVars>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestRemoteVars>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestRemoteVars<
    TData = Awaited<ReturnType<typeof getRequestRemoteVars>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    params: GetRequestRemoteVarsParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestRemoteVars>>,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestRemoteVars>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestRemoteVars>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestRemoteVars<
    TData = Awaited<ReturnType<typeof getRequestRemoteVars>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    params: GetRequestRemoteVarsParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestRemoteVars>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get All Remote Vars
 */

export function useGetRequestRemoteVars<
    TData = Awaited<ReturnType<typeof getRequestRemoteVars>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    params: GetRequestRemoteVarsParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestRemoteVars>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions = getGetRequestRemoteVarsQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * Registering new variable. If the variable exists for (name, api_key)
then the _required_version_ and _default_value_ will be overwritten.

 * @summary Put User Remote Var
 */
export const putRequestRemoteVars = (
    params: PutRequestRemoteVarsParams,
    options?: SecondParameter<typeof customInstance>,
) => {
    return customInstance<unknown>(
        { url: `/remote_vars`, method: 'PUT', params },
        options,
    )
}

export const getPutRequestRemoteVarsMutationOptions = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof putRequestRemoteVars>>,
        TError,
        { params: PutRequestRemoteVarsParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof putRequestRemoteVars>>,
    TError,
    { params: PutRequestRemoteVarsParams },
    TContext
> => {
    const mutationKey = ['putRequestRemoteVars']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof putRequestRemoteVars>>,
        { params: PutRequestRemoteVarsParams }
    > = (props) => {
        const { params } = props ?? {}

        return putRequestRemoteVars(params, requestOptions)
    }

    return { mutationFn, ...mutationOptions }
}

export type PutRequestRemoteVarsMutationResult = NonNullable<
    Awaited<ReturnType<typeof putRequestRemoteVars>>
>

export type PutRequestRemoteVarsMutationError =
    ErrorType<void | HTTPValidationErrorDTO>

/**
 * @summary Put User Remote Var
 */
export const usePutRequestRemoteVars = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof putRequestRemoteVars>>,
        TError,
        { params: PutRequestRemoteVarsParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof putRequestRemoteVars>>,
    TError,
    { params: PutRequestRemoteVarsParams },
    TContext
> => {
    const mutationOptions = getPutRequestRemoteVarsMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * Remove user override of a registered remote variable.
Removal of non-existing override will be silently ignored.

 * @summary Delete User Remote Var
 */
export const deleteRequestRemoteVars = (
    params: DeleteRequestRemoteVarsParams,
    options?: SecondParameter<typeof customInstance>,
) => {
    return customInstance<unknown>(
        { url: `/remote_vars`, method: 'DELETE', params },
        options,
    )
}

export const getDeleteRequestRemoteVarsMutationOptions = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof deleteRequestRemoteVars>>,
        TError,
        { params: DeleteRequestRemoteVarsParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof deleteRequestRemoteVars>>,
    TError,
    { params: DeleteRequestRemoteVarsParams },
    TContext
> => {
    const mutationKey = ['deleteRequestRemoteVars']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof deleteRequestRemoteVars>>,
        { params: DeleteRequestRemoteVarsParams }
    > = (props) => {
        const { params } = props ?? {}

        return deleteRequestRemoteVars(params, requestOptions)
    }

    return { mutationFn, ...mutationOptions }
}

export type DeleteRequestRemoteVarsMutationResult = NonNullable<
    Awaited<ReturnType<typeof deleteRequestRemoteVars>>
>

export type DeleteRequestRemoteVarsMutationError =
    ErrorType<void | HTTPValidationErrorDTO>

/**
 * @summary Delete User Remote Var
 */
export const useDeleteRequestRemoteVars = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof deleteRequestRemoteVars>>,
        TError,
        { params: DeleteRequestRemoteVarsParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof deleteRequestRemoteVars>>,
    TError,
    { params: DeleteRequestRemoteVarsParams },
    TContext
> => {
    const mutationOptions = getDeleteRequestRemoteVarsMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * List with deleted file information

 * @summary Get Deleted Files Info
 */
export const getRequestJobsDeletedFilesInfoByJobUuid = (
    jobUuid: string,
    params: GetRequestJobsDeletedFilesInfoByJobUuidParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<DeletedFilesInfoResponseDTO>(
        {
            url: `/jobs/${encodeURIComponent(String(jobUuid))}/deleted_files_info`,
            method: 'GET',
            params,
            signal,
        },
        options,
    )
}

export const getGetRequestJobsDeletedFilesInfoByJobUuidQueryKey = (
    jobUuid: string,
    params: GetRequestJobsDeletedFilesInfoByJobUuidParams,
) => {
    return [
        `/jobs/${jobUuid}/deleted_files_info`,
        ...(params ? [params] : []),
    ] as const
}

export const getGetRequestJobsDeletedFilesInfoByJobUuidQueryOptions = <
    TData = Awaited<ReturnType<typeof getRequestJobsDeletedFilesInfoByJobUuid>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestJobsDeletedFilesInfoByJobUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<typeof getRequestJobsDeletedFilesInfoByJobUuid>
                >,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {}

    const queryKey =
        queryOptions?.queryKey ??
        getGetRequestJobsDeletedFilesInfoByJobUuidQueryKey(jobUuid, params)

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getRequestJobsDeletedFilesInfoByJobUuid>>
    > = ({ signal }) =>
        getRequestJobsDeletedFilesInfoByJobUuid(
            jobUuid,
            params,
            requestOptions,
            signal,
        )

    return {
        queryKey,
        queryFn,
        enabled: !!jobUuid,
        ...queryOptions,
    } as UseQueryOptions<
        Awaited<ReturnType<typeof getRequestJobsDeletedFilesInfoByJobUuid>>,
        TError,
        TData
    > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRequestJobsDeletedFilesInfoByJobUuidQueryResult = NonNullable<
    Awaited<ReturnType<typeof getRequestJobsDeletedFilesInfoByJobUuid>>
>
export type GetRequestJobsDeletedFilesInfoByJobUuidQueryError =
    ErrorType<HTTPValidationErrorDTO>

export function useGetRequestJobsDeletedFilesInfoByJobUuid<
    TData = Awaited<ReturnType<typeof getRequestJobsDeletedFilesInfoByJobUuid>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestJobsDeletedFilesInfoByJobUuidParams,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<typeof getRequestJobsDeletedFilesInfoByJobUuid>
                >,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<
                        ReturnType<
                            typeof getRequestJobsDeletedFilesInfoByJobUuid
                        >
                    >,
                    TError,
                    Awaited<
                        ReturnType<
                            typeof getRequestJobsDeletedFilesInfoByJobUuid
                        >
                    >
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestJobsDeletedFilesInfoByJobUuid<
    TData = Awaited<ReturnType<typeof getRequestJobsDeletedFilesInfoByJobUuid>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestJobsDeletedFilesInfoByJobUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<typeof getRequestJobsDeletedFilesInfoByJobUuid>
                >,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<
                        ReturnType<
                            typeof getRequestJobsDeletedFilesInfoByJobUuid
                        >
                    >,
                    TError,
                    Awaited<
                        ReturnType<
                            typeof getRequestJobsDeletedFilesInfoByJobUuid
                        >
                    >
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestJobsDeletedFilesInfoByJobUuid<
    TData = Awaited<ReturnType<typeof getRequestJobsDeletedFilesInfoByJobUuid>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestJobsDeletedFilesInfoByJobUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<typeof getRequestJobsDeletedFilesInfoByJobUuid>
                >,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get Deleted Files Info
 */

export function useGetRequestJobsDeletedFilesInfoByJobUuid<
    TData = Awaited<ReturnType<typeof getRequestJobsDeletedFilesInfoByJobUuid>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestJobsDeletedFilesInfoByJobUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<typeof getRequestJobsDeletedFilesInfoByJobUuid>
                >,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions = getGetRequestJobsDeletedFilesInfoByJobUuidQueryOptions(
        jobUuid,
        params,
        options,
    )

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * Set properties of a file.
Is currently used to set info on the grouping of a live/burst photo,
but may be extended in the future to support more properties.

 * @summary Post File Props
 */
export const postRequestJobsFilesPropertiesByJobUuidAndFileUuid = (
    jobUuid: string,
    fileUuid: string,
    params: PostRequestJobsFilesPropertiesByJobUuidAndFileUuidParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<unknown>(
        {
            url: `/jobs/${encodeURIComponent(String(jobUuid))}/files/${encodeURIComponent(String(fileUuid))}/properties`,
            method: 'POST',
            params,
            signal,
        },
        options,
    )
}

export const getPostRequestJobsFilesPropertiesByJobUuidAndFileUuidMutationOptions =
    <
        TError = ErrorType<void | HTTPValidationErrorDTO>,
        TContext = unknown,
    >(options?: {
        mutation?: UseMutationOptions<
            Awaited<
                ReturnType<
                    typeof postRequestJobsFilesPropertiesByJobUuidAndFileUuid
                >
            >,
            TError,
            {
                jobUuid: string
                fileUuid: string
                params: PostRequestJobsFilesPropertiesByJobUuidAndFileUuidParams
            },
            TContext
        >
        request?: SecondParameter<typeof customInstance>
    }): UseMutationOptions<
        Awaited<
            ReturnType<
                typeof postRequestJobsFilesPropertiesByJobUuidAndFileUuid
            >
        >,
        TError,
        {
            jobUuid: string
            fileUuid: string
            params: PostRequestJobsFilesPropertiesByJobUuidAndFileUuidParams
        },
        TContext
    > => {
        const mutationKey = [
            'postRequestJobsFilesPropertiesByJobUuidAndFileUuid',
        ]
        const { mutation: mutationOptions, request: requestOptions } = options
            ? options.mutation &&
              'mutationKey' in options.mutation &&
              options.mutation.mutationKey
                ? options
                : { ...options, mutation: { ...options.mutation, mutationKey } }
            : { mutation: { mutationKey }, request: undefined }

        const mutationFn: MutationFunction<
            Awaited<
                ReturnType<
                    typeof postRequestJobsFilesPropertiesByJobUuidAndFileUuid
                >
            >,
            {
                jobUuid: string
                fileUuid: string
                params: PostRequestJobsFilesPropertiesByJobUuidAndFileUuidParams
            }
        > = (props) => {
            const { jobUuid, fileUuid, params } = props ?? {}

            return postRequestJobsFilesPropertiesByJobUuidAndFileUuid(
                jobUuid,
                fileUuid,
                params,
                requestOptions,
            )
        }

        return { mutationFn, ...mutationOptions }
    }

export type PostRequestJobsFilesPropertiesByJobUuidAndFileUuidMutationResult =
    NonNullable<
        Awaited<
            ReturnType<
                typeof postRequestJobsFilesPropertiesByJobUuidAndFileUuid
            >
        >
    >

export type PostRequestJobsFilesPropertiesByJobUuidAndFileUuidMutationError =
    ErrorType<void | HTTPValidationErrorDTO>

/**
 * @summary Post File Props
 */
export const usePostRequestJobsFilesPropertiesByJobUuidAndFileUuid = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<
                typeof postRequestJobsFilesPropertiesByJobUuidAndFileUuid
            >
        >,
        TError,
        {
            jobUuid: string
            fileUuid: string
            params: PostRequestJobsFilesPropertiesByJobUuidAndFileUuidParams
        },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<
        ReturnType<typeof postRequestJobsFilesPropertiesByJobUuidAndFileUuid>
    >,
    TError,
    {
        jobUuid: string
        fileUuid: string
        params: PostRequestJobsFilesPropertiesByJobUuidAndFileUuidParams
    },
    TContext
> => {
    const mutationOptions =
        getPostRequestJobsFilesPropertiesByJobUuidAndFileUuidMutationOptions(
            options,
        )

    return useMutation(mutationOptions)
}

/**
 * Get the properties that are set on a file.

Is currently used to return info on the grouping of a live/burst photo,
but may be extended in the future to support more properties.

 * @summary Get File Props
 */
export const getRequestJobsFilesPropertiesByJobUuidAndFileUuid = (
    jobUuid: string,
    fileUuid: string,
    params: GetRequestJobsFilesPropertiesByJobUuidAndFileUuidParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<FilePropResponseDTO>(
        {
            url: `/jobs/${encodeURIComponent(String(jobUuid))}/files/${encodeURIComponent(String(fileUuid))}/properties`,
            method: 'GET',
            params,
            signal,
        },
        options,
    )
}

export const getGetRequestJobsFilesPropertiesByJobUuidAndFileUuidQueryKey = (
    jobUuid: string,
    fileUuid: string,
    params: GetRequestJobsFilesPropertiesByJobUuidAndFileUuidParams,
) => {
    return [
        `/jobs/${jobUuid}/files/${fileUuid}/properties`,
        ...(params ? [params] : []),
    ] as const
}

export const getGetRequestJobsFilesPropertiesByJobUuidAndFileUuidQueryOptions =
    <
        TData = Awaited<
            ReturnType<typeof getRequestJobsFilesPropertiesByJobUuidAndFileUuid>
        >,
        TError = ErrorType<void | HTTPValidationErrorDTO>,
    >(
        jobUuid: string,
        fileUuid: string,
        params: GetRequestJobsFilesPropertiesByJobUuidAndFileUuidParams,
        options?: {
            query?: Partial<
                UseQueryOptions<
                    Awaited<
                        ReturnType<
                            typeof getRequestJobsFilesPropertiesByJobUuidAndFileUuid
                        >
                    >,
                    TError,
                    TData
                >
            >
            request?: SecondParameter<typeof customInstance>
        },
    ) => {
        const { query: queryOptions, request: requestOptions } = options ?? {}

        const queryKey =
            queryOptions?.queryKey ??
            getGetRequestJobsFilesPropertiesByJobUuidAndFileUuidQueryKey(
                jobUuid,
                fileUuid,
                params,
            )

        const queryFn: QueryFunction<
            Awaited<
                ReturnType<
                    typeof getRequestJobsFilesPropertiesByJobUuidAndFileUuid
                >
            >
        > = ({ signal }) =>
            getRequestJobsFilesPropertiesByJobUuidAndFileUuid(
                jobUuid,
                fileUuid,
                params,
                requestOptions,
                signal,
            )

        return {
            queryKey,
            queryFn,
            enabled: !!(jobUuid && fileUuid),
            ...queryOptions,
        } as UseQueryOptions<
            Awaited<
                ReturnType<
                    typeof getRequestJobsFilesPropertiesByJobUuidAndFileUuid
                >
            >,
            TError,
            TData
        > & { queryKey: DataTag<QueryKey, TData, TError> }
    }

export type GetRequestJobsFilesPropertiesByJobUuidAndFileUuidQueryResult =
    NonNullable<
        Awaited<
            ReturnType<typeof getRequestJobsFilesPropertiesByJobUuidAndFileUuid>
        >
    >
export type GetRequestJobsFilesPropertiesByJobUuidAndFileUuidQueryError =
    ErrorType<void | HTTPValidationErrorDTO>

export function useGetRequestJobsFilesPropertiesByJobUuidAndFileUuid<
    TData = Awaited<
        ReturnType<typeof getRequestJobsFilesPropertiesByJobUuidAndFileUuid>
    >,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    fileUuid: string,
    params: GetRequestJobsFilesPropertiesByJobUuidAndFileUuidParams,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestJobsFilesPropertiesByJobUuidAndFileUuid
                    >
                >,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<
                        ReturnType<
                            typeof getRequestJobsFilesPropertiesByJobUuidAndFileUuid
                        >
                    >,
                    TError,
                    Awaited<
                        ReturnType<
                            typeof getRequestJobsFilesPropertiesByJobUuidAndFileUuid
                        >
                    >
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestJobsFilesPropertiesByJobUuidAndFileUuid<
    TData = Awaited<
        ReturnType<typeof getRequestJobsFilesPropertiesByJobUuidAndFileUuid>
    >,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    fileUuid: string,
    params: GetRequestJobsFilesPropertiesByJobUuidAndFileUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestJobsFilesPropertiesByJobUuidAndFileUuid
                    >
                >,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<
                        ReturnType<
                            typeof getRequestJobsFilesPropertiesByJobUuidAndFileUuid
                        >
                    >,
                    TError,
                    Awaited<
                        ReturnType<
                            typeof getRequestJobsFilesPropertiesByJobUuidAndFileUuid
                        >
                    >
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestJobsFilesPropertiesByJobUuidAndFileUuid<
    TData = Awaited<
        ReturnType<typeof getRequestJobsFilesPropertiesByJobUuidAndFileUuid>
    >,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    fileUuid: string,
    params: GetRequestJobsFilesPropertiesByJobUuidAndFileUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestJobsFilesPropertiesByJobUuidAndFileUuid
                    >
                >,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get File Props
 */

export function useGetRequestJobsFilesPropertiesByJobUuidAndFileUuid<
    TData = Awaited<
        ReturnType<typeof getRequestJobsFilesPropertiesByJobUuidAndFileUuid>
    >,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    fileUuid: string,
    params: GetRequestJobsFilesPropertiesByJobUuidAndFileUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestJobsFilesPropertiesByJobUuidAndFileUuid
                    >
                >,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions =
        getGetRequestJobsFilesPropertiesByJobUuidAndFileUuidQueryOptions(
            jobUuid,
            fileUuid,
            params,
            options,
        )

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * Check if a file exists in a job

 * @summary Get File Exists By Checksum Handler
 */
export const getRequestJobsFileExistsByChecksumByJobUuidAndChecksum = (
    jobUuid: string,
    checksum: string,
    params: GetRequestJobsFileExistsByChecksumByJobUuidAndChecksumParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<GetRequestJobsFileExistsByChecksumByJobUuidAndChecksum200>(
        {
            url: `/jobs/${encodeURIComponent(String(jobUuid))}/file_exists_by_checksum/${encodeURIComponent(String(checksum))}`,
            method: 'GET',
            params,
            signal,
        },
        options,
    )
}

export const getGetRequestJobsFileExistsByChecksumByJobUuidAndChecksumQueryKey =
    (
        jobUuid: string,
        checksum: string,
        params: GetRequestJobsFileExistsByChecksumByJobUuidAndChecksumParams,
    ) => {
        return [
            `/jobs/${jobUuid}/file_exists_by_checksum/${checksum}`,
            ...(params ? [params] : []),
        ] as const
    }

export const getGetRequestJobsFileExistsByChecksumByJobUuidAndChecksumQueryOptions =
    <
        TData = Awaited<
            ReturnType<
                typeof getRequestJobsFileExistsByChecksumByJobUuidAndChecksum
            >
        >,
        TError = ErrorType<void | HTTPValidationErrorDTO>,
    >(
        jobUuid: string,
        checksum: string,
        params: GetRequestJobsFileExistsByChecksumByJobUuidAndChecksumParams,
        options?: {
            query?: Partial<
                UseQueryOptions<
                    Awaited<
                        ReturnType<
                            typeof getRequestJobsFileExistsByChecksumByJobUuidAndChecksum
                        >
                    >,
                    TError,
                    TData
                >
            >
            request?: SecondParameter<typeof customInstance>
        },
    ) => {
        const { query: queryOptions, request: requestOptions } = options ?? {}

        const queryKey =
            queryOptions?.queryKey ??
            getGetRequestJobsFileExistsByChecksumByJobUuidAndChecksumQueryKey(
                jobUuid,
                checksum,
                params,
            )

        const queryFn: QueryFunction<
            Awaited<
                ReturnType<
                    typeof getRequestJobsFileExistsByChecksumByJobUuidAndChecksum
                >
            >
        > = ({ signal }) =>
            getRequestJobsFileExistsByChecksumByJobUuidAndChecksum(
                jobUuid,
                checksum,
                params,
                requestOptions,
                signal,
            )

        return {
            queryKey,
            queryFn,
            enabled: !!(jobUuid && checksum),
            ...queryOptions,
        } as UseQueryOptions<
            Awaited<
                ReturnType<
                    typeof getRequestJobsFileExistsByChecksumByJobUuidAndChecksum
                >
            >,
            TError,
            TData
        > & { queryKey: DataTag<QueryKey, TData, TError> }
    }

export type GetRequestJobsFileExistsByChecksumByJobUuidAndChecksumQueryResult =
    NonNullable<
        Awaited<
            ReturnType<
                typeof getRequestJobsFileExistsByChecksumByJobUuidAndChecksum
            >
        >
    >
export type GetRequestJobsFileExistsByChecksumByJobUuidAndChecksumQueryError =
    ErrorType<void | HTTPValidationErrorDTO>

export function useGetRequestJobsFileExistsByChecksumByJobUuidAndChecksum<
    TData = Awaited<
        ReturnType<
            typeof getRequestJobsFileExistsByChecksumByJobUuidAndChecksum
        >
    >,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    checksum: string,
    params: GetRequestJobsFileExistsByChecksumByJobUuidAndChecksumParams,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestJobsFileExistsByChecksumByJobUuidAndChecksum
                    >
                >,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<
                        ReturnType<
                            typeof getRequestJobsFileExistsByChecksumByJobUuidAndChecksum
                        >
                    >,
                    TError,
                    Awaited<
                        ReturnType<
                            typeof getRequestJobsFileExistsByChecksumByJobUuidAndChecksum
                        >
                    >
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestJobsFileExistsByChecksumByJobUuidAndChecksum<
    TData = Awaited<
        ReturnType<
            typeof getRequestJobsFileExistsByChecksumByJobUuidAndChecksum
        >
    >,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    checksum: string,
    params: GetRequestJobsFileExistsByChecksumByJobUuidAndChecksumParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestJobsFileExistsByChecksumByJobUuidAndChecksum
                    >
                >,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<
                        ReturnType<
                            typeof getRequestJobsFileExistsByChecksumByJobUuidAndChecksum
                        >
                    >,
                    TError,
                    Awaited<
                        ReturnType<
                            typeof getRequestJobsFileExistsByChecksumByJobUuidAndChecksum
                        >
                    >
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestJobsFileExistsByChecksumByJobUuidAndChecksum<
    TData = Awaited<
        ReturnType<
            typeof getRequestJobsFileExistsByChecksumByJobUuidAndChecksum
        >
    >,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    checksum: string,
    params: GetRequestJobsFileExistsByChecksumByJobUuidAndChecksumParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestJobsFileExistsByChecksumByJobUuidAndChecksum
                    >
                >,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get File Exists By Checksum Handler
 */

export function useGetRequestJobsFileExistsByChecksumByJobUuidAndChecksum<
    TData = Awaited<
        ReturnType<
            typeof getRequestJobsFileExistsByChecksumByJobUuidAndChecksum
        >
    >,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    checksum: string,
    params: GetRequestJobsFileExistsByChecksumByJobUuidAndChecksumParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestJobsFileExistsByChecksumByJobUuidAndChecksum
                    >
                >,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions =
        getGetRequestJobsFileExistsByChecksumByJobUuidAndChecksumQueryOptions(
            jobUuid,
            checksum,
            params,
            options,
        )

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * Check if files belong in a job

 * @summary Get Files Exists By Checksum Handler
 */
export const getRequestJobsFilesExistsByChecksumByJobUuid = (
    jobUuid: string,
    params: GetRequestJobsFilesExistsByChecksumByJobUuidParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<GetRequestJobsFilesExistsByChecksumByJobUuid200>(
        {
            url: `/jobs/${encodeURIComponent(String(jobUuid))}/files_exists_by_checksum`,
            method: 'GET',
            params,
            signal,
        },
        options,
    )
}

export const getGetRequestJobsFilesExistsByChecksumByJobUuidQueryKey = (
    jobUuid: string,
    params: GetRequestJobsFilesExistsByChecksumByJobUuidParams,
) => {
    return [
        `/jobs/${jobUuid}/files_exists_by_checksum`,
        ...(params ? [params] : []),
    ] as const
}

export const getGetRequestJobsFilesExistsByChecksumByJobUuidQueryOptions = <
    TData = Awaited<
        ReturnType<typeof getRequestJobsFilesExistsByChecksumByJobUuid>
    >,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestJobsFilesExistsByChecksumByJobUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestJobsFilesExistsByChecksumByJobUuid
                    >
                >,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {}

    const queryKey =
        queryOptions?.queryKey ??
        getGetRequestJobsFilesExistsByChecksumByJobUuidQueryKey(jobUuid, params)

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getRequestJobsFilesExistsByChecksumByJobUuid>>
    > = ({ signal }) =>
        getRequestJobsFilesExistsByChecksumByJobUuid(
            jobUuid,
            params,
            requestOptions,
            signal,
        )

    return {
        queryKey,
        queryFn,
        enabled: !!jobUuid,
        ...queryOptions,
    } as UseQueryOptions<
        Awaited<
            ReturnType<typeof getRequestJobsFilesExistsByChecksumByJobUuid>
        >,
        TError,
        TData
    > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRequestJobsFilesExistsByChecksumByJobUuidQueryResult =
    NonNullable<
        Awaited<ReturnType<typeof getRequestJobsFilesExistsByChecksumByJobUuid>>
    >
export type GetRequestJobsFilesExistsByChecksumByJobUuidQueryError =
    ErrorType<void | HTTPValidationErrorDTO>

export function useGetRequestJobsFilesExistsByChecksumByJobUuid<
    TData = Awaited<
        ReturnType<typeof getRequestJobsFilesExistsByChecksumByJobUuid>
    >,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestJobsFilesExistsByChecksumByJobUuidParams,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestJobsFilesExistsByChecksumByJobUuid
                    >
                >,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<
                        ReturnType<
                            typeof getRequestJobsFilesExistsByChecksumByJobUuid
                        >
                    >,
                    TError,
                    Awaited<
                        ReturnType<
                            typeof getRequestJobsFilesExistsByChecksumByJobUuid
                        >
                    >
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestJobsFilesExistsByChecksumByJobUuid<
    TData = Awaited<
        ReturnType<typeof getRequestJobsFilesExistsByChecksumByJobUuid>
    >,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestJobsFilesExistsByChecksumByJobUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestJobsFilesExistsByChecksumByJobUuid
                    >
                >,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<
                        ReturnType<
                            typeof getRequestJobsFilesExistsByChecksumByJobUuid
                        >
                    >,
                    TError,
                    Awaited<
                        ReturnType<
                            typeof getRequestJobsFilesExistsByChecksumByJobUuid
                        >
                    >
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestJobsFilesExistsByChecksumByJobUuid<
    TData = Awaited<
        ReturnType<typeof getRequestJobsFilesExistsByChecksumByJobUuid>
    >,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestJobsFilesExistsByChecksumByJobUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestJobsFilesExistsByChecksumByJobUuid
                    >
                >,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get Files Exists By Checksum Handler
 */

export function useGetRequestJobsFilesExistsByChecksumByJobUuid<
    TData = Awaited<
        ReturnType<typeof getRequestJobsFilesExistsByChecksumByJobUuid>
    >,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestJobsFilesExistsByChecksumByJobUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestJobsFilesExistsByChecksumByJobUuid
                    >
                >,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions =
        getGetRequestJobsFilesExistsByChecksumByJobUuidQueryOptions(
            jobUuid,
            params,
            options,
        )

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * @summary Get Supported Extensions
 */
export const getRequestSupportedExtensions = (
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<SupportedExtensionsResponseDTO>(
        { url: `/supported_extensions`, method: 'GET', signal },
        options,
    )
}

export const getGetRequestSupportedExtensionsQueryKey = () => {
    return [`/supported_extensions`] as const
}

export const getGetRequestSupportedExtensionsQueryOptions = <
    TData = Awaited<ReturnType<typeof getRequestSupportedExtensions>>,
    TError = ErrorType<unknown>,
>(options?: {
    query?: Partial<
        UseQueryOptions<
            Awaited<ReturnType<typeof getRequestSupportedExtensions>>,
            TError,
            TData
        >
    >
    request?: SecondParameter<typeof customInstance>
}) => {
    const { query: queryOptions, request: requestOptions } = options ?? {}

    const queryKey =
        queryOptions?.queryKey ?? getGetRequestSupportedExtensionsQueryKey()

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getRequestSupportedExtensions>>
    > = ({ signal }) => getRequestSupportedExtensions(requestOptions, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof getRequestSupportedExtensions>>,
        TError,
        TData
    > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRequestSupportedExtensionsQueryResult = NonNullable<
    Awaited<ReturnType<typeof getRequestSupportedExtensions>>
>
export type GetRequestSupportedExtensionsQueryError = ErrorType<unknown>

export function useGetRequestSupportedExtensions<
    TData = Awaited<ReturnType<typeof getRequestSupportedExtensions>>,
    TError = ErrorType<unknown>,
>(options: {
    query: Partial<
        UseQueryOptions<
            Awaited<ReturnType<typeof getRequestSupportedExtensions>>,
            TError,
            TData
        >
    > &
        Pick<
            DefinedInitialDataOptions<
                Awaited<ReturnType<typeof getRequestSupportedExtensions>>,
                TError,
                Awaited<ReturnType<typeof getRequestSupportedExtensions>>
            >,
            'initialData'
        >
    request?: SecondParameter<typeof customInstance>
}): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestSupportedExtensions<
    TData = Awaited<ReturnType<typeof getRequestSupportedExtensions>>,
    TError = ErrorType<unknown>,
>(options?: {
    query?: Partial<
        UseQueryOptions<
            Awaited<ReturnType<typeof getRequestSupportedExtensions>>,
            TError,
            TData
        >
    > &
        Pick<
            UndefinedInitialDataOptions<
                Awaited<ReturnType<typeof getRequestSupportedExtensions>>,
                TError,
                Awaited<ReturnType<typeof getRequestSupportedExtensions>>
            >,
            'initialData'
        >
    request?: SecondParameter<typeof customInstance>
}): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestSupportedExtensions<
    TData = Awaited<ReturnType<typeof getRequestSupportedExtensions>>,
    TError = ErrorType<unknown>,
>(options?: {
    query?: Partial<
        UseQueryOptions<
            Awaited<ReturnType<typeof getRequestSupportedExtensions>>,
            TError,
            TData
        >
    >
    request?: SecondParameter<typeof customInstance>
}): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get Supported Extensions
 */

export function useGetRequestSupportedExtensions<
    TData = Awaited<ReturnType<typeof getRequestSupportedExtensions>>,
    TError = ErrorType<unknown>,
>(options?: {
    query?: Partial<
        UseQueryOptions<
            Awaited<ReturnType<typeof getRequestSupportedExtensions>>,
            TError,
            TData
        >
    >
    request?: SecondParameter<typeof customInstance>
}): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions = getGetRequestSupportedExtensionsQueryOptions(options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * Update consent values stored in the local consent system

 * @summary Update Data Protection Consents
 */
export const postRequestDataProtectionConsentValues = (
    postRequestDataProtectionConsentValuesBodies: BodyType<PostRequestDataProtectionConsentValuesBodies>,
    params: PostRequestDataProtectionConsentValuesParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<DataProtectionUpdateRequestResultDTO>(
        {
            url: `/data_protection/consent_values`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: postRequestDataProtectionConsentValuesBodies,
            params,
            signal,
        },
        options,
    )
}

export const getPostRequestDataProtectionConsentValuesMutationOptions = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestDataProtectionConsentValues>>,
        TError,
        {
            data: BodyType<PostRequestDataProtectionConsentValuesBodies>
            params: PostRequestDataProtectionConsentValuesParams
        },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestDataProtectionConsentValues>>,
    TError,
    {
        data: BodyType<PostRequestDataProtectionConsentValuesBodies>
        params: PostRequestDataProtectionConsentValuesParams
    },
    TContext
> => {
    const mutationKey = ['postRequestDataProtectionConsentValues']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postRequestDataProtectionConsentValues>>,
        {
            data: BodyType<PostRequestDataProtectionConsentValuesBodies>
            params: PostRequestDataProtectionConsentValuesParams
        }
    > = (props) => {
        const { data, params } = props ?? {}

        return postRequestDataProtectionConsentValues(
            data,
            params,
            requestOptions,
        )
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestDataProtectionConsentValuesMutationResult = NonNullable<
    Awaited<ReturnType<typeof postRequestDataProtectionConsentValues>>
>
export type PostRequestDataProtectionConsentValuesMutationBody =
    BodyType<PostRequestDataProtectionConsentValuesBodies>
export type PostRequestDataProtectionConsentValuesMutationError =
    ErrorType<HTTPValidationErrorDTO>

/**
 * @summary Update Data Protection Consents
 */
export const usePostRequestDataProtectionConsentValues = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestDataProtectionConsentValues>>,
        TError,
        {
            data: BodyType<PostRequestDataProtectionConsentValuesBodies>
            params: PostRequestDataProtectionConsentValuesParams
        },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestDataProtectionConsentValues>>,
    TError,
    {
        data: BodyType<PostRequestDataProtectionConsentValuesBodies>
        params: PostRequestDataProtectionConsentValuesParams
    },
    TContext
> => {
    const mutationOptions =
        getPostRequestDataProtectionConsentValuesMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * Consent values stored in the local consent system

 * @summary Get Data Protection Consents
 */
export const getRequestDataProtectionConsentValues = (
    params: GetRequestDataProtectionConsentValuesParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<ConsentsResponseDTO>(
        {
            url: `/data_protection/consent_values`,
            method: 'GET',
            params,
            signal,
        },
        options,
    )
}

export const getGetRequestDataProtectionConsentValuesQueryKey = (
    params: GetRequestDataProtectionConsentValuesParams,
) => {
    return [
        `/data_protection/consent_values`,
        ...(params ? [params] : []),
    ] as const
}

export const getGetRequestDataProtectionConsentValuesQueryOptions = <
    TData = Awaited<ReturnType<typeof getRequestDataProtectionConsentValues>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestDataProtectionConsentValuesParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<typeof getRequestDataProtectionConsentValues>
                >,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {}

    const queryKey =
        queryOptions?.queryKey ??
        getGetRequestDataProtectionConsentValuesQueryKey(params)

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getRequestDataProtectionConsentValues>>
    > = ({ signal }) =>
        getRequestDataProtectionConsentValues(params, requestOptions, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof getRequestDataProtectionConsentValues>>,
        TError,
        TData
    > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRequestDataProtectionConsentValuesQueryResult = NonNullable<
    Awaited<ReturnType<typeof getRequestDataProtectionConsentValues>>
>
export type GetRequestDataProtectionConsentValuesQueryError =
    ErrorType<HTTPValidationErrorDTO>

export function useGetRequestDataProtectionConsentValues<
    TData = Awaited<ReturnType<typeof getRequestDataProtectionConsentValues>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestDataProtectionConsentValuesParams,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<typeof getRequestDataProtectionConsentValues>
                >,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<
                        ReturnType<typeof getRequestDataProtectionConsentValues>
                    >,
                    TError,
                    Awaited<
                        ReturnType<typeof getRequestDataProtectionConsentValues>
                    >
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestDataProtectionConsentValues<
    TData = Awaited<ReturnType<typeof getRequestDataProtectionConsentValues>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestDataProtectionConsentValuesParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<typeof getRequestDataProtectionConsentValues>
                >,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<
                        ReturnType<typeof getRequestDataProtectionConsentValues>
                    >,
                    TError,
                    Awaited<
                        ReturnType<typeof getRequestDataProtectionConsentValues>
                    >
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestDataProtectionConsentValues<
    TData = Awaited<ReturnType<typeof getRequestDataProtectionConsentValues>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestDataProtectionConsentValuesParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<typeof getRequestDataProtectionConsentValues>
                >,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get Data Protection Consents
 */

export function useGetRequestDataProtectionConsentValues<
    TData = Awaited<ReturnType<typeof getRequestDataProtectionConsentValues>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestDataProtectionConsentValuesParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<typeof getRequestDataProtectionConsentValues>
                >,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions = getGetRequestDataProtectionConsentValuesQueryOptions(
        params,
        options,
    )

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * An endpoint returning all the metadata stored in connection with the user

 * @summary Request Data Access
 */
export const getRequestDataProtectionRequestDataAccess = (
    params: GetRequestDataProtectionRequestDataAccessParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<unknown>(
        {
            url: `/data_protection/request_data_access`,
            method: 'GET',
            params,
            signal,
        },
        options,
    )
}

export const getGetRequestDataProtectionRequestDataAccessQueryKey = (
    params: GetRequestDataProtectionRequestDataAccessParams,
) => {
    return [
        `/data_protection/request_data_access`,
        ...(params ? [params] : []),
    ] as const
}

export const getGetRequestDataProtectionRequestDataAccessQueryOptions = <
    TData = Awaited<
        ReturnType<typeof getRequestDataProtectionRequestDataAccess>
    >,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestDataProtectionRequestDataAccessParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<typeof getRequestDataProtectionRequestDataAccess>
                >,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {}

    const queryKey =
        queryOptions?.queryKey ??
        getGetRequestDataProtectionRequestDataAccessQueryKey(params)

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getRequestDataProtectionRequestDataAccess>>
    > = ({ signal }) =>
        getRequestDataProtectionRequestDataAccess(
            params,
            requestOptions,
            signal,
        )

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof getRequestDataProtectionRequestDataAccess>>,
        TError,
        TData
    > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRequestDataProtectionRequestDataAccessQueryResult = NonNullable<
    Awaited<ReturnType<typeof getRequestDataProtectionRequestDataAccess>>
>
export type GetRequestDataProtectionRequestDataAccessQueryError =
    ErrorType<HTTPValidationErrorDTO>

export function useGetRequestDataProtectionRequestDataAccess<
    TData = Awaited<
        ReturnType<typeof getRequestDataProtectionRequestDataAccess>
    >,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestDataProtectionRequestDataAccessParams,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<typeof getRequestDataProtectionRequestDataAccess>
                >,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<
                        ReturnType<
                            typeof getRequestDataProtectionRequestDataAccess
                        >
                    >,
                    TError,
                    Awaited<
                        ReturnType<
                            typeof getRequestDataProtectionRequestDataAccess
                        >
                    >
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestDataProtectionRequestDataAccess<
    TData = Awaited<
        ReturnType<typeof getRequestDataProtectionRequestDataAccess>
    >,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestDataProtectionRequestDataAccessParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<typeof getRequestDataProtectionRequestDataAccess>
                >,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<
                        ReturnType<
                            typeof getRequestDataProtectionRequestDataAccess
                        >
                    >,
                    TError,
                    Awaited<
                        ReturnType<
                            typeof getRequestDataProtectionRequestDataAccess
                        >
                    >
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestDataProtectionRequestDataAccess<
    TData = Awaited<
        ReturnType<typeof getRequestDataProtectionRequestDataAccess>
    >,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestDataProtectionRequestDataAccessParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<typeof getRequestDataProtectionRequestDataAccess>
                >,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Request Data Access
 */

export function useGetRequestDataProtectionRequestDataAccess<
    TData = Awaited<
        ReturnType<typeof getRequestDataProtectionRequestDataAccess>
    >,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestDataProtectionRequestDataAccessParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<typeof getRequestDataProtectionRequestDataAccess>
                >,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions =
        getGetRequestDataProtectionRequestDataAccessQueryOptions(
            params,
            options,
        )

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * This is an endpoint to check if we are able to ask for a data request

 * @summary Allow New Data Access Request
 */
export const getRequestDataProtectionRequestDataAccessAvailability = (
    params: GetRequestDataProtectionRequestDataAccessAvailabilityParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<DataProtectionRequestResultDTO>(
        {
            url: `/data_protection/request_data_access_availability`,
            method: 'GET',
            params,
            signal,
        },
        options,
    )
}

export const getGetRequestDataProtectionRequestDataAccessAvailabilityQueryKey =
    (params: GetRequestDataProtectionRequestDataAccessAvailabilityParams) => {
        return [
            `/data_protection/request_data_access_availability`,
            ...(params ? [params] : []),
        ] as const
    }

export const getGetRequestDataProtectionRequestDataAccessAvailabilityQueryOptions =
    <
        TData = Awaited<
            ReturnType<
                typeof getRequestDataProtectionRequestDataAccessAvailability
            >
        >,
        TError = ErrorType<HTTPValidationErrorDTO | void>,
    >(
        params: GetRequestDataProtectionRequestDataAccessAvailabilityParams,
        options?: {
            query?: Partial<
                UseQueryOptions<
                    Awaited<
                        ReturnType<
                            typeof getRequestDataProtectionRequestDataAccessAvailability
                        >
                    >,
                    TError,
                    TData
                >
            >
            request?: SecondParameter<typeof customInstance>
        },
    ) => {
        const { query: queryOptions, request: requestOptions } = options ?? {}

        const queryKey =
            queryOptions?.queryKey ??
            getGetRequestDataProtectionRequestDataAccessAvailabilityQueryKey(
                params,
            )

        const queryFn: QueryFunction<
            Awaited<
                ReturnType<
                    typeof getRequestDataProtectionRequestDataAccessAvailability
                >
            >
        > = ({ signal }) =>
            getRequestDataProtectionRequestDataAccessAvailability(
                params,
                requestOptions,
                signal,
            )

        return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
            Awaited<
                ReturnType<
                    typeof getRequestDataProtectionRequestDataAccessAvailability
                >
            >,
            TError,
            TData
        > & { queryKey: DataTag<QueryKey, TData, TError> }
    }

export type GetRequestDataProtectionRequestDataAccessAvailabilityQueryResult =
    NonNullable<
        Awaited<
            ReturnType<
                typeof getRequestDataProtectionRequestDataAccessAvailability
            >
        >
    >
export type GetRequestDataProtectionRequestDataAccessAvailabilityQueryError =
    ErrorType<HTTPValidationErrorDTO | void>

export function useGetRequestDataProtectionRequestDataAccessAvailability<
    TData = Awaited<
        ReturnType<typeof getRequestDataProtectionRequestDataAccessAvailability>
    >,
    TError = ErrorType<HTTPValidationErrorDTO | void>,
>(
    params: GetRequestDataProtectionRequestDataAccessAvailabilityParams,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestDataProtectionRequestDataAccessAvailability
                    >
                >,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<
                        ReturnType<
                            typeof getRequestDataProtectionRequestDataAccessAvailability
                        >
                    >,
                    TError,
                    Awaited<
                        ReturnType<
                            typeof getRequestDataProtectionRequestDataAccessAvailability
                        >
                    >
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestDataProtectionRequestDataAccessAvailability<
    TData = Awaited<
        ReturnType<typeof getRequestDataProtectionRequestDataAccessAvailability>
    >,
    TError = ErrorType<HTTPValidationErrorDTO | void>,
>(
    params: GetRequestDataProtectionRequestDataAccessAvailabilityParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestDataProtectionRequestDataAccessAvailability
                    >
                >,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<
                        ReturnType<
                            typeof getRequestDataProtectionRequestDataAccessAvailability
                        >
                    >,
                    TError,
                    Awaited<
                        ReturnType<
                            typeof getRequestDataProtectionRequestDataAccessAvailability
                        >
                    >
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestDataProtectionRequestDataAccessAvailability<
    TData = Awaited<
        ReturnType<typeof getRequestDataProtectionRequestDataAccessAvailability>
    >,
    TError = ErrorType<HTTPValidationErrorDTO | void>,
>(
    params: GetRequestDataProtectionRequestDataAccessAvailabilityParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestDataProtectionRequestDataAccessAvailability
                    >
                >,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Allow New Data Access Request
 */

export function useGetRequestDataProtectionRequestDataAccessAvailability<
    TData = Awaited<
        ReturnType<typeof getRequestDataProtectionRequestDataAccessAvailability>
    >,
    TError = ErrorType<HTTPValidationErrorDTO | void>,
>(
    params: GetRequestDataProtectionRequestDataAccessAvailabilityParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestDataProtectionRequestDataAccessAvailability
                    >
                >,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions =
        getGetRequestDataProtectionRequestDataAccessAvailabilityQueryOptions(
            params,
            options,
        )

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * Request account deletion. The user will be locked out of Capture and all auths will be disabled.
The actual user account deletion will be an async process.

 * @summary Request Account Deletion
 */
export const postRequestDataProtectionRequestAccountDeletion = (
    params: PostRequestDataProtectionRequestAccountDeletionParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<AccountDeletionRequestResultDTO>(
        {
            url: `/data_protection/request_account_deletion`,
            method: 'POST',
            params,
            signal,
        },
        options,
    )
}

export const getPostRequestDataProtectionRequestAccountDeletionMutationOptions =
    <
        TError = ErrorType<HTTPValidationErrorDTO | void>,
        TContext = unknown,
    >(options?: {
        mutation?: UseMutationOptions<
            Awaited<
                ReturnType<
                    typeof postRequestDataProtectionRequestAccountDeletion
                >
            >,
            TError,
            { params: PostRequestDataProtectionRequestAccountDeletionParams },
            TContext
        >
        request?: SecondParameter<typeof customInstance>
    }): UseMutationOptions<
        Awaited<
            ReturnType<typeof postRequestDataProtectionRequestAccountDeletion>
        >,
        TError,
        { params: PostRequestDataProtectionRequestAccountDeletionParams },
        TContext
    > => {
        const mutationKey = ['postRequestDataProtectionRequestAccountDeletion']
        const { mutation: mutationOptions, request: requestOptions } = options
            ? options.mutation &&
              'mutationKey' in options.mutation &&
              options.mutation.mutationKey
                ? options
                : { ...options, mutation: { ...options.mutation, mutationKey } }
            : { mutation: { mutationKey }, request: undefined }

        const mutationFn: MutationFunction<
            Awaited<
                ReturnType<
                    typeof postRequestDataProtectionRequestAccountDeletion
                >
            >,
            { params: PostRequestDataProtectionRequestAccountDeletionParams }
        > = (props) => {
            const { params } = props ?? {}

            return postRequestDataProtectionRequestAccountDeletion(
                params,
                requestOptions,
            )
        }

        return { mutationFn, ...mutationOptions }
    }

export type PostRequestDataProtectionRequestAccountDeletionMutationResult =
    NonNullable<
        Awaited<
            ReturnType<typeof postRequestDataProtectionRequestAccountDeletion>
        >
    >

export type PostRequestDataProtectionRequestAccountDeletionMutationError =
    ErrorType<HTTPValidationErrorDTO | void>

/**
 * @summary Request Account Deletion
 */
export const usePostRequestDataProtectionRequestAccountDeletion = <
    TError = ErrorType<HTTPValidationErrorDTO | void>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<typeof postRequestDataProtectionRequestAccountDeletion>
        >,
        TError,
        { params: PostRequestDataProtectionRequestAccountDeletionParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestDataProtectionRequestAccountDeletion>>,
    TError,
    { params: PostRequestDataProtectionRequestAccountDeletionParams },
    TContext
> => {
    const mutationOptions =
        getPostRequestDataProtectionRequestAccountDeletionMutationOptions(
            options,
        )

    return useMutation(mutationOptions)
}

/**
 * adds a comment to a file.

 * @summary Post Comment
 */
export const postRequestJobsFilesByIdCommentsByJobUuidAndFileUuid = (
    jobUuid: string,
    fileUuid: string,
    params: PostRequestJobsFilesByIdCommentsByJobUuidAndFileUuidParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<CommentResponseDTO>(
        {
            url: `/jobs/${encodeURIComponent(String(jobUuid))}/files_by_id/${encodeURIComponent(String(fileUuid))}/comments`,
            method: 'POST',
            params,
            signal,
        },
        options,
    )
}

export const getPostRequestJobsFilesByIdCommentsByJobUuidAndFileUuidMutationOptions =
    <
        TError = ErrorType<void | HTTPValidationErrorDTO>,
        TContext = unknown,
    >(options?: {
        mutation?: UseMutationOptions<
            Awaited<
                ReturnType<
                    typeof postRequestJobsFilesByIdCommentsByJobUuidAndFileUuid
                >
            >,
            TError,
            {
                jobUuid: string
                fileUuid: string
                params: PostRequestJobsFilesByIdCommentsByJobUuidAndFileUuidParams
            },
            TContext
        >
        request?: SecondParameter<typeof customInstance>
    }): UseMutationOptions<
        Awaited<
            ReturnType<
                typeof postRequestJobsFilesByIdCommentsByJobUuidAndFileUuid
            >
        >,
        TError,
        {
            jobUuid: string
            fileUuid: string
            params: PostRequestJobsFilesByIdCommentsByJobUuidAndFileUuidParams
        },
        TContext
    > => {
        const mutationKey = [
            'postRequestJobsFilesByIdCommentsByJobUuidAndFileUuid',
        ]
        const { mutation: mutationOptions, request: requestOptions } = options
            ? options.mutation &&
              'mutationKey' in options.mutation &&
              options.mutation.mutationKey
                ? options
                : { ...options, mutation: { ...options.mutation, mutationKey } }
            : { mutation: { mutationKey }, request: undefined }

        const mutationFn: MutationFunction<
            Awaited<
                ReturnType<
                    typeof postRequestJobsFilesByIdCommentsByJobUuidAndFileUuid
                >
            >,
            {
                jobUuid: string
                fileUuid: string
                params: PostRequestJobsFilesByIdCommentsByJobUuidAndFileUuidParams
            }
        > = (props) => {
            const { jobUuid, fileUuid, params } = props ?? {}

            return postRequestJobsFilesByIdCommentsByJobUuidAndFileUuid(
                jobUuid,
                fileUuid,
                params,
                requestOptions,
            )
        }

        return { mutationFn, ...mutationOptions }
    }

export type PostRequestJobsFilesByIdCommentsByJobUuidAndFileUuidMutationResult =
    NonNullable<
        Awaited<
            ReturnType<
                typeof postRequestJobsFilesByIdCommentsByJobUuidAndFileUuid
            >
        >
    >

export type PostRequestJobsFilesByIdCommentsByJobUuidAndFileUuidMutationError =
    ErrorType<void | HTTPValidationErrorDTO>

/**
 * @summary Post Comment
 */
export const usePostRequestJobsFilesByIdCommentsByJobUuidAndFileUuid = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<
                typeof postRequestJobsFilesByIdCommentsByJobUuidAndFileUuid
            >
        >,
        TError,
        {
            jobUuid: string
            fileUuid: string
            params: PostRequestJobsFilesByIdCommentsByJobUuidAndFileUuidParams
        },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<
        ReturnType<typeof postRequestJobsFilesByIdCommentsByJobUuidAndFileUuid>
    >,
    TError,
    {
        jobUuid: string
        fileUuid: string
        params: PostRequestJobsFilesByIdCommentsByJobUuidAndFileUuidParams
    },
    TContext
> => {
    const mutationOptions =
        getPostRequestJobsFilesByIdCommentsByJobUuidAndFileUuidMutationOptions(
            options,
        )

    return useMutation(mutationOptions)
}

/**
 * edit an existing comment

 * @summary Edit Comment
 */
export const putRequestJobsFilesByIdCommentsByJobUuidAndFileUuidAndCommentUuid =
    (
        jobUuid: string,
        fileUuid: string,
        commentUuid: string,
        params: PutRequestJobsFilesByIdCommentsByJobUuidAndFileUuidAndCommentUuidParams,
        options?: SecondParameter<typeof customInstance>,
    ) => {
        return customInstance<CommentResponseDTO>(
            {
                url: `/jobs/${encodeURIComponent(String(jobUuid))}/files_by_id/${encodeURIComponent(String(fileUuid))}/comments/${encodeURIComponent(String(commentUuid))}`,
                method: 'PUT',
                params,
            },
            options,
        )
    }

export const getPutRequestJobsFilesByIdCommentsByJobUuidAndFileUuidAndCommentUuidMutationOptions =
    <
        TError = ErrorType<void | HTTPValidationErrorDTO>,
        TContext = unknown,
    >(options?: {
        mutation?: UseMutationOptions<
            Awaited<
                ReturnType<
                    typeof putRequestJobsFilesByIdCommentsByJobUuidAndFileUuidAndCommentUuid
                >
            >,
            TError,
            {
                jobUuid: string
                fileUuid: string
                commentUuid: string
                params: PutRequestJobsFilesByIdCommentsByJobUuidAndFileUuidAndCommentUuidParams
            },
            TContext
        >
        request?: SecondParameter<typeof customInstance>
    }): UseMutationOptions<
        Awaited<
            ReturnType<
                typeof putRequestJobsFilesByIdCommentsByJobUuidAndFileUuidAndCommentUuid
            >
        >,
        TError,
        {
            jobUuid: string
            fileUuid: string
            commentUuid: string
            params: PutRequestJobsFilesByIdCommentsByJobUuidAndFileUuidAndCommentUuidParams
        },
        TContext
    > => {
        const mutationKey = [
            'putRequestJobsFilesByIdCommentsByJobUuidAndFileUuidAndCommentUuid',
        ]
        const { mutation: mutationOptions, request: requestOptions } = options
            ? options.mutation &&
              'mutationKey' in options.mutation &&
              options.mutation.mutationKey
                ? options
                : { ...options, mutation: { ...options.mutation, mutationKey } }
            : { mutation: { mutationKey }, request: undefined }

        const mutationFn: MutationFunction<
            Awaited<
                ReturnType<
                    typeof putRequestJobsFilesByIdCommentsByJobUuidAndFileUuidAndCommentUuid
                >
            >,
            {
                jobUuid: string
                fileUuid: string
                commentUuid: string
                params: PutRequestJobsFilesByIdCommentsByJobUuidAndFileUuidAndCommentUuidParams
            }
        > = (props) => {
            const { jobUuid, fileUuid, commentUuid, params } = props ?? {}

            return putRequestJobsFilesByIdCommentsByJobUuidAndFileUuidAndCommentUuid(
                jobUuid,
                fileUuid,
                commentUuid,
                params,
                requestOptions,
            )
        }

        return { mutationFn, ...mutationOptions }
    }

export type PutRequestJobsFilesByIdCommentsByJobUuidAndFileUuidAndCommentUuidMutationResult =
    NonNullable<
        Awaited<
            ReturnType<
                typeof putRequestJobsFilesByIdCommentsByJobUuidAndFileUuidAndCommentUuid
            >
        >
    >

export type PutRequestJobsFilesByIdCommentsByJobUuidAndFileUuidAndCommentUuidMutationError =
    ErrorType<void | HTTPValidationErrorDTO>

/**
 * @summary Edit Comment
 */
export const usePutRequestJobsFilesByIdCommentsByJobUuidAndFileUuidAndCommentUuid =
    <
        TError = ErrorType<void | HTTPValidationErrorDTO>,
        TContext = unknown,
    >(options?: {
        mutation?: UseMutationOptions<
            Awaited<
                ReturnType<
                    typeof putRequestJobsFilesByIdCommentsByJobUuidAndFileUuidAndCommentUuid
                >
            >,
            TError,
            {
                jobUuid: string
                fileUuid: string
                commentUuid: string
                params: PutRequestJobsFilesByIdCommentsByJobUuidAndFileUuidAndCommentUuidParams
            },
            TContext
        >
        request?: SecondParameter<typeof customInstance>
    }): UseMutationResult<
        Awaited<
            ReturnType<
                typeof putRequestJobsFilesByIdCommentsByJobUuidAndFileUuidAndCommentUuid
            >
        >,
        TError,
        {
            jobUuid: string
            fileUuid: string
            commentUuid: string
            params: PutRequestJobsFilesByIdCommentsByJobUuidAndFileUuidAndCommentUuidParams
        },
        TContext
    > => {
        const mutationOptions =
            getPutRequestJobsFilesByIdCommentsByJobUuidAndFileUuidAndCommentUuidMutationOptions(
                options,
            )

        return useMutation(mutationOptions)
    }

/**
 * delete an existing comment

 * @summary Delete Comment
 */
export const deleteRequestJobsFilesByIdCommentsByJobUuidAndFileUuidAndCommentUuid =
    (
        jobUuid: string,
        fileUuid: string,
        commentUuid: string,
        params: DeleteRequestJobsFilesByIdCommentsByJobUuidAndFileUuidAndCommentUuidParams,
        options?: SecondParameter<typeof customInstance>,
    ) => {
        return customInstance<CommentResponseDTO>(
            {
                url: `/jobs/${encodeURIComponent(String(jobUuid))}/files_by_id/${encodeURIComponent(String(fileUuid))}/comments/${encodeURIComponent(String(commentUuid))}`,
                method: 'DELETE',
                params,
            },
            options,
        )
    }

export const getDeleteRequestJobsFilesByIdCommentsByJobUuidAndFileUuidAndCommentUuidMutationOptions =
    <
        TError = ErrorType<void | HTTPValidationErrorDTO>,
        TContext = unknown,
    >(options?: {
        mutation?: UseMutationOptions<
            Awaited<
                ReturnType<
                    typeof deleteRequestJobsFilesByIdCommentsByJobUuidAndFileUuidAndCommentUuid
                >
            >,
            TError,
            {
                jobUuid: string
                fileUuid: string
                commentUuid: string
                params: DeleteRequestJobsFilesByIdCommentsByJobUuidAndFileUuidAndCommentUuidParams
            },
            TContext
        >
        request?: SecondParameter<typeof customInstance>
    }): UseMutationOptions<
        Awaited<
            ReturnType<
                typeof deleteRequestJobsFilesByIdCommentsByJobUuidAndFileUuidAndCommentUuid
            >
        >,
        TError,
        {
            jobUuid: string
            fileUuid: string
            commentUuid: string
            params: DeleteRequestJobsFilesByIdCommentsByJobUuidAndFileUuidAndCommentUuidParams
        },
        TContext
    > => {
        const mutationKey = [
            'deleteRequestJobsFilesByIdCommentsByJobUuidAndFileUuidAndCommentUuid',
        ]
        const { mutation: mutationOptions, request: requestOptions } = options
            ? options.mutation &&
              'mutationKey' in options.mutation &&
              options.mutation.mutationKey
                ? options
                : { ...options, mutation: { ...options.mutation, mutationKey } }
            : { mutation: { mutationKey }, request: undefined }

        const mutationFn: MutationFunction<
            Awaited<
                ReturnType<
                    typeof deleteRequestJobsFilesByIdCommentsByJobUuidAndFileUuidAndCommentUuid
                >
            >,
            {
                jobUuid: string
                fileUuid: string
                commentUuid: string
                params: DeleteRequestJobsFilesByIdCommentsByJobUuidAndFileUuidAndCommentUuidParams
            }
        > = (props) => {
            const { jobUuid, fileUuid, commentUuid, params } = props ?? {}

            return deleteRequestJobsFilesByIdCommentsByJobUuidAndFileUuidAndCommentUuid(
                jobUuid,
                fileUuid,
                commentUuid,
                params,
                requestOptions,
            )
        }

        return { mutationFn, ...mutationOptions }
    }

export type DeleteRequestJobsFilesByIdCommentsByJobUuidAndFileUuidAndCommentUuidMutationResult =
    NonNullable<
        Awaited<
            ReturnType<
                typeof deleteRequestJobsFilesByIdCommentsByJobUuidAndFileUuidAndCommentUuid
            >
        >
    >

export type DeleteRequestJobsFilesByIdCommentsByJobUuidAndFileUuidAndCommentUuidMutationError =
    ErrorType<void | HTTPValidationErrorDTO>

/**
 * @summary Delete Comment
 */
export const useDeleteRequestJobsFilesByIdCommentsByJobUuidAndFileUuidAndCommentUuid =
    <
        TError = ErrorType<void | HTTPValidationErrorDTO>,
        TContext = unknown,
    >(options?: {
        mutation?: UseMutationOptions<
            Awaited<
                ReturnType<
                    typeof deleteRequestJobsFilesByIdCommentsByJobUuidAndFileUuidAndCommentUuid
                >
            >,
            TError,
            {
                jobUuid: string
                fileUuid: string
                commentUuid: string
                params: DeleteRequestJobsFilesByIdCommentsByJobUuidAndFileUuidAndCommentUuidParams
            },
            TContext
        >
        request?: SecondParameter<typeof customInstance>
    }): UseMutationResult<
        Awaited<
            ReturnType<
                typeof deleteRequestJobsFilesByIdCommentsByJobUuidAndFileUuidAndCommentUuid
            >
        >,
        TError,
        {
            jobUuid: string
            fileUuid: string
            commentUuid: string
            params: DeleteRequestJobsFilesByIdCommentsByJobUuidAndFileUuidAndCommentUuidParams
        },
        TContext
    > => {
        const mutationOptions =
            getDeleteRequestJobsFilesByIdCommentsByJobUuidAndFileUuidAndCommentUuidMutationOptions(
                options,
            )

        return useMutation(mutationOptions)
    }

/**
 * adds a reaction

 * @summary Post Reaction
 */
export const postRequestJobsFilesByIdReactionByJobUuidAndFileUuid = (
    jobUuid: string,
    fileUuid: string,
    params: PostRequestJobsFilesByIdReactionByJobUuidAndFileUuidParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<SetReactionResponseDTO>(
        {
            url: `/jobs/${encodeURIComponent(String(jobUuid))}/files_by_id/${encodeURIComponent(String(fileUuid))}/reaction`,
            method: 'POST',
            params,
            signal,
        },
        options,
    )
}

export const getPostRequestJobsFilesByIdReactionByJobUuidAndFileUuidMutationOptions =
    <
        TError = ErrorType<void | HTTPValidationErrorDTO>,
        TContext = unknown,
    >(options?: {
        mutation?: UseMutationOptions<
            Awaited<
                ReturnType<
                    typeof postRequestJobsFilesByIdReactionByJobUuidAndFileUuid
                >
            >,
            TError,
            {
                jobUuid: string
                fileUuid: string
                params: PostRequestJobsFilesByIdReactionByJobUuidAndFileUuidParams
            },
            TContext
        >
        request?: SecondParameter<typeof customInstance>
    }): UseMutationOptions<
        Awaited<
            ReturnType<
                typeof postRequestJobsFilesByIdReactionByJobUuidAndFileUuid
            >
        >,
        TError,
        {
            jobUuid: string
            fileUuid: string
            params: PostRequestJobsFilesByIdReactionByJobUuidAndFileUuidParams
        },
        TContext
    > => {
        const mutationKey = [
            'postRequestJobsFilesByIdReactionByJobUuidAndFileUuid',
        ]
        const { mutation: mutationOptions, request: requestOptions } = options
            ? options.mutation &&
              'mutationKey' in options.mutation &&
              options.mutation.mutationKey
                ? options
                : { ...options, mutation: { ...options.mutation, mutationKey } }
            : { mutation: { mutationKey }, request: undefined }

        const mutationFn: MutationFunction<
            Awaited<
                ReturnType<
                    typeof postRequestJobsFilesByIdReactionByJobUuidAndFileUuid
                >
            >,
            {
                jobUuid: string
                fileUuid: string
                params: PostRequestJobsFilesByIdReactionByJobUuidAndFileUuidParams
            }
        > = (props) => {
            const { jobUuid, fileUuid, params } = props ?? {}

            return postRequestJobsFilesByIdReactionByJobUuidAndFileUuid(
                jobUuid,
                fileUuid,
                params,
                requestOptions,
            )
        }

        return { mutationFn, ...mutationOptions }
    }

export type PostRequestJobsFilesByIdReactionByJobUuidAndFileUuidMutationResult =
    NonNullable<
        Awaited<
            ReturnType<
                typeof postRequestJobsFilesByIdReactionByJobUuidAndFileUuid
            >
        >
    >

export type PostRequestJobsFilesByIdReactionByJobUuidAndFileUuidMutationError =
    ErrorType<void | HTTPValidationErrorDTO>

/**
 * @summary Post Reaction
 */
export const usePostRequestJobsFilesByIdReactionByJobUuidAndFileUuid = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<
                typeof postRequestJobsFilesByIdReactionByJobUuidAndFileUuid
            >
        >,
        TError,
        {
            jobUuid: string
            fileUuid: string
            params: PostRequestJobsFilesByIdReactionByJobUuidAndFileUuidParams
        },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<
        ReturnType<typeof postRequestJobsFilesByIdReactionByJobUuidAndFileUuid>
    >,
    TError,
    {
        jobUuid: string
        fileUuid: string
        params: PostRequestJobsFilesByIdReactionByJobUuidAndFileUuidParams
    },
    TContext
> => {
    const mutationOptions =
        getPostRequestJobsFilesByIdReactionByJobUuidAndFileUuidMutationOptions(
            options,
        )

    return useMutation(mutationOptions)
}

/**
 * removes a reaction

 * @summary Delete Reaction
 */
export const deleteRequestJobsFilesByIdReactionByJobUuidAndFileUuid = (
    jobUuid: string,
    fileUuid: string,
    params: DeleteRequestJobsFilesByIdReactionByJobUuidAndFileUuidParams,
    options?: SecondParameter<typeof customInstance>,
) => {
    return customInstance<SetReactionResponseDTO>(
        {
            url: `/jobs/${encodeURIComponent(String(jobUuid))}/files_by_id/${encodeURIComponent(String(fileUuid))}/reaction`,
            method: 'DELETE',
            params,
        },
        options,
    )
}

export const getDeleteRequestJobsFilesByIdReactionByJobUuidAndFileUuidMutationOptions =
    <
        TError = ErrorType<void | HTTPValidationErrorDTO>,
        TContext = unknown,
    >(options?: {
        mutation?: UseMutationOptions<
            Awaited<
                ReturnType<
                    typeof deleteRequestJobsFilesByIdReactionByJobUuidAndFileUuid
                >
            >,
            TError,
            {
                jobUuid: string
                fileUuid: string
                params: DeleteRequestJobsFilesByIdReactionByJobUuidAndFileUuidParams
            },
            TContext
        >
        request?: SecondParameter<typeof customInstance>
    }): UseMutationOptions<
        Awaited<
            ReturnType<
                typeof deleteRequestJobsFilesByIdReactionByJobUuidAndFileUuid
            >
        >,
        TError,
        {
            jobUuid: string
            fileUuid: string
            params: DeleteRequestJobsFilesByIdReactionByJobUuidAndFileUuidParams
        },
        TContext
    > => {
        const mutationKey = [
            'deleteRequestJobsFilesByIdReactionByJobUuidAndFileUuid',
        ]
        const { mutation: mutationOptions, request: requestOptions } = options
            ? options.mutation &&
              'mutationKey' in options.mutation &&
              options.mutation.mutationKey
                ? options
                : { ...options, mutation: { ...options.mutation, mutationKey } }
            : { mutation: { mutationKey }, request: undefined }

        const mutationFn: MutationFunction<
            Awaited<
                ReturnType<
                    typeof deleteRequestJobsFilesByIdReactionByJobUuidAndFileUuid
                >
            >,
            {
                jobUuid: string
                fileUuid: string
                params: DeleteRequestJobsFilesByIdReactionByJobUuidAndFileUuidParams
            }
        > = (props) => {
            const { jobUuid, fileUuid, params } = props ?? {}

            return deleteRequestJobsFilesByIdReactionByJobUuidAndFileUuid(
                jobUuid,
                fileUuid,
                params,
                requestOptions,
            )
        }

        return { mutationFn, ...mutationOptions }
    }

export type DeleteRequestJobsFilesByIdReactionByJobUuidAndFileUuidMutationResult =
    NonNullable<
        Awaited<
            ReturnType<
                typeof deleteRequestJobsFilesByIdReactionByJobUuidAndFileUuid
            >
        >
    >

export type DeleteRequestJobsFilesByIdReactionByJobUuidAndFileUuidMutationError =
    ErrorType<void | HTTPValidationErrorDTO>

/**
 * @summary Delete Reaction
 */
export const useDeleteRequestJobsFilesByIdReactionByJobUuidAndFileUuid = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<
                typeof deleteRequestJobsFilesByIdReactionByJobUuidAndFileUuid
            >
        >,
        TError,
        {
            jobUuid: string
            fileUuid: string
            params: DeleteRequestJobsFilesByIdReactionByJobUuidAndFileUuidParams
        },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<
        ReturnType<
            typeof deleteRequestJobsFilesByIdReactionByJobUuidAndFileUuid
        >
    >,
    TError,
    {
        jobUuid: string
        fileUuid: string
        params: DeleteRequestJobsFilesByIdReactionByJobUuidAndFileUuidParams
    },
    TContext
> => {
    const mutationOptions =
        getDeleteRequestJobsFilesByIdReactionByJobUuidAndFileUuidMutationOptions(
            options,
        )

    return useMutation(mutationOptions)
}

/**
 * Get information for all active files that have a reaction connected to the file.
Only owner can query this information

 * @summary Get Reactions For Job
 */
export const getRequestJobsReactionsByJobUuid = (
    jobUuid: string,
    params: GetRequestJobsReactionsByJobUuidParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<ReactionsResponseDTO>(
        {
            url: `/jobs/${encodeURIComponent(String(jobUuid))}/reactions`,
            method: 'GET',
            params,
            signal,
        },
        options,
    )
}

export const getGetRequestJobsReactionsByJobUuidQueryKey = (
    jobUuid: string,
    params: GetRequestJobsReactionsByJobUuidParams,
) => {
    return [`/jobs/${jobUuid}/reactions`, ...(params ? [params] : [])] as const
}

export const getGetRequestJobsReactionsByJobUuidQueryOptions = <
    TData = Awaited<ReturnType<typeof getRequestJobsReactionsByJobUuid>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestJobsReactionsByJobUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestJobsReactionsByJobUuid>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {}

    const queryKey =
        queryOptions?.queryKey ??
        getGetRequestJobsReactionsByJobUuidQueryKey(jobUuid, params)

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getRequestJobsReactionsByJobUuid>>
    > = ({ signal }) =>
        getRequestJobsReactionsByJobUuid(
            jobUuid,
            params,
            requestOptions,
            signal,
        )

    return {
        queryKey,
        queryFn,
        enabled: !!jobUuid,
        ...queryOptions,
    } as UseQueryOptions<
        Awaited<ReturnType<typeof getRequestJobsReactionsByJobUuid>>,
        TError,
        TData
    > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRequestJobsReactionsByJobUuidQueryResult = NonNullable<
    Awaited<ReturnType<typeof getRequestJobsReactionsByJobUuid>>
>
export type GetRequestJobsReactionsByJobUuidQueryError =
    ErrorType<void | HTTPValidationErrorDTO>

export function useGetRequestJobsReactionsByJobUuid<
    TData = Awaited<ReturnType<typeof getRequestJobsReactionsByJobUuid>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestJobsReactionsByJobUuidParams,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestJobsReactionsByJobUuid>>,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<
                        ReturnType<typeof getRequestJobsReactionsByJobUuid>
                    >,
                    TError,
                    Awaited<ReturnType<typeof getRequestJobsReactionsByJobUuid>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestJobsReactionsByJobUuid<
    TData = Awaited<ReturnType<typeof getRequestJobsReactionsByJobUuid>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestJobsReactionsByJobUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestJobsReactionsByJobUuid>>,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<
                        ReturnType<typeof getRequestJobsReactionsByJobUuid>
                    >,
                    TError,
                    Awaited<ReturnType<typeof getRequestJobsReactionsByJobUuid>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestJobsReactionsByJobUuid<
    TData = Awaited<ReturnType<typeof getRequestJobsReactionsByJobUuid>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestJobsReactionsByJobUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestJobsReactionsByJobUuid>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get Reactions For Job
 */

export function useGetRequestJobsReactionsByJobUuid<
    TData = Awaited<ReturnType<typeof getRequestJobsReactionsByJobUuid>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestJobsReactionsByJobUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestJobsReactionsByJobUuid>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions = getGetRequestJobsReactionsByJobUuidQueryOptions(
        jobUuid,
        params,
        options,
    )

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * Endpoint for Mitt Telenor integration with Min Sky.
Bearer auth where the auth is a TelenorID access token

 * @summary Get Mitt Telenor User Info
 */
export const getRequestMittTelenorNoUserInfoByTelenorId = (
    telenorId: string,
    params?: GetRequestMittTelenorNoUserInfoByTelenorIdParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<UserInfoDTO>(
        {
            url: `/mitt_telenor/no/user_info/${encodeURIComponent(String(telenorId))}`,
            method: 'GET',
            params,
            signal,
        },
        options,
    )
}

export const getGetRequestMittTelenorNoUserInfoByTelenorIdQueryKey = (
    telenorId: string,
    params?: GetRequestMittTelenorNoUserInfoByTelenorIdParams,
) => {
    return [
        `/mitt_telenor/no/user_info/${telenorId}`,
        ...(params ? [params] : []),
    ] as const
}

export const getGetRequestMittTelenorNoUserInfoByTelenorIdQueryOptions = <
    TData = Awaited<
        ReturnType<typeof getRequestMittTelenorNoUserInfoByTelenorId>
    >,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    telenorId: string,
    params?: GetRequestMittTelenorNoUserInfoByTelenorIdParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestMittTelenorNoUserInfoByTelenorId
                    >
                >,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {}

    const queryKey =
        queryOptions?.queryKey ??
        getGetRequestMittTelenorNoUserInfoByTelenorIdQueryKey(telenorId, params)

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getRequestMittTelenorNoUserInfoByTelenorId>>
    > = ({ signal }) =>
        getRequestMittTelenorNoUserInfoByTelenorId(
            telenorId,
            params,
            requestOptions,
            signal,
        )

    return {
        queryKey,
        queryFn,
        enabled: !!telenorId,
        ...queryOptions,
    } as UseQueryOptions<
        Awaited<ReturnType<typeof getRequestMittTelenorNoUserInfoByTelenorId>>,
        TError,
        TData
    > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRequestMittTelenorNoUserInfoByTelenorIdQueryResult = NonNullable<
    Awaited<ReturnType<typeof getRequestMittTelenorNoUserInfoByTelenorId>>
>
export type GetRequestMittTelenorNoUserInfoByTelenorIdQueryError =
    ErrorType<void | HTTPValidationErrorDTO>

export function useGetRequestMittTelenorNoUserInfoByTelenorId<
    TData = Awaited<
        ReturnType<typeof getRequestMittTelenorNoUserInfoByTelenorId>
    >,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    telenorId: string,
    params: undefined | GetRequestMittTelenorNoUserInfoByTelenorIdParams,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestMittTelenorNoUserInfoByTelenorId
                    >
                >,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<
                        ReturnType<
                            typeof getRequestMittTelenorNoUserInfoByTelenorId
                        >
                    >,
                    TError,
                    Awaited<
                        ReturnType<
                            typeof getRequestMittTelenorNoUserInfoByTelenorId
                        >
                    >
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestMittTelenorNoUserInfoByTelenorId<
    TData = Awaited<
        ReturnType<typeof getRequestMittTelenorNoUserInfoByTelenorId>
    >,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    telenorId: string,
    params?: GetRequestMittTelenorNoUserInfoByTelenorIdParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestMittTelenorNoUserInfoByTelenorId
                    >
                >,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<
                        ReturnType<
                            typeof getRequestMittTelenorNoUserInfoByTelenorId
                        >
                    >,
                    TError,
                    Awaited<
                        ReturnType<
                            typeof getRequestMittTelenorNoUserInfoByTelenorId
                        >
                    >
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestMittTelenorNoUserInfoByTelenorId<
    TData = Awaited<
        ReturnType<typeof getRequestMittTelenorNoUserInfoByTelenorId>
    >,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    telenorId: string,
    params?: GetRequestMittTelenorNoUserInfoByTelenorIdParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestMittTelenorNoUserInfoByTelenorId
                    >
                >,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get Mitt Telenor User Info
 */

export function useGetRequestMittTelenorNoUserInfoByTelenorId<
    TData = Awaited<
        ReturnType<typeof getRequestMittTelenorNoUserInfoByTelenorId>
    >,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    telenorId: string,
    params?: GetRequestMittTelenorNoUserInfoByTelenorIdParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestMittTelenorNoUserInfoByTelenorId
                    >
                >,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions =
        getGetRequestMittTelenorNoUserInfoByTelenorIdQueryOptions(
            telenorId,
            params,
            options,
        )

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * Change the privacy mode of a story job.
The underlying implementation manipulates the three flags
allow_sharing, allow_anonymous_access and allow_discovery
to conform to the privacy mode.
Optionally, if the privacy mode is set to either protected or shared,
a timestamp may be provided to specify the
expiration date of the sharing. This can also be used to update the
expiration date on a share, by providing the same privacy mode and
an updated expiration timestamp. If private mode is provided, the
expiration will always be removed.

 * @summary Set Privacy Mode
 */
export const postRequestJobsPrivacyModeByJobUuid = (
    jobUuid: string,
    params: PostRequestJobsPrivacyModeByJobUuidParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<JobSetPermissionsResponseDTO>(
        {
            url: `/jobs/${encodeURIComponent(String(jobUuid))}/privacy_mode`,
            method: 'POST',
            params,
            signal,
        },
        options,
    )
}

export const getPostRequestJobsPrivacyModeByJobUuidMutationOptions = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestJobsPrivacyModeByJobUuid>>,
        TError,
        { jobUuid: string; params: PostRequestJobsPrivacyModeByJobUuidParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestJobsPrivacyModeByJobUuid>>,
    TError,
    { jobUuid: string; params: PostRequestJobsPrivacyModeByJobUuidParams },
    TContext
> => {
    const mutationKey = ['postRequestJobsPrivacyModeByJobUuid']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postRequestJobsPrivacyModeByJobUuid>>,
        { jobUuid: string; params: PostRequestJobsPrivacyModeByJobUuidParams }
    > = (props) => {
        const { jobUuid, params } = props ?? {}

        return postRequestJobsPrivacyModeByJobUuid(
            jobUuid,
            params,
            requestOptions,
        )
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestJobsPrivacyModeByJobUuidMutationResult = NonNullable<
    Awaited<ReturnType<typeof postRequestJobsPrivacyModeByJobUuid>>
>

export type PostRequestJobsPrivacyModeByJobUuidMutationError =
    ErrorType<void | HTTPValidationErrorDTO>

/**
 * @summary Set Privacy Mode
 */
export const usePostRequestJobsPrivacyModeByJobUuid = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestJobsPrivacyModeByJobUuid>>,
        TError,
        { jobUuid: string; params: PostRequestJobsPrivacyModeByJobUuidParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestJobsPrivacyModeByJobUuid>>,
    TError,
    { jobUuid: string; params: PostRequestJobsPrivacyModeByJobUuidParams },
    TContext
> => {
    const mutationOptions =
        getPostRequestJobsPrivacyModeByJobUuidMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * Get the current privacy mode of a story job.

 * @summary Get Privacy Mode
 */
export const getRequestJobsPrivacyModeByJobUuid = (
    jobUuid: string,
    params: GetRequestJobsPrivacyModeByJobUuidParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<JobPrivacyModeResponseDTO>(
        {
            url: `/jobs/${encodeURIComponent(String(jobUuid))}/privacy_mode`,
            method: 'GET',
            params,
            signal,
        },
        options,
    )
}

export const getGetRequestJobsPrivacyModeByJobUuidQueryKey = (
    jobUuid: string,
    params: GetRequestJobsPrivacyModeByJobUuidParams,
) => {
    return [
        `/jobs/${jobUuid}/privacy_mode`,
        ...(params ? [params] : []),
    ] as const
}

export const getGetRequestJobsPrivacyModeByJobUuidQueryOptions = <
    TData = Awaited<ReturnType<typeof getRequestJobsPrivacyModeByJobUuid>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestJobsPrivacyModeByJobUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestJobsPrivacyModeByJobUuid>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {}

    const queryKey =
        queryOptions?.queryKey ??
        getGetRequestJobsPrivacyModeByJobUuidQueryKey(jobUuid, params)

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getRequestJobsPrivacyModeByJobUuid>>
    > = ({ signal }) =>
        getRequestJobsPrivacyModeByJobUuid(
            jobUuid,
            params,
            requestOptions,
            signal,
        )

    return {
        queryKey,
        queryFn,
        enabled: !!jobUuid,
        ...queryOptions,
    } as UseQueryOptions<
        Awaited<ReturnType<typeof getRequestJobsPrivacyModeByJobUuid>>,
        TError,
        TData
    > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRequestJobsPrivacyModeByJobUuidQueryResult = NonNullable<
    Awaited<ReturnType<typeof getRequestJobsPrivacyModeByJobUuid>>
>
export type GetRequestJobsPrivacyModeByJobUuidQueryError =
    ErrorType<void | HTTPValidationErrorDTO>

export function useGetRequestJobsPrivacyModeByJobUuid<
    TData = Awaited<ReturnType<typeof getRequestJobsPrivacyModeByJobUuid>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestJobsPrivacyModeByJobUuidParams,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestJobsPrivacyModeByJobUuid>>,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<
                        ReturnType<typeof getRequestJobsPrivacyModeByJobUuid>
                    >,
                    TError,
                    Awaited<
                        ReturnType<typeof getRequestJobsPrivacyModeByJobUuid>
                    >
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestJobsPrivacyModeByJobUuid<
    TData = Awaited<ReturnType<typeof getRequestJobsPrivacyModeByJobUuid>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestJobsPrivacyModeByJobUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestJobsPrivacyModeByJobUuid>>,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<
                        ReturnType<typeof getRequestJobsPrivacyModeByJobUuid>
                    >,
                    TError,
                    Awaited<
                        ReturnType<typeof getRequestJobsPrivacyModeByJobUuid>
                    >
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestJobsPrivacyModeByJobUuid<
    TData = Awaited<ReturnType<typeof getRequestJobsPrivacyModeByJobUuid>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestJobsPrivacyModeByJobUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestJobsPrivacyModeByJobUuid>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get Privacy Mode
 */

export function useGetRequestJobsPrivacyModeByJobUuid<
    TData = Awaited<ReturnType<typeof getRequestJobsPrivacyModeByJobUuid>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestJobsPrivacyModeByJobUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestJobsPrivacyModeByJobUuid>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions = getGetRequestJobsPrivacyModeByJobUuidQueryOptions(
        jobUuid,
        params,
        options,
    )

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * Change the permission settings on a story job. At least one permission
setting is needed. Any unspecified settings is left unchanged

 * @summary Set Permissions
 */
export const postRequestJobsPermissionsByJobUuid = (
    jobUuid: string,
    params: PostRequestJobsPermissionsByJobUuidParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<JobSetPermissionsResponseDTO>(
        {
            url: `/jobs/${encodeURIComponent(String(jobUuid))}/permissions`,
            method: 'POST',
            params,
            signal,
        },
        options,
    )
}

export const getPostRequestJobsPermissionsByJobUuidMutationOptions = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestJobsPermissionsByJobUuid>>,
        TError,
        { jobUuid: string; params: PostRequestJobsPermissionsByJobUuidParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestJobsPermissionsByJobUuid>>,
    TError,
    { jobUuid: string; params: PostRequestJobsPermissionsByJobUuidParams },
    TContext
> => {
    const mutationKey = ['postRequestJobsPermissionsByJobUuid']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postRequestJobsPermissionsByJobUuid>>,
        { jobUuid: string; params: PostRequestJobsPermissionsByJobUuidParams }
    > = (props) => {
        const { jobUuid, params } = props ?? {}

        return postRequestJobsPermissionsByJobUuid(
            jobUuid,
            params,
            requestOptions,
        )
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestJobsPermissionsByJobUuidMutationResult = NonNullable<
    Awaited<ReturnType<typeof postRequestJobsPermissionsByJobUuid>>
>

export type PostRequestJobsPermissionsByJobUuidMutationError =
    ErrorType<void | HTTPValidationErrorDTO>

/**
 * @summary Set Permissions
 */
export const usePostRequestJobsPermissionsByJobUuid = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestJobsPermissionsByJobUuid>>,
        TError,
        { jobUuid: string; params: PostRequestJobsPermissionsByJobUuidParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestJobsPermissionsByJobUuid>>,
    TError,
    { jobUuid: string; params: PostRequestJobsPermissionsByJobUuidParams },
    TContext
> => {
    const mutationOptions =
        getPostRequestJobsPermissionsByJobUuidMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * Get the current permission settings of a story job.

 * @summary Get Permissions
 */
export const getRequestJobsPermissionsByJobUuid = (
    jobUuid: string,
    params: GetRequestJobsPermissionsByJobUuidParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<JobPermissionsResponseDTO>(
        {
            url: `/jobs/${encodeURIComponent(String(jobUuid))}/permissions`,
            method: 'GET',
            params,
            signal,
        },
        options,
    )
}

export const getGetRequestJobsPermissionsByJobUuidQueryKey = (
    jobUuid: string,
    params: GetRequestJobsPermissionsByJobUuidParams,
) => {
    return [
        `/jobs/${jobUuid}/permissions`,
        ...(params ? [params] : []),
    ] as const
}

export const getGetRequestJobsPermissionsByJobUuidQueryOptions = <
    TData = Awaited<ReturnType<typeof getRequestJobsPermissionsByJobUuid>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestJobsPermissionsByJobUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestJobsPermissionsByJobUuid>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {}

    const queryKey =
        queryOptions?.queryKey ??
        getGetRequestJobsPermissionsByJobUuidQueryKey(jobUuid, params)

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getRequestJobsPermissionsByJobUuid>>
    > = ({ signal }) =>
        getRequestJobsPermissionsByJobUuid(
            jobUuid,
            params,
            requestOptions,
            signal,
        )

    return {
        queryKey,
        queryFn,
        enabled: !!jobUuid,
        ...queryOptions,
    } as UseQueryOptions<
        Awaited<ReturnType<typeof getRequestJobsPermissionsByJobUuid>>,
        TError,
        TData
    > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRequestJobsPermissionsByJobUuidQueryResult = NonNullable<
    Awaited<ReturnType<typeof getRequestJobsPermissionsByJobUuid>>
>
export type GetRequestJobsPermissionsByJobUuidQueryError =
    ErrorType<void | HTTPValidationErrorDTO>

export function useGetRequestJobsPermissionsByJobUuid<
    TData = Awaited<ReturnType<typeof getRequestJobsPermissionsByJobUuid>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestJobsPermissionsByJobUuidParams,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestJobsPermissionsByJobUuid>>,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<
                        ReturnType<typeof getRequestJobsPermissionsByJobUuid>
                    >,
                    TError,
                    Awaited<
                        ReturnType<typeof getRequestJobsPermissionsByJobUuid>
                    >
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestJobsPermissionsByJobUuid<
    TData = Awaited<ReturnType<typeof getRequestJobsPermissionsByJobUuid>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestJobsPermissionsByJobUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestJobsPermissionsByJobUuid>>,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<
                        ReturnType<typeof getRequestJobsPermissionsByJobUuid>
                    >,
                    TError,
                    Awaited<
                        ReturnType<typeof getRequestJobsPermissionsByJobUuid>
                    >
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestJobsPermissionsByJobUuid<
    TData = Awaited<ReturnType<typeof getRequestJobsPermissionsByJobUuid>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestJobsPermissionsByJobUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestJobsPermissionsByJobUuid>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get Permissions
 */

export function useGetRequestJobsPermissionsByJobUuid<
    TData = Awaited<ReturnType<typeof getRequestJobsPermissionsByJobUuid>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestJobsPermissionsByJobUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestJobsPermissionsByJobUuid>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions = getGetRequestJobsPermissionsByJobUuidQueryOptions(
        jobUuid,
        params,
        options,
    )

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * add story subscription

 * @summary Subscribe To Story
 */
export const postRequestJobsSubscribeByJobUuid = (
    jobUuid: string,
    params: PostRequestJobsSubscribeByJobUuidParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<unknown>(
        {
            url: `/jobs/${encodeURIComponent(String(jobUuid))}/subscribe`,
            method: 'POST',
            params,
            signal,
        },
        options,
    )
}

export const getPostRequestJobsSubscribeByJobUuidMutationOptions = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestJobsSubscribeByJobUuid>>,
        TError,
        { jobUuid: string; params: PostRequestJobsSubscribeByJobUuidParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestJobsSubscribeByJobUuid>>,
    TError,
    { jobUuid: string; params: PostRequestJobsSubscribeByJobUuidParams },
    TContext
> => {
    const mutationKey = ['postRequestJobsSubscribeByJobUuid']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postRequestJobsSubscribeByJobUuid>>,
        { jobUuid: string; params: PostRequestJobsSubscribeByJobUuidParams }
    > = (props) => {
        const { jobUuid, params } = props ?? {}

        return postRequestJobsSubscribeByJobUuid(
            jobUuid,
            params,
            requestOptions,
        )
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestJobsSubscribeByJobUuidMutationResult = NonNullable<
    Awaited<ReturnType<typeof postRequestJobsSubscribeByJobUuid>>
>

export type PostRequestJobsSubscribeByJobUuidMutationError =
    ErrorType<void | HTTPValidationErrorDTO>

/**
 * @summary Subscribe To Story
 */
export const usePostRequestJobsSubscribeByJobUuid = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestJobsSubscribeByJobUuid>>,
        TError,
        { jobUuid: string; params: PostRequestJobsSubscribeByJobUuidParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestJobsSubscribeByJobUuid>>,
    TError,
    { jobUuid: string; params: PostRequestJobsSubscribeByJobUuidParams },
    TContext
> => {
    const mutationOptions =
        getPostRequestJobsSubscribeByJobUuidMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * unsubscription

 * @summary Unsubscribe To Story
 */
export const postRequestJobsUnsubscribeByJobUuid = (
    jobUuid: string,
    params: PostRequestJobsUnsubscribeByJobUuidParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<unknown>(
        {
            url: `/jobs/${encodeURIComponent(String(jobUuid))}/unsubscribe`,
            method: 'POST',
            params,
            signal,
        },
        options,
    )
}

export const getPostRequestJobsUnsubscribeByJobUuidMutationOptions = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestJobsUnsubscribeByJobUuid>>,
        TError,
        { jobUuid: string; params: PostRequestJobsUnsubscribeByJobUuidParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestJobsUnsubscribeByJobUuid>>,
    TError,
    { jobUuid: string; params: PostRequestJobsUnsubscribeByJobUuidParams },
    TContext
> => {
    const mutationKey = ['postRequestJobsUnsubscribeByJobUuid']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postRequestJobsUnsubscribeByJobUuid>>,
        { jobUuid: string; params: PostRequestJobsUnsubscribeByJobUuidParams }
    > = (props) => {
        const { jobUuid, params } = props ?? {}

        return postRequestJobsUnsubscribeByJobUuid(
            jobUuid,
            params,
            requestOptions,
        )
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestJobsUnsubscribeByJobUuidMutationResult = NonNullable<
    Awaited<ReturnType<typeof postRequestJobsUnsubscribeByJobUuid>>
>

export type PostRequestJobsUnsubscribeByJobUuidMutationError =
    ErrorType<void | HTTPValidationErrorDTO>

/**
 * @summary Unsubscribe To Story
 */
export const usePostRequestJobsUnsubscribeByJobUuid = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestJobsUnsubscribeByJobUuid>>,
        TError,
        { jobUuid: string; params: PostRequestJobsUnsubscribeByJobUuidParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestJobsUnsubscribeByJobUuid>>,
    TError,
    { jobUuid: string; params: PostRequestJobsUnsubscribeByJobUuidParams },
    TContext
> => {
    const mutationOptions =
        getPostRequestJobsUnsubscribeByJobUuidMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * Receipt as bytes in body
 * @summary Post Apple Store Receipt
 */
export const postRequestAppleStoreReceipt = (
    params: PostRequestAppleStoreReceiptParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<AppleStoreResponseDTO>(
        { url: `/apple_store_receipt`, method: 'POST', params, signal },
        options,
    )
}

export const getPostRequestAppleStoreReceiptMutationOptions = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestAppleStoreReceipt>>,
        TError,
        { params: PostRequestAppleStoreReceiptParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestAppleStoreReceipt>>,
    TError,
    { params: PostRequestAppleStoreReceiptParams },
    TContext
> => {
    const mutationKey = ['postRequestAppleStoreReceipt']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postRequestAppleStoreReceipt>>,
        { params: PostRequestAppleStoreReceiptParams }
    > = (props) => {
        const { params } = props ?? {}

        return postRequestAppleStoreReceipt(params, requestOptions)
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestAppleStoreReceiptMutationResult = NonNullable<
    Awaited<ReturnType<typeof postRequestAppleStoreReceipt>>
>

export type PostRequestAppleStoreReceiptMutationError =
    ErrorType<void | HTTPValidationErrorDTO>

/**
 * @summary Post Apple Store Receipt
 */
export const usePostRequestAppleStoreReceipt = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestAppleStoreReceipt>>,
        TError,
        { params: PostRequestAppleStoreReceiptParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestAppleStoreReceipt>>,
    TError,
    { params: PostRequestAppleStoreReceiptParams },
    TContext
> => {
    const mutationOptions =
        getPostRequestAppleStoreReceiptMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * Purchase data as bytes in body
 * @summary Validate Android Purchase
 */
export const postRequestAndroidValidatePurchase = (
    params: PostRequestAndroidValidatePurchaseParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<AndroidStoreResponseDTO>(
        { url: `/android_validate_purchase`, method: 'POST', params, signal },
        options,
    )
}

export const getPostRequestAndroidValidatePurchaseMutationOptions = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestAndroidValidatePurchase>>,
        TError,
        { params: PostRequestAndroidValidatePurchaseParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestAndroidValidatePurchase>>,
    TError,
    { params: PostRequestAndroidValidatePurchaseParams },
    TContext
> => {
    const mutationKey = ['postRequestAndroidValidatePurchase']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postRequestAndroidValidatePurchase>>,
        { params: PostRequestAndroidValidatePurchaseParams }
    > = (props) => {
        const { params } = props ?? {}

        return postRequestAndroidValidatePurchase(params, requestOptions)
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestAndroidValidatePurchaseMutationResult = NonNullable<
    Awaited<ReturnType<typeof postRequestAndroidValidatePurchase>>
>

export type PostRequestAndroidValidatePurchaseMutationError =
    ErrorType<void | HTTPValidationErrorDTO>

/**
 * @summary Validate Android Purchase
 */
export const usePostRequestAndroidValidatePurchase = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestAndroidValidatePurchase>>,
        TError,
        { params: PostRequestAndroidValidatePurchaseParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestAndroidValidatePurchase>>,
    TError,
    { params: PostRequestAndroidValidatePurchaseParams },
    TContext
> => {
    const mutationOptions =
        getPostRequestAndroidValidatePurchaseMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * This call is used to get current valid apple product ids

 * @summary Get Apple Store Products
 */
export const getRequestAppleStoreProducts = (
    params: GetRequestAppleStoreProductsParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<ProductListResponseDTO>(
        { url: `/apple_store_products`, method: 'GET', params, signal },
        options,
    )
}

export const getGetRequestAppleStoreProductsQueryKey = (
    params: GetRequestAppleStoreProductsParams,
) => {
    return [`/apple_store_products`, ...(params ? [params] : [])] as const
}

export const getGetRequestAppleStoreProductsQueryOptions = <
    TData = Awaited<ReturnType<typeof getRequestAppleStoreProducts>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestAppleStoreProductsParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestAppleStoreProducts>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {}

    const queryKey =
        queryOptions?.queryKey ??
        getGetRequestAppleStoreProductsQueryKey(params)

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getRequestAppleStoreProducts>>
    > = ({ signal }) =>
        getRequestAppleStoreProducts(params, requestOptions, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof getRequestAppleStoreProducts>>,
        TError,
        TData
    > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRequestAppleStoreProductsQueryResult = NonNullable<
    Awaited<ReturnType<typeof getRequestAppleStoreProducts>>
>
export type GetRequestAppleStoreProductsQueryError =
    ErrorType<HTTPValidationErrorDTO>

export function useGetRequestAppleStoreProducts<
    TData = Awaited<ReturnType<typeof getRequestAppleStoreProducts>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestAppleStoreProductsParams,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestAppleStoreProducts>>,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestAppleStoreProducts>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestAppleStoreProducts>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestAppleStoreProducts<
    TData = Awaited<ReturnType<typeof getRequestAppleStoreProducts>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestAppleStoreProductsParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestAppleStoreProducts>>,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestAppleStoreProducts>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestAppleStoreProducts>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestAppleStoreProducts<
    TData = Awaited<ReturnType<typeof getRequestAppleStoreProducts>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestAppleStoreProductsParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestAppleStoreProducts>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get Apple Store Products
 */

export function useGetRequestAppleStoreProducts<
    TData = Awaited<ReturnType<typeof getRequestAppleStoreProducts>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestAppleStoreProductsParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestAppleStoreProducts>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions = getGetRequestAppleStoreProductsQueryOptions(
        params,
        options,
    )

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * A list of available android products
 * @summary Get Android Store Products
 */
export const getRequestAndroidStoreProducts = (
    params: GetRequestAndroidStoreProductsParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<ProductListResponseDTO>(
        { url: `/android_store_products`, method: 'GET', params, signal },
        options,
    )
}

export const getGetRequestAndroidStoreProductsQueryKey = (
    params: GetRequestAndroidStoreProductsParams,
) => {
    return [`/android_store_products`, ...(params ? [params] : [])] as const
}

export const getGetRequestAndroidStoreProductsQueryOptions = <
    TData = Awaited<ReturnType<typeof getRequestAndroidStoreProducts>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestAndroidStoreProductsParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestAndroidStoreProducts>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {}

    const queryKey =
        queryOptions?.queryKey ??
        getGetRequestAndroidStoreProductsQueryKey(params)

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getRequestAndroidStoreProducts>>
    > = ({ signal }) =>
        getRequestAndroidStoreProducts(params, requestOptions, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof getRequestAndroidStoreProducts>>,
        TError,
        TData
    > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRequestAndroidStoreProductsQueryResult = NonNullable<
    Awaited<ReturnType<typeof getRequestAndroidStoreProducts>>
>
export type GetRequestAndroidStoreProductsQueryError =
    ErrorType<HTTPValidationErrorDTO>

export function useGetRequestAndroidStoreProducts<
    TData = Awaited<ReturnType<typeof getRequestAndroidStoreProducts>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestAndroidStoreProductsParams,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestAndroidStoreProducts>>,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestAndroidStoreProducts>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestAndroidStoreProducts>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestAndroidStoreProducts<
    TData = Awaited<ReturnType<typeof getRequestAndroidStoreProducts>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestAndroidStoreProductsParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestAndroidStoreProducts>>,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestAndroidStoreProducts>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestAndroidStoreProducts>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestAndroidStoreProducts<
    TData = Awaited<ReturnType<typeof getRequestAndroidStoreProducts>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestAndroidStoreProductsParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestAndroidStoreProducts>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get Android Store Products
 */

export function useGetRequestAndroidStoreProducts<
    TData = Awaited<ReturnType<typeof getRequestAndroidStoreProducts>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestAndroidStoreProductsParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestAndroidStoreProducts>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions = getGetRequestAndroidStoreProductsQueryOptions(
        params,
        options,
    )

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * A list of available web store/stripe products
 * @summary Get Stripe Products
 */
export const getRequestStripeProducts = (
    params: GetRequestStripeProductsParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<StripeProductListResponseDTO>(
        { url: `/stripe_products`, method: 'GET', params, signal },
        options,
    )
}

export const getGetRequestStripeProductsQueryKey = (
    params: GetRequestStripeProductsParams,
) => {
    return [`/stripe_products`, ...(params ? [params] : [])] as const
}

export const getGetRequestStripeProductsQueryOptions = <
    TData = Awaited<ReturnType<typeof getRequestStripeProducts>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestStripeProductsParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestStripeProducts>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {}

    const queryKey =
        queryOptions?.queryKey ?? getGetRequestStripeProductsQueryKey(params)

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getRequestStripeProducts>>
    > = ({ signal }) => getRequestStripeProducts(params, requestOptions, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof getRequestStripeProducts>>,
        TError,
        TData
    > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRequestStripeProductsQueryResult = NonNullable<
    Awaited<ReturnType<typeof getRequestStripeProducts>>
>
export type GetRequestStripeProductsQueryError =
    ErrorType<HTTPValidationErrorDTO>

export function useGetRequestStripeProducts<
    TData = Awaited<ReturnType<typeof getRequestStripeProducts>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestStripeProductsParams,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestStripeProducts>>,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestStripeProducts>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestStripeProducts>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestStripeProducts<
    TData = Awaited<ReturnType<typeof getRequestStripeProducts>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestStripeProductsParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestStripeProducts>>,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestStripeProducts>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestStripeProducts>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestStripeProducts<
    TData = Awaited<ReturnType<typeof getRequestStripeProducts>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestStripeProductsParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestStripeProducts>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get Stripe Products
 */

export function useGetRequestStripeProducts<
    TData = Awaited<ReturnType<typeof getRequestStripeProducts>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestStripeProductsParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestStripeProducts>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions = getGetRequestStripeProductsQueryOptions(
        params,
        options,
    )

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * @summary Logout Backchannel
 */
export const postRequestLogoutBackchannel = (
    params?: PostRequestLogoutBackchannelParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<MessageResponseDTO>(
        { url: `/logout_backchannel`, method: 'POST', params, signal },
        options,
    )
}

export const getPostRequestLogoutBackchannelMutationOptions = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestLogoutBackchannel>>,
        TError,
        { params?: PostRequestLogoutBackchannelParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestLogoutBackchannel>>,
    TError,
    { params?: PostRequestLogoutBackchannelParams },
    TContext
> => {
    const mutationKey = ['postRequestLogoutBackchannel']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postRequestLogoutBackchannel>>,
        { params?: PostRequestLogoutBackchannelParams }
    > = (props) => {
        const { params } = props ?? {}

        return postRequestLogoutBackchannel(params, requestOptions)
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestLogoutBackchannelMutationResult = NonNullable<
    Awaited<ReturnType<typeof postRequestLogoutBackchannel>>
>

export type PostRequestLogoutBackchannelMutationError =
    ErrorType<HTTPValidationErrorDTO>

/**
 * @summary Logout Backchannel
 */
export const usePostRequestLogoutBackchannel = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestLogoutBackchannel>>,
        TError,
        { params?: PostRequestLogoutBackchannelParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestLogoutBackchannel>>,
    TError,
    { params?: PostRequestLogoutBackchannelParams },
    TContext
> => {
    const mutationOptions =
        getPostRequestLogoutBackchannelMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * Returns the profile job for a user,
if the  profile job does not exists it will be created

 * @summary Get Profile Album
 */
export const getRequestProfileAlbum = (
    params: GetRequestProfileAlbumParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<PictureJobResponseDTO>(
        { url: `/profile_album`, method: 'GET', params, signal },
        options,
    )
}

export const getGetRequestProfileAlbumQueryKey = (
    params: GetRequestProfileAlbumParams,
) => {
    return [`/profile_album`, ...(params ? [params] : [])] as const
}

export const getGetRequestProfileAlbumQueryOptions = <
    TData = Awaited<ReturnType<typeof getRequestProfileAlbum>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestProfileAlbumParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestProfileAlbum>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {}

    const queryKey =
        queryOptions?.queryKey ?? getGetRequestProfileAlbumQueryKey(params)

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getRequestProfileAlbum>>
    > = ({ signal }) => getRequestProfileAlbum(params, requestOptions, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof getRequestProfileAlbum>>,
        TError,
        TData
    > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRequestProfileAlbumQueryResult = NonNullable<
    Awaited<ReturnType<typeof getRequestProfileAlbum>>
>
export type GetRequestProfileAlbumQueryError = ErrorType<HTTPValidationErrorDTO>

export function useGetRequestProfileAlbum<
    TData = Awaited<ReturnType<typeof getRequestProfileAlbum>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestProfileAlbumParams,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestProfileAlbum>>,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestProfileAlbum>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestProfileAlbum>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestProfileAlbum<
    TData = Awaited<ReturnType<typeof getRequestProfileAlbum>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestProfileAlbumParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestProfileAlbum>>,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestProfileAlbum>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestProfileAlbum>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestProfileAlbum<
    TData = Awaited<ReturnType<typeof getRequestProfileAlbum>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestProfileAlbumParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestProfileAlbum>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get Profile Album
 */

export function useGetRequestProfileAlbum<
    TData = Awaited<ReturnType<typeof getRequestProfileAlbum>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestProfileAlbumParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestProfileAlbum>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions = getGetRequestProfileAlbumQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * Validate if subscription is paid successfully
 * @summary Validate Purchase Status
 */
export const getRequestStripePurchaseValidatePurchaseBySubscriptionId = (
    subscriptionId: string,
    params: GetRequestStripePurchaseValidatePurchaseBySubscriptionIdParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<unknown>(
        {
            url: `/stripe_purchase/${encodeURIComponent(String(subscriptionId))}/validate_purchase`,
            method: 'GET',
            params,
            signal,
        },
        options,
    )
}

export const getGetRequestStripePurchaseValidatePurchaseBySubscriptionIdQueryKey =
    (
        subscriptionId: string,
        params: GetRequestStripePurchaseValidatePurchaseBySubscriptionIdParams,
    ) => {
        return [
            `/stripe_purchase/${subscriptionId}/validate_purchase`,
            ...(params ? [params] : []),
        ] as const
    }

export const getGetRequestStripePurchaseValidatePurchaseBySubscriptionIdQueryOptions =
    <
        TData = Awaited<
            ReturnType<
                typeof getRequestStripePurchaseValidatePurchaseBySubscriptionId
            >
        >,
        TError = ErrorType<void | HTTPValidationErrorDTO>,
    >(
        subscriptionId: string,
        params: GetRequestStripePurchaseValidatePurchaseBySubscriptionIdParams,
        options?: {
            query?: Partial<
                UseQueryOptions<
                    Awaited<
                        ReturnType<
                            typeof getRequestStripePurchaseValidatePurchaseBySubscriptionId
                        >
                    >,
                    TError,
                    TData
                >
            >
            request?: SecondParameter<typeof customInstance>
        },
    ) => {
        const { query: queryOptions, request: requestOptions } = options ?? {}

        const queryKey =
            queryOptions?.queryKey ??
            getGetRequestStripePurchaseValidatePurchaseBySubscriptionIdQueryKey(
                subscriptionId,
                params,
            )

        const queryFn: QueryFunction<
            Awaited<
                ReturnType<
                    typeof getRequestStripePurchaseValidatePurchaseBySubscriptionId
                >
            >
        > = ({ signal }) =>
            getRequestStripePurchaseValidatePurchaseBySubscriptionId(
                subscriptionId,
                params,
                requestOptions,
                signal,
            )

        return {
            queryKey,
            queryFn,
            enabled: !!subscriptionId,
            ...queryOptions,
        } as UseQueryOptions<
            Awaited<
                ReturnType<
                    typeof getRequestStripePurchaseValidatePurchaseBySubscriptionId
                >
            >,
            TError,
            TData
        > & { queryKey: DataTag<QueryKey, TData, TError> }
    }

export type GetRequestStripePurchaseValidatePurchaseBySubscriptionIdQueryResult =
    NonNullable<
        Awaited<
            ReturnType<
                typeof getRequestStripePurchaseValidatePurchaseBySubscriptionId
            >
        >
    >
export type GetRequestStripePurchaseValidatePurchaseBySubscriptionIdQueryError =
    ErrorType<void | HTTPValidationErrorDTO>

export function useGetRequestStripePurchaseValidatePurchaseBySubscriptionId<
    TData = Awaited<
        ReturnType<
            typeof getRequestStripePurchaseValidatePurchaseBySubscriptionId
        >
    >,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    subscriptionId: string,
    params: GetRequestStripePurchaseValidatePurchaseBySubscriptionIdParams,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestStripePurchaseValidatePurchaseBySubscriptionId
                    >
                >,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<
                        ReturnType<
                            typeof getRequestStripePurchaseValidatePurchaseBySubscriptionId
                        >
                    >,
                    TError,
                    Awaited<
                        ReturnType<
                            typeof getRequestStripePurchaseValidatePurchaseBySubscriptionId
                        >
                    >
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestStripePurchaseValidatePurchaseBySubscriptionId<
    TData = Awaited<
        ReturnType<
            typeof getRequestStripePurchaseValidatePurchaseBySubscriptionId
        >
    >,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    subscriptionId: string,
    params: GetRequestStripePurchaseValidatePurchaseBySubscriptionIdParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestStripePurchaseValidatePurchaseBySubscriptionId
                    >
                >,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<
                        ReturnType<
                            typeof getRequestStripePurchaseValidatePurchaseBySubscriptionId
                        >
                    >,
                    TError,
                    Awaited<
                        ReturnType<
                            typeof getRequestStripePurchaseValidatePurchaseBySubscriptionId
                        >
                    >
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestStripePurchaseValidatePurchaseBySubscriptionId<
    TData = Awaited<
        ReturnType<
            typeof getRequestStripePurchaseValidatePurchaseBySubscriptionId
        >
    >,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    subscriptionId: string,
    params: GetRequestStripePurchaseValidatePurchaseBySubscriptionIdParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestStripePurchaseValidatePurchaseBySubscriptionId
                    >
                >,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Validate Purchase Status
 */

export function useGetRequestStripePurchaseValidatePurchaseBySubscriptionId<
    TData = Awaited<
        ReturnType<
            typeof getRequestStripePurchaseValidatePurchaseBySubscriptionId
        >
    >,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    subscriptionId: string,
    params: GetRequestStripePurchaseValidatePurchaseBySubscriptionIdParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestStripePurchaseValidatePurchaseBySubscriptionId
                    >
                >,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions =
        getGetRequestStripePurchaseValidatePurchaseBySubscriptionIdQueryOptions(
            subscriptionId,
            params,
            options,
        )

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * Update the web store/stripe payment details of a user
 * @summary Post Stripe Payment Method
 */
export const postRequestStripePaymentMethod = (
    params: PostRequestStripePaymentMethodParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<unknown>(
        { url: `/stripe_payment_method`, method: 'POST', params, signal },
        options,
    )
}

export const getPostRequestStripePaymentMethodMutationOptions = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestStripePaymentMethod>>,
        TError,
        { params: PostRequestStripePaymentMethodParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestStripePaymentMethod>>,
    TError,
    { params: PostRequestStripePaymentMethodParams },
    TContext
> => {
    const mutationKey = ['postRequestStripePaymentMethod']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postRequestStripePaymentMethod>>,
        { params: PostRequestStripePaymentMethodParams }
    > = (props) => {
        const { params } = props ?? {}

        return postRequestStripePaymentMethod(params, requestOptions)
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestStripePaymentMethodMutationResult = NonNullable<
    Awaited<ReturnType<typeof postRequestStripePaymentMethod>>
>

export type PostRequestStripePaymentMethodMutationError =
    ErrorType<void | HTTPValidationErrorDTO>

/**
 * @summary Post Stripe Payment Method
 */
export const usePostRequestStripePaymentMethod = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestStripePaymentMethod>>,
        TError,
        { params: PostRequestStripePaymentMethodParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestStripePaymentMethod>>,
    TError,
    { params: PostRequestStripePaymentMethodParams },
    TContext
> => {
    const mutationOptions =
        getPostRequestStripePaymentMethodMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * Get the web store/stripe payment details of a user
 * @summary Get Stripe Payment Method
 */
export const getRequestStripePaymentMethod = (
    params: GetRequestStripePaymentMethodParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<PaymentInfoResponseDTO>(
        { url: `/stripe_payment_method`, method: 'GET', params, signal },
        options,
    )
}

export const getGetRequestStripePaymentMethodQueryKey = (
    params: GetRequestStripePaymentMethodParams,
) => {
    return [`/stripe_payment_method`, ...(params ? [params] : [])] as const
}

export const getGetRequestStripePaymentMethodQueryOptions = <
    TData = Awaited<ReturnType<typeof getRequestStripePaymentMethod>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    params: GetRequestStripePaymentMethodParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestStripePaymentMethod>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {}

    const queryKey =
        queryOptions?.queryKey ??
        getGetRequestStripePaymentMethodQueryKey(params)

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getRequestStripePaymentMethod>>
    > = ({ signal }) =>
        getRequestStripePaymentMethod(params, requestOptions, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof getRequestStripePaymentMethod>>,
        TError,
        TData
    > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRequestStripePaymentMethodQueryResult = NonNullable<
    Awaited<ReturnType<typeof getRequestStripePaymentMethod>>
>
export type GetRequestStripePaymentMethodQueryError =
    ErrorType<void | HTTPValidationErrorDTO>

export function useGetRequestStripePaymentMethod<
    TData = Awaited<ReturnType<typeof getRequestStripePaymentMethod>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    params: GetRequestStripePaymentMethodParams,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestStripePaymentMethod>>,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestStripePaymentMethod>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestStripePaymentMethod>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestStripePaymentMethod<
    TData = Awaited<ReturnType<typeof getRequestStripePaymentMethod>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    params: GetRequestStripePaymentMethodParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestStripePaymentMethod>>,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestStripePaymentMethod>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestStripePaymentMethod>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestStripePaymentMethod<
    TData = Awaited<ReturnType<typeof getRequestStripePaymentMethod>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    params: GetRequestStripePaymentMethodParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestStripePaymentMethod>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get Stripe Payment Method
 */

export function useGetRequestStripePaymentMethod<
    TData = Awaited<ReturnType<typeof getRequestStripePaymentMethod>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    params: GetRequestStripePaymentMethodParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestStripePaymentMethod>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions = getGetRequestStripePaymentMethodQueryOptions(
        params,
        options,
    )

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * Reactive a cancelled subscription while it is still active

 * @summary Cancel Stripe Purchase
 */
export const postRequestStripePurchaseCancelBySubscriptionId = (
    subscriptionId: string,
    params: PostRequestStripePurchaseCancelBySubscriptionIdParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<unknown>(
        {
            url: `/stripe_purchase/${encodeURIComponent(String(subscriptionId))}/cancel`,
            method: 'POST',
            params,
            signal,
        },
        options,
    )
}

export const getPostRequestStripePurchaseCancelBySubscriptionIdMutationOptions =
    <
        TError = ErrorType<void | HTTPValidationErrorDTO>,
        TContext = unknown,
    >(options?: {
        mutation?: UseMutationOptions<
            Awaited<
                ReturnType<
                    typeof postRequestStripePurchaseCancelBySubscriptionId
                >
            >,
            TError,
            {
                subscriptionId: string
                params: PostRequestStripePurchaseCancelBySubscriptionIdParams
            },
            TContext
        >
        request?: SecondParameter<typeof customInstance>
    }): UseMutationOptions<
        Awaited<
            ReturnType<typeof postRequestStripePurchaseCancelBySubscriptionId>
        >,
        TError,
        {
            subscriptionId: string
            params: PostRequestStripePurchaseCancelBySubscriptionIdParams
        },
        TContext
    > => {
        const mutationKey = ['postRequestStripePurchaseCancelBySubscriptionId']
        const { mutation: mutationOptions, request: requestOptions } = options
            ? options.mutation &&
              'mutationKey' in options.mutation &&
              options.mutation.mutationKey
                ? options
                : { ...options, mutation: { ...options.mutation, mutationKey } }
            : { mutation: { mutationKey }, request: undefined }

        const mutationFn: MutationFunction<
            Awaited<
                ReturnType<
                    typeof postRequestStripePurchaseCancelBySubscriptionId
                >
            >,
            {
                subscriptionId: string
                params: PostRequestStripePurchaseCancelBySubscriptionIdParams
            }
        > = (props) => {
            const { subscriptionId, params } = props ?? {}

            return postRequestStripePurchaseCancelBySubscriptionId(
                subscriptionId,
                params,
                requestOptions,
            )
        }

        return { mutationFn, ...mutationOptions }
    }

export type PostRequestStripePurchaseCancelBySubscriptionIdMutationResult =
    NonNullable<
        Awaited<
            ReturnType<typeof postRequestStripePurchaseCancelBySubscriptionId>
        >
    >

export type PostRequestStripePurchaseCancelBySubscriptionIdMutationError =
    ErrorType<void | HTTPValidationErrorDTO>

/**
 * @summary Cancel Stripe Purchase
 */
export const usePostRequestStripePurchaseCancelBySubscriptionId = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<typeof postRequestStripePurchaseCancelBySubscriptionId>
        >,
        TError,
        {
            subscriptionId: string
            params: PostRequestStripePurchaseCancelBySubscriptionIdParams
        },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestStripePurchaseCancelBySubscriptionId>>,
    TError,
    {
        subscriptionId: string
        params: PostRequestStripePurchaseCancelBySubscriptionIdParams
    },
    TContext
> => {
    const mutationOptions =
        getPostRequestStripePurchaseCancelBySubscriptionIdMutationOptions(
            options,
        )

    return useMutation(mutationOptions)
}

/**
 * Reactive a cancelled subscription while it is still active

 * @summary Reactivate Stripe Purchase
 */
export const postRequestStripePurchaseReactivateBySubscriptionId = (
    subscriptionId: string,
    params: PostRequestStripePurchaseReactivateBySubscriptionIdParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<unknown>(
        {
            url: `/stripe_purchase/${encodeURIComponent(String(subscriptionId))}/reactivate`,
            method: 'POST',
            params,
            signal,
        },
        options,
    )
}

export const getPostRequestStripePurchaseReactivateBySubscriptionIdMutationOptions =
    <
        TError = ErrorType<void | HTTPValidationErrorDTO>,
        TContext = unknown,
    >(options?: {
        mutation?: UseMutationOptions<
            Awaited<
                ReturnType<
                    typeof postRequestStripePurchaseReactivateBySubscriptionId
                >
            >,
            TError,
            {
                subscriptionId: string
                params: PostRequestStripePurchaseReactivateBySubscriptionIdParams
            },
            TContext
        >
        request?: SecondParameter<typeof customInstance>
    }): UseMutationOptions<
        Awaited<
            ReturnType<
                typeof postRequestStripePurchaseReactivateBySubscriptionId
            >
        >,
        TError,
        {
            subscriptionId: string
            params: PostRequestStripePurchaseReactivateBySubscriptionIdParams
        },
        TContext
    > => {
        const mutationKey = [
            'postRequestStripePurchaseReactivateBySubscriptionId',
        ]
        const { mutation: mutationOptions, request: requestOptions } = options
            ? options.mutation &&
              'mutationKey' in options.mutation &&
              options.mutation.mutationKey
                ? options
                : { ...options, mutation: { ...options.mutation, mutationKey } }
            : { mutation: { mutationKey }, request: undefined }

        const mutationFn: MutationFunction<
            Awaited<
                ReturnType<
                    typeof postRequestStripePurchaseReactivateBySubscriptionId
                >
            >,
            {
                subscriptionId: string
                params: PostRequestStripePurchaseReactivateBySubscriptionIdParams
            }
        > = (props) => {
            const { subscriptionId, params } = props ?? {}

            return postRequestStripePurchaseReactivateBySubscriptionId(
                subscriptionId,
                params,
                requestOptions,
            )
        }

        return { mutationFn, ...mutationOptions }
    }

export type PostRequestStripePurchaseReactivateBySubscriptionIdMutationResult =
    NonNullable<
        Awaited<
            ReturnType<
                typeof postRequestStripePurchaseReactivateBySubscriptionId
            >
        >
    >

export type PostRequestStripePurchaseReactivateBySubscriptionIdMutationError =
    ErrorType<void | HTTPValidationErrorDTO>

/**
 * @summary Reactivate Stripe Purchase
 */
export const usePostRequestStripePurchaseReactivateBySubscriptionId = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<
                typeof postRequestStripePurchaseReactivateBySubscriptionId
            >
        >,
        TError,
        {
            subscriptionId: string
            params: PostRequestStripePurchaseReactivateBySubscriptionIdParams
        },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<
        ReturnType<typeof postRequestStripePurchaseReactivateBySubscriptionId>
    >,
    TError,
    {
        subscriptionId: string
        params: PostRequestStripePurchaseReactivateBySubscriptionIdParams
    },
    TContext
> => {
    const mutationOptions =
        getPostRequestStripePurchaseReactivateBySubscriptionIdMutationOptions(
            options,
        )

    return useMutation(mutationOptions)
}

/**
 * Update the web store/stripe subscription of a user
 * @summary Update Stripe Purchase
 */
export const postRequestUpdateStripePurchase = (
    params: PostRequestUpdateStripePurchaseParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<UpdateStripePurchaseResponseDTO>(
        { url: `/update_stripe_purchase`, method: 'POST', params, signal },
        options,
    )
}

export const getPostRequestUpdateStripePurchaseMutationOptions = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestUpdateStripePurchase>>,
        TError,
        { params: PostRequestUpdateStripePurchaseParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestUpdateStripePurchase>>,
    TError,
    { params: PostRequestUpdateStripePurchaseParams },
    TContext
> => {
    const mutationKey = ['postRequestUpdateStripePurchase']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postRequestUpdateStripePurchase>>,
        { params: PostRequestUpdateStripePurchaseParams }
    > = (props) => {
        const { params } = props ?? {}

        return postRequestUpdateStripePurchase(params, requestOptions)
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestUpdateStripePurchaseMutationResult = NonNullable<
    Awaited<ReturnType<typeof postRequestUpdateStripePurchase>>
>

export type PostRequestUpdateStripePurchaseMutationError =
    ErrorType<void | HTTPValidationErrorDTO>

/**
 * @summary Update Stripe Purchase
 */
export const usePostRequestUpdateStripePurchase = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestUpdateStripePurchase>>,
        TError,
        { params: PostRequestUpdateStripePurchaseParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestUpdateStripePurchase>>,
    TError,
    { params: PostRequestUpdateStripePurchaseParams },
    TContext
> => {
    const mutationOptions =
        getPostRequestUpdateStripePurchaseMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary Update Stripe Purchase New
 */
export const putRequestStripeV1Purchase = (
    params: PutRequestStripeV1PurchaseParams,
    options?: SecondParameter<typeof customInstance>,
) => {
    return customInstance<UpdateStripePurchaseResponseDTO>(
        { url: `/stripe/v1/purchase`, method: 'PUT', params },
        options,
    )
}

export const getPutRequestStripeV1PurchaseMutationOptions = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof putRequestStripeV1Purchase>>,
        TError,
        { params: PutRequestStripeV1PurchaseParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof putRequestStripeV1Purchase>>,
    TError,
    { params: PutRequestStripeV1PurchaseParams },
    TContext
> => {
    const mutationKey = ['putRequestStripeV1Purchase']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof putRequestStripeV1Purchase>>,
        { params: PutRequestStripeV1PurchaseParams }
    > = (props) => {
        const { params } = props ?? {}

        return putRequestStripeV1Purchase(params, requestOptions)
    }

    return { mutationFn, ...mutationOptions }
}

export type PutRequestStripeV1PurchaseMutationResult = NonNullable<
    Awaited<ReturnType<typeof putRequestStripeV1Purchase>>
>

export type PutRequestStripeV1PurchaseMutationError =
    ErrorType<HTTPValidationErrorDTO>

/**
 * @summary Update Stripe Purchase New
 */
export const usePutRequestStripeV1Purchase = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof putRequestStripeV1Purchase>>,
        TError,
        { params: PutRequestStripeV1PurchaseParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof putRequestStripeV1Purchase>>,
    TError,
    { params: PutRequestStripeV1PurchaseParams },
    TContext
> => {
    const mutationOptions =
        getPutRequestStripeV1PurchaseMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary Post Stripe Purchase New
 */
export const postRequestStripeV1Purchase = (
    params: PostRequestStripeV1PurchaseParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<PostRequestStripeV1Purchase200>(
        { url: `/stripe/v1/purchase`, method: 'POST', params, signal },
        options,
    )
}

export const getPostRequestStripeV1PurchaseMutationOptions = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestStripeV1Purchase>>,
        TError,
        { params: PostRequestStripeV1PurchaseParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestStripeV1Purchase>>,
    TError,
    { params: PostRequestStripeV1PurchaseParams },
    TContext
> => {
    const mutationKey = ['postRequestStripeV1Purchase']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postRequestStripeV1Purchase>>,
        { params: PostRequestStripeV1PurchaseParams }
    > = (props) => {
        const { params } = props ?? {}

        return postRequestStripeV1Purchase(params, requestOptions)
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestStripeV1PurchaseMutationResult = NonNullable<
    Awaited<ReturnType<typeof postRequestStripeV1Purchase>>
>

export type PostRequestStripeV1PurchaseMutationError =
    ErrorType<HTTPValidationErrorDTO>

/**
 * @summary Post Stripe Purchase New
 */
export const usePostRequestStripeV1Purchase = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestStripeV1Purchase>>,
        TError,
        { params: PostRequestStripeV1PurchaseParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestStripeV1Purchase>>,
    TError,
    { params: PostRequestStripeV1PurchaseParams },
    TContext
> => {
    const mutationOptions =
        getPostRequestStripeV1PurchaseMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * Make a web store/stripe purchase
 * @summary Post Stripe Purchase
 */
export const postRequestStripePurchase = (
    params: PostRequestStripePurchaseParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<PostRequestStripePurchase200>(
        { url: `/stripe_purchase`, method: 'POST', params, signal },
        options,
    )
}

export const getPostRequestStripePurchaseMutationOptions = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestStripePurchase>>,
        TError,
        { params: PostRequestStripePurchaseParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestStripePurchase>>,
    TError,
    { params: PostRequestStripePurchaseParams },
    TContext
> => {
    const mutationKey = ['postRequestStripePurchase']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postRequestStripePurchase>>,
        { params: PostRequestStripePurchaseParams }
    > = (props) => {
        const { params } = props ?? {}

        return postRequestStripePurchase(params, requestOptions)
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestStripePurchaseMutationResult = NonNullable<
    Awaited<ReturnType<typeof postRequestStripePurchase>>
>

export type PostRequestStripePurchaseMutationError =
    ErrorType<void | HTTPValidationErrorDTO>

/**
 * @summary Post Stripe Purchase
 */
export const usePostRequestStripePurchase = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestStripePurchase>>,
        TError,
        { params: PostRequestStripePurchaseParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestStripePurchase>>,
    TError,
    { params: PostRequestStripePurchaseParams },
    TContext
> => {
    const mutationOptions = getPostRequestStripePurchaseMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary Post Create Setup Intent
 */
export const postRequestStripeCreateSetupIntent = (
    params: PostRequestStripeCreateSetupIntentParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<StripeSetupIntentCreateResponseDTO>(
        { url: `/stripe/create_setup_intent`, method: 'POST', params, signal },
        options,
    )
}

export const getPostRequestStripeCreateSetupIntentMutationOptions = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestStripeCreateSetupIntent>>,
        TError,
        { params: PostRequestStripeCreateSetupIntentParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestStripeCreateSetupIntent>>,
    TError,
    { params: PostRequestStripeCreateSetupIntentParams },
    TContext
> => {
    const mutationKey = ['postRequestStripeCreateSetupIntent']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postRequestStripeCreateSetupIntent>>,
        { params: PostRequestStripeCreateSetupIntentParams }
    > = (props) => {
        const { params } = props ?? {}

        return postRequestStripeCreateSetupIntent(params, requestOptions)
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestStripeCreateSetupIntentMutationResult = NonNullable<
    Awaited<ReturnType<typeof postRequestStripeCreateSetupIntent>>
>

export type PostRequestStripeCreateSetupIntentMutationError =
    ErrorType<HTTPValidationErrorDTO>

/**
 * @summary Post Create Setup Intent
 */
export const usePostRequestStripeCreateSetupIntent = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestStripeCreateSetupIntent>>,
        TError,
        { params: PostRequestStripeCreateSetupIntentParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestStripeCreateSetupIntent>>,
    TError,
    { params: PostRequestStripeCreateSetupIntentParams },
    TContext
> => {
    const mutationOptions =
        getPostRequestStripeCreateSetupIntentMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary Post Finish Setup Intent
 */
export const postRequestStripeFinishSetupIntent = (
    params: PostRequestStripeFinishSetupIntentParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<MessageResponseDTO>(
        { url: `/stripe/finish_setup_intent`, method: 'POST', params, signal },
        options,
    )
}

export const getPostRequestStripeFinishSetupIntentMutationOptions = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestStripeFinishSetupIntent>>,
        TError,
        { params: PostRequestStripeFinishSetupIntentParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestStripeFinishSetupIntent>>,
    TError,
    { params: PostRequestStripeFinishSetupIntentParams },
    TContext
> => {
    const mutationKey = ['postRequestStripeFinishSetupIntent']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postRequestStripeFinishSetupIntent>>,
        { params: PostRequestStripeFinishSetupIntentParams }
    > = (props) => {
        const { params } = props ?? {}

        return postRequestStripeFinishSetupIntent(params, requestOptions)
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestStripeFinishSetupIntentMutationResult = NonNullable<
    Awaited<ReturnType<typeof postRequestStripeFinishSetupIntent>>
>

export type PostRequestStripeFinishSetupIntentMutationError =
    ErrorType<HTTPValidationErrorDTO>

/**
 * @summary Post Finish Setup Intent
 */
export const usePostRequestStripeFinishSetupIntent = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestStripeFinishSetupIntent>>,
        TError,
        { params: PostRequestStripeFinishSetupIntentParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestStripeFinishSetupIntent>>,
    TError,
    { params: PostRequestStripeFinishSetupIntentParams },
    TContext
> => {
    const mutationOptions =
        getPostRequestStripeFinishSetupIntentMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary Get Customer Exists
 */
export const getRequestStripeCustomerExists = (
    params: GetRequestStripeCustomerExistsParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<MessageResponseDTO>(
        { url: `/stripe/customer_exists`, method: 'GET', params, signal },
        options,
    )
}

export const getGetRequestStripeCustomerExistsQueryKey = (
    params: GetRequestStripeCustomerExistsParams,
) => {
    return [`/stripe/customer_exists`, ...(params ? [params] : [])] as const
}

export const getGetRequestStripeCustomerExistsQueryOptions = <
    TData = Awaited<ReturnType<typeof getRequestStripeCustomerExists>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestStripeCustomerExistsParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestStripeCustomerExists>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {}

    const queryKey =
        queryOptions?.queryKey ??
        getGetRequestStripeCustomerExistsQueryKey(params)

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getRequestStripeCustomerExists>>
    > = ({ signal }) =>
        getRequestStripeCustomerExists(params, requestOptions, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof getRequestStripeCustomerExists>>,
        TError,
        TData
    > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRequestStripeCustomerExistsQueryResult = NonNullable<
    Awaited<ReturnType<typeof getRequestStripeCustomerExists>>
>
export type GetRequestStripeCustomerExistsQueryError =
    ErrorType<HTTPValidationErrorDTO>

export function useGetRequestStripeCustomerExists<
    TData = Awaited<ReturnType<typeof getRequestStripeCustomerExists>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestStripeCustomerExistsParams,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestStripeCustomerExists>>,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestStripeCustomerExists>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestStripeCustomerExists>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestStripeCustomerExists<
    TData = Awaited<ReturnType<typeof getRequestStripeCustomerExists>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestStripeCustomerExistsParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestStripeCustomerExists>>,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestStripeCustomerExists>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestStripeCustomerExists>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestStripeCustomerExists<
    TData = Awaited<ReturnType<typeof getRequestStripeCustomerExists>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestStripeCustomerExistsParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestStripeCustomerExists>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get Customer Exists
 */

export function useGetRequestStripeCustomerExists<
    TData = Awaited<ReturnType<typeof getRequestStripeCustomerExists>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestStripeCustomerExistsParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestStripeCustomerExists>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions = getGetRequestStripeCustomerExistsQueryOptions(
        params,
        options,
    )

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * Returns the cover job for a user,
if the cover job does not exists it will be created

 * @summary Get Cover Album
 */
export const getRequestCoverAlbum = (
    params: GetRequestCoverAlbumParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<PictureJobResponseDTO>(
        { url: `/cover_album`, method: 'GET', params, signal },
        options,
    )
}

export const getGetRequestCoverAlbumQueryKey = (
    params: GetRequestCoverAlbumParams,
) => {
    return [`/cover_album`, ...(params ? [params] : [])] as const
}

export const getGetRequestCoverAlbumQueryOptions = <
    TData = Awaited<ReturnType<typeof getRequestCoverAlbum>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestCoverAlbumParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestCoverAlbum>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {}

    const queryKey =
        queryOptions?.queryKey ?? getGetRequestCoverAlbumQueryKey(params)

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getRequestCoverAlbum>>
    > = ({ signal }) => getRequestCoverAlbum(params, requestOptions, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof getRequestCoverAlbum>>,
        TError,
        TData
    > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRequestCoverAlbumQueryResult = NonNullable<
    Awaited<ReturnType<typeof getRequestCoverAlbum>>
>
export type GetRequestCoverAlbumQueryError = ErrorType<HTTPValidationErrorDTO>

export function useGetRequestCoverAlbum<
    TData = Awaited<ReturnType<typeof getRequestCoverAlbum>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestCoverAlbumParams,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestCoverAlbum>>,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestCoverAlbum>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestCoverAlbum>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestCoverAlbum<
    TData = Awaited<ReturnType<typeof getRequestCoverAlbum>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestCoverAlbumParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestCoverAlbum>>,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestCoverAlbum>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestCoverAlbum>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestCoverAlbum<
    TData = Awaited<ReturnType<typeof getRequestCoverAlbum>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestCoverAlbumParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestCoverAlbum>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get Cover Album
 */

export function useGetRequestCoverAlbum<
    TData = Awaited<ReturnType<typeof getRequestCoverAlbum>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestCoverAlbumParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestCoverAlbum>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions = getGetRequestCoverAlbumQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * This call is there to upload the new profile picture.
Only the user can change his or her profile picture.
If no content is provided in the body, we assume dedup.
In the case of dedup, the checksum must be provided

 * @summary Post Profile Picture
 */
export const postRequestProfilePicture = (
    postRequestProfilePictureBodiesTwo: BodyType<PostRequestProfilePictureBodiesTwo>,
    params: PostRequestProfilePictureParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    const formData = new FormData()
    if (postRequestProfilePictureBodiesTwo.file !== undefined) {
        formData.append('file', postRequestProfilePictureBodiesTwo.file)
    }

    return customInstance<void | ProfilePictureUploadResponseDTO>(
        {
            url: `/profile/picture`,
            method: 'POST',
            headers: { 'Content-Type': 'multipart/form-data' },
            data: formData,
            params,
            signal,
        },
        options,
    )
}

export const getPostRequestProfilePictureMutationOptions = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestProfilePicture>>,
        TError,
        {
            data: BodyType<PostRequestProfilePictureBodiesTwo>
            params: PostRequestProfilePictureParams
        },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestProfilePicture>>,
    TError,
    {
        data: BodyType<PostRequestProfilePictureBodiesTwo>
        params: PostRequestProfilePictureParams
    },
    TContext
> => {
    const mutationKey = ['postRequestProfilePicture']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postRequestProfilePicture>>,
        {
            data: BodyType<PostRequestProfilePictureBodiesTwo>
            params: PostRequestProfilePictureParams
        }
    > = (props) => {
        const { data, params } = props ?? {}

        return postRequestProfilePicture(data, params, requestOptions)
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestProfilePictureMutationResult = NonNullable<
    Awaited<ReturnType<typeof postRequestProfilePicture>>
>
export type PostRequestProfilePictureMutationBody =
    BodyType<PostRequestProfilePictureBodiesTwo>
export type PostRequestProfilePictureMutationError =
    ErrorType<void | HTTPValidationErrorDTO>

/**
 * @summary Post Profile Picture
 */
export const usePostRequestProfilePicture = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestProfilePicture>>,
        TError,
        {
            data: BodyType<PostRequestProfilePictureBodiesTwo>
            params: PostRequestProfilePictureParams
        },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestProfilePicture>>,
    TError,
    {
        data: BodyType<PostRequestProfilePictureBodiesTwo>
        params: PostRequestProfilePictureParams
    },
    TContext
> => {
    const mutationOptions = getPostRequestProfilePictureMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * This call gets the full size version of the profile picture.
Only the owner can access the full size profile image

 * @summary Get Profile Picture
 */
export const getRequestProfilePicture = (
    params: GetRequestProfilePictureParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<Blob>(
        {
            url: `/profile/picture`,
            method: 'GET',
            params,
            responseType: 'blob',
            signal,
        },
        options,
    )
}

export const getGetRequestProfilePictureQueryKey = (
    params: GetRequestProfilePictureParams,
) => {
    return [`/profile/picture`, ...(params ? [params] : [])] as const
}

export const getGetRequestProfilePictureQueryOptions = <
    TData = Awaited<ReturnType<typeof getRequestProfilePicture>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    params: GetRequestProfilePictureParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestProfilePicture>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {}

    const queryKey =
        queryOptions?.queryKey ?? getGetRequestProfilePictureQueryKey(params)

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getRequestProfilePicture>>
    > = ({ signal }) => getRequestProfilePicture(params, requestOptions, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof getRequestProfilePicture>>,
        TError,
        TData
    > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRequestProfilePictureQueryResult = NonNullable<
    Awaited<ReturnType<typeof getRequestProfilePicture>>
>
export type GetRequestProfilePictureQueryError =
    ErrorType<void | HTTPValidationErrorDTO>

export function useGetRequestProfilePicture<
    TData = Awaited<ReturnType<typeof getRequestProfilePicture>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    params: GetRequestProfilePictureParams,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestProfilePicture>>,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestProfilePicture>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestProfilePicture>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestProfilePicture<
    TData = Awaited<ReturnType<typeof getRequestProfilePicture>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    params: GetRequestProfilePictureParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestProfilePicture>>,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestProfilePicture>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestProfilePicture>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestProfilePicture<
    TData = Awaited<ReturnType<typeof getRequestProfilePicture>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    params: GetRequestProfilePictureParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestProfilePicture>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get Profile Picture
 */

export function useGetRequestProfilePicture<
    TData = Awaited<ReturnType<typeof getRequestProfilePicture>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    params: GetRequestProfilePictureParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestProfilePicture>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions = getGetRequestProfilePictureQueryOptions(
        params,
        options,
    )

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * This call removes the profile picture of the user

 * @summary Delete Profile Picture
 */
export const deleteRequestProfilePicture = (
    params: DeleteRequestProfilePictureParams,
    options?: SecondParameter<typeof customInstance>,
) => {
    return customInstance<MessageAndResultResponseDTO>(
        { url: `/profile/picture`, method: 'DELETE', params },
        options,
    )
}

export const getDeleteRequestProfilePictureMutationOptions = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof deleteRequestProfilePicture>>,
        TError,
        { params: DeleteRequestProfilePictureParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof deleteRequestProfilePicture>>,
    TError,
    { params: DeleteRequestProfilePictureParams },
    TContext
> => {
    const mutationKey = ['deleteRequestProfilePicture']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof deleteRequestProfilePicture>>,
        { params: DeleteRequestProfilePictureParams }
    > = (props) => {
        const { params } = props ?? {}

        return deleteRequestProfilePicture(params, requestOptions)
    }

    return { mutationFn, ...mutationOptions }
}

export type DeleteRequestProfilePictureMutationResult = NonNullable<
    Awaited<ReturnType<typeof deleteRequestProfilePicture>>
>

export type DeleteRequestProfilePictureMutationError =
    ErrorType<void | HTTPValidationErrorDTO>

/**
 * @summary Delete Profile Picture
 */
export const useDeleteRequestProfilePicture = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof deleteRequestProfilePicture>>,
        TError,
        { params: DeleteRequestProfilePictureParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof deleteRequestProfilePicture>>,
    TError,
    { params: DeleteRequestProfilePictureParams },
    TContext
> => {
    const mutationOptions =
        getDeleteRequestProfilePictureMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * this call is used to restore a deleted album

 * @summary Rollback Album
 */
export const postRequestJobsRollbackJobByJobUuid = (
    jobUuid: string,
    params: PostRequestJobsRollbackJobByJobUuidParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<unknown>(
        {
            url: `/jobs/${encodeURIComponent(String(jobUuid))}/rollback_job`,
            method: 'POST',
            params,
            signal,
        },
        options,
    )
}

export const getPostRequestJobsRollbackJobByJobUuidMutationOptions = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestJobsRollbackJobByJobUuid>>,
        TError,
        { jobUuid: string; params: PostRequestJobsRollbackJobByJobUuidParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestJobsRollbackJobByJobUuid>>,
    TError,
    { jobUuid: string; params: PostRequestJobsRollbackJobByJobUuidParams },
    TContext
> => {
    const mutationKey = ['postRequestJobsRollbackJobByJobUuid']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postRequestJobsRollbackJobByJobUuid>>,
        { jobUuid: string; params: PostRequestJobsRollbackJobByJobUuidParams }
    > = (props) => {
        const { jobUuid, params } = props ?? {}

        return postRequestJobsRollbackJobByJobUuid(
            jobUuid,
            params,
            requestOptions,
        )
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestJobsRollbackJobByJobUuidMutationResult = NonNullable<
    Awaited<ReturnType<typeof postRequestJobsRollbackJobByJobUuid>>
>

export type PostRequestJobsRollbackJobByJobUuidMutationError =
    ErrorType<void | HTTPValidationErrorDTO>

/**
 * @summary Rollback Album
 */
export const usePostRequestJobsRollbackJobByJobUuid = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestJobsRollbackJobByJobUuid>>,
        TError,
        { jobUuid: string; params: PostRequestJobsRollbackJobByJobUuidParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestJobsRollbackJobByJobUuid>>,
    TError,
    { jobUuid: string; params: PostRequestJobsRollbackJobByJobUuidParams },
    TContext
> => {
    const mutationOptions =
        getPostRequestJobsRollbackJobByJobUuidMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * this call is used to restore deleted albums

 * @summary Rollback Albums
 */
export const postRequestRollbackAlbums = (
    jobUUIDListDTO: BodyType<JobUUIDListDTO>,
    params: PostRequestRollbackAlbumsParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<RestoreAlbumsResponseDTO>(
        {
            url: `/rollback_albums`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: jobUUIDListDTO,
            params,
            signal,
        },
        options,
    )
}

export const getPostRequestRollbackAlbumsMutationOptions = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestRollbackAlbums>>,
        TError,
        {
            data: BodyType<JobUUIDListDTO>
            params: PostRequestRollbackAlbumsParams
        },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestRollbackAlbums>>,
    TError,
    { data: BodyType<JobUUIDListDTO>; params: PostRequestRollbackAlbumsParams },
    TContext
> => {
    const mutationKey = ['postRequestRollbackAlbums']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postRequestRollbackAlbums>>,
        {
            data: BodyType<JobUUIDListDTO>
            params: PostRequestRollbackAlbumsParams
        }
    > = (props) => {
        const { data, params } = props ?? {}

        return postRequestRollbackAlbums(data, params, requestOptions)
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestRollbackAlbumsMutationResult = NonNullable<
    Awaited<ReturnType<typeof postRequestRollbackAlbums>>
>
export type PostRequestRollbackAlbumsMutationBody = BodyType<JobUUIDListDTO>
export type PostRequestRollbackAlbumsMutationError =
    ErrorType<void | HTTPValidationErrorDTO>

/**
 * @summary Rollback Albums
 */
export const usePostRequestRollbackAlbums = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestRollbackAlbums>>,
        TError,
        {
            data: BodyType<JobUUIDListDTO>
            params: PostRequestRollbackAlbumsParams
        },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestRollbackAlbums>>,
    TError,
    { data: BodyType<JobUUIDListDTO>; params: PostRequestRollbackAlbumsParams },
    TContext
> => {
    const mutationOptions = getPostRequestRollbackAlbumsMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary Rollback Files
 */
export const postRequestJobsMultiRollbackByJobUuid = (
    jobUuid: string,
    fileUUIDRollbackListDTO: BodyType<FileUUIDRollbackListDTO>,
    params: PostRequestJobsMultiRollbackByJobUuidParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<RollbackMultiResponseDTO>(
        {
            url: `/jobs/${encodeURIComponent(String(jobUuid))}/multi_rollback`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: fileUUIDRollbackListDTO,
            params,
            signal,
        },
        options,
    )
}

export const getPostRequestJobsMultiRollbackByJobUuidMutationOptions = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestJobsMultiRollbackByJobUuid>>,
        TError,
        {
            jobUuid: string
            data: BodyType<FileUUIDRollbackListDTO>
            params: PostRequestJobsMultiRollbackByJobUuidParams
        },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestJobsMultiRollbackByJobUuid>>,
    TError,
    {
        jobUuid: string
        data: BodyType<FileUUIDRollbackListDTO>
        params: PostRequestJobsMultiRollbackByJobUuidParams
    },
    TContext
> => {
    const mutationKey = ['postRequestJobsMultiRollbackByJobUuid']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postRequestJobsMultiRollbackByJobUuid>>,
        {
            jobUuid: string
            data: BodyType<FileUUIDRollbackListDTO>
            params: PostRequestJobsMultiRollbackByJobUuidParams
        }
    > = (props) => {
        const { jobUuid, data, params } = props ?? {}

        return postRequestJobsMultiRollbackByJobUuid(
            jobUuid,
            data,
            params,
            requestOptions,
        )
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestJobsMultiRollbackByJobUuidMutationResult = NonNullable<
    Awaited<ReturnType<typeof postRequestJobsMultiRollbackByJobUuid>>
>
export type PostRequestJobsMultiRollbackByJobUuidMutationBody =
    BodyType<FileUUIDRollbackListDTO>
export type PostRequestJobsMultiRollbackByJobUuidMutationError =
    ErrorType<HTTPValidationErrorDTO>

/**
 * @summary Rollback Files
 */
export const usePostRequestJobsMultiRollbackByJobUuid = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestJobsMultiRollbackByJobUuid>>,
        TError,
        {
            jobUuid: string
            data: BodyType<FileUUIDRollbackListDTO>
            params: PostRequestJobsMultiRollbackByJobUuidParams
        },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestJobsMultiRollbackByJobUuid>>,
    TError,
    {
        jobUuid: string
        data: BodyType<FileUUIDRollbackListDTO>
        params: PostRequestJobsMultiRollbackByJobUuidParams
    },
    TContext
> => {
    const mutationOptions =
        getPostRequestJobsMultiRollbackByJobUuidMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * Use /jobs/{job_uuid}/multi_rollback
 * @deprecated
 * @summary Rollback File
 */
export const postRequestJobsRollbackByJobUuid = (
    jobUuid: string,
    params: PostRequestJobsRollbackByJobUuidParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<UsageAndQuotaResponseDTO>(
        {
            url: `/jobs/${encodeURIComponent(String(jobUuid))}/rollback`,
            method: 'POST',
            params,
            signal,
        },
        options,
    )
}

export const getPostRequestJobsRollbackByJobUuidMutationOptions = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestJobsRollbackByJobUuid>>,
        TError,
        { jobUuid: string; params: PostRequestJobsRollbackByJobUuidParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestJobsRollbackByJobUuid>>,
    TError,
    { jobUuid: string; params: PostRequestJobsRollbackByJobUuidParams },
    TContext
> => {
    const mutationKey = ['postRequestJobsRollbackByJobUuid']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postRequestJobsRollbackByJobUuid>>,
        { jobUuid: string; params: PostRequestJobsRollbackByJobUuidParams }
    > = (props) => {
        const { jobUuid, params } = props ?? {}

        return postRequestJobsRollbackByJobUuid(jobUuid, params, requestOptions)
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestJobsRollbackByJobUuidMutationResult = NonNullable<
    Awaited<ReturnType<typeof postRequestJobsRollbackByJobUuid>>
>

export type PostRequestJobsRollbackByJobUuidMutationError =
    ErrorType<void | HTTPValidationErrorDTO>

/**
 * @deprecated
 * @summary Rollback File
 */
export const usePostRequestJobsRollbackByJobUuid = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestJobsRollbackByJobUuid>>,
        TError,
        { jobUuid: string; params: PostRequestJobsRollbackByJobUuidParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestJobsRollbackByJobUuid>>,
    TError,
    { jobUuid: string; params: PostRequestJobsRollbackByJobUuidParams },
    TContext
> => {
    const mutationOptions =
        getPostRequestJobsRollbackByJobUuidMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * this call archives the files for a job
 * @deprecated
 * @summary Post File Delete
 */
export const postRequestJobsFilesByIdDeleteByJobUuidAndFileUuid = (
    jobUuid: string,
    fileUuid: string,
    params: PostRequestJobsFilesByIdDeleteByJobUuidAndFileUuidParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<PostRequestJobsFilesByIdDeleteByJobUuidAndFileUuid200>(
        {
            url: `/jobs/${encodeURIComponent(String(jobUuid))}/files_by_id/${encodeURIComponent(String(fileUuid))}/delete`,
            method: 'POST',
            params,
            signal,
        },
        options,
    )
}

export const getPostRequestJobsFilesByIdDeleteByJobUuidAndFileUuidMutationOptions =
    <
        TError = ErrorType<void | HTTPValidationErrorDTO>,
        TContext = unknown,
    >(options?: {
        mutation?: UseMutationOptions<
            Awaited<
                ReturnType<
                    typeof postRequestJobsFilesByIdDeleteByJobUuidAndFileUuid
                >
            >,
            TError,
            {
                jobUuid: string
                fileUuid: string
                params: PostRequestJobsFilesByIdDeleteByJobUuidAndFileUuidParams
            },
            TContext
        >
        request?: SecondParameter<typeof customInstance>
    }): UseMutationOptions<
        Awaited<
            ReturnType<
                typeof postRequestJobsFilesByIdDeleteByJobUuidAndFileUuid
            >
        >,
        TError,
        {
            jobUuid: string
            fileUuid: string
            params: PostRequestJobsFilesByIdDeleteByJobUuidAndFileUuidParams
        },
        TContext
    > => {
        const mutationKey = [
            'postRequestJobsFilesByIdDeleteByJobUuidAndFileUuid',
        ]
        const { mutation: mutationOptions, request: requestOptions } = options
            ? options.mutation &&
              'mutationKey' in options.mutation &&
              options.mutation.mutationKey
                ? options
                : { ...options, mutation: { ...options.mutation, mutationKey } }
            : { mutation: { mutationKey }, request: undefined }

        const mutationFn: MutationFunction<
            Awaited<
                ReturnType<
                    typeof postRequestJobsFilesByIdDeleteByJobUuidAndFileUuid
                >
            >,
            {
                jobUuid: string
                fileUuid: string
                params: PostRequestJobsFilesByIdDeleteByJobUuidAndFileUuidParams
            }
        > = (props) => {
            const { jobUuid, fileUuid, params } = props ?? {}

            return postRequestJobsFilesByIdDeleteByJobUuidAndFileUuid(
                jobUuid,
                fileUuid,
                params,
                requestOptions,
            )
        }

        return { mutationFn, ...mutationOptions }
    }

export type PostRequestJobsFilesByIdDeleteByJobUuidAndFileUuidMutationResult =
    NonNullable<
        Awaited<
            ReturnType<
                typeof postRequestJobsFilesByIdDeleteByJobUuidAndFileUuid
            >
        >
    >

export type PostRequestJobsFilesByIdDeleteByJobUuidAndFileUuidMutationError =
    ErrorType<void | HTTPValidationErrorDTO>

/**
 * @deprecated
 * @summary Post File Delete
 */
export const usePostRequestJobsFilesByIdDeleteByJobUuidAndFileUuid = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<
                typeof postRequestJobsFilesByIdDeleteByJobUuidAndFileUuid
            >
        >,
        TError,
        {
            jobUuid: string
            fileUuid: string
            params: PostRequestJobsFilesByIdDeleteByJobUuidAndFileUuidParams
        },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<
        ReturnType<typeof postRequestJobsFilesByIdDeleteByJobUuidAndFileUuid>
    >,
    TError,
    {
        jobUuid: string
        fileUuid: string
        params: PostRequestJobsFilesByIdDeleteByJobUuidAndFileUuidParams
    },
    TContext
> => {
    const mutationOptions =
        getPostRequestJobsFilesByIdDeleteByJobUuidAndFileUuidMutationOptions(
            options,
        )

    return useMutation(mutationOptions)
}

/**
 * Specific action will be executed for the provided reference.
Currently supported actions:
  - canceling user requested account termination

 * @summary Post User Callbacks
 */
export const postRequestUsercallbacksByRefId = (
    refId: string,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<CancelAccountDeletionDTO>(
        {
            url: `/usercallbacks/${encodeURIComponent(String(refId))}`,
            method: 'POST',
            signal,
        },
        options,
    )
}

export const getPostRequestUsercallbacksByRefIdMutationOptions = <
    TError = ErrorType<CancelAccountDeletionDTO | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestUsercallbacksByRefId>>,
        TError,
        { refId: string },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestUsercallbacksByRefId>>,
    TError,
    { refId: string },
    TContext
> => {
    const mutationKey = ['postRequestUsercallbacksByRefId']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postRequestUsercallbacksByRefId>>,
        { refId: string }
    > = (props) => {
        const { refId } = props ?? {}

        return postRequestUsercallbacksByRefId(refId, requestOptions)
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestUsercallbacksByRefIdMutationResult = NonNullable<
    Awaited<ReturnType<typeof postRequestUsercallbacksByRefId>>
>

export type PostRequestUsercallbacksByRefIdMutationError = ErrorType<
    CancelAccountDeletionDTO | HTTPValidationErrorDTO
>

/**
 * @summary Post User Callbacks
 */
export const usePostRequestUsercallbacksByRefId = <
    TError = ErrorType<CancelAccountDeletionDTO | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestUsercallbacksByRefId>>,
        TError,
        { refId: string },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestUsercallbacksByRefId>>,
    TError,
    { refId: string },
    TContext
> => {
    const mutationOptions =
        getPostRequestUsercallbacksByRefIdMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * this will list up the options available for a user

 * @summary Get Option Names
 */
export const getRequestOptions = (
    params: GetRequestOptionsParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<string[]>(
        { url: `/options`, method: 'GET', params, signal },
        options,
    )
}

export const getGetRequestOptionsQueryKey = (
    params: GetRequestOptionsParams,
) => {
    return [`/options`, ...(params ? [params] : [])] as const
}

export const getGetRequestOptionsQueryOptions = <
    TData = Awaited<ReturnType<typeof getRequestOptions>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestOptionsParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestOptions>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {}

    const queryKey =
        queryOptions?.queryKey ?? getGetRequestOptionsQueryKey(params)

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getRequestOptions>>
    > = ({ signal }) => getRequestOptions(params, requestOptions, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof getRequestOptions>>,
        TError,
        TData
    > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRequestOptionsQueryResult = NonNullable<
    Awaited<ReturnType<typeof getRequestOptions>>
>
export type GetRequestOptionsQueryError = ErrorType<HTTPValidationErrorDTO>

export function useGetRequestOptions<
    TData = Awaited<ReturnType<typeof getRequestOptions>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestOptionsParams,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestOptions>>,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestOptions>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestOptions>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestOptions<
    TData = Awaited<ReturnType<typeof getRequestOptions>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestOptionsParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestOptions>>,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestOptions>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestOptions>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestOptions<
    TData = Awaited<ReturnType<typeof getRequestOptions>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestOptionsParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestOptions>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get Option Names
 */

export function useGetRequestOptions<
    TData = Awaited<ReturnType<typeof getRequestOptions>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestOptionsParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestOptions>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions = getGetRequestOptionsQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * this call returns the option_value for a the given option and user

 * @summary Get Option
 */
export const getRequestOptionsByName = (
    name: string,
    params: GetRequestOptionsByNameParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<GetRequestOptionsByName200>(
        {
            url: `/options/${encodeURIComponent(String(name))}`,
            method: 'GET',
            params,
            signal,
        },
        options,
    )
}

export const getGetRequestOptionsByNameQueryKey = (
    name: string,
    params: GetRequestOptionsByNameParams,
) => {
    return [`/options/${name}`, ...(params ? [params] : [])] as const
}

export const getGetRequestOptionsByNameQueryOptions = <
    TData = Awaited<ReturnType<typeof getRequestOptionsByName>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    name: string,
    params: GetRequestOptionsByNameParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestOptionsByName>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {}

    const queryKey =
        queryOptions?.queryKey ??
        getGetRequestOptionsByNameQueryKey(name, params)

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getRequestOptionsByName>>
    > = ({ signal }) =>
        getRequestOptionsByName(name, params, requestOptions, signal)

    return {
        queryKey,
        queryFn,
        enabled: !!name,
        ...queryOptions,
    } as UseQueryOptions<
        Awaited<ReturnType<typeof getRequestOptionsByName>>,
        TError,
        TData
    > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRequestOptionsByNameQueryResult = NonNullable<
    Awaited<ReturnType<typeof getRequestOptionsByName>>
>
export type GetRequestOptionsByNameQueryError =
    ErrorType<HTTPValidationErrorDTO>

export function useGetRequestOptionsByName<
    TData = Awaited<ReturnType<typeof getRequestOptionsByName>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    name: string,
    params: GetRequestOptionsByNameParams,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestOptionsByName>>,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestOptionsByName>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestOptionsByName>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestOptionsByName<
    TData = Awaited<ReturnType<typeof getRequestOptionsByName>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    name: string,
    params: GetRequestOptionsByNameParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestOptionsByName>>,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestOptionsByName>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestOptionsByName>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestOptionsByName<
    TData = Awaited<ReturnType<typeof getRequestOptionsByName>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    name: string,
    params: GetRequestOptionsByNameParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestOptionsByName>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get Option
 */

export function useGetRequestOptionsByName<
    TData = Awaited<ReturnType<typeof getRequestOptionsByName>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    name: string,
    params: GetRequestOptionsByNameParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestOptionsByName>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions = getGetRequestOptionsByNameQueryOptions(
        name,
        params,
        options,
    )

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * this call deletes an option for a user
 * @summary Delete Option
 */
export const deleteRequestOptionsByName = (
    name: string,
    params: DeleteRequestOptionsByNameParams,
    options?: SecondParameter<typeof customInstance>,
) => {
    return customInstance<unknown>(
        {
            url: `/options/${encodeURIComponent(String(name))}`,
            method: 'DELETE',
            params,
        },
        options,
    )
}

export const getDeleteRequestOptionsByNameMutationOptions = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof deleteRequestOptionsByName>>,
        TError,
        { name: string; params: DeleteRequestOptionsByNameParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof deleteRequestOptionsByName>>,
    TError,
    { name: string; params: DeleteRequestOptionsByNameParams },
    TContext
> => {
    const mutationKey = ['deleteRequestOptionsByName']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof deleteRequestOptionsByName>>,
        { name: string; params: DeleteRequestOptionsByNameParams }
    > = (props) => {
        const { name, params } = props ?? {}

        return deleteRequestOptionsByName(name, params, requestOptions)
    }

    return { mutationFn, ...mutationOptions }
}

export type DeleteRequestOptionsByNameMutationResult = NonNullable<
    Awaited<ReturnType<typeof deleteRequestOptionsByName>>
>

export type DeleteRequestOptionsByNameMutationError =
    ErrorType<HTTPValidationErrorDTO>

/**
 * @summary Delete Option
 */
export const useDeleteRequestOptionsByName = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof deleteRequestOptionsByName>>,
        TError,
        { name: string; params: DeleteRequestOptionsByNameParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof deleteRequestOptionsByName>>,
    TError,
    { name: string; params: DeleteRequestOptionsByNameParams },
    TContext
> => {
    const mutationOptions =
        getDeleteRequestOptionsByNameMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * this call updates if exists or inserts a new options for a user

 * @summary Put Option
 */
export const putRequestOptionsByName = (
    name: string,
    params: PutRequestOptionsByNameParams,
    options?: SecondParameter<typeof customInstance>,
) => {
    return customInstance<unknown>(
        {
            url: `/options/${encodeURIComponent(String(name))}`,
            method: 'PUT',
            params,
        },
        options,
    )
}

export const getPutRequestOptionsByNameMutationOptions = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof putRequestOptionsByName>>,
        TError,
        { name: string; params: PutRequestOptionsByNameParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof putRequestOptionsByName>>,
    TError,
    { name: string; params: PutRequestOptionsByNameParams },
    TContext
> => {
    const mutationKey = ['putRequestOptionsByName']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof putRequestOptionsByName>>,
        { name: string; params: PutRequestOptionsByNameParams }
    > = (props) => {
        const { name, params } = props ?? {}

        return putRequestOptionsByName(name, params, requestOptions)
    }

    return { mutationFn, ...mutationOptions }
}

export type PutRequestOptionsByNameMutationResult = NonNullable<
    Awaited<ReturnType<typeof putRequestOptionsByName>>
>

export type PutRequestOptionsByNameMutationError =
    ErrorType<HTTPValidationErrorDTO>

/**
 * @summary Put Option
 */
export const usePutRequestOptionsByName = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof putRequestOptionsByName>>,
        TError,
        { name: string; params: PutRequestOptionsByNameParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof putRequestOptionsByName>>,
    TError,
    { name: string; params: PutRequestOptionsByNameParams },
    TContext
> => {
    const mutationOptions = getPutRequestOptionsByNameMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * This call is used to send client event information. Each message can contain multiple client behavior information.

 * @summary Post Client Event
 */
export const postRequestClientEvent = (
    params: PostRequestClientEventParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<unknown>(
        { url: `/client_event`, method: 'POST', params, signal },
        options,
    )
}

export const getPostRequestClientEventMutationOptions = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestClientEvent>>,
        TError,
        { params: PostRequestClientEventParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestClientEvent>>,
    TError,
    { params: PostRequestClientEventParams },
    TContext
> => {
    const mutationKey = ['postRequestClientEvent']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postRequestClientEvent>>,
        { params: PostRequestClientEventParams }
    > = (props) => {
        const { params } = props ?? {}

        return postRequestClientEvent(params, requestOptions)
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestClientEventMutationResult = NonNullable<
    Awaited<ReturnType<typeof postRequestClientEvent>>
>

export type PostRequestClientEventMutationError =
    ErrorType<void | HTTPValidationErrorDTO>

/**
 * @summary Post Client Event
 */
export const usePostRequestClientEvent = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestClientEvent>>,
        TError,
        { params: PostRequestClientEventParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestClientEvent>>,
    TError,
    { params: PostRequestClientEventParams },
    TContext
> => {
    const mutationOptions = getPostRequestClientEventMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * This call is used to delete viewed memory client events for the user.

 * @summary Delete Memory Client Event
 */
export const deleteRequestClientEventMemoryDeletion = (
    params: DeleteRequestClientEventMemoryDeletionParams,
    options?: SecondParameter<typeof customInstance>,
) => {
    return customInstance<unknown>(
        { url: `/client_event/memory_deletion`, method: 'DELETE', params },
        options,
    )
}

export const getDeleteRequestClientEventMemoryDeletionMutationOptions = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof deleteRequestClientEventMemoryDeletion>>,
        TError,
        { params: DeleteRequestClientEventMemoryDeletionParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof deleteRequestClientEventMemoryDeletion>>,
    TError,
    { params: DeleteRequestClientEventMemoryDeletionParams },
    TContext
> => {
    const mutationKey = ['deleteRequestClientEventMemoryDeletion']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof deleteRequestClientEventMemoryDeletion>>,
        { params: DeleteRequestClientEventMemoryDeletionParams }
    > = (props) => {
        const { params } = props ?? {}

        return deleteRequestClientEventMemoryDeletion(params, requestOptions)
    }

    return { mutationFn, ...mutationOptions }
}

export type DeleteRequestClientEventMemoryDeletionMutationResult = NonNullable<
    Awaited<ReturnType<typeof deleteRequestClientEventMemoryDeletion>>
>

export type DeleteRequestClientEventMemoryDeletionMutationError =
    ErrorType<HTTPValidationErrorDTO>

/**
 * @summary Delete Memory Client Event
 */
export const useDeleteRequestClientEventMemoryDeletion = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof deleteRequestClientEventMemoryDeletion>>,
        TError,
        { params: DeleteRequestClientEventMemoryDeletionParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof deleteRequestClientEventMemoryDeletion>>,
    TError,
    { params: DeleteRequestClientEventMemoryDeletionParams },
    TContext
> => {
    const mutationOptions =
        getDeleteRequestClientEventMemoryDeletionMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * This call is used to upsert account attribute. It will send a client notification of the upsert.

 * @summary Post Account Attribute
 */
export const postRequestAccountAttribute = (
    postRequestAccountAttributeBodies: BodyType<PostRequestAccountAttributeBodies>,
    params: PostRequestAccountAttributeParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<AccountAttributeResponseDTO>(
        {
            url: `/account_attribute`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: postRequestAccountAttributeBodies,
            params,
            signal,
        },
        options,
    )
}

export const getPostRequestAccountAttributeMutationOptions = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestAccountAttribute>>,
        TError,
        {
            data: BodyType<PostRequestAccountAttributeBodies>
            params: PostRequestAccountAttributeParams
        },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestAccountAttribute>>,
    TError,
    {
        data: BodyType<PostRequestAccountAttributeBodies>
        params: PostRequestAccountAttributeParams
    },
    TContext
> => {
    const mutationKey = ['postRequestAccountAttribute']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postRequestAccountAttribute>>,
        {
            data: BodyType<PostRequestAccountAttributeBodies>
            params: PostRequestAccountAttributeParams
        }
    > = (props) => {
        const { data, params } = props ?? {}

        return postRequestAccountAttribute(data, params, requestOptions)
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestAccountAttributeMutationResult = NonNullable<
    Awaited<ReturnType<typeof postRequestAccountAttribute>>
>
export type PostRequestAccountAttributeMutationBody =
    BodyType<PostRequestAccountAttributeBodies>
export type PostRequestAccountAttributeMutationError =
    ErrorType<void | HTTPValidationErrorDTO>

/**
 * @summary Post Account Attribute
 */
export const usePostRequestAccountAttribute = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestAccountAttribute>>,
        TError,
        {
            data: BodyType<PostRequestAccountAttributeBodies>
            params: PostRequestAccountAttributeParams
        },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestAccountAttribute>>,
    TError,
    {
        data: BodyType<PostRequestAccountAttributeBodies>
        params: PostRequestAccountAttributeParams
    },
    TContext
> => {
    const mutationOptions =
        getPostRequestAccountAttributeMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * This call is used to get account attributes of a user

 * @summary Get Account Attributes
 */
export const getRequestAccountAttribute = (
    params: GetRequestAccountAttributeParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<AccountAttributeResponseDTO>(
        { url: `/account_attribute`, method: 'GET', params, signal },
        options,
    )
}

export const getGetRequestAccountAttributeQueryKey = (
    params: GetRequestAccountAttributeParams,
) => {
    return [`/account_attribute`, ...(params ? [params] : [])] as const
}

export const getGetRequestAccountAttributeQueryOptions = <
    TData = Awaited<ReturnType<typeof getRequestAccountAttribute>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestAccountAttributeParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestAccountAttribute>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {}

    const queryKey =
        queryOptions?.queryKey ?? getGetRequestAccountAttributeQueryKey(params)

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getRequestAccountAttribute>>
    > = ({ signal }) =>
        getRequestAccountAttribute(params, requestOptions, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof getRequestAccountAttribute>>,
        TError,
        TData
    > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRequestAccountAttributeQueryResult = NonNullable<
    Awaited<ReturnType<typeof getRequestAccountAttribute>>
>
export type GetRequestAccountAttributeQueryError =
    ErrorType<HTTPValidationErrorDTO>

export function useGetRequestAccountAttribute<
    TData = Awaited<ReturnType<typeof getRequestAccountAttribute>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestAccountAttributeParams,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestAccountAttribute>>,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestAccountAttribute>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestAccountAttribute>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestAccountAttribute<
    TData = Awaited<ReturnType<typeof getRequestAccountAttribute>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestAccountAttributeParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestAccountAttribute>>,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestAccountAttribute>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestAccountAttribute>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestAccountAttribute<
    TData = Awaited<ReturnType<typeof getRequestAccountAttribute>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestAccountAttributeParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestAccountAttribute>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get Account Attributes
 */

export function useGetRequestAccountAttribute<
    TData = Awaited<ReturnType<typeof getRequestAccountAttribute>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestAccountAttributeParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestAccountAttribute>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions = getGetRequestAccountAttributeQueryOptions(
        params,
        options,
    )

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * This call is used to delete account attributes of a user

 * @summary Delete Account Attributes
 */
export const deleteRequestAccountAttribute = (
    params: DeleteRequestAccountAttributeParams,
    options?: SecondParameter<typeof customInstance>,
) => {
    return customInstance<unknown>(
        { url: `/account_attribute`, method: 'DELETE', params },
        options,
    )
}

export const getDeleteRequestAccountAttributeMutationOptions = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof deleteRequestAccountAttribute>>,
        TError,
        { params: DeleteRequestAccountAttributeParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof deleteRequestAccountAttribute>>,
    TError,
    { params: DeleteRequestAccountAttributeParams },
    TContext
> => {
    const mutationKey = ['deleteRequestAccountAttribute']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof deleteRequestAccountAttribute>>,
        { params: DeleteRequestAccountAttributeParams }
    > = (props) => {
        const { params } = props ?? {}

        return deleteRequestAccountAttribute(params, requestOptions)
    }

    return { mutationFn, ...mutationOptions }
}

export type DeleteRequestAccountAttributeMutationResult = NonNullable<
    Awaited<ReturnType<typeof deleteRequestAccountAttribute>>
>

export type DeleteRequestAccountAttributeMutationError =
    ErrorType<void | HTTPValidationErrorDTO>

/**
 * @summary Delete Account Attributes
 */
export const useDeleteRequestAccountAttribute = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof deleteRequestAccountAttribute>>,
        TError,
        { params: DeleteRequestAccountAttributeParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof deleteRequestAccountAttribute>>,
    TError,
    { params: DeleteRequestAccountAttributeParams },
    TContext
> => {
    const mutationOptions =
        getDeleteRequestAccountAttributeMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * Delete a job

 * @deprecated
 * @summary Post Job Delete
 */
export const postRequestJobsDeleteByJobUuid = (
    jobUuid: string,
    params: PostRequestJobsDeleteByJobUuidParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<unknown>(
        {
            url: `/jobs/${encodeURIComponent(String(jobUuid))}/delete`,
            method: 'POST',
            params,
            signal,
        },
        options,
    )
}

export const getPostRequestJobsDeleteByJobUuidMutationOptions = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestJobsDeleteByJobUuid>>,
        TError,
        { jobUuid: string; params: PostRequestJobsDeleteByJobUuidParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestJobsDeleteByJobUuid>>,
    TError,
    { jobUuid: string; params: PostRequestJobsDeleteByJobUuidParams },
    TContext
> => {
    const mutationKey = ['postRequestJobsDeleteByJobUuid']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postRequestJobsDeleteByJobUuid>>,
        { jobUuid: string; params: PostRequestJobsDeleteByJobUuidParams }
    > = (props) => {
        const { jobUuid, params } = props ?? {}

        return postRequestJobsDeleteByJobUuid(jobUuid, params, requestOptions)
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestJobsDeleteByJobUuidMutationResult = NonNullable<
    Awaited<ReturnType<typeof postRequestJobsDeleteByJobUuid>>
>

export type PostRequestJobsDeleteByJobUuidMutationError =
    ErrorType<void | HTTPValidationErrorDTO>

/**
 * @deprecated
 * @summary Post Job Delete
 */
export const usePostRequestJobsDeleteByJobUuid = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestJobsDeleteByJobUuid>>,
        TError,
        { jobUuid: string; params: PostRequestJobsDeleteByJobUuidParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestJobsDeleteByJobUuid>>,
    TError,
    { jobUuid: string; params: PostRequestJobsDeleteByJobUuidParams },
    TContext
> => {
    const mutationOptions =
        getPostRequestJobsDeleteByJobUuidMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * This call is used to delete user credit card.

 * @summary Delete Cc Stripe
 */
export const deleteRequestStripeUserCreditCard = (
    params: DeleteRequestStripeUserCreditCardParams,
    options?: SecondParameter<typeof customInstance>,
) => {
    return customInstance<StripeDeleteCCResponseDTO>(
        { url: `/stripe_user_credit_card`, method: 'DELETE', params },
        options,
    )
}

export const getDeleteRequestStripeUserCreditCardMutationOptions = <
    TError = ErrorType<
        void | StripeDeleteCCResponseDTO | HTTPValidationErrorDTO
    >,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof deleteRequestStripeUserCreditCard>>,
        TError,
        { params: DeleteRequestStripeUserCreditCardParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof deleteRequestStripeUserCreditCard>>,
    TError,
    { params: DeleteRequestStripeUserCreditCardParams },
    TContext
> => {
    const mutationKey = ['deleteRequestStripeUserCreditCard']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof deleteRequestStripeUserCreditCard>>,
        { params: DeleteRequestStripeUserCreditCardParams }
    > = (props) => {
        const { params } = props ?? {}

        return deleteRequestStripeUserCreditCard(params, requestOptions)
    }

    return { mutationFn, ...mutationOptions }
}

export type DeleteRequestStripeUserCreditCardMutationResult = NonNullable<
    Awaited<ReturnType<typeof deleteRequestStripeUserCreditCard>>
>

export type DeleteRequestStripeUserCreditCardMutationError = ErrorType<
    void | StripeDeleteCCResponseDTO | HTTPValidationErrorDTO
>

/**
 * @summary Delete Cc Stripe
 */
export const useDeleteRequestStripeUserCreditCard = <
    TError = ErrorType<
        void | StripeDeleteCCResponseDTO | HTTPValidationErrorDTO
    >,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof deleteRequestStripeUserCreditCard>>,
        TError,
        { params: DeleteRequestStripeUserCreditCardParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof deleteRequestStripeUserCreditCard>>,
    TError,
    { params: DeleteRequestStripeUserCreditCardParams },
    TContext
> => {
    const mutationOptions =
        getDeleteRequestStripeUserCreditCardMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * This call saves all the files from a story or share to a timeline.
Before attempting to keep any files, it will first check if the
user has sufficient storage space available to save all the files.
Note that it is possible to pass this initial test,
but still receive a 413 if files are added to the account in question
while this call is processed.

 * @summary Keep All Files
 */
export const postRequestJobsKeepAllFilesByJobUuid = (
    jobUuid: string,
    params: PostRequestJobsKeepAllFilesByJobUuidParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<unknown>(
        {
            url: `/jobs/${encodeURIComponent(String(jobUuid))}/keep-all-files`,
            method: 'POST',
            params,
            signal,
        },
        options,
    )
}

export const getPostRequestJobsKeepAllFilesByJobUuidMutationOptions = <
    TError = ErrorType<
        | void
        | MessageResponseDTO
        | KeepFilesQuotaExceededDTO
        | HTTPValidationErrorDTO
    >,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestJobsKeepAllFilesByJobUuid>>,
        TError,
        { jobUuid: string; params: PostRequestJobsKeepAllFilesByJobUuidParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestJobsKeepAllFilesByJobUuid>>,
    TError,
    { jobUuid: string; params: PostRequestJobsKeepAllFilesByJobUuidParams },
    TContext
> => {
    const mutationKey = ['postRequestJobsKeepAllFilesByJobUuid']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postRequestJobsKeepAllFilesByJobUuid>>,
        { jobUuid: string; params: PostRequestJobsKeepAllFilesByJobUuidParams }
    > = (props) => {
        const { jobUuid, params } = props ?? {}

        return postRequestJobsKeepAllFilesByJobUuid(
            jobUuid,
            params,
            requestOptions,
        )
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestJobsKeepAllFilesByJobUuidMutationResult = NonNullable<
    Awaited<ReturnType<typeof postRequestJobsKeepAllFilesByJobUuid>>
>

export type PostRequestJobsKeepAllFilesByJobUuidMutationError = ErrorType<
    | void
    | MessageResponseDTO
    | KeepFilesQuotaExceededDTO
    | HTTPValidationErrorDTO
>

/**
 * @summary Keep All Files
 */
export const usePostRequestJobsKeepAllFilesByJobUuid = <
    TError = ErrorType<
        | void
        | MessageResponseDTO
        | KeepFilesQuotaExceededDTO
        | HTTPValidationErrorDTO
    >,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestJobsKeepAllFilesByJobUuid>>,
        TError,
        { jobUuid: string; params: PostRequestJobsKeepAllFilesByJobUuidParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestJobsKeepAllFilesByJobUuid>>,
    TError,
    { jobUuid: string; params: PostRequestJobsKeepAllFilesByJobUuidParams },
    TContext
> => {
    const mutationOptions =
        getPostRequestJobsKeepAllFilesByJobUuidMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * This call keeps all specified files from a story or share to a timeline
Before attempting to keep any files, it will first check if the
user has sufficient storage space available to save all the files.
Note that it is possible to pass this initial test,
but still receive a 413, if files are added to the account in question
while this call is processed.
If any of the files specified is the master of a group,
all group members will be kept as well

 * @summary Keep Files
 */
export const postRequestJobsKeepFilesByJobUuid = (
    jobUuid: BodyType<string>,
    postRequestJobsKeepFilesByJobUuidBodies: string,
    params: PostRequestJobsKeepFilesByJobUuidParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<PostRequestJobsKeepFilesByJobUuid200>(
        {
            url: `/jobs/${encodeURIComponent(String(jobUuid))}/keep-files`,
            method: 'POST',
            headers: { 'Content-Type': 'text/plain' },
            data: postRequestJobsKeepFilesByJobUuidBodies,
            params,
            signal,
        },
        options,
    )
}

export const getPostRequestJobsKeepFilesByJobUuidMutationOptions = <
    TError = ErrorType<
        | void
        | MessageResponseDTO
        | KeepFilesQuotaExceededDTO
        | HTTPValidationErrorDTO
    >,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestJobsKeepFilesByJobUuid>>,
        TError,
        {
            jobUuid: string
            data: BodyType<string>
            params: PostRequestJobsKeepFilesByJobUuidParams
        },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestJobsKeepFilesByJobUuid>>,
    TError,
    {
        jobUuid: string
        data: BodyType<string>
        params: PostRequestJobsKeepFilesByJobUuidParams
    },
    TContext
> => {
    const mutationKey = ['postRequestJobsKeepFilesByJobUuid']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postRequestJobsKeepFilesByJobUuid>>,
        {
            jobUuid: string
            data: BodyType<string>
            params: PostRequestJobsKeepFilesByJobUuidParams
        }
    > = (props) => {
        const { jobUuid, data, params } = props ?? {}

        return postRequestJobsKeepFilesByJobUuid(
            jobUuid,
            data,
            params,
            requestOptions,
        )
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestJobsKeepFilesByJobUuidMutationResult = NonNullable<
    Awaited<ReturnType<typeof postRequestJobsKeepFilesByJobUuid>>
>
export type PostRequestJobsKeepFilesByJobUuidMutationBody = BodyType<string>
export type PostRequestJobsKeepFilesByJobUuidMutationError = ErrorType<
    | void
    | MessageResponseDTO
    | KeepFilesQuotaExceededDTO
    | HTTPValidationErrorDTO
>

/**
 * @summary Keep Files
 */
export const usePostRequestJobsKeepFilesByJobUuid = <
    TError = ErrorType<
        | void
        | MessageResponseDTO
        | KeepFilesQuotaExceededDTO
        | HTTPValidationErrorDTO
    >,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestJobsKeepFilesByJobUuid>>,
        TError,
        {
            jobUuid: string
            data: BodyType<string>
            params: PostRequestJobsKeepFilesByJobUuidParams
        },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestJobsKeepFilesByJobUuid>>,
    TError,
    {
        jobUuid: string
        data: BodyType<string>
        params: PostRequestJobsKeepFilesByJobUuidParams
    },
    TContext
> => {
    const mutationOptions =
        getPostRequestJobsKeepFilesByJobUuidMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * Approve the provided ToS version

 * @summary Post Approve Tos
 */
export const postRequestApproveTos = (
    params: PostRequestApproveTosParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<unknown>(
        { url: `/approve_tos`, method: 'POST', params, signal },
        options,
    )
}

export const getPostRequestApproveTosMutationOptions = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestApproveTos>>,
        TError,
        { params: PostRequestApproveTosParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestApproveTos>>,
    TError,
    { params: PostRequestApproveTosParams },
    TContext
> => {
    const mutationKey = ['postRequestApproveTos']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postRequestApproveTos>>,
        { params: PostRequestApproveTosParams }
    > = (props) => {
        const { params } = props ?? {}

        return postRequestApproveTos(params, requestOptions)
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestApproveTosMutationResult = NonNullable<
    Awaited<ReturnType<typeof postRequestApproveTos>>
>

export type PostRequestApproveTosMutationError =
    ErrorType<void | HTTPValidationErrorDTO>

/**
 * @summary Post Approve Tos
 */
export const usePostRequestApproveTos = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestApproveTos>>,
        TError,
        { params: PostRequestApproveTosParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestApproveTos>>,
    TError,
    { params: PostRequestApproveTosParams },
    TContext
> => {
    const mutationOptions = getPostRequestApproveTosMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * Set the allowed recipients of a shared job.
The users can be provided by the recipients user_id or with an msisdn or email
associated with the recipients TelenorID account.
If an msisdn or email not associated with a TelenorID account is provided, a temporary link is created
which can be used by the recipient when a TelenorID account is created and the share is accessed.

 * @summary Post Recipients
 */
export const postRequestJobsRecipientsByJobUuid = (
    jobUuid: string,
    recipientListDTO: BodyType<RecipientListDTO>,
    params: PostRequestJobsRecipientsByJobUuidParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<SetJobRecipientResponseDTO>(
        {
            url: `/jobs/${encodeURIComponent(String(jobUuid))}/recipients`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: recipientListDTO,
            params,
            signal,
        },
        options,
    )
}

export const getPostRequestJobsRecipientsByJobUuidMutationOptions = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestJobsRecipientsByJobUuid>>,
        TError,
        {
            jobUuid: string
            data: BodyType<RecipientListDTO>
            params: PostRequestJobsRecipientsByJobUuidParams
        },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestJobsRecipientsByJobUuid>>,
    TError,
    {
        jobUuid: string
        data: BodyType<RecipientListDTO>
        params: PostRequestJobsRecipientsByJobUuidParams
    },
    TContext
> => {
    const mutationKey = ['postRequestJobsRecipientsByJobUuid']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postRequestJobsRecipientsByJobUuid>>,
        {
            jobUuid: string
            data: BodyType<RecipientListDTO>
            params: PostRequestJobsRecipientsByJobUuidParams
        }
    > = (props) => {
        const { jobUuid, data, params } = props ?? {}

        return postRequestJobsRecipientsByJobUuid(
            jobUuid,
            data,
            params,
            requestOptions,
        )
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestJobsRecipientsByJobUuidMutationResult = NonNullable<
    Awaited<ReturnType<typeof postRequestJobsRecipientsByJobUuid>>
>
export type PostRequestJobsRecipientsByJobUuidMutationBody =
    BodyType<RecipientListDTO>
export type PostRequestJobsRecipientsByJobUuidMutationError =
    ErrorType<void | HTTPValidationErrorDTO>

/**
 * @summary Post Recipients
 */
export const usePostRequestJobsRecipientsByJobUuid = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestJobsRecipientsByJobUuid>>,
        TError,
        {
            jobUuid: string
            data: BodyType<RecipientListDTO>
            params: PostRequestJobsRecipientsByJobUuidParams
        },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestJobsRecipientsByJobUuid>>,
    TError,
    {
        jobUuid: string
        data: BodyType<RecipientListDTO>
        params: PostRequestJobsRecipientsByJobUuidParams
    },
    TContext
> => {
    const mutationOptions =
        getPostRequestJobsRecipientsByJobUuidMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * Set the expiration date of a shared job.
This overrides the default share lifetime of 30 days

 * @summary Post Job Expiration
 */
export const postRequestJobsExpirationDateByJobUuid = (
    jobUuid: string,
    shareExpirationDTO: BodyType<ShareExpirationDTO>,
    params: PostRequestJobsExpirationDateByJobUuidParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<unknown>(
        {
            url: `/jobs/${encodeURIComponent(String(jobUuid))}/expiration_date`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: shareExpirationDTO,
            params,
            signal,
        },
        options,
    )
}

export const getPostRequestJobsExpirationDateByJobUuidMutationOptions = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestJobsExpirationDateByJobUuid>>,
        TError,
        {
            jobUuid: string
            data: BodyType<ShareExpirationDTO>
            params: PostRequestJobsExpirationDateByJobUuidParams
        },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestJobsExpirationDateByJobUuid>>,
    TError,
    {
        jobUuid: string
        data: BodyType<ShareExpirationDTO>
        params: PostRequestJobsExpirationDateByJobUuidParams
    },
    TContext
> => {
    const mutationKey = ['postRequestJobsExpirationDateByJobUuid']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postRequestJobsExpirationDateByJobUuid>>,
        {
            jobUuid: string
            data: BodyType<ShareExpirationDTO>
            params: PostRequestJobsExpirationDateByJobUuidParams
        }
    > = (props) => {
        const { jobUuid, data, params } = props ?? {}

        return postRequestJobsExpirationDateByJobUuid(
            jobUuid,
            data,
            params,
            requestOptions,
        )
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestJobsExpirationDateByJobUuidMutationResult = NonNullable<
    Awaited<ReturnType<typeof postRequestJobsExpirationDateByJobUuid>>
>
export type PostRequestJobsExpirationDateByJobUuidMutationBody =
    BodyType<ShareExpirationDTO>
export type PostRequestJobsExpirationDateByJobUuidMutationError =
    ErrorType<void | HTTPValidationErrorDTO>

/**
 * @summary Post Job Expiration
 */
export const usePostRequestJobsExpirationDateByJobUuid = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestJobsExpirationDateByJobUuid>>,
        TError,
        {
            jobUuid: string
            data: BodyType<ShareExpirationDTO>
            params: PostRequestJobsExpirationDateByJobUuidParams
        },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestJobsExpirationDateByJobUuid>>,
    TError,
    {
        jobUuid: string
        data: BodyType<ShareExpirationDTO>
        params: PostRequestJobsExpirationDateByJobUuidParams
    },
    TContext
> => {
    const mutationOptions =
        getPostRequestJobsExpirationDateByJobUuidMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * this call is used to change the password for a story job.

 * @summary Post Job Password
 */
export const postRequestJobsPasswordByJobUuid = (
    jobUuid: string,
    params: PostRequestJobsPasswordByJobUuidParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<unknown>(
        {
            url: `/jobs/${encodeURIComponent(String(jobUuid))}/password`,
            method: 'POST',
            params,
            signal,
        },
        options,
    )
}

export const getPostRequestJobsPasswordByJobUuidMutationOptions = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestJobsPasswordByJobUuid>>,
        TError,
        { jobUuid: string; params: PostRequestJobsPasswordByJobUuidParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestJobsPasswordByJobUuid>>,
    TError,
    { jobUuid: string; params: PostRequestJobsPasswordByJobUuidParams },
    TContext
> => {
    const mutationKey = ['postRequestJobsPasswordByJobUuid']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postRequestJobsPasswordByJobUuid>>,
        { jobUuid: string; params: PostRequestJobsPasswordByJobUuidParams }
    > = (props) => {
        const { jobUuid, params } = props ?? {}

        return postRequestJobsPasswordByJobUuid(jobUuid, params, requestOptions)
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestJobsPasswordByJobUuidMutationResult = NonNullable<
    Awaited<ReturnType<typeof postRequestJobsPasswordByJobUuid>>
>

export type PostRequestJobsPasswordByJobUuidMutationError =
    ErrorType<void | HTTPValidationErrorDTO>

/**
 * @summary Post Job Password
 */
export const usePostRequestJobsPasswordByJobUuid = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestJobsPasswordByJobUuid>>,
        TError,
        { jobUuid: string; params: PostRequestJobsPasswordByJobUuidParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestJobsPasswordByJobUuid>>,
    TError,
    { jobUuid: string; params: PostRequestJobsPasswordByJobUuidParams },
    TContext
> => {
    const mutationOptions =
        getPostRequestJobsPasswordByJobUuidMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary Post Web Client Stats
 */
export const postRequestWebClientStats = (
    webClientStatsDTO: BodyType<WebClientStatsDTO>,
    params: PostRequestWebClientStatsParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<unknown>(
        {
            url: `/web_client_stats`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: webClientStatsDTO,
            params,
            signal,
        },
        options,
    )
}

export const getPostRequestWebClientStatsMutationOptions = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestWebClientStats>>,
        TError,
        {
            data: BodyType<WebClientStatsDTO>
            params: PostRequestWebClientStatsParams
        },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestWebClientStats>>,
    TError,
    {
        data: BodyType<WebClientStatsDTO>
        params: PostRequestWebClientStatsParams
    },
    TContext
> => {
    const mutationKey = ['postRequestWebClientStats']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postRequestWebClientStats>>,
        {
            data: BodyType<WebClientStatsDTO>
            params: PostRequestWebClientStatsParams
        }
    > = (props) => {
        const { data, params } = props ?? {}

        return postRequestWebClientStats(data, params, requestOptions)
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestWebClientStatsMutationResult = NonNullable<
    Awaited<ReturnType<typeof postRequestWebClientStats>>
>
export type PostRequestWebClientStatsMutationBody = BodyType<WebClientStatsDTO>
export type PostRequestWebClientStatsMutationError =
    ErrorType<HTTPValidationErrorDTO>

/**
 * @summary Post Web Client Stats
 */
export const usePostRequestWebClientStats = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestWebClientStats>>,
        TError,
        {
            data: BodyType<WebClientStatsDTO>
            params: PostRequestWebClientStatsParams
        },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestWebClientStats>>,
    TError,
    {
        data: BodyType<WebClientStatsDTO>
        params: PostRequestWebClientStatsParams
    },
    TContext
> => {
    const mutationOptions = getPostRequestWebClientStatsMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * this call updates the client status

 * @summary Client Stats
 */
export const postRequestClientStats = (
    postRequestClientStatsBodies: BodyType<PostRequestClientStatsBodies>,
    params: PostRequestClientStatsParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<unknown>(
        {
            url: `/client_stats`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: postRequestClientStatsBodies,
            params,
            signal,
        },
        options,
    )
}

export const getPostRequestClientStatsMutationOptions = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestClientStats>>,
        TError,
        {
            data: BodyType<PostRequestClientStatsBodies>
            params: PostRequestClientStatsParams
        },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestClientStats>>,
    TError,
    {
        data: BodyType<PostRequestClientStatsBodies>
        params: PostRequestClientStatsParams
    },
    TContext
> => {
    const mutationKey = ['postRequestClientStats']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postRequestClientStats>>,
        {
            data: BodyType<PostRequestClientStatsBodies>
            params: PostRequestClientStatsParams
        }
    > = (props) => {
        const { data, params } = props ?? {}

        return postRequestClientStats(data, params, requestOptions)
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestClientStatsMutationResult = NonNullable<
    Awaited<ReturnType<typeof postRequestClientStats>>
>
export type PostRequestClientStatsMutationBody =
    BodyType<PostRequestClientStatsBodies>
export type PostRequestClientStatsMutationError =
    ErrorType<void | HTTPValidationErrorDTO>

/**
 * @summary Client Stats
 */
export const usePostRequestClientStats = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestClientStats>>,
        TError,
        {
            data: BodyType<PostRequestClientStatsBodies>
            params: PostRequestClientStatsParams
        },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestClientStats>>,
    TError,
    {
        data: BodyType<PostRequestClientStatsBodies>
        params: PostRequestClientStatsParams
    },
    TContext
> => {
    const mutationOptions = getPostRequestClientStatsMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * this call sets the cover id on a story job

 * @summary Post Job Cover
 */
export const postRequestJobsCoverByJobUuid = (
    jobUuid: string,
    params: PostRequestJobsCoverByJobUuidParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<unknown>(
        {
            url: `/jobs/${encodeURIComponent(String(jobUuid))}/cover`,
            method: 'POST',
            params,
            signal,
        },
        options,
    )
}

export const getPostRequestJobsCoverByJobUuidMutationOptions = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestJobsCoverByJobUuid>>,
        TError,
        { jobUuid: string; params: PostRequestJobsCoverByJobUuidParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestJobsCoverByJobUuid>>,
    TError,
    { jobUuid: string; params: PostRequestJobsCoverByJobUuidParams },
    TContext
> => {
    const mutationKey = ['postRequestJobsCoverByJobUuid']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postRequestJobsCoverByJobUuid>>,
        { jobUuid: string; params: PostRequestJobsCoverByJobUuidParams }
    > = (props) => {
        const { jobUuid, params } = props ?? {}

        return postRequestJobsCoverByJobUuid(jobUuid, params, requestOptions)
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestJobsCoverByJobUuidMutationResult = NonNullable<
    Awaited<ReturnType<typeof postRequestJobsCoverByJobUuid>>
>

export type PostRequestJobsCoverByJobUuidMutationError =
    ErrorType<void | HTTPValidationErrorDTO>

/**
 * @summary Post Job Cover
 */
export const usePostRequestJobsCoverByJobUuid = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestJobsCoverByJobUuid>>,
        TError,
        { jobUuid: string; params: PostRequestJobsCoverByJobUuidParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestJobsCoverByJobUuid>>,
    TError,
    { jobUuid: string; params: PostRequestJobsCoverByJobUuidParams },
    TContext
> => {
    const mutationOptions =
        getPostRequestJobsCoverByJobUuidMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * this call deletes the cover id on a story job

 * @summary Delete Job Cover
 */
export const deleteRequestJobsCoverByJobUuid = (
    jobUuid: string,
    params: DeleteRequestJobsCoverByJobUuidParams,
    options?: SecondParameter<typeof customInstance>,
) => {
    return customInstance<unknown>(
        {
            url: `/jobs/${encodeURIComponent(String(jobUuid))}/cover`,
            method: 'DELETE',
            params,
        },
        options,
    )
}

export const getDeleteRequestJobsCoverByJobUuidMutationOptions = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof deleteRequestJobsCoverByJobUuid>>,
        TError,
        { jobUuid: string; params: DeleteRequestJobsCoverByJobUuidParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof deleteRequestJobsCoverByJobUuid>>,
    TError,
    { jobUuid: string; params: DeleteRequestJobsCoverByJobUuidParams },
    TContext
> => {
    const mutationKey = ['deleteRequestJobsCoverByJobUuid']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof deleteRequestJobsCoverByJobUuid>>,
        { jobUuid: string; params: DeleteRequestJobsCoverByJobUuidParams }
    > = (props) => {
        const { jobUuid, params } = props ?? {}

        return deleteRequestJobsCoverByJobUuid(jobUuid, params, requestOptions)
    }

    return { mutationFn, ...mutationOptions }
}

export type DeleteRequestJobsCoverByJobUuidMutationResult = NonNullable<
    Awaited<ReturnType<typeof deleteRequestJobsCoverByJobUuid>>
>

export type DeleteRequestJobsCoverByJobUuidMutationError =
    ErrorType<void | HTTPValidationErrorDTO>

/**
 * @summary Delete Job Cover
 */
export const useDeleteRequestJobsCoverByJobUuid = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof deleteRequestJobsCoverByJobUuid>>,
        TError,
        { jobUuid: string; params: DeleteRequestJobsCoverByJobUuidParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof deleteRequestJobsCoverByJobUuid>>,
    TError,
    { jobUuid: string; params: DeleteRequestJobsCoverByJobUuidParams },
    TContext
> => {
    const mutationOptions =
        getDeleteRequestJobsCoverByJobUuidMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * this call is used to change the title for shared and story jobs.

 * @summary Post Job Name
 */
export const postRequestJobsNameByJobUuid = (
    jobUuid: string,
    params: PostRequestJobsNameByJobUuidParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<unknown>(
        {
            url: `/jobs/${encodeURIComponent(String(jobUuid))}/name`,
            method: 'POST',
            params,
            signal,
        },
        options,
    )
}

export const getPostRequestJobsNameByJobUuidMutationOptions = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestJobsNameByJobUuid>>,
        TError,
        { jobUuid: string; params: PostRequestJobsNameByJobUuidParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestJobsNameByJobUuid>>,
    TError,
    { jobUuid: string; params: PostRequestJobsNameByJobUuidParams },
    TContext
> => {
    const mutationKey = ['postRequestJobsNameByJobUuid']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postRequestJobsNameByJobUuid>>,
        { jobUuid: string; params: PostRequestJobsNameByJobUuidParams }
    > = (props) => {
        const { jobUuid, params } = props ?? {}

        return postRequestJobsNameByJobUuid(jobUuid, params, requestOptions)
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestJobsNameByJobUuidMutationResult = NonNullable<
    Awaited<ReturnType<typeof postRequestJobsNameByJobUuid>>
>

export type PostRequestJobsNameByJobUuidMutationError =
    ErrorType<void | HTTPValidationErrorDTO>

/**
 * @summary Post Job Name
 */
export const usePostRequestJobsNameByJobUuid = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestJobsNameByJobUuid>>,
        TError,
        { jobUuid: string; params: PostRequestJobsNameByJobUuidParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestJobsNameByJobUuid>>,
    TError,
    { jobUuid: string; params: PostRequestJobsNameByJobUuidParams },
    TContext
> => {
    const mutationOptions =
        getPostRequestJobsNameByJobUuidMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * this call is used to update the password for a user to a story

 * @summary Post Job Store Password
 */
export const postRequestJobsStorePasswordByJobUuid = (
    jobUuid: string,
    params: PostRequestJobsStorePasswordByJobUuidParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<unknown>(
        {
            url: `/jobs/${encodeURIComponent(String(jobUuid))}/store-password`,
            method: 'POST',
            params,
            signal,
        },
        options,
    )
}

export const getPostRequestJobsStorePasswordByJobUuidMutationOptions = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestJobsStorePasswordByJobUuid>>,
        TError,
        {
            jobUuid: string
            params: PostRequestJobsStorePasswordByJobUuidParams
        },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestJobsStorePasswordByJobUuid>>,
    TError,
    { jobUuid: string; params: PostRequestJobsStorePasswordByJobUuidParams },
    TContext
> => {
    const mutationKey = ['postRequestJobsStorePasswordByJobUuid']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postRequestJobsStorePasswordByJobUuid>>,
        { jobUuid: string; params: PostRequestJobsStorePasswordByJobUuidParams }
    > = (props) => {
        const { jobUuid, params } = props ?? {}

        return postRequestJobsStorePasswordByJobUuid(
            jobUuid,
            params,
            requestOptions,
        )
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestJobsStorePasswordByJobUuidMutationResult = NonNullable<
    Awaited<ReturnType<typeof postRequestJobsStorePasswordByJobUuid>>
>

export type PostRequestJobsStorePasswordByJobUuidMutationError =
    ErrorType<void | HTTPValidationErrorDTO>

/**
 * @summary Post Job Store Password
 */
export const usePostRequestJobsStorePasswordByJobUuid = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestJobsStorePasswordByJobUuid>>,
        TError,
        {
            jobUuid: string
            params: PostRequestJobsStorePasswordByJobUuidParams
        },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestJobsStorePasswordByJobUuid>>,
    TError,
    { jobUuid: string; params: PostRequestJobsStorePasswordByJobUuidParams },
    TContext
> => {
    const mutationOptions =
        getPostRequestJobsStorePasswordByJobUuidMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary Get Metatags Inject
 */
export const getRequestMetatagsByJobUuid = (
    jobUuid: string,
    params: GetRequestMetatagsByJobUuidParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<string>(
        {
            url: `/metatags/${encodeURIComponent(String(jobUuid))}`,
            method: 'GET',
            params,
            signal,
        },
        options,
    )
}

export const getGetRequestMetatagsByJobUuidQueryKey = (
    jobUuid: string,
    params: GetRequestMetatagsByJobUuidParams,
) => {
    return [`/metatags/${jobUuid}`, ...(params ? [params] : [])] as const
}

export const getGetRequestMetatagsByJobUuidQueryOptions = <
    TData = Awaited<ReturnType<typeof getRequestMetatagsByJobUuid>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestMetatagsByJobUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestMetatagsByJobUuid>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {}

    const queryKey =
        queryOptions?.queryKey ??
        getGetRequestMetatagsByJobUuidQueryKey(jobUuid, params)

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getRequestMetatagsByJobUuid>>
    > = ({ signal }) =>
        getRequestMetatagsByJobUuid(jobUuid, params, requestOptions, signal)

    return {
        queryKey,
        queryFn,
        enabled: !!jobUuid,
        ...queryOptions,
    } as UseQueryOptions<
        Awaited<ReturnType<typeof getRequestMetatagsByJobUuid>>,
        TError,
        TData
    > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRequestMetatagsByJobUuidQueryResult = NonNullable<
    Awaited<ReturnType<typeof getRequestMetatagsByJobUuid>>
>
export type GetRequestMetatagsByJobUuidQueryError =
    ErrorType<HTTPValidationErrorDTO>

export function useGetRequestMetatagsByJobUuid<
    TData = Awaited<ReturnType<typeof getRequestMetatagsByJobUuid>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestMetatagsByJobUuidParams,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestMetatagsByJobUuid>>,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestMetatagsByJobUuid>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestMetatagsByJobUuid>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestMetatagsByJobUuid<
    TData = Awaited<ReturnType<typeof getRequestMetatagsByJobUuid>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestMetatagsByJobUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestMetatagsByJobUuid>>,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestMetatagsByJobUuid>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestMetatagsByJobUuid>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestMetatagsByJobUuid<
    TData = Awaited<ReturnType<typeof getRequestMetatagsByJobUuid>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestMetatagsByJobUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestMetatagsByJobUuid>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get Metatags Inject
 */

export function useGetRequestMetatagsByJobUuid<
    TData = Awaited<ReturnType<typeof getRequestMetatagsByJobUuid>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestMetatagsByJobUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestMetatagsByJobUuid>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions = getGetRequestMetatagsByJobUuidQueryOptions(
        jobUuid,
        params,
        options,
    )

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * It authorizes request from search backend and returns user's
search attributes or .

 * @summary Get Search Verify Job Access
 */
export const getRequestSearchVerifyByJobUuid = (
    jobUuid: string,
    params: GetRequestSearchVerifyByJobUuidParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<SearchUserAttrsDTO>(
        {
            url: `/search/verify/${encodeURIComponent(String(jobUuid))}`,
            method: 'GET',
            params,
            signal,
        },
        options,
    )
}

export const getGetRequestSearchVerifyByJobUuidQueryKey = (
    jobUuid: string,
    params: GetRequestSearchVerifyByJobUuidParams,
) => {
    return [`/search/verify/${jobUuid}`, ...(params ? [params] : [])] as const
}

export const getGetRequestSearchVerifyByJobUuidQueryOptions = <
    TData = Awaited<ReturnType<typeof getRequestSearchVerifyByJobUuid>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestSearchVerifyByJobUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestSearchVerifyByJobUuid>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {}

    const queryKey =
        queryOptions?.queryKey ??
        getGetRequestSearchVerifyByJobUuidQueryKey(jobUuid, params)

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getRequestSearchVerifyByJobUuid>>
    > = ({ signal }) =>
        getRequestSearchVerifyByJobUuid(jobUuid, params, requestOptions, signal)

    return {
        queryKey,
        queryFn,
        enabled: !!jobUuid,
        ...queryOptions,
    } as UseQueryOptions<
        Awaited<ReturnType<typeof getRequestSearchVerifyByJobUuid>>,
        TError,
        TData
    > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRequestSearchVerifyByJobUuidQueryResult = NonNullable<
    Awaited<ReturnType<typeof getRequestSearchVerifyByJobUuid>>
>
export type GetRequestSearchVerifyByJobUuidQueryError =
    ErrorType<void | HTTPValidationErrorDTO>

export function useGetRequestSearchVerifyByJobUuid<
    TData = Awaited<ReturnType<typeof getRequestSearchVerifyByJobUuid>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestSearchVerifyByJobUuidParams,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestSearchVerifyByJobUuid>>,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestSearchVerifyByJobUuid>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestSearchVerifyByJobUuid>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestSearchVerifyByJobUuid<
    TData = Awaited<ReturnType<typeof getRequestSearchVerifyByJobUuid>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestSearchVerifyByJobUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestSearchVerifyByJobUuid>>,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestSearchVerifyByJobUuid>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestSearchVerifyByJobUuid>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestSearchVerifyByJobUuid<
    TData = Awaited<ReturnType<typeof getRequestSearchVerifyByJobUuid>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestSearchVerifyByJobUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestSearchVerifyByJobUuid>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get Search Verify Job Access
 */

export function useGetRequestSearchVerifyByJobUuid<
    TData = Awaited<ReturnType<typeof getRequestSearchVerifyByJobUuid>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestSearchVerifyByJobUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestSearchVerifyByJobUuid>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions = getGetRequestSearchVerifyByJobUuidQueryOptions(
        jobUuid,
        params,
        options,
    )

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * It authorizes request from search backend and returns user's
search attributes.

 * @summary Get Search Verify User Access
 */
export const getRequestSearchVerifyUserAccess = (
    params: GetRequestSearchVerifyUserAccessParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<SearchUserAttrsDTO>(
        { url: `/search/verify_user_access`, method: 'GET', params, signal },
        options,
    )
}

export const getGetRequestSearchVerifyUserAccessQueryKey = (
    params: GetRequestSearchVerifyUserAccessParams,
) => {
    return [`/search/verify_user_access`, ...(params ? [params] : [])] as const
}

export const getGetRequestSearchVerifyUserAccessQueryOptions = <
    TData = Awaited<ReturnType<typeof getRequestSearchVerifyUserAccess>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    params: GetRequestSearchVerifyUserAccessParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestSearchVerifyUserAccess>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {}

    const queryKey =
        queryOptions?.queryKey ??
        getGetRequestSearchVerifyUserAccessQueryKey(params)

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getRequestSearchVerifyUserAccess>>
    > = ({ signal }) =>
        getRequestSearchVerifyUserAccess(params, requestOptions, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof getRequestSearchVerifyUserAccess>>,
        TError,
        TData
    > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRequestSearchVerifyUserAccessQueryResult = NonNullable<
    Awaited<ReturnType<typeof getRequestSearchVerifyUserAccess>>
>
export type GetRequestSearchVerifyUserAccessQueryError =
    ErrorType<void | HTTPValidationErrorDTO>

export function useGetRequestSearchVerifyUserAccess<
    TData = Awaited<ReturnType<typeof getRequestSearchVerifyUserAccess>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    params: GetRequestSearchVerifyUserAccessParams,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestSearchVerifyUserAccess>>,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<
                        ReturnType<typeof getRequestSearchVerifyUserAccess>
                    >,
                    TError,
                    Awaited<ReturnType<typeof getRequestSearchVerifyUserAccess>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestSearchVerifyUserAccess<
    TData = Awaited<ReturnType<typeof getRequestSearchVerifyUserAccess>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    params: GetRequestSearchVerifyUserAccessParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestSearchVerifyUserAccess>>,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<
                        ReturnType<typeof getRequestSearchVerifyUserAccess>
                    >,
                    TError,
                    Awaited<ReturnType<typeof getRequestSearchVerifyUserAccess>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestSearchVerifyUserAccess<
    TData = Awaited<ReturnType<typeof getRequestSearchVerifyUserAccess>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    params: GetRequestSearchVerifyUserAccessParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestSearchVerifyUserAccess>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get Search Verify User Access
 */

export function useGetRequestSearchVerifyUserAccess<
    TData = Awaited<ReturnType<typeof getRequestSearchVerifyUserAccess>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    params: GetRequestSearchVerifyUserAccessParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestSearchVerifyUserAccess>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions = getGetRequestSearchVerifyUserAccessQueryOptions(
        params,
        options,
    )

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * It is used by to search backend to notify Capture backend about the initial
processing of the corresponding job has been successfully fulfilled.
Successful call clears the `search.processing` remote variables if the
initial processing has been finished.

 * @summary Post Search Notify
 */
export const postRequestSearchNotifyByJobUuid = (
    jobUuid: string,
    notificationModelDTO: BodyType<NotificationModelDTO>,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<SearchUserAttrsDTO>(
        {
            url: `/search/notify/${encodeURIComponent(String(jobUuid))}`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: notificationModelDTO,
            signal,
        },
        options,
    )
}

export const getPostRequestSearchNotifyByJobUuidMutationOptions = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestSearchNotifyByJobUuid>>,
        TError,
        { jobUuid: string; data: BodyType<NotificationModelDTO> },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestSearchNotifyByJobUuid>>,
    TError,
    { jobUuid: string; data: BodyType<NotificationModelDTO> },
    TContext
> => {
    const mutationKey = ['postRequestSearchNotifyByJobUuid']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postRequestSearchNotifyByJobUuid>>,
        { jobUuid: string; data: BodyType<NotificationModelDTO> }
    > = (props) => {
        const { jobUuid, data } = props ?? {}

        return postRequestSearchNotifyByJobUuid(jobUuid, data, requestOptions)
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestSearchNotifyByJobUuidMutationResult = NonNullable<
    Awaited<ReturnType<typeof postRequestSearchNotifyByJobUuid>>
>
export type PostRequestSearchNotifyByJobUuidMutationBody =
    BodyType<NotificationModelDTO>
export type PostRequestSearchNotifyByJobUuidMutationError =
    ErrorType<void | HTTPValidationErrorDTO>

/**
 * @summary Post Search Notify
 */
export const usePostRequestSearchNotifyByJobUuid = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestSearchNotifyByJobUuid>>,
        TError,
        { jobUuid: string; data: BodyType<NotificationModelDTO> },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestSearchNotifyByJobUuid>>,
    TError,
    { jobUuid: string; data: BodyType<NotificationModelDTO> },
    TContext
> => {
    const mutationOptions =
        getPostRequestSearchNotifyByJobUuidMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * It verifies if the job exists, it is a timeline job and it is allowed
to be processed.

 * @summary Get Search User Info
 */
export const getRequestSearchUserInfoByJobUuid = (
    jobUuid: string,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<SearchUserAttrsDTO>(
        {
            url: `/search/user_info/${encodeURIComponent(String(jobUuid))}`,
            method: 'GET',
            signal,
        },
        options,
    )
}

export const getGetRequestSearchUserInfoByJobUuidQueryKey = (
    jobUuid: string,
) => {
    return [`/search/user_info/${jobUuid}`] as const
}

export const getGetRequestSearchUserInfoByJobUuidQueryOptions = <
    TData = Awaited<ReturnType<typeof getRequestSearchUserInfoByJobUuid>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestSearchUserInfoByJobUuid>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {}

    const queryKey =
        queryOptions?.queryKey ??
        getGetRequestSearchUserInfoByJobUuidQueryKey(jobUuid)

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getRequestSearchUserInfoByJobUuid>>
    > = ({ signal }) =>
        getRequestSearchUserInfoByJobUuid(jobUuid, requestOptions, signal)

    return {
        queryKey,
        queryFn,
        enabled: !!jobUuid,
        ...queryOptions,
    } as UseQueryOptions<
        Awaited<ReturnType<typeof getRequestSearchUserInfoByJobUuid>>,
        TError,
        TData
    > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRequestSearchUserInfoByJobUuidQueryResult = NonNullable<
    Awaited<ReturnType<typeof getRequestSearchUserInfoByJobUuid>>
>
export type GetRequestSearchUserInfoByJobUuidQueryError =
    ErrorType<void | HTTPValidationErrorDTO>

export function useGetRequestSearchUserInfoByJobUuid<
    TData = Awaited<ReturnType<typeof getRequestSearchUserInfoByJobUuid>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestSearchUserInfoByJobUuid>>,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<
                        ReturnType<typeof getRequestSearchUserInfoByJobUuid>
                    >,
                    TError,
                    Awaited<
                        ReturnType<typeof getRequestSearchUserInfoByJobUuid>
                    >
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestSearchUserInfoByJobUuid<
    TData = Awaited<ReturnType<typeof getRequestSearchUserInfoByJobUuid>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestSearchUserInfoByJobUuid>>,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<
                        ReturnType<typeof getRequestSearchUserInfoByJobUuid>
                    >,
                    TError,
                    Awaited<
                        ReturnType<typeof getRequestSearchUserInfoByJobUuid>
                    >
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestSearchUserInfoByJobUuid<
    TData = Awaited<ReturnType<typeof getRequestSearchUserInfoByJobUuid>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestSearchUserInfoByJobUuid>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get Search User Info
 */

export function useGetRequestSearchUserInfoByJobUuid<
    TData = Awaited<ReturnType<typeof getRequestSearchUserInfoByJobUuid>>,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestSearchUserInfoByJobUuid>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions = getGetRequestSearchUserInfoByJobUuidQueryOptions(
        jobUuid,
        options,
    )

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * Get the list of photos that might fall into the provided category.

 * @summary Get Photos Classified As Documents
 */
export const getRequestJobsClassificationByJobUuid = (
    jobUuid: string,
    params: GetRequestJobsClassificationByJobUuidParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<GetRequestJobsClassificationByJobUuid200>(
        {
            url: `/jobs/${encodeURIComponent(String(jobUuid))}/classification`,
            method: 'GET',
            params,
            signal,
        },
        options,
    )
}

export const getGetRequestJobsClassificationByJobUuidQueryKey = (
    jobUuid: string,
    params: GetRequestJobsClassificationByJobUuidParams,
) => {
    return [
        `/jobs/${jobUuid}/classification`,
        ...(params ? [params] : []),
    ] as const
}

export const getGetRequestJobsClassificationByJobUuidQueryOptions = <
    TData = Awaited<ReturnType<typeof getRequestJobsClassificationByJobUuid>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestJobsClassificationByJobUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<typeof getRequestJobsClassificationByJobUuid>
                >,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {}

    const queryKey =
        queryOptions?.queryKey ??
        getGetRequestJobsClassificationByJobUuidQueryKey(jobUuid, params)

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getRequestJobsClassificationByJobUuid>>
    > = ({ signal }) =>
        getRequestJobsClassificationByJobUuid(
            jobUuid,
            params,
            requestOptions,
            signal,
        )

    return {
        queryKey,
        queryFn,
        enabled: !!jobUuid,
        ...queryOptions,
    } as UseQueryOptions<
        Awaited<ReturnType<typeof getRequestJobsClassificationByJobUuid>>,
        TError,
        TData
    > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRequestJobsClassificationByJobUuidQueryResult = NonNullable<
    Awaited<ReturnType<typeof getRequestJobsClassificationByJobUuid>>
>
export type GetRequestJobsClassificationByJobUuidQueryError =
    ErrorType<HTTPValidationErrorDTO>

export function useGetRequestJobsClassificationByJobUuid<
    TData = Awaited<ReturnType<typeof getRequestJobsClassificationByJobUuid>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestJobsClassificationByJobUuidParams,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<typeof getRequestJobsClassificationByJobUuid>
                >,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<
                        ReturnType<typeof getRequestJobsClassificationByJobUuid>
                    >,
                    TError,
                    Awaited<
                        ReturnType<typeof getRequestJobsClassificationByJobUuid>
                    >
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestJobsClassificationByJobUuid<
    TData = Awaited<ReturnType<typeof getRequestJobsClassificationByJobUuid>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestJobsClassificationByJobUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<typeof getRequestJobsClassificationByJobUuid>
                >,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<
                        ReturnType<typeof getRequestJobsClassificationByJobUuid>
                    >,
                    TError,
                    Awaited<
                        ReturnType<typeof getRequestJobsClassificationByJobUuid>
                    >
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestJobsClassificationByJobUuid<
    TData = Awaited<ReturnType<typeof getRequestJobsClassificationByJobUuid>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestJobsClassificationByJobUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<typeof getRequestJobsClassificationByJobUuid>
                >,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get Photos Classified As Documents
 */

export function useGetRequestJobsClassificationByJobUuid<
    TData = Awaited<ReturnType<typeof getRequestJobsClassificationByJobUuid>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: GetRequestJobsClassificationByJobUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<typeof getRequestJobsClassificationByJobUuid>
                >,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions = getGetRequestJobsClassificationByJobUuidQueryOptions(
        jobUuid,
        params,
        options,
    )

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * This call adds user statistics events

 * @summary Post User Statistics Events
 */
export const postRequestUserStatisticsEvents = (
    userStatsEventsModelDTO: BodyType<UserStatsEventsModelDTO>,
    params: PostRequestUserStatisticsEventsParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<PostCumulusEventsResponseDTO>(
        {
            url: `/user_statistics_events`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: userStatsEventsModelDTO,
            params,
            signal,
        },
        options,
    )
}

export const getPostRequestUserStatisticsEventsMutationOptions = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestUserStatisticsEvents>>,
        TError,
        {
            data: BodyType<UserStatsEventsModelDTO>
            params: PostRequestUserStatisticsEventsParams
        },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestUserStatisticsEvents>>,
    TError,
    {
        data: BodyType<UserStatsEventsModelDTO>
        params: PostRequestUserStatisticsEventsParams
    },
    TContext
> => {
    const mutationKey = ['postRequestUserStatisticsEvents']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postRequestUserStatisticsEvents>>,
        {
            data: BodyType<UserStatsEventsModelDTO>
            params: PostRequestUserStatisticsEventsParams
        }
    > = (props) => {
        const { data, params } = props ?? {}

        return postRequestUserStatisticsEvents(data, params, requestOptions)
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestUserStatisticsEventsMutationResult = NonNullable<
    Awaited<ReturnType<typeof postRequestUserStatisticsEvents>>
>
export type PostRequestUserStatisticsEventsMutationBody =
    BodyType<UserStatsEventsModelDTO>
export type PostRequestUserStatisticsEventsMutationError =
    ErrorType<void | HTTPValidationErrorDTO>

/**
 * @summary Post User Statistics Events
 */
export const usePostRequestUserStatisticsEvents = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestUserStatisticsEvents>>,
        TError,
        {
            data: BodyType<UserStatsEventsModelDTO>
            params: PostRequestUserStatisticsEventsParams
        },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestUserStatisticsEvents>>,
    TError,
    {
        data: BodyType<UserStatsEventsModelDTO>
        params: PostRequestUserStatisticsEventsParams
    },
    TContext
> => {
    const mutationOptions =
        getPostRequestUserStatisticsEventsMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary Post Debug Events
 */
export const postRequestDebugEvents = (
    params: PostRequestDebugEventsParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<PostCumulusEventsResponseDTO>(
        { url: `/debug_events`, method: 'POST', params, signal },
        options,
    )
}

export const getPostRequestDebugEventsMutationOptions = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestDebugEvents>>,
        TError,
        { params: PostRequestDebugEventsParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestDebugEvents>>,
    TError,
    { params: PostRequestDebugEventsParams },
    TContext
> => {
    const mutationKey = ['postRequestDebugEvents']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postRequestDebugEvents>>,
        { params: PostRequestDebugEventsParams }
    > = (props) => {
        const { params } = props ?? {}

        return postRequestDebugEvents(params, requestOptions)
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestDebugEventsMutationResult = NonNullable<
    Awaited<ReturnType<typeof postRequestDebugEvents>>
>

export type PostRequestDebugEventsMutationError =
    ErrorType<HTTPValidationErrorDTO>

/**
 * @summary Post Debug Events
 */
export const usePostRequestDebugEvents = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestDebugEvents>>,
        TError,
        { params: PostRequestDebugEventsParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestDebugEvents>>,
    TError,
    { params: PostRequestDebugEventsParams },
    TContext
> => {
    const mutationOptions = getPostRequestDebugEventsMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * Push run-time statistics for the client
- $ref: '#/parameters/captureTokenParam'
- $ref: '#/parameters/app_key_required'
- $ref: '#/parameters/device_id_query_required'
- name: run_time_statitics
  in: body
  description: run-time data
  required: True
  schema:
    type: object
    properties:
      run_time_intervals:
        type: array
        items:
          type: object
          properties:
            start_time:
              type: integer
            end_time:
              type: integer
            start_battery_level:
              type: float
            end_battery_level:
              type: float
            with_ac:
              type: bool
            with_wifi:
              type: bool
            with_background_boost:
              type: bool
              required: False

 * @summary Post Run Time Statistics
 */
export const postRequestRunTimeStatistics = (
    runTimeStatsModelDTO: BodyType<RunTimeStatsModelDTO>,
    params: PostRequestRunTimeStatisticsParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<unknown>(
        {
            url: `/run_time_statistics`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: runTimeStatsModelDTO,
            params,
            signal,
        },
        options,
    )
}

export const getPostRequestRunTimeStatisticsMutationOptions = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestRunTimeStatistics>>,
        TError,
        {
            data: BodyType<RunTimeStatsModelDTO>
            params: PostRequestRunTimeStatisticsParams
        },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestRunTimeStatistics>>,
    TError,
    {
        data: BodyType<RunTimeStatsModelDTO>
        params: PostRequestRunTimeStatisticsParams
    },
    TContext
> => {
    const mutationKey = ['postRequestRunTimeStatistics']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postRequestRunTimeStatistics>>,
        {
            data: BodyType<RunTimeStatsModelDTO>
            params: PostRequestRunTimeStatisticsParams
        }
    > = (props) => {
        const { data, params } = props ?? {}

        return postRequestRunTimeStatistics(data, params, requestOptions)
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestRunTimeStatisticsMutationResult = NonNullable<
    Awaited<ReturnType<typeof postRequestRunTimeStatistics>>
>
export type PostRequestRunTimeStatisticsMutationBody =
    BodyType<RunTimeStatsModelDTO>
export type PostRequestRunTimeStatisticsMutationError =
    ErrorType<void | HTTPValidationErrorDTO>

/**
 * @summary Post Run Time Statistics
 */
export const usePostRequestRunTimeStatistics = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestRunTimeStatistics>>,
        TError,
        {
            data: BodyType<RunTimeStatsModelDTO>
            params: PostRequestRunTimeStatisticsParams
        },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestRunTimeStatistics>>,
    TError,
    {
        data: BodyType<RunTimeStatsModelDTO>
        params: PostRequestRunTimeStatisticsParams
    },
    TContext
> => {
    const mutationOptions =
        getPostRequestRunTimeStatisticsMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * Signal if clients should send user statistics

 * @summary Should Send User Statistics Events
 */
export const getRequestShouldSendUserStatisticsEvents = (
    params: GetRequestShouldSendUserStatisticsEventsParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<ShouldSendUserStatsEventsResponseDTO>(
        {
            url: `/should_send_user_statistics_events`,
            method: 'GET',
            params,
            signal,
        },
        options,
    )
}

export const getGetRequestShouldSendUserStatisticsEventsQueryKey = (
    params: GetRequestShouldSendUserStatisticsEventsParams,
) => {
    return [
        `/should_send_user_statistics_events`,
        ...(params ? [params] : []),
    ] as const
}

export const getGetRequestShouldSendUserStatisticsEventsQueryOptions = <
    TData = Awaited<
        ReturnType<typeof getRequestShouldSendUserStatisticsEvents>
    >,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestShouldSendUserStatisticsEventsParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<typeof getRequestShouldSendUserStatisticsEvents>
                >,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {}

    const queryKey =
        queryOptions?.queryKey ??
        getGetRequestShouldSendUserStatisticsEventsQueryKey(params)

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getRequestShouldSendUserStatisticsEvents>>
    > = ({ signal }) =>
        getRequestShouldSendUserStatisticsEvents(params, requestOptions, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof getRequestShouldSendUserStatisticsEvents>>,
        TError,
        TData
    > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRequestShouldSendUserStatisticsEventsQueryResult = NonNullable<
    Awaited<ReturnType<typeof getRequestShouldSendUserStatisticsEvents>>
>
export type GetRequestShouldSendUserStatisticsEventsQueryError =
    ErrorType<HTTPValidationErrorDTO>

export function useGetRequestShouldSendUserStatisticsEvents<
    TData = Awaited<
        ReturnType<typeof getRequestShouldSendUserStatisticsEvents>
    >,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestShouldSendUserStatisticsEventsParams,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<typeof getRequestShouldSendUserStatisticsEvents>
                >,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<
                        ReturnType<
                            typeof getRequestShouldSendUserStatisticsEvents
                        >
                    >,
                    TError,
                    Awaited<
                        ReturnType<
                            typeof getRequestShouldSendUserStatisticsEvents
                        >
                    >
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestShouldSendUserStatisticsEvents<
    TData = Awaited<
        ReturnType<typeof getRequestShouldSendUserStatisticsEvents>
    >,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestShouldSendUserStatisticsEventsParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<typeof getRequestShouldSendUserStatisticsEvents>
                >,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<
                        ReturnType<
                            typeof getRequestShouldSendUserStatisticsEvents
                        >
                    >,
                    TError,
                    Awaited<
                        ReturnType<
                            typeof getRequestShouldSendUserStatisticsEvents
                        >
                    >
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestShouldSendUserStatisticsEvents<
    TData = Awaited<
        ReturnType<typeof getRequestShouldSendUserStatisticsEvents>
    >,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestShouldSendUserStatisticsEventsParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<typeof getRequestShouldSendUserStatisticsEvents>
                >,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Should Send User Statistics Events
 */

export function useGetRequestShouldSendUserStatisticsEvents<
    TData = Awaited<
        ReturnType<typeof getRequestShouldSendUserStatisticsEvents>
    >,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestShouldSendUserStatisticsEventsParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<typeof getRequestShouldSendUserStatisticsEvents>
                >,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions =
        getGetRequestShouldSendUserStatisticsEventsQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * @summary Get Thumbnail Profile Picture
 */
export const getRequest3ProfilePictureByUserUuid = (
    userUuid: string,
    params?: GetRequest3ProfilePictureByUserUuidParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<unknown>(
        {
            url: `/3/profile/${encodeURIComponent(String(userUuid))}/picture`,
            method: 'GET',
            params,
            signal,
        },
        options,
    )
}

export const getGetRequest3ProfilePictureByUserUuidQueryKey = (
    userUuid: string,
    params?: GetRequest3ProfilePictureByUserUuidParams,
) => {
    return [
        `/3/profile/${userUuid}/picture`,
        ...(params ? [params] : []),
    ] as const
}

export const getGetRequest3ProfilePictureByUserUuidQueryOptions = <
    TData = Awaited<ReturnType<typeof getRequest3ProfilePictureByUserUuid>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    userUuid: string,
    params?: GetRequest3ProfilePictureByUserUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequest3ProfilePictureByUserUuid>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {}

    const queryKey =
        queryOptions?.queryKey ??
        getGetRequest3ProfilePictureByUserUuidQueryKey(userUuid, params)

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getRequest3ProfilePictureByUserUuid>>
    > = ({ signal }) =>
        getRequest3ProfilePictureByUserUuid(
            userUuid,
            params,
            requestOptions,
            signal,
        )

    return {
        queryKey,
        queryFn,
        enabled: !!userUuid,
        ...queryOptions,
    } as UseQueryOptions<
        Awaited<ReturnType<typeof getRequest3ProfilePictureByUserUuid>>,
        TError,
        TData
    > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRequest3ProfilePictureByUserUuidQueryResult = NonNullable<
    Awaited<ReturnType<typeof getRequest3ProfilePictureByUserUuid>>
>
export type GetRequest3ProfilePictureByUserUuidQueryError =
    ErrorType<HTTPValidationErrorDTO>

export function useGetRequest3ProfilePictureByUserUuid<
    TData = Awaited<ReturnType<typeof getRequest3ProfilePictureByUserUuid>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    userUuid: string,
    params: undefined | GetRequest3ProfilePictureByUserUuidParams,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequest3ProfilePictureByUserUuid>>,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<
                        ReturnType<typeof getRequest3ProfilePictureByUserUuid>
                    >,
                    TError,
                    Awaited<
                        ReturnType<typeof getRequest3ProfilePictureByUserUuid>
                    >
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequest3ProfilePictureByUserUuid<
    TData = Awaited<ReturnType<typeof getRequest3ProfilePictureByUserUuid>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    userUuid: string,
    params?: GetRequest3ProfilePictureByUserUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequest3ProfilePictureByUserUuid>>,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<
                        ReturnType<typeof getRequest3ProfilePictureByUserUuid>
                    >,
                    TError,
                    Awaited<
                        ReturnType<typeof getRequest3ProfilePictureByUserUuid>
                    >
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequest3ProfilePictureByUserUuid<
    TData = Awaited<ReturnType<typeof getRequest3ProfilePictureByUserUuid>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    userUuid: string,
    params?: GetRequest3ProfilePictureByUserUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequest3ProfilePictureByUserUuid>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get Thumbnail Profile Picture
 */

export function useGetRequest3ProfilePictureByUserUuid<
    TData = Awaited<ReturnType<typeof getRequest3ProfilePictureByUserUuid>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    userUuid: string,
    params?: GetRequest3ProfilePictureByUserUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequest3ProfilePictureByUserUuid>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions = getGetRequest3ProfilePictureByUserUuidQueryOptions(
        userUuid,
        params,
        options,
    )

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * @summary Get Thumbnail
 */
export const getRequest3ByFileUuid = (
    fileUuid: string,
    params: GetRequest3ByFileUuidParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<unknown>(
        {
            url: `/3/${encodeURIComponent(String(fileUuid))}`,
            method: 'GET',
            params,
            signal,
        },
        options,
    )
}

export const getGetRequest3ByFileUuidQueryKey = (
    fileUuid: string,
    params: GetRequest3ByFileUuidParams,
) => {
    return [`/3/${fileUuid}`, ...(params ? [params] : [])] as const
}

export const getGetRequest3ByFileUuidQueryOptions = <
    TData = Awaited<ReturnType<typeof getRequest3ByFileUuid>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    fileUuid: string,
    params: GetRequest3ByFileUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequest3ByFileUuid>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {}

    const queryKey =
        queryOptions?.queryKey ??
        getGetRequest3ByFileUuidQueryKey(fileUuid, params)

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getRequest3ByFileUuid>>
    > = ({ signal }) =>
        getRequest3ByFileUuid(fileUuid, params, requestOptions, signal)

    return {
        queryKey,
        queryFn,
        enabled: !!fileUuid,
        ...queryOptions,
    } as UseQueryOptions<
        Awaited<ReturnType<typeof getRequest3ByFileUuid>>,
        TError,
        TData
    > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRequest3ByFileUuidQueryResult = NonNullable<
    Awaited<ReturnType<typeof getRequest3ByFileUuid>>
>
export type GetRequest3ByFileUuidQueryError = ErrorType<HTTPValidationErrorDTO>

export function useGetRequest3ByFileUuid<
    TData = Awaited<ReturnType<typeof getRequest3ByFileUuid>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    fileUuid: string,
    params: GetRequest3ByFileUuidParams,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequest3ByFileUuid>>,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequest3ByFileUuid>>,
                    TError,
                    Awaited<ReturnType<typeof getRequest3ByFileUuid>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequest3ByFileUuid<
    TData = Awaited<ReturnType<typeof getRequest3ByFileUuid>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    fileUuid: string,
    params: GetRequest3ByFileUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequest3ByFileUuid>>,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequest3ByFileUuid>>,
                    TError,
                    Awaited<ReturnType<typeof getRequest3ByFileUuid>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequest3ByFileUuid<
    TData = Awaited<ReturnType<typeof getRequest3ByFileUuid>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    fileUuid: string,
    params: GetRequest3ByFileUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequest3ByFileUuid>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get Thumbnail
 */

export function useGetRequest3ByFileUuid<
    TData = Awaited<ReturnType<typeof getRequest3ByFileUuid>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    fileUuid: string,
    params: GetRequest3ByFileUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequest3ByFileUuid>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions = getGetRequest3ByFileUuidQueryOptions(
        fileUuid,
        params,
        options,
    )

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * @summary Get Thumbnail Trash
 */
export const getRequest3TrashByJobUuidAndFileUuid = (
    jobUuid: string,
    fileUuid: string,
    params: GetRequest3TrashByJobUuidAndFileUuidParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<unknown>(
        {
            url: `/3/trash/${encodeURIComponent(String(jobUuid))}/${encodeURIComponent(String(fileUuid))}`,
            method: 'GET',
            params,
            signal,
        },
        options,
    )
}

export const getGetRequest3TrashByJobUuidAndFileUuidQueryKey = (
    jobUuid: string,
    fileUuid: string,
    params: GetRequest3TrashByJobUuidAndFileUuidParams,
) => {
    return [
        `/3/trash/${jobUuid}/${fileUuid}`,
        ...(params ? [params] : []),
    ] as const
}

export const getGetRequest3TrashByJobUuidAndFileUuidQueryOptions = <
    TData = Awaited<ReturnType<typeof getRequest3TrashByJobUuidAndFileUuid>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    fileUuid: string,
    params: GetRequest3TrashByJobUuidAndFileUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<typeof getRequest3TrashByJobUuidAndFileUuid>
                >,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {}

    const queryKey =
        queryOptions?.queryKey ??
        getGetRequest3TrashByJobUuidAndFileUuidQueryKey(
            jobUuid,
            fileUuid,
            params,
        )

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getRequest3TrashByJobUuidAndFileUuid>>
    > = ({ signal }) =>
        getRequest3TrashByJobUuidAndFileUuid(
            jobUuid,
            fileUuid,
            params,
            requestOptions,
            signal,
        )

    return {
        queryKey,
        queryFn,
        enabled: !!(jobUuid && fileUuid),
        ...queryOptions,
    } as UseQueryOptions<
        Awaited<ReturnType<typeof getRequest3TrashByJobUuidAndFileUuid>>,
        TError,
        TData
    > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRequest3TrashByJobUuidAndFileUuidQueryResult = NonNullable<
    Awaited<ReturnType<typeof getRequest3TrashByJobUuidAndFileUuid>>
>
export type GetRequest3TrashByJobUuidAndFileUuidQueryError =
    ErrorType<HTTPValidationErrorDTO>

export function useGetRequest3TrashByJobUuidAndFileUuid<
    TData = Awaited<ReturnType<typeof getRequest3TrashByJobUuidAndFileUuid>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    fileUuid: string,
    params: GetRequest3TrashByJobUuidAndFileUuidParams,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<typeof getRequest3TrashByJobUuidAndFileUuid>
                >,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<
                        ReturnType<typeof getRequest3TrashByJobUuidAndFileUuid>
                    >,
                    TError,
                    Awaited<
                        ReturnType<typeof getRequest3TrashByJobUuidAndFileUuid>
                    >
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequest3TrashByJobUuidAndFileUuid<
    TData = Awaited<ReturnType<typeof getRequest3TrashByJobUuidAndFileUuid>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    fileUuid: string,
    params: GetRequest3TrashByJobUuidAndFileUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<typeof getRequest3TrashByJobUuidAndFileUuid>
                >,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<
                        ReturnType<typeof getRequest3TrashByJobUuidAndFileUuid>
                    >,
                    TError,
                    Awaited<
                        ReturnType<typeof getRequest3TrashByJobUuidAndFileUuid>
                    >
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequest3TrashByJobUuidAndFileUuid<
    TData = Awaited<ReturnType<typeof getRequest3TrashByJobUuidAndFileUuid>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    fileUuid: string,
    params: GetRequest3TrashByJobUuidAndFileUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<typeof getRequest3TrashByJobUuidAndFileUuid>
                >,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get Thumbnail Trash
 */

export function useGetRequest3TrashByJobUuidAndFileUuid<
    TData = Awaited<ReturnType<typeof getRequest3TrashByJobUuidAndFileUuid>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    fileUuid: string,
    params: GetRequest3TrashByJobUuidAndFileUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<typeof getRequest3TrashByJobUuidAndFileUuid>
                >,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions = getGetRequest3TrashByJobUuidAndFileUuidQueryOptions(
        jobUuid,
        fileUuid,
        params,
        options,
    )

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * Lists files given by uuid for a specific job. The returned files 
may be reduced with file filters such as _file_type_filters_, 
_uploaded_since_ or _reaction_ parameters

 * @summary Post Files By Uuid
 */
export const postRequestJobsFilesByUuidByJobUuid = (
    jobUuid: string,
    params: PostRequestJobsFilesByUuidByJobUuidParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<FileObjectResponseDTO[]>(
        {
            url: `/jobs/${encodeURIComponent(String(jobUuid))}/files_by_uuid`,
            method: 'POST',
            params,
            signal,
        },
        options,
    )
}

export const getPostRequestJobsFilesByUuidByJobUuidMutationOptions = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestJobsFilesByUuidByJobUuid>>,
        TError,
        { jobUuid: string; params: PostRequestJobsFilesByUuidByJobUuidParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestJobsFilesByUuidByJobUuid>>,
    TError,
    { jobUuid: string; params: PostRequestJobsFilesByUuidByJobUuidParams },
    TContext
> => {
    const mutationKey = ['postRequestJobsFilesByUuidByJobUuid']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postRequestJobsFilesByUuidByJobUuid>>,
        { jobUuid: string; params: PostRequestJobsFilesByUuidByJobUuidParams }
    > = (props) => {
        const { jobUuid, params } = props ?? {}

        return postRequestJobsFilesByUuidByJobUuid(
            jobUuid,
            params,
            requestOptions,
        )
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestJobsFilesByUuidByJobUuidMutationResult = NonNullable<
    Awaited<ReturnType<typeof postRequestJobsFilesByUuidByJobUuid>>
>

export type PostRequestJobsFilesByUuidByJobUuidMutationError =
    ErrorType<void | HTTPValidationErrorDTO>

/**
 * @summary Post Files By Uuid
 */
export const usePostRequestJobsFilesByUuidByJobUuid = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestJobsFilesByUuidByJobUuid>>,
        TError,
        { jobUuid: string; params: PostRequestJobsFilesByUuidByJobUuidParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestJobsFilesByUuidByJobUuid>>,
    TError,
    { jobUuid: string; params: PostRequestJobsFilesByUuidByJobUuidParams },
    TContext
> => {
    const mutationOptions =
        getPostRequestJobsFilesByUuidByJobUuidMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * Get digital legacy_data
 * @summary Save Digital Legacy
 */
export const postRequestDigitalLegacy = (
    digitalLegacyContactDTO: BodyType<DigitalLegacyContactDTO[]>,
    params: PostRequestDigitalLegacyParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<unknown>(
        {
            url: `/digital_legacy`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: digitalLegacyContactDTO,
            params,
            signal,
        },
        options,
    )
}

export const getPostRequestDigitalLegacyMutationOptions = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestDigitalLegacy>>,
        TError,
        {
            data: BodyType<DigitalLegacyContactDTO[]>
            params: PostRequestDigitalLegacyParams
        },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestDigitalLegacy>>,
    TError,
    {
        data: BodyType<DigitalLegacyContactDTO[]>
        params: PostRequestDigitalLegacyParams
    },
    TContext
> => {
    const mutationKey = ['postRequestDigitalLegacy']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postRequestDigitalLegacy>>,
        {
            data: BodyType<DigitalLegacyContactDTO[]>
            params: PostRequestDigitalLegacyParams
        }
    > = (props) => {
        const { data, params } = props ?? {}

        return postRequestDigitalLegacy(data, params, requestOptions)
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestDigitalLegacyMutationResult = NonNullable<
    Awaited<ReturnType<typeof postRequestDigitalLegacy>>
>
export type PostRequestDigitalLegacyMutationBody = BodyType<
    DigitalLegacyContactDTO[]
>
export type PostRequestDigitalLegacyMutationError =
    ErrorType<void | HTTPValidationErrorDTO>

/**
 * @summary Save Digital Legacy
 */
export const usePostRequestDigitalLegacy = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestDigitalLegacy>>,
        TError,
        {
            data: BodyType<DigitalLegacyContactDTO[]>
            params: PostRequestDigitalLegacyParams
        },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestDigitalLegacy>>,
    TError,
    {
        data: BodyType<DigitalLegacyContactDTO[]>
        params: PostRequestDigitalLegacyParams
    },
    TContext
> => {
    const mutationOptions = getPostRequestDigitalLegacyMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * Get digital legacy_data
 * @summary Get Digital Legacy
 */
export const getRequestDigitalLegacy = (
    params: GetRequestDigitalLegacyParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<DigitalLegacyInfoDTO>(
        { url: `/digital_legacy`, method: 'GET', params, signal },
        options,
    )
}

export const getGetRequestDigitalLegacyQueryKey = (
    params: GetRequestDigitalLegacyParams,
) => {
    return [`/digital_legacy`, ...(params ? [params] : [])] as const
}

export const getGetRequestDigitalLegacyQueryOptions = <
    TData = Awaited<ReturnType<typeof getRequestDigitalLegacy>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestDigitalLegacyParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestDigitalLegacy>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {}

    const queryKey =
        queryOptions?.queryKey ?? getGetRequestDigitalLegacyQueryKey(params)

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getRequestDigitalLegacy>>
    > = ({ signal }) => getRequestDigitalLegacy(params, requestOptions, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof getRequestDigitalLegacy>>,
        TError,
        TData
    > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRequestDigitalLegacyQueryResult = NonNullable<
    Awaited<ReturnType<typeof getRequestDigitalLegacy>>
>
export type GetRequestDigitalLegacyQueryError =
    ErrorType<HTTPValidationErrorDTO>

export function useGetRequestDigitalLegacy<
    TData = Awaited<ReturnType<typeof getRequestDigitalLegacy>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestDigitalLegacyParams,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestDigitalLegacy>>,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestDigitalLegacy>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestDigitalLegacy>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestDigitalLegacy<
    TData = Awaited<ReturnType<typeof getRequestDigitalLegacy>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestDigitalLegacyParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestDigitalLegacy>>,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestDigitalLegacy>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestDigitalLegacy>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestDigitalLegacy<
    TData = Awaited<ReturnType<typeof getRequestDigitalLegacy>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestDigitalLegacyParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestDigitalLegacy>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get Digital Legacy
 */

export function useGetRequestDigitalLegacy<
    TData = Awaited<ReturnType<typeof getRequestDigitalLegacy>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestDigitalLegacyParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestDigitalLegacy>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions = getGetRequestDigitalLegacyQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * @summary Get Stripe Subscriptions
 */
export const getRequestStripeSubscriptions = (
    params: GetRequestStripeSubscriptionsParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<StripeSubscriptionResponseDTO>(
        { url: `/stripe/subscriptions`, method: 'GET', params, signal },
        options,
    )
}

export const getGetRequestStripeSubscriptionsQueryKey = (
    params: GetRequestStripeSubscriptionsParams,
) => {
    return [`/stripe/subscriptions`, ...(params ? [params] : [])] as const
}

export const getGetRequestStripeSubscriptionsQueryOptions = <
    TData = Awaited<ReturnType<typeof getRequestStripeSubscriptions>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestStripeSubscriptionsParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestStripeSubscriptions>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {}

    const queryKey =
        queryOptions?.queryKey ??
        getGetRequestStripeSubscriptionsQueryKey(params)

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getRequestStripeSubscriptions>>
    > = ({ signal }) =>
        getRequestStripeSubscriptions(params, requestOptions, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof getRequestStripeSubscriptions>>,
        TError,
        TData
    > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRequestStripeSubscriptionsQueryResult = NonNullable<
    Awaited<ReturnType<typeof getRequestStripeSubscriptions>>
>
export type GetRequestStripeSubscriptionsQueryError =
    ErrorType<HTTPValidationErrorDTO>

export function useGetRequestStripeSubscriptions<
    TData = Awaited<ReturnType<typeof getRequestStripeSubscriptions>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestStripeSubscriptionsParams,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestStripeSubscriptions>>,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestStripeSubscriptions>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestStripeSubscriptions>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestStripeSubscriptions<
    TData = Awaited<ReturnType<typeof getRequestStripeSubscriptions>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestStripeSubscriptionsParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestStripeSubscriptions>>,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestStripeSubscriptions>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestStripeSubscriptions>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestStripeSubscriptions<
    TData = Awaited<ReturnType<typeof getRequestStripeSubscriptions>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestStripeSubscriptionsParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestStripeSubscriptions>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get Stripe Subscriptions
 */

export function useGetRequestStripeSubscriptions<
    TData = Awaited<ReturnType<typeof getRequestStripeSubscriptions>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestStripeSubscriptionsParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestStripeSubscriptions>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions = getGetRequestStripeSubscriptionsQueryOptions(
        params,
        options,
    )

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * This call is the html login.
When the client calls this function it returns the redirect response
of a call to TelenorID to extract the oauth code. We also provide a
redirect call to TelenorID, that redirect is the oauth2callback call.
Normally, if everything is OK this function will return the same
response as the oauth2callback call.

 * @deprecated
 * @summary Oauth2Login Connect Get Handler
 */
export const getRequestConnectOauth2login = (
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<unknown>(
        { url: `/connect/oauth2login`, method: 'GET', signal },
        options,
    )
}

export const getGetRequestConnectOauth2loginQueryKey = () => {
    return [`/connect/oauth2login`] as const
}

export const getGetRequestConnectOauth2loginQueryOptions = <
    TData = Awaited<ReturnType<typeof getRequestConnectOauth2login>>,
    TError = ErrorType<void>,
>(options?: {
    query?: Partial<
        UseQueryOptions<
            Awaited<ReturnType<typeof getRequestConnectOauth2login>>,
            TError,
            TData
        >
    >
    request?: SecondParameter<typeof customInstance>
}) => {
    const { query: queryOptions, request: requestOptions } = options ?? {}

    const queryKey =
        queryOptions?.queryKey ?? getGetRequestConnectOauth2loginQueryKey()

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getRequestConnectOauth2login>>
    > = ({ signal }) => getRequestConnectOauth2login(requestOptions, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof getRequestConnectOauth2login>>,
        TError,
        TData
    > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRequestConnectOauth2loginQueryResult = NonNullable<
    Awaited<ReturnType<typeof getRequestConnectOauth2login>>
>
export type GetRequestConnectOauth2loginQueryError = ErrorType<void>

export function useGetRequestConnectOauth2login<
    TData = Awaited<ReturnType<typeof getRequestConnectOauth2login>>,
    TError = ErrorType<void>,
>(options: {
    query: Partial<
        UseQueryOptions<
            Awaited<ReturnType<typeof getRequestConnectOauth2login>>,
            TError,
            TData
        >
    > &
        Pick<
            DefinedInitialDataOptions<
                Awaited<ReturnType<typeof getRequestConnectOauth2login>>,
                TError,
                Awaited<ReturnType<typeof getRequestConnectOauth2login>>
            >,
            'initialData'
        >
    request?: SecondParameter<typeof customInstance>
}): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestConnectOauth2login<
    TData = Awaited<ReturnType<typeof getRequestConnectOauth2login>>,
    TError = ErrorType<void>,
>(options?: {
    query?: Partial<
        UseQueryOptions<
            Awaited<ReturnType<typeof getRequestConnectOauth2login>>,
            TError,
            TData
        >
    > &
        Pick<
            UndefinedInitialDataOptions<
                Awaited<ReturnType<typeof getRequestConnectOauth2login>>,
                TError,
                Awaited<ReturnType<typeof getRequestConnectOauth2login>>
            >,
            'initialData'
        >
    request?: SecondParameter<typeof customInstance>
}): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestConnectOauth2login<
    TData = Awaited<ReturnType<typeof getRequestConnectOauth2login>>,
    TError = ErrorType<void>,
>(options?: {
    query?: Partial<
        UseQueryOptions<
            Awaited<ReturnType<typeof getRequestConnectOauth2login>>,
            TError,
            TData
        >
    >
    request?: SecondParameter<typeof customInstance>
}): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @deprecated
 * @summary Oauth2Login Connect Get Handler
 */

export function useGetRequestConnectOauth2login<
    TData = Awaited<ReturnType<typeof getRequestConnectOauth2login>>,
    TError = ErrorType<void>,
>(options?: {
    query?: Partial<
        UseQueryOptions<
            Awaited<ReturnType<typeof getRequestConnectOauth2login>>,
            TError,
            TData
        >
    >
    request?: SecondParameter<typeof customInstance>
}): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions = getGetRequestConnectOauth2loginQueryOptions(options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * This is a redirect call that we give to TelenorID after a successful
TelenorID login. The call from TelenorID returns a code. The code is sent
back to TelenorID with an oauth-token. In return we receive an
access_token. Then we call TelenorID to get user information with the
access_token. The first redirect url (login-test.univex.no) Then we generate
a Capture auth token and return the auth token in the first redirect url.
TODO: should local be added?

 * @deprecated
 * @summary Oauth2Callback Connect Get Handler
 */
export const getRequestConnectOauth2callback = (
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<unknown>(
        { url: `/connect/oauth2callback`, method: 'GET', signal },
        options,
    )
}

export const getGetRequestConnectOauth2callbackQueryKey = () => {
    return [`/connect/oauth2callback`] as const
}

export const getGetRequestConnectOauth2callbackQueryOptions = <
    TData = Awaited<ReturnType<typeof getRequestConnectOauth2callback>>,
    TError = ErrorType<void>,
>(options?: {
    query?: Partial<
        UseQueryOptions<
            Awaited<ReturnType<typeof getRequestConnectOauth2callback>>,
            TError,
            TData
        >
    >
    request?: SecondParameter<typeof customInstance>
}) => {
    const { query: queryOptions, request: requestOptions } = options ?? {}

    const queryKey =
        queryOptions?.queryKey ?? getGetRequestConnectOauth2callbackQueryKey()

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getRequestConnectOauth2callback>>
    > = ({ signal }) => getRequestConnectOauth2callback(requestOptions, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof getRequestConnectOauth2callback>>,
        TError,
        TData
    > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRequestConnectOauth2callbackQueryResult = NonNullable<
    Awaited<ReturnType<typeof getRequestConnectOauth2callback>>
>
export type GetRequestConnectOauth2callbackQueryError = ErrorType<void>

export function useGetRequestConnectOauth2callback<
    TData = Awaited<ReturnType<typeof getRequestConnectOauth2callback>>,
    TError = ErrorType<void>,
>(options: {
    query: Partial<
        UseQueryOptions<
            Awaited<ReturnType<typeof getRequestConnectOauth2callback>>,
            TError,
            TData
        >
    > &
        Pick<
            DefinedInitialDataOptions<
                Awaited<ReturnType<typeof getRequestConnectOauth2callback>>,
                TError,
                Awaited<ReturnType<typeof getRequestConnectOauth2callback>>
            >,
            'initialData'
        >
    request?: SecondParameter<typeof customInstance>
}): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestConnectOauth2callback<
    TData = Awaited<ReturnType<typeof getRequestConnectOauth2callback>>,
    TError = ErrorType<void>,
>(options?: {
    query?: Partial<
        UseQueryOptions<
            Awaited<ReturnType<typeof getRequestConnectOauth2callback>>,
            TError,
            TData
        >
    > &
        Pick<
            UndefinedInitialDataOptions<
                Awaited<ReturnType<typeof getRequestConnectOauth2callback>>,
                TError,
                Awaited<ReturnType<typeof getRequestConnectOauth2callback>>
            >,
            'initialData'
        >
    request?: SecondParameter<typeof customInstance>
}): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestConnectOauth2callback<
    TData = Awaited<ReturnType<typeof getRequestConnectOauth2callback>>,
    TError = ErrorType<void>,
>(options?: {
    query?: Partial<
        UseQueryOptions<
            Awaited<ReturnType<typeof getRequestConnectOauth2callback>>,
            TError,
            TData
        >
    >
    request?: SecondParameter<typeof customInstance>
}): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @deprecated
 * @summary Oauth2Callback Connect Get Handler
 */

export function useGetRequestConnectOauth2callback<
    TData = Awaited<ReturnType<typeof getRequestConnectOauth2callback>>,
    TError = ErrorType<void>,
>(options?: {
    query?: Partial<
        UseQueryOptions<
            Awaited<ReturnType<typeof getRequestConnectOauth2callback>>,
            TError,
            TData
        >
    >
    request?: SecondParameter<typeof customInstance>
}): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions = getGetRequestConnectOauth2callbackQueryOptions(options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * Get memories from a job for a date range

 * @summary Get Instant By Date
 */
export const getRequestJobsInstantsByJobUuidAndDateRange = (
    jobUuid: string,
    dateRange: string,
    params: GetRequestJobsInstantsByJobUuidAndDateRangeParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<InstantDTO>(
        {
            url: `/jobs/${encodeURIComponent(String(jobUuid))}/instants/${encodeURIComponent(String(dateRange))}`,
            method: 'GET',
            params,
            signal,
        },
        options,
    )
}

export const getGetRequestJobsInstantsByJobUuidAndDateRangeQueryKey = (
    jobUuid: string,
    dateRange: string,
    params: GetRequestJobsInstantsByJobUuidAndDateRangeParams,
) => {
    return [
        `/jobs/${jobUuid}/instants/${dateRange}`,
        ...(params ? [params] : []),
    ] as const
}

export const getGetRequestJobsInstantsByJobUuidAndDateRangeQueryOptions = <
    TData = Awaited<
        ReturnType<typeof getRequestJobsInstantsByJobUuidAndDateRange>
    >,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    dateRange: string,
    params: GetRequestJobsInstantsByJobUuidAndDateRangeParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestJobsInstantsByJobUuidAndDateRange
                    >
                >,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {}

    const queryKey =
        queryOptions?.queryKey ??
        getGetRequestJobsInstantsByJobUuidAndDateRangeQueryKey(
            jobUuid,
            dateRange,
            params,
        )

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getRequestJobsInstantsByJobUuidAndDateRange>>
    > = ({ signal }) =>
        getRequestJobsInstantsByJobUuidAndDateRange(
            jobUuid,
            dateRange,
            params,
            requestOptions,
            signal,
        )

    return {
        queryKey,
        queryFn,
        enabled: !!(jobUuid && dateRange),
        ...queryOptions,
    } as UseQueryOptions<
        Awaited<ReturnType<typeof getRequestJobsInstantsByJobUuidAndDateRange>>,
        TError,
        TData
    > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRequestJobsInstantsByJobUuidAndDateRangeQueryResult =
    NonNullable<
        Awaited<ReturnType<typeof getRequestJobsInstantsByJobUuidAndDateRange>>
    >
export type GetRequestJobsInstantsByJobUuidAndDateRangeQueryError =
    ErrorType<void | HTTPValidationErrorDTO>

export function useGetRequestJobsInstantsByJobUuidAndDateRange<
    TData = Awaited<
        ReturnType<typeof getRequestJobsInstantsByJobUuidAndDateRange>
    >,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    dateRange: string,
    params: GetRequestJobsInstantsByJobUuidAndDateRangeParams,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestJobsInstantsByJobUuidAndDateRange
                    >
                >,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<
                        ReturnType<
                            typeof getRequestJobsInstantsByJobUuidAndDateRange
                        >
                    >,
                    TError,
                    Awaited<
                        ReturnType<
                            typeof getRequestJobsInstantsByJobUuidAndDateRange
                        >
                    >
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestJobsInstantsByJobUuidAndDateRange<
    TData = Awaited<
        ReturnType<typeof getRequestJobsInstantsByJobUuidAndDateRange>
    >,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    dateRange: string,
    params: GetRequestJobsInstantsByJobUuidAndDateRangeParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestJobsInstantsByJobUuidAndDateRange
                    >
                >,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<
                        ReturnType<
                            typeof getRequestJobsInstantsByJobUuidAndDateRange
                        >
                    >,
                    TError,
                    Awaited<
                        ReturnType<
                            typeof getRequestJobsInstantsByJobUuidAndDateRange
                        >
                    >
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestJobsInstantsByJobUuidAndDateRange<
    TData = Awaited<
        ReturnType<typeof getRequestJobsInstantsByJobUuidAndDateRange>
    >,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    dateRange: string,
    params: GetRequestJobsInstantsByJobUuidAndDateRangeParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestJobsInstantsByJobUuidAndDateRange
                    >
                >,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get Instant By Date
 */

export function useGetRequestJobsInstantsByJobUuidAndDateRange<
    TData = Awaited<
        ReturnType<typeof getRequestJobsInstantsByJobUuidAndDateRange>
    >,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    dateRange: string,
    params: GetRequestJobsInstantsByJobUuidAndDateRangeParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestJobsInstantsByJobUuidAndDateRange
                    >
                >,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions =
        getGetRequestJobsInstantsByJobUuidAndDateRangeQueryOptions(
            jobUuid,
            dateRange,
            params,
            options,
        )

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * Get collection by uuid

 * @summary Get Memory Collection
 */
export const getRequestMemoryCollectionsByCollectionUuid = (
    collectionUuid: string,
    params: GetRequestMemoryCollectionsByCollectionUuidParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<CollectionDTO>(
        {
            url: `/memory_collections/${encodeURIComponent(String(collectionUuid))}`,
            method: 'GET',
            params,
            signal,
        },
        options,
    )
}

export const getGetRequestMemoryCollectionsByCollectionUuidQueryKey = (
    collectionUuid: string,
    params: GetRequestMemoryCollectionsByCollectionUuidParams,
) => {
    return [
        `/memory_collections/${collectionUuid}`,
        ...(params ? [params] : []),
    ] as const
}

export const getGetRequestMemoryCollectionsByCollectionUuidQueryOptions = <
    TData = Awaited<
        ReturnType<typeof getRequestMemoryCollectionsByCollectionUuid>
    >,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    collectionUuid: string,
    params: GetRequestMemoryCollectionsByCollectionUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestMemoryCollectionsByCollectionUuid
                    >
                >,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {}

    const queryKey =
        queryOptions?.queryKey ??
        getGetRequestMemoryCollectionsByCollectionUuidQueryKey(
            collectionUuid,
            params,
        )

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getRequestMemoryCollectionsByCollectionUuid>>
    > = ({ signal }) =>
        getRequestMemoryCollectionsByCollectionUuid(
            collectionUuid,
            params,
            requestOptions,
            signal,
        )

    return {
        queryKey,
        queryFn,
        enabled: !!collectionUuid,
        ...queryOptions,
    } as UseQueryOptions<
        Awaited<ReturnType<typeof getRequestMemoryCollectionsByCollectionUuid>>,
        TError,
        TData
    > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRequestMemoryCollectionsByCollectionUuidQueryResult =
    NonNullable<
        Awaited<ReturnType<typeof getRequestMemoryCollectionsByCollectionUuid>>
    >
export type GetRequestMemoryCollectionsByCollectionUuidQueryError =
    ErrorType<void | HTTPValidationErrorDTO>

export function useGetRequestMemoryCollectionsByCollectionUuid<
    TData = Awaited<
        ReturnType<typeof getRequestMemoryCollectionsByCollectionUuid>
    >,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    collectionUuid: string,
    params: GetRequestMemoryCollectionsByCollectionUuidParams,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestMemoryCollectionsByCollectionUuid
                    >
                >,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<
                        ReturnType<
                            typeof getRequestMemoryCollectionsByCollectionUuid
                        >
                    >,
                    TError,
                    Awaited<
                        ReturnType<
                            typeof getRequestMemoryCollectionsByCollectionUuid
                        >
                    >
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestMemoryCollectionsByCollectionUuid<
    TData = Awaited<
        ReturnType<typeof getRequestMemoryCollectionsByCollectionUuid>
    >,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    collectionUuid: string,
    params: GetRequestMemoryCollectionsByCollectionUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestMemoryCollectionsByCollectionUuid
                    >
                >,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<
                        ReturnType<
                            typeof getRequestMemoryCollectionsByCollectionUuid
                        >
                    >,
                    TError,
                    Awaited<
                        ReturnType<
                            typeof getRequestMemoryCollectionsByCollectionUuid
                        >
                    >
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestMemoryCollectionsByCollectionUuid<
    TData = Awaited<
        ReturnType<typeof getRequestMemoryCollectionsByCollectionUuid>
    >,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    collectionUuid: string,
    params: GetRequestMemoryCollectionsByCollectionUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestMemoryCollectionsByCollectionUuid
                    >
                >,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get Memory Collection
 */

export function useGetRequestMemoryCollectionsByCollectionUuid<
    TData = Awaited<
        ReturnType<typeof getRequestMemoryCollectionsByCollectionUuid>
    >,
    TError = ErrorType<void | HTTPValidationErrorDTO>,
>(
    collectionUuid: string,
    params: GetRequestMemoryCollectionsByCollectionUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestMemoryCollectionsByCollectionUuid
                    >
                >,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions =
        getGetRequestMemoryCollectionsByCollectionUuidQueryOptions(
            collectionUuid,
            params,
            options,
        )

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * Delete a collection by uuid

 * @summary Delete Memory Collection By Uuid
 */
export const deleteRequestMemoryCollectionsByCollectionUuid = (
    collectionUuid: string,
    params: DeleteRequestMemoryCollectionsByCollectionUuidParams,
    options?: SecondParameter<typeof customInstance>,
) => {
    return customInstance<MessageResponseDTO>(
        {
            url: `/memory_collections/${encodeURIComponent(String(collectionUuid))}`,
            method: 'DELETE',
            params,
        },
        options,
    )
}

export const getDeleteRequestMemoryCollectionsByCollectionUuidMutationOptions =
    <
        TError = ErrorType<void | HTTPValidationErrorDTO>,
        TContext = unknown,
    >(options?: {
        mutation?: UseMutationOptions<
            Awaited<
                ReturnType<
                    typeof deleteRequestMemoryCollectionsByCollectionUuid
                >
            >,
            TError,
            {
                collectionUuid: string
                params: DeleteRequestMemoryCollectionsByCollectionUuidParams
            },
            TContext
        >
        request?: SecondParameter<typeof customInstance>
    }): UseMutationOptions<
        Awaited<
            ReturnType<typeof deleteRequestMemoryCollectionsByCollectionUuid>
        >,
        TError,
        {
            collectionUuid: string
            params: DeleteRequestMemoryCollectionsByCollectionUuidParams
        },
        TContext
    > => {
        const mutationKey = ['deleteRequestMemoryCollectionsByCollectionUuid']
        const { mutation: mutationOptions, request: requestOptions } = options
            ? options.mutation &&
              'mutationKey' in options.mutation &&
              options.mutation.mutationKey
                ? options
                : { ...options, mutation: { ...options.mutation, mutationKey } }
            : { mutation: { mutationKey }, request: undefined }

        const mutationFn: MutationFunction<
            Awaited<
                ReturnType<
                    typeof deleteRequestMemoryCollectionsByCollectionUuid
                >
            >,
            {
                collectionUuid: string
                params: DeleteRequestMemoryCollectionsByCollectionUuidParams
            }
        > = (props) => {
            const { collectionUuid, params } = props ?? {}

            return deleteRequestMemoryCollectionsByCollectionUuid(
                collectionUuid,
                params,
                requestOptions,
            )
        }

        return { mutationFn, ...mutationOptions }
    }

export type DeleteRequestMemoryCollectionsByCollectionUuidMutationResult =
    NonNullable<
        Awaited<
            ReturnType<typeof deleteRequestMemoryCollectionsByCollectionUuid>
        >
    >

export type DeleteRequestMemoryCollectionsByCollectionUuidMutationError =
    ErrorType<void | HTTPValidationErrorDTO>

/**
 * @summary Delete Memory Collection By Uuid
 */
export const useDeleteRequestMemoryCollectionsByCollectionUuid = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<typeof deleteRequestMemoryCollectionsByCollectionUuid>
        >,
        TError,
        {
            collectionUuid: string
            params: DeleteRequestMemoryCollectionsByCollectionUuidParams
        },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof deleteRequestMemoryCollectionsByCollectionUuid>>,
    TError,
    {
        collectionUuid: string
        params: DeleteRequestMemoryCollectionsByCollectionUuidParams
    },
    TContext
> => {
    const mutationOptions =
        getDeleteRequestMemoryCollectionsByCollectionUuidMutationOptions(
            options,
        )

    return useMutation(mutationOptions)
}

/**
 * Get external urls the clients should use
 * @summary Get External Urls
 */
export const getRequestExternalUrls = (
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<GetRequestExternalUrls200>(
        { url: `/external_urls`, method: 'GET', signal },
        options,
    )
}

export const getGetRequestExternalUrlsQueryKey = () => {
    return [`/external_urls`] as const
}

export const getGetRequestExternalUrlsQueryOptions = <
    TData = Awaited<ReturnType<typeof getRequestExternalUrls>>,
    TError = ErrorType<unknown>,
>(options?: {
    query?: Partial<
        UseQueryOptions<
            Awaited<ReturnType<typeof getRequestExternalUrls>>,
            TError,
            TData
        >
    >
    request?: SecondParameter<typeof customInstance>
}) => {
    const { query: queryOptions, request: requestOptions } = options ?? {}

    const queryKey =
        queryOptions?.queryKey ?? getGetRequestExternalUrlsQueryKey()

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getRequestExternalUrls>>
    > = ({ signal }) => getRequestExternalUrls(requestOptions, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof getRequestExternalUrls>>,
        TError,
        TData
    > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRequestExternalUrlsQueryResult = NonNullable<
    Awaited<ReturnType<typeof getRequestExternalUrls>>
>
export type GetRequestExternalUrlsQueryError = ErrorType<unknown>

export function useGetRequestExternalUrls<
    TData = Awaited<ReturnType<typeof getRequestExternalUrls>>,
    TError = ErrorType<unknown>,
>(options: {
    query: Partial<
        UseQueryOptions<
            Awaited<ReturnType<typeof getRequestExternalUrls>>,
            TError,
            TData
        >
    > &
        Pick<
            DefinedInitialDataOptions<
                Awaited<ReturnType<typeof getRequestExternalUrls>>,
                TError,
                Awaited<ReturnType<typeof getRequestExternalUrls>>
            >,
            'initialData'
        >
    request?: SecondParameter<typeof customInstance>
}): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestExternalUrls<
    TData = Awaited<ReturnType<typeof getRequestExternalUrls>>,
    TError = ErrorType<unknown>,
>(options?: {
    query?: Partial<
        UseQueryOptions<
            Awaited<ReturnType<typeof getRequestExternalUrls>>,
            TError,
            TData
        >
    > &
        Pick<
            UndefinedInitialDataOptions<
                Awaited<ReturnType<typeof getRequestExternalUrls>>,
                TError,
                Awaited<ReturnType<typeof getRequestExternalUrls>>
            >,
            'initialData'
        >
    request?: SecondParameter<typeof customInstance>
}): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestExternalUrls<
    TData = Awaited<ReturnType<typeof getRequestExternalUrls>>,
    TError = ErrorType<unknown>,
>(options?: {
    query?: Partial<
        UseQueryOptions<
            Awaited<ReturnType<typeof getRequestExternalUrls>>,
            TError,
            TData
        >
    >
    request?: SecondParameter<typeof customInstance>
}): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get External Urls
 */

export function useGetRequestExternalUrls<
    TData = Awaited<ReturnType<typeof getRequestExternalUrls>>,
    TError = ErrorType<unknown>,
>(options?: {
    query?: Partial<
        UseQueryOptions<
            Awaited<ReturnType<typeof getRequestExternalUrls>>,
            TError,
            TData
        >
    >
    request?: SecondParameter<typeof customInstance>
}): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions = getGetRequestExternalUrlsQueryOptions(options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * @summary Post Stripe Create Customer
 */
export const postRequestStripeCreateCustomer = (
    params: PostRequestStripeCreateCustomerParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<MessageResponseDTO>(
        { url: `/stripe/create_customer`, method: 'POST', params, signal },
        options,
    )
}

export const getPostRequestStripeCreateCustomerMutationOptions = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestStripeCreateCustomer>>,
        TError,
        { params: PostRequestStripeCreateCustomerParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestStripeCreateCustomer>>,
    TError,
    { params: PostRequestStripeCreateCustomerParams },
    TContext
> => {
    const mutationKey = ['postRequestStripeCreateCustomer']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postRequestStripeCreateCustomer>>,
        { params: PostRequestStripeCreateCustomerParams }
    > = (props) => {
        const { params } = props ?? {}

        return postRequestStripeCreateCustomer(params, requestOptions)
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestStripeCreateCustomerMutationResult = NonNullable<
    Awaited<ReturnType<typeof postRequestStripeCreateCustomer>>
>

export type PostRequestStripeCreateCustomerMutationError =
    ErrorType<void | HTTPValidationErrorDTO>

/**
 * @summary Post Stripe Create Customer
 */
export const usePostRequestStripeCreateCustomer = <
    TError = ErrorType<void | HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestStripeCreateCustomer>>,
        TError,
        { params: PostRequestStripeCreateCustomerParams },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestStripeCreateCustomer>>,
    TError,
    { params: PostRequestStripeCreateCustomerParams },
    TContext
> => {
    const mutationOptions =
        getPostRequestStripeCreateCustomerMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary Post Webhook Stripe
 */
export const postRequestWebhookStripeV1 = (
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<unknown>(
        { url: `/webhook/stripe/v1`, method: 'POST', signal },
        options,
    )
}

export const getPostRequestWebhookStripeV1MutationOptions = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestWebhookStripeV1>>,
        TError,
        void,
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestWebhookStripeV1>>,
    TError,
    void,
    TContext
> => {
    const mutationKey = ['postRequestWebhookStripeV1']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postRequestWebhookStripeV1>>,
        void
    > = () => {
        return postRequestWebhookStripeV1(requestOptions)
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestWebhookStripeV1MutationResult = NonNullable<
    Awaited<ReturnType<typeof postRequestWebhookStripeV1>>
>

export type PostRequestWebhookStripeV1MutationError =
    ErrorType<HTTPValidationErrorDTO>

/**
 * @summary Post Webhook Stripe
 */
export const usePostRequestWebhookStripeV1 = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestWebhookStripeV1>>,
        TError,
        void,
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestWebhookStripeV1>>,
    TError,
    void,
    TContext
> => {
    const mutationOptions =
        getPostRequestWebhookStripeV1MutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary Post App Store Server Notifications
 */
export const postRequestWebhookAppleV2AppStoreServerNotifications = (
    appStoreServerNotificationsDTO: BodyType<AppStoreServerNotificationsDTO>,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<unknown>(
        {
            url: `/webhook/apple/v2/app_store_server_notifications`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: appStoreServerNotificationsDTO,
            signal,
        },
        options,
    )
}

export const getPostRequestWebhookAppleV2AppStoreServerNotificationsMutationOptions =
    <TError = ErrorType<HTTPValidationErrorDTO>, TContext = unknown>(options?: {
        mutation?: UseMutationOptions<
            Awaited<
                ReturnType<
                    typeof postRequestWebhookAppleV2AppStoreServerNotifications
                >
            >,
            TError,
            { data: BodyType<AppStoreServerNotificationsDTO> },
            TContext
        >
        request?: SecondParameter<typeof customInstance>
    }): UseMutationOptions<
        Awaited<
            ReturnType<
                typeof postRequestWebhookAppleV2AppStoreServerNotifications
            >
        >,
        TError,
        { data: BodyType<AppStoreServerNotificationsDTO> },
        TContext
    > => {
        const mutationKey = [
            'postRequestWebhookAppleV2AppStoreServerNotifications',
        ]
        const { mutation: mutationOptions, request: requestOptions } = options
            ? options.mutation &&
              'mutationKey' in options.mutation &&
              options.mutation.mutationKey
                ? options
                : { ...options, mutation: { ...options.mutation, mutationKey } }
            : { mutation: { mutationKey }, request: undefined }

        const mutationFn: MutationFunction<
            Awaited<
                ReturnType<
                    typeof postRequestWebhookAppleV2AppStoreServerNotifications
                >
            >,
            { data: BodyType<AppStoreServerNotificationsDTO> }
        > = (props) => {
            const { data } = props ?? {}

            return postRequestWebhookAppleV2AppStoreServerNotifications(
                data,
                requestOptions,
            )
        }

        return { mutationFn, ...mutationOptions }
    }

export type PostRequestWebhookAppleV2AppStoreServerNotificationsMutationResult =
    NonNullable<
        Awaited<
            ReturnType<
                typeof postRequestWebhookAppleV2AppStoreServerNotifications
            >
        >
    >
export type PostRequestWebhookAppleV2AppStoreServerNotificationsMutationBody =
    BodyType<AppStoreServerNotificationsDTO>
export type PostRequestWebhookAppleV2AppStoreServerNotificationsMutationError =
    ErrorType<HTTPValidationErrorDTO>

/**
 * @summary Post App Store Server Notifications
 */
export const usePostRequestWebhookAppleV2AppStoreServerNotifications = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<
                typeof postRequestWebhookAppleV2AppStoreServerNotifications
            >
        >,
        TError,
        { data: BodyType<AppStoreServerNotificationsDTO> },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<
        ReturnType<typeof postRequestWebhookAppleV2AppStoreServerNotifications>
    >,
    TError,
    { data: BodyType<AppStoreServerNotificationsDTO> },
    TContext
> => {
    const mutationOptions =
        getPostRequestWebhookAppleV2AppStoreServerNotificationsMutationOptions(
            options,
        )

    return useMutation(mutationOptions)
}

/**
 * @summary Set Email Consent
 */
export const postRequestUserEmailConsent = (
    setEmailConsentDTO: BodyType<SetEmailConsentDTO>,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<MessageResponseDTO>(
        {
            url: `/user/email_consent`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: setEmailConsentDTO,
            signal,
        },
        options,
    )
}

export const getPostRequestUserEmailConsentMutationOptions = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestUserEmailConsent>>,
        TError,
        { data: BodyType<SetEmailConsentDTO> },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestUserEmailConsent>>,
    TError,
    { data: BodyType<SetEmailConsentDTO> },
    TContext
> => {
    const mutationKey = ['postRequestUserEmailConsent']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postRequestUserEmailConsent>>,
        { data: BodyType<SetEmailConsentDTO> }
    > = (props) => {
        const { data } = props ?? {}

        return postRequestUserEmailConsent(data, requestOptions)
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestUserEmailConsentMutationResult = NonNullable<
    Awaited<ReturnType<typeof postRequestUserEmailConsent>>
>
export type PostRequestUserEmailConsentMutationBody =
    BodyType<SetEmailConsentDTO>
export type PostRequestUserEmailConsentMutationError =
    ErrorType<HTTPValidationErrorDTO>

/**
 * @summary Set Email Consent
 */
export const usePostRequestUserEmailConsent = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestUserEmailConsent>>,
        TError,
        { data: BodyType<SetEmailConsentDTO> },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestUserEmailConsent>>,
    TError,
    { data: BodyType<SetEmailConsentDTO> },
    TContext
> => {
    const mutationOptions =
        getPostRequestUserEmailConsentMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary Get Email Consent
 */
export const getRequestUserEmailConsent = (
    params: GetRequestUserEmailConsentParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<ConsentResponsev2DTO>(
        { url: `/user/email_consent`, method: 'GET', params, signal },
        options,
    )
}

export const getGetRequestUserEmailConsentQueryKey = (
    params: GetRequestUserEmailConsentParams,
) => {
    return [`/user/email_consent`, ...(params ? [params] : [])] as const
}

export const getGetRequestUserEmailConsentQueryOptions = <
    TData = Awaited<ReturnType<typeof getRequestUserEmailConsent>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestUserEmailConsentParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestUserEmailConsent>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {}

    const queryKey =
        queryOptions?.queryKey ?? getGetRequestUserEmailConsentQueryKey(params)

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getRequestUserEmailConsent>>
    > = ({ signal }) =>
        getRequestUserEmailConsent(params, requestOptions, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof getRequestUserEmailConsent>>,
        TError,
        TData
    > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRequestUserEmailConsentQueryResult = NonNullable<
    Awaited<ReturnType<typeof getRequestUserEmailConsent>>
>
export type GetRequestUserEmailConsentQueryError =
    ErrorType<HTTPValidationErrorDTO>

export function useGetRequestUserEmailConsent<
    TData = Awaited<ReturnType<typeof getRequestUserEmailConsent>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestUserEmailConsentParams,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestUserEmailConsent>>,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestUserEmailConsent>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestUserEmailConsent>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestUserEmailConsent<
    TData = Awaited<ReturnType<typeof getRequestUserEmailConsent>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestUserEmailConsentParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestUserEmailConsent>>,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestUserEmailConsent>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestUserEmailConsent>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestUserEmailConsent<
    TData = Awaited<ReturnType<typeof getRequestUserEmailConsent>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestUserEmailConsentParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestUserEmailConsent>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get Email Consent
 */

export function useGetRequestUserEmailConsent<
    TData = Awaited<ReturnType<typeof getRequestUserEmailConsent>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: GetRequestUserEmailConsentParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestUserEmailConsent>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions = getGetRequestUserEmailConsentQueryOptions(
        params,
        options,
    )

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * @summary Rename Album Group
 */
export const postRequestAlbumGroupNameByGroupUuid = (
    groupUuid: string,
    params?: PostRequestAlbumGroupNameByGroupUuidParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<AlbumGroupDTO>(
        {
            url: `/album/group/${encodeURIComponent(String(groupUuid))}/name`,
            method: 'POST',
            params,
            signal,
        },
        options,
    )
}

export const getPostRequestAlbumGroupNameByGroupUuidMutationOptions = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestAlbumGroupNameByGroupUuid>>,
        TError,
        {
            groupUuid: string
            params?: PostRequestAlbumGroupNameByGroupUuidParams
        },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestAlbumGroupNameByGroupUuid>>,
    TError,
    { groupUuid: string; params?: PostRequestAlbumGroupNameByGroupUuidParams },
    TContext
> => {
    const mutationKey = ['postRequestAlbumGroupNameByGroupUuid']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postRequestAlbumGroupNameByGroupUuid>>,
        {
            groupUuid: string
            params?: PostRequestAlbumGroupNameByGroupUuidParams
        }
    > = (props) => {
        const { groupUuid, params } = props ?? {}

        return postRequestAlbumGroupNameByGroupUuid(
            groupUuid,
            params,
            requestOptions,
        )
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestAlbumGroupNameByGroupUuidMutationResult = NonNullable<
    Awaited<ReturnType<typeof postRequestAlbumGroupNameByGroupUuid>>
>

export type PostRequestAlbumGroupNameByGroupUuidMutationError =
    ErrorType<HTTPValidationErrorDTO>

/**
 * @summary Rename Album Group
 */
export const usePostRequestAlbumGroupNameByGroupUuid = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestAlbumGroupNameByGroupUuid>>,
        TError,
        {
            groupUuid: string
            params?: PostRequestAlbumGroupNameByGroupUuidParams
        },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestAlbumGroupNameByGroupUuid>>,
    TError,
    { groupUuid: string; params?: PostRequestAlbumGroupNameByGroupUuidParams },
    TContext
> => {
    const mutationOptions =
        getPostRequestAlbumGroupNameByGroupUuidMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary Remove From Album Group
 */
export const deleteRequestAlbumGroupsMembersByGroupUuid = (
    groupUuid: string,
    albumListModelDTO: BodyType<AlbumListModelDTO>,
    options?: SecondParameter<typeof customInstance>,
) => {
    return customInstance<DeleteRequestAlbumGroupsMembersByGroupUuid200>(
        {
            url: `/album/groups/${encodeURIComponent(String(groupUuid))}/members`,
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
            data: albumListModelDTO,
        },
        options,
    )
}

export const getDeleteRequestAlbumGroupsMembersByGroupUuidMutationOptions = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof deleteRequestAlbumGroupsMembersByGroupUuid>>,
        TError,
        { groupUuid: string; data: BodyType<AlbumListModelDTO> },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof deleteRequestAlbumGroupsMembersByGroupUuid>>,
    TError,
    { groupUuid: string; data: BodyType<AlbumListModelDTO> },
    TContext
> => {
    const mutationKey = ['deleteRequestAlbumGroupsMembersByGroupUuid']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof deleteRequestAlbumGroupsMembersByGroupUuid>>,
        { groupUuid: string; data: BodyType<AlbumListModelDTO> }
    > = (props) => {
        const { groupUuid, data } = props ?? {}

        return deleteRequestAlbumGroupsMembersByGroupUuid(
            groupUuid,
            data,
            requestOptions,
        )
    }

    return { mutationFn, ...mutationOptions }
}

export type DeleteRequestAlbumGroupsMembersByGroupUuidMutationResult =
    NonNullable<
        Awaited<ReturnType<typeof deleteRequestAlbumGroupsMembersByGroupUuid>>
    >
export type DeleteRequestAlbumGroupsMembersByGroupUuidMutationBody =
    BodyType<AlbumListModelDTO>
export type DeleteRequestAlbumGroupsMembersByGroupUuidMutationError =
    ErrorType<HTTPValidationErrorDTO>

/**
 * @summary Remove From Album Group
 */
export const useDeleteRequestAlbumGroupsMembersByGroupUuid = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof deleteRequestAlbumGroupsMembersByGroupUuid>>,
        TError,
        { groupUuid: string; data: BodyType<AlbumListModelDTO> },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof deleteRequestAlbumGroupsMembersByGroupUuid>>,
    TError,
    { groupUuid: string; data: BodyType<AlbumListModelDTO> },
    TContext
> => {
    const mutationOptions =
        getDeleteRequestAlbumGroupsMembersByGroupUuidMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary Add Albums To Group
 */
export const postRequestAlbumGroupsMembersByGroupUuid = (
    groupUuid: string,
    albumListModelDTO: BodyType<AlbumListModelDTO>,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<AlbumGroupDTO>(
        {
            url: `/album/groups/${encodeURIComponent(String(groupUuid))}/members`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: albumListModelDTO,
            signal,
        },
        options,
    )
}

export const getPostRequestAlbumGroupsMembersByGroupUuidMutationOptions = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestAlbumGroupsMembersByGroupUuid>>,
        TError,
        { groupUuid: string; data: BodyType<AlbumListModelDTO> },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestAlbumGroupsMembersByGroupUuid>>,
    TError,
    { groupUuid: string; data: BodyType<AlbumListModelDTO> },
    TContext
> => {
    const mutationKey = ['postRequestAlbumGroupsMembersByGroupUuid']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postRequestAlbumGroupsMembersByGroupUuid>>,
        { groupUuid: string; data: BodyType<AlbumListModelDTO> }
    > = (props) => {
        const { groupUuid, data } = props ?? {}

        return postRequestAlbumGroupsMembersByGroupUuid(
            groupUuid,
            data,
            requestOptions,
        )
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestAlbumGroupsMembersByGroupUuidMutationResult =
    NonNullable<
        Awaited<ReturnType<typeof postRequestAlbumGroupsMembersByGroupUuid>>
    >
export type PostRequestAlbumGroupsMembersByGroupUuidMutationBody =
    BodyType<AlbumListModelDTO>
export type PostRequestAlbumGroupsMembersByGroupUuidMutationError =
    ErrorType<HTTPValidationErrorDTO>

/**
 * @summary Add Albums To Group
 */
export const usePostRequestAlbumGroupsMembersByGroupUuid = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestAlbumGroupsMembersByGroupUuid>>,
        TError,
        { groupUuid: string; data: BodyType<AlbumListModelDTO> },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestAlbumGroupsMembersByGroupUuid>>,
    TError,
    { groupUuid: string; data: BodyType<AlbumListModelDTO> },
    TContext
> => {
    const mutationOptions =
        getPostRequestAlbumGroupsMembersByGroupUuidMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary Create Album Group
 */
export const postRequestAlbumGroups = (
    albumListModelDTO: BodyType<AlbumListModelDTO>,
    params?: PostRequestAlbumGroupsParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<AlbumGroupDTO>(
        {
            url: `/album/groups`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: albumListModelDTO,
            params,
            signal,
        },
        options,
    )
}

export const getPostRequestAlbumGroupsMutationOptions = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestAlbumGroups>>,
        TError,
        {
            data: BodyType<AlbumListModelDTO>
            params?: PostRequestAlbumGroupsParams
        },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestAlbumGroups>>,
    TError,
    {
        data: BodyType<AlbumListModelDTO>
        params?: PostRequestAlbumGroupsParams
    },
    TContext
> => {
    const mutationKey = ['postRequestAlbumGroups']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postRequestAlbumGroups>>,
        {
            data: BodyType<AlbumListModelDTO>
            params?: PostRequestAlbumGroupsParams
        }
    > = (props) => {
        const { data, params } = props ?? {}

        return postRequestAlbumGroups(data, params, requestOptions)
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestAlbumGroupsMutationResult = NonNullable<
    Awaited<ReturnType<typeof postRequestAlbumGroups>>
>
export type PostRequestAlbumGroupsMutationBody = BodyType<AlbumListModelDTO>
export type PostRequestAlbumGroupsMutationError =
    ErrorType<HTTPValidationErrorDTO>

/**
 * @summary Create Album Group
 */
export const usePostRequestAlbumGroups = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestAlbumGroups>>,
        TError,
        {
            data: BodyType<AlbumListModelDTO>
            params?: PostRequestAlbumGroupsParams
        },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestAlbumGroups>>,
    TError,
    {
        data: BodyType<AlbumListModelDTO>
        params?: PostRequestAlbumGroupsParams
    },
    TContext
> => {
    const mutationOptions = getPostRequestAlbumGroupsMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary Get Album Groups
 */
export const getRequestAlbumGroups = (
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<AlbumGroupDTO[]>(
        { url: `/album/groups`, method: 'GET', signal },
        options,
    )
}

export const getGetRequestAlbumGroupsQueryKey = () => {
    return [`/album/groups`] as const
}

export const getGetRequestAlbumGroupsQueryOptions = <
    TData = Awaited<ReturnType<typeof getRequestAlbumGroups>>,
    TError = ErrorType<unknown>,
>(options?: {
    query?: Partial<
        UseQueryOptions<
            Awaited<ReturnType<typeof getRequestAlbumGroups>>,
            TError,
            TData
        >
    >
    request?: SecondParameter<typeof customInstance>
}) => {
    const { query: queryOptions, request: requestOptions } = options ?? {}

    const queryKey =
        queryOptions?.queryKey ?? getGetRequestAlbumGroupsQueryKey()

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getRequestAlbumGroups>>
    > = ({ signal }) => getRequestAlbumGroups(requestOptions, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof getRequestAlbumGroups>>,
        TError,
        TData
    > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRequestAlbumGroupsQueryResult = NonNullable<
    Awaited<ReturnType<typeof getRequestAlbumGroups>>
>
export type GetRequestAlbumGroupsQueryError = ErrorType<unknown>

export function useGetRequestAlbumGroups<
    TData = Awaited<ReturnType<typeof getRequestAlbumGroups>>,
    TError = ErrorType<unknown>,
>(options: {
    query: Partial<
        UseQueryOptions<
            Awaited<ReturnType<typeof getRequestAlbumGroups>>,
            TError,
            TData
        >
    > &
        Pick<
            DefinedInitialDataOptions<
                Awaited<ReturnType<typeof getRequestAlbumGroups>>,
                TError,
                Awaited<ReturnType<typeof getRequestAlbumGroups>>
            >,
            'initialData'
        >
    request?: SecondParameter<typeof customInstance>
}): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestAlbumGroups<
    TData = Awaited<ReturnType<typeof getRequestAlbumGroups>>,
    TError = ErrorType<unknown>,
>(options?: {
    query?: Partial<
        UseQueryOptions<
            Awaited<ReturnType<typeof getRequestAlbumGroups>>,
            TError,
            TData
        >
    > &
        Pick<
            UndefinedInitialDataOptions<
                Awaited<ReturnType<typeof getRequestAlbumGroups>>,
                TError,
                Awaited<ReturnType<typeof getRequestAlbumGroups>>
            >,
            'initialData'
        >
    request?: SecondParameter<typeof customInstance>
}): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestAlbumGroups<
    TData = Awaited<ReturnType<typeof getRequestAlbumGroups>>,
    TError = ErrorType<unknown>,
>(options?: {
    query?: Partial<
        UseQueryOptions<
            Awaited<ReturnType<typeof getRequestAlbumGroups>>,
            TError,
            TData
        >
    >
    request?: SecondParameter<typeof customInstance>
}): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get Album Groups
 */

export function useGetRequestAlbumGroups<
    TData = Awaited<ReturnType<typeof getRequestAlbumGroups>>,
    TError = ErrorType<unknown>,
>(options?: {
    query?: Partial<
        UseQueryOptions<
            Awaited<ReturnType<typeof getRequestAlbumGroups>>,
            TError,
            TData
        >
    >
    request?: SecondParameter<typeof customInstance>
}): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions = getGetRequestAlbumGroupsQueryOptions(options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * @summary Get Job Events
 */
export const getRequestJobsEventsByJobUuid = (
    jobUuid: string,
    params?: GetRequestJobsEventsByJobUuidParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<JobEventsRespDTO[]>(
        {
            url: `/jobs/${encodeURIComponent(String(jobUuid))}/events`,
            method: 'GET',
            params,
            signal,
        },
        options,
    )
}

export const getGetRequestJobsEventsByJobUuidQueryKey = (
    jobUuid: string,
    params?: GetRequestJobsEventsByJobUuidParams,
) => {
    return [`/jobs/${jobUuid}/events`, ...(params ? [params] : [])] as const
}

export const getGetRequestJobsEventsByJobUuidQueryOptions = <
    TData = Awaited<ReturnType<typeof getRequestJobsEventsByJobUuid>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params?: GetRequestJobsEventsByJobUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestJobsEventsByJobUuid>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {}

    const queryKey =
        queryOptions?.queryKey ??
        getGetRequestJobsEventsByJobUuidQueryKey(jobUuid, params)

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getRequestJobsEventsByJobUuid>>
    > = ({ signal }) =>
        getRequestJobsEventsByJobUuid(jobUuid, params, requestOptions, signal)

    return {
        queryKey,
        queryFn,
        enabled: !!jobUuid,
        ...queryOptions,
    } as UseQueryOptions<
        Awaited<ReturnType<typeof getRequestJobsEventsByJobUuid>>,
        TError,
        TData
    > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRequestJobsEventsByJobUuidQueryResult = NonNullable<
    Awaited<ReturnType<typeof getRequestJobsEventsByJobUuid>>
>
export type GetRequestJobsEventsByJobUuidQueryError =
    ErrorType<HTTPValidationErrorDTO>

export function useGetRequestJobsEventsByJobUuid<
    TData = Awaited<ReturnType<typeof getRequestJobsEventsByJobUuid>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params: undefined | GetRequestJobsEventsByJobUuidParams,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestJobsEventsByJobUuid>>,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestJobsEventsByJobUuid>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestJobsEventsByJobUuid>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestJobsEventsByJobUuid<
    TData = Awaited<ReturnType<typeof getRequestJobsEventsByJobUuid>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params?: GetRequestJobsEventsByJobUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestJobsEventsByJobUuid>>,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestJobsEventsByJobUuid>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestJobsEventsByJobUuid>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestJobsEventsByJobUuid<
    TData = Awaited<ReturnType<typeof getRequestJobsEventsByJobUuid>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params?: GetRequestJobsEventsByJobUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestJobsEventsByJobUuid>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get Job Events
 */

export function useGetRequestJobsEventsByJobUuid<
    TData = Awaited<ReturnType<typeof getRequestJobsEventsByJobUuid>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    jobUuid: string,
    params?: GetRequestJobsEventsByJobUuidParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestJobsEventsByJobUuid>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions = getGetRequestJobsEventsByJobUuidQueryOptions(
        jobUuid,
        params,
        options,
    )

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * @summary Get User Events
 */
export const getRequestUserEvents = (
    params?: GetRequestUserEventsParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<UserEventsRespDTO[]>(
        { url: `/user/events`, method: 'GET', params, signal },
        options,
    )
}

export const getGetRequestUserEventsQueryKey = (
    params?: GetRequestUserEventsParams,
) => {
    return [`/user/events`, ...(params ? [params] : [])] as const
}

export const getGetRequestUserEventsQueryOptions = <
    TData = Awaited<ReturnType<typeof getRequestUserEvents>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params?: GetRequestUserEventsParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestUserEvents>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {}

    const queryKey =
        queryOptions?.queryKey ?? getGetRequestUserEventsQueryKey(params)

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getRequestUserEvents>>
    > = ({ signal }) => getRequestUserEvents(params, requestOptions, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof getRequestUserEvents>>,
        TError,
        TData
    > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRequestUserEventsQueryResult = NonNullable<
    Awaited<ReturnType<typeof getRequestUserEvents>>
>
export type GetRequestUserEventsQueryError = ErrorType<HTTPValidationErrorDTO>

export function useGetRequestUserEvents<
    TData = Awaited<ReturnType<typeof getRequestUserEvents>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params: undefined | GetRequestUserEventsParams,
    options: {
        query: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestUserEvents>>,
                TError,
                TData
            >
        > &
            Pick<
                DefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestUserEvents>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestUserEvents>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestUserEvents<
    TData = Awaited<ReturnType<typeof getRequestUserEvents>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params?: GetRequestUserEventsParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestUserEvents>>,
                TError,
                TData
            >
        > &
            Pick<
                UndefinedInitialDataOptions<
                    Awaited<ReturnType<typeof getRequestUserEvents>>,
                    TError,
                    Awaited<ReturnType<typeof getRequestUserEvents>>
                >,
                'initialData'
            >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestUserEvents<
    TData = Awaited<ReturnType<typeof getRequestUserEvents>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params?: GetRequestUserEventsParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestUserEvents>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get User Events
 */

export function useGetRequestUserEvents<
    TData = Awaited<ReturnType<typeof getRequestUserEvents>>,
    TError = ErrorType<HTTPValidationErrorDTO>,
>(
    params?: GetRequestUserEventsParams,
    options?: {
        query?: Partial<
            UseQueryOptions<
                Awaited<ReturnType<typeof getRequestUserEvents>>,
                TError,
                TData
            >
        >
        request?: SecondParameter<typeof customInstance>
    },
): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions = getGetRequestUserEventsQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * @summary Insert Album Seen Event
 */
export const postRequestUserEventsAlbumSeen = (
    insertAlbumSeenEventDTO: BodyType<InsertAlbumSeenEventDTO>,
    params: PostRequestUserEventsAlbumSeenParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<UserEventsRespDTO[]>(
        {
            url: `/user/events/album_seen`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: insertAlbumSeenEventDTO,
            params,
            signal,
        },
        options,
    )
}

export const getPostRequestUserEventsAlbumSeenMutationOptions = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestUserEventsAlbumSeen>>,
        TError,
        {
            data: BodyType<InsertAlbumSeenEventDTO>
            params: PostRequestUserEventsAlbumSeenParams
        },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestUserEventsAlbumSeen>>,
    TError,
    {
        data: BodyType<InsertAlbumSeenEventDTO>
        params: PostRequestUserEventsAlbumSeenParams
    },
    TContext
> => {
    const mutationKey = ['postRequestUserEventsAlbumSeen']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postRequestUserEventsAlbumSeen>>,
        {
            data: BodyType<InsertAlbumSeenEventDTO>
            params: PostRequestUserEventsAlbumSeenParams
        }
    > = (props) => {
        const { data, params } = props ?? {}

        return postRequestUserEventsAlbumSeen(data, params, requestOptions)
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestUserEventsAlbumSeenMutationResult = NonNullable<
    Awaited<ReturnType<typeof postRequestUserEventsAlbumSeen>>
>
export type PostRequestUserEventsAlbumSeenMutationBody =
    BodyType<InsertAlbumSeenEventDTO>
export type PostRequestUserEventsAlbumSeenMutationError =
    ErrorType<HTTPValidationErrorDTO>

/**
 * @summary Insert Album Seen Event
 */
export const usePostRequestUserEventsAlbumSeen = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestUserEventsAlbumSeen>>,
        TError,
        {
            data: BodyType<InsertAlbumSeenEventDTO>
            params: PostRequestUserEventsAlbumSeenParams
        },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestUserEventsAlbumSeen>>,
    TError,
    {
        data: BodyType<InsertAlbumSeenEventDTO>
        params: PostRequestUserEventsAlbumSeenParams
    },
    TContext
> => {
    const mutationOptions =
        getPostRequestUserEventsAlbumSeenMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary Post User Feedback
 */
export const postRequestUserFeedback = (
    feedbackDataDTO: BodyType<FeedbackDataDTO>,
    params: PostRequestUserFeedbackParams,
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<unknown>(
        {
            url: `/user_feedback`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: feedbackDataDTO,
            params,
            signal,
        },
        options,
    )
}

export const getPostRequestUserFeedbackMutationOptions = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestUserFeedback>>,
        TError,
        {
            data: BodyType<FeedbackDataDTO>
            params: PostRequestUserFeedbackParams
        },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
    Awaited<ReturnType<typeof postRequestUserFeedback>>,
    TError,
    { data: BodyType<FeedbackDataDTO>; params: PostRequestUserFeedbackParams },
    TContext
> => {
    const mutationKey = ['postRequestUserFeedback']
    const { mutation: mutationOptions, request: requestOptions } = options
        ? options.mutation &&
          'mutationKey' in options.mutation &&
          options.mutation.mutationKey
            ? options
            : { ...options, mutation: { ...options.mutation, mutationKey } }
        : { mutation: { mutationKey }, request: undefined }

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postRequestUserFeedback>>,
        {
            data: BodyType<FeedbackDataDTO>
            params: PostRequestUserFeedbackParams
        }
    > = (props) => {
        const { data, params } = props ?? {}

        return postRequestUserFeedback(data, params, requestOptions)
    }

    return { mutationFn, ...mutationOptions }
}

export type PostRequestUserFeedbackMutationResult = NonNullable<
    Awaited<ReturnType<typeof postRequestUserFeedback>>
>
export type PostRequestUserFeedbackMutationBody = BodyType<FeedbackDataDTO>
export type PostRequestUserFeedbackMutationError =
    ErrorType<HTTPValidationErrorDTO>

/**
 * @summary Post User Feedback
 */
export const usePostRequestUserFeedback = <
    TError = ErrorType<HTTPValidationErrorDTO>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postRequestUserFeedback>>,
        TError,
        {
            data: BodyType<FeedbackDataDTO>
            params: PostRequestUserFeedbackParams
        },
        TContext
    >
    request?: SecondParameter<typeof customInstance>
}): UseMutationResult<
    Awaited<ReturnType<typeof postRequestUserFeedback>>,
    TError,
    { data: BodyType<FeedbackDataDTO>; params: PostRequestUserFeedbackParams },
    TContext
> => {
    const mutationOptions = getPostRequestUserFeedbackMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary Get Well Known Jwks
 */
export const getRequestWellKnownOpenidConfigurationJwks = (
    options?: SecondParameter<typeof customInstance>,
    signal?: AbortSignal,
) => {
    return customInstance<JwkRespDTO>(
        {
            url: `/.well-known/openid-configuration/jwks`,
            method: 'GET',
            signal,
        },
        options,
    )
}

export const getGetRequestWellKnownOpenidConfigurationJwksQueryKey = () => {
    return [`/.well-known/openid-configuration/jwks`] as const
}

export const getGetRequestWellKnownOpenidConfigurationJwksQueryOptions = <
    TData = Awaited<
        ReturnType<typeof getRequestWellKnownOpenidConfigurationJwks>
    >,
    TError = ErrorType<unknown>,
>(options?: {
    query?: Partial<
        UseQueryOptions<
            Awaited<
                ReturnType<typeof getRequestWellKnownOpenidConfigurationJwks>
            >,
            TError,
            TData
        >
    >
    request?: SecondParameter<typeof customInstance>
}) => {
    const { query: queryOptions, request: requestOptions } = options ?? {}

    const queryKey =
        queryOptions?.queryKey ??
        getGetRequestWellKnownOpenidConfigurationJwksQueryKey()

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof getRequestWellKnownOpenidConfigurationJwks>>
    > = ({ signal }) =>
        getRequestWellKnownOpenidConfigurationJwks(requestOptions, signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof getRequestWellKnownOpenidConfigurationJwks>>,
        TError,
        TData
    > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRequestWellKnownOpenidConfigurationJwksQueryResult = NonNullable<
    Awaited<ReturnType<typeof getRequestWellKnownOpenidConfigurationJwks>>
>
export type GetRequestWellKnownOpenidConfigurationJwksQueryError =
    ErrorType<unknown>

export function useGetRequestWellKnownOpenidConfigurationJwks<
    TData = Awaited<
        ReturnType<typeof getRequestWellKnownOpenidConfigurationJwks>
    >,
    TError = ErrorType<unknown>,
>(options: {
    query: Partial<
        UseQueryOptions<
            Awaited<
                ReturnType<typeof getRequestWellKnownOpenidConfigurationJwks>
            >,
            TError,
            TData
        >
    > &
        Pick<
            DefinedInitialDataOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestWellKnownOpenidConfigurationJwks
                    >
                >,
                TError,
                Awaited<
                    ReturnType<
                        typeof getRequestWellKnownOpenidConfigurationJwks
                    >
                >
            >,
            'initialData'
        >
    request?: SecondParameter<typeof customInstance>
}): DefinedUseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestWellKnownOpenidConfigurationJwks<
    TData = Awaited<
        ReturnType<typeof getRequestWellKnownOpenidConfigurationJwks>
    >,
    TError = ErrorType<unknown>,
>(options?: {
    query?: Partial<
        UseQueryOptions<
            Awaited<
                ReturnType<typeof getRequestWellKnownOpenidConfigurationJwks>
            >,
            TError,
            TData
        >
    > &
        Pick<
            UndefinedInitialDataOptions<
                Awaited<
                    ReturnType<
                        typeof getRequestWellKnownOpenidConfigurationJwks
                    >
                >,
                TError,
                Awaited<
                    ReturnType<
                        typeof getRequestWellKnownOpenidConfigurationJwks
                    >
                >
            >,
            'initialData'
        >
    request?: SecondParameter<typeof customInstance>
}): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
export function useGetRequestWellKnownOpenidConfigurationJwks<
    TData = Awaited<
        ReturnType<typeof getRequestWellKnownOpenidConfigurationJwks>
    >,
    TError = ErrorType<unknown>,
>(options?: {
    query?: Partial<
        UseQueryOptions<
            Awaited<
                ReturnType<typeof getRequestWellKnownOpenidConfigurationJwks>
            >,
            TError,
            TData
        >
    >
    request?: SecondParameter<typeof customInstance>
}): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get Well Known Jwks
 */

export function useGetRequestWellKnownOpenidConfigurationJwks<
    TData = Awaited<
        ReturnType<typeof getRequestWellKnownOpenidConfigurationJwks>
    >,
    TError = ErrorType<unknown>,
>(options?: {
    query?: Partial<
        UseQueryOptions<
            Awaited<
                ReturnType<typeof getRequestWellKnownOpenidConfigurationJwks>
            >,
            TError,
            TData
        >
    >
    request?: SecondParameter<typeof customInstance>
}): UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
} {
    const queryOptions =
        getGetRequestWellKnownOpenidConfigurationJwksQueryOptions(options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: DataTag<QueryKey, TData, TError>
    }

    query.queryKey = queryOptions.queryKey

    return query
}
