import { FlowPrompt, PurchaseProductInfo } from '@capture/capture-components'
import SvgArrowBack from '@capture/capture-components/src/icons/ArrowBack'
import { RightPeriodDTO } from '@capture/client-api/src/orval'
import { useDispatch, useSelector } from 'react-redux'
import { subscribeToStoragePlan } from '~/API/storagePlan'
import { _ } from '~/assets/localization/util'
import { useStorageSelect } from '~/components/Settings/Storage/useStorageSelect'
import { StripePayment } from '~/components/StripePaymentPrompt'
import { SelectedPlanDescription } from '~/components/SubscribeModal/StepSub'
import { getAvailableTrialPeriod } from '~/state/storagePlan/selectors'

export const StepStripe = (props: {
    selectedPlanId: string
    onBackButtonClick: () => void
    onPurchasePlanConfirmed: () => void
}) => {
    const { selectedPlanId, onBackButtonClick, onPurchasePlanConfirmed } = props
    const { selectedPlan } = useStorageSelect(selectedPlanId)
    const dispatch = useDispatch()
    const availableTrialPeriod = useSelector(getAvailableTrialPeriod)

    const selectedPlanDescription = _('with_free_trial_detail')
        .replace('%num_free_trial%', availableTrialPeriod.toString())
        .replace('%price_string%', selectedPlan!.priceString)
        .replace(
            '%period%',
            selectedPlan!.period === RightPeriodDTO.monthly
                ? _('month').toLowerCase()
                : _('year').toLowerCase(),
        )

    return (
        <StripePayment.Content
            title={_('enter_payment_information')}
            icon={
                <FlowPrompt.IconButton
                    icon={SvgArrowBack}
                    onPress={onBackButtonClick}
                />
            }
            hideCloseButton={true}
            preventCloseOnClickOutside={true}
            paymentForm={
                <StripePayment.PaymentForm
                    confirmText={_('subscribe_with_free_trial').replace(
                        '%num_free_trial%',
                        availableTrialPeriod.toString(),
                    )}
                    onConfirmSucceeded={async () => {
                        try {
                            await subscribeToStoragePlan(
                                dispatch,
                                selectedPlan!.id,
                            )
                        } finally {
                            onPurchasePlanConfirmed()
                        }
                    }}
                    onCancel={onBackButtonClick}>
                    <SelectedPlanDescription>
                        {selectedPlanDescription}
                    </SelectedPlanDescription>
                </StripePayment.PaymentForm>
            }>
            {
                <PurchaseProductInfo
                    sizeText={selectedPlan!.storage}
                    priceText={selectedPlan!.label}
                />
            }
        </StripePayment.Content>
    )
}
