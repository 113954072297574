import { isStagingEnv, isTestEnv } from '~/config/constants'
import type { Action } from '~/state/common/actions'
import type { EventProps } from './trackers/CumulusTracker'
import { InternalTracker } from './trackers/CumulusTracker'

export interface EventTracker {
    handleTrackingConsentGranted: () => void // Start by assuming no consent is given.
    trackEvent: (
        context: string,
        event: string,
        label?: string,
        value?: number,
    ) => void
    trackPageChange: (newPath: string, hash?: string) => void
    trackUserID: (userId: string) => void
    trackAction: (action: Action, getState: () => unknown) => void
    setDimension?: (dimensionName: string, value: string) => void
}

interface EnvironmentOptions {
    emitOnDev?: boolean
    emitOnStaging?: boolean
}

const trackers: {
    externalTrackers: EventTracker[]
    internalTrackers: InternalTracker[]
} = { externalTrackers: [], internalTrackers: [] }

let hasTrackingConsentBeenGranted = false

export const allowInternalTracking = () => {
    if (!hasTrackingConsentBeenGranted) {
        trackers.internalTrackers.forEach((t) =>
            t.handleTrackingConsentGranted(),
        )
    }
}

export const trackingConsentHasBeenGranted = () => {
    if (!hasTrackingConsentBeenGranted) {
        hasTrackingConsentBeenGranted = true
        trackers.internalTrackers.forEach((t) =>
            t.handleTrackingConsentGranted(),
        )
        trackers.externalTrackers.forEach((t) =>
            t.handleTrackingConsentGranted(),
        )
    }
}

export function addTracker(t: EventTracker) {
    if (hasTrackingConsentBeenGranted) {
        t.handleTrackingConsentGranted()
    }
    trackers.externalTrackers.push(t)
}

export const addTrackerInternal = (getUserID: () => string) => {
    trackers.internalTrackers.push(new InternalTracker(getUserID))
}

// External trackers actions
export function trackEvent(
    context: string,
    event: string,
    label?: string,
    value?: number,
    options?: EnvironmentOptions,
) {
    if (isTestEnv || isStagingEnv) {
        console.debug(
            `Sending external analytics event \nContext: ${context} \nEvent: ${event} \nLabel: ${label} \nValue: ${value}`,
        )
    }
    if (isTestEnv && !options?.emitOnDev) return
    if (isStagingEnv && !options?.emitOnStaging) return
    trackers.externalTrackers.forEach((t) =>
        t.trackEvent(context, event, label, value),
    )
}

export const trackPageChange = (
    newPath: string,
    hash?: string,
    options?: EnvironmentOptions,
) => {
    if (isTestEnv && !options?.emitOnDev) return
    if (isStagingEnv && !options?.emitOnStaging) return
    trackers.externalTrackers.forEach((t) => t.trackPageChange(newPath, hash))
}

export const trackUserID = (userId: string, options?: EnvironmentOptions) => {
    if (isTestEnv && !options?.emitOnDev) return
    if (isStagingEnv && !options?.emitOnStaging) return
    trackers.externalTrackers.forEach((t) => t.trackUserID(userId))
}

export const trackAction = (
    action: Action,
    getState: () => unknown,
    options?: EnvironmentOptions,
) => {
    if (isTestEnv && !options?.emitOnDev) return
    if (isStagingEnv && !options?.emitOnStaging) return
    trackers.externalTrackers.forEach((t) => t.trackAction(action, getState))
}
export const setDimension = (
    dimension: string,
    value: string,
    options?: EnvironmentOptions,
) => {
    if (isTestEnv && !options?.emitOnDev) return
    if (isStagingEnv && !options?.emitOnStaging) return
    trackers.externalTrackers.forEach((t) => t.setDimension?.(dimension, value))
}

// Internal trackers actions
export function trackMultipleEventsInternal(
    events: EventProps[],
    options?: EnvironmentOptions,
) {
    if (isTestEnv && !options?.emitOnDev) return
    if (isStagingEnv && !options?.emitOnStaging) return
    trackers.internalTrackers.forEach((t) => t.trackEvents(events))
}

export function trackEventInternal(
    eventName: EventProps['eventName'],
    value?: EventProps['eventValue'],
    options?: EnvironmentOptions,
) {
    if (isTestEnv || isStagingEnv) {
        console.debug(
            `Sending internal analytics event \nAction: ${eventName}, Payload: ${JSON.stringify(
                value,
            )}`,
        )
    }
    if (isTestEnv && !options?.emitOnDev) return
    if (isStagingEnv && !options?.emitOnStaging) return
    trackers.internalTrackers.forEach((t) => t.trackEvent(eventName, value))
}

export const trackActionInternal = (
    action: Action,
    options?: EnvironmentOptions,
) => {
    if (isTestEnv || isStagingEnv) {
        console.debug(
            `Sending internal analytics event \nAction: ${JSON.stringify(
                action,
            )}`,
        )
    }
    if (isTestEnv && !options?.emitOnDev) return
    if (isStagingEnv && !options?.emitOnStaging) return
    trackers.internalTrackers.forEach((t) => t.trackAction(action))
}

export const trackClientStatsInternal = (
    timeOfLastForegroundEvent: number,
    coolDownPeriod: number,
    options?: EnvironmentOptions,
) => {
    if (isTestEnv && !options?.emitOnDev) return
    if (isStagingEnv && !options?.emitOnStaging) return
    trackers.internalTrackers.forEach((t) =>
        t.trackClientStats(timeOfLastForegroundEvent, coolDownPeriod),
    )
}
