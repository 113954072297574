import { FlowPrompt, Typography } from '@capture/capture-components'
import { _ } from '~/assets/localization/util'

export const StepNoSub = (props: {
    onPrimaryButtonClick: () => void
    onSecondaryButtonClick: () => void
}) => {
    const { onPrimaryButtonClick, onSecondaryButtonClick } = props

    return (
        <FlowPrompt.Content
            title={_('no_subscription_title')}
            hideCloseButton={true}
            preventCloseOnClickOutside={true}>
            <Typography.Paragraph>
                {_('no_subscription_limit')}
                <br /> <br />
                {_('no_subscription_reconsider')}
            </Typography.Paragraph>
            <FlowPrompt.ButtonsSection>
                <FlowPrompt.ActionButton
                    variant="primary"
                    type="submit"
                    onPress={onPrimaryButtonClick}
                    key="primary">
                    {_('subscribe_skip')}
                </FlowPrompt.ActionButton>
                <FlowPrompt.ActionButton
                    variant="secondary"
                    $fluid
                    onPress={onSecondaryButtonClick}
                    key="secondary">
                    {_('go_back')}
                </FlowPrompt.ActionButton>
            </FlowPrompt.ButtonsSection>
        </FlowPrompt.Content>
    )
}
