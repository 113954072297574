import {
    FlowPrompt,
    Typography,
    SubscriptionsGrid,
} from '@capture/capture-components'
import SvgArrowBack from '@capture/capture-components/src/icons/ArrowBack'
import { RightPeriodDTO } from '@capture/client-api/src/orval'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { _ } from '~/assets/localization/util'
import { colors } from '~/assets/styleConstants'
import { useStorageSelect } from '~/components/Settings/Storage/useStorageSelect'
import { isMinSky, TELENOR_MINE_SIDER_LINK } from '~/config/constants'
import { getAvailableTrialPeriod } from '~/state/storagePlan/selectors'

const ScrollablePlanContainer = styled.div`
    overflow-x: auto;
`

const BusinessSecondaryText = styled(Typography.Secondary)`
    a {
        text-decoration: underline;
        color: ${colors.info};
    }
`

export const SelectedPlanDescription = styled(Typography.Paragraph)`
    text-align: center;
    color: ${colors.captureGreen};
`

export const StepSub = (props: {
    selectedPlanId: string | undefined
    onSelectedPlanIdChange: (planId: string) => void
    onPrimaryButtonClick: () => void
    onBackButtonClick: () => void
}) => {
    const {
        selectedPlanId,
        onSelectedPlanIdChange,
        onPrimaryButtonClick,
        onBackButtonClick,
    } = props
    const { planGroups, selectedPlan } = useStorageSelect(selectedPlanId)
    const availableTrialPeriod = useSelector(getAvailableTrialPeriod)

    const selectedPlanDescription = selectedPlan
        ? _('with_free_trial_detail')
              .replace('%num_free_trial%', availableTrialPeriod.toString())
              .replace('%price_string%', selectedPlan.priceString)
              .replace(
                  '%period%',
                  selectedPlan.period === RightPeriodDTO.monthly
                      ? _('month').toLowerCase()
                      : _('year').toLowerCase(),
              )
        : undefined

    return (
        <FlowPrompt.Content
            hideCloseButton={true}
            title={_('choose_subscription')}
            preventCloseOnClickOutside={true}
            icon={
                <FlowPrompt.IconButton
                    icon={SvgArrowBack}
                    onPress={onBackButtonClick}
                />
            }>
            <Typography.Paragraph>
                {_('select_plan_to_see_details')}
            </Typography.Paragraph>
            <ScrollablePlanContainer>
                <SubscriptionsGrid.PlansTable
                    headers={[_('storage'), _('monthly_sub'), _('yearly_sub')]}
                    plans={planGroups}
                    onChoosePlan={onSelectedPlanIdChange}
                />
            </ScrollablePlanContainer>
            {selectedPlan && (
                <SelectedPlanDescription>
                    {selectedPlanDescription}
                </SelectedPlanDescription>
            )}
            <FlowPrompt.ButtonsSection>
                <FlowPrompt.ActionButton
                    variant="primary"
                    type="submit"
                    isDisabled={!selectedPlan}
                    onPress={onPrimaryButtonClick}
                    key="primary">
                    {_('subscribe_with_free_trial').replace(
                        '%num_free_trial%',
                        availableTrialPeriod.toString(),
                    )}
                </FlowPrompt.ActionButton>
            </FlowPrompt.ButtonsSection>
            {isMinSky && (
                <BusinessSecondaryText
                    dangerouslySetInnerHTML={{
                        __html: _('business_users_html__format')
                            .replace('%telenor_mine_sider%', _('mine_sider'))
                            .replace(
                                '%mine_sider_link%',
                                TELENOR_MINE_SIDER_LINK,
                            ),
                    }}
                />
            )}
        </FlowPrompt.Content>
    )
}
