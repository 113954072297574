/**
 * Generated by orval v7.7.0 🍺
 * Do not edit manually.
 * FastAPI
 * OpenAPI spec version: 0.1.0
 */

export enum ConsentKeyDTO {
    facial_recognition = 'facial_recognition',
    advanced_filtering_and_categorization = 'advanced_filtering_and_categorization',
    face_grouping = 'face_grouping',
    help_us_improve = 'help_us_improve',
    stay_up_to_date__email = 'stay_up_to_date__email',
    stay_up_to_date__push_notification = 'stay_up_to_date__push_notification',
    stay_up_to_date__sms = 'stay_up_to_date__sms',
}
