/**
 * Generated by orval v7.7.0 🍺
 * Do not edit manually.
 * FastAPI
 * OpenAPI spec version: 0.1.0
 */

export enum RollbackErrorReasonDTO {
    quota_exceeded_error = 'quota_exceeded_error',
    file_not_found_error = 'file_not_found_error',
    forbidden_error = 'forbidden_error',
    file_is_trashed_error = 'file_is_trashed_error',
    is_directory_error = 'is_directory_error',
    unable_to_rollback_error = 'unable_to_rollback_error',
    not_master_error = 'not_master_error',
    not_latest_revision_error = 'not_latest_revision_error',
}
