import type {
    RequiredCommonQueryParams,
    StripeProductsMode,
} from '~/@types/backend-types'
import { getRandomUUID } from '~/utilities/uuid'
import { localStorageGet, localStorageSet } from '~/utilities/webStorage'

/**
 * This file contains constants used throughout the app, and also a set of semi-constants that is based on the provisioning of the app.
 * As the exact same bundle are to be deployed in any environment and expected to work accordingly,
 * the provisioning is done runtime, based on where (domain) the application is deployed, in this file.
 */

export const getDeviceProps = (): {
    device_id: string
    device_name: string
} => {
    const stored = JSON.parse(
        atob(localStorageGet('capture_device') || btoa('{}')),
    )
    if (
        stored?.device_id &&
        stored.device_name &&
        stored.device_id !== 'fae886eb-0be9-471a-a174-6ea95a002f86' // old hardcoded ID
    ) {
        return stored
    }

    const device_id = getRandomUUID()
    const device_name = isMinSky ? 'Min Sky Web' : 'Capture Web'
    localStorageSet(
        'capture_device',
        btoa(JSON.stringify({ device_id, device_name })),
    )
    return { device_id, device_name }
}
export const getSessionID = () => {
    const id = atob(localStorageGet('session_ID') || btoa(''))
    if (id) {
        return id
    }
    const newID = getRandomUUID()
    localStorageSet('session_ID', btoa(newID))
    return newID
}

export type LanguageDefinition = {
    key: string
    name: string
    appLang?: RequiredCommonQueryParams['app_lang']
    connectKey: string
}

export const appLanguages: LanguageDefinition[] = [
    {
        key: 'nb_NO',
        name: 'Norsk',
        appLang: 'no',
        connectKey: 'no',
    },
    {
        key: 'en_US',
        name: 'English',
        appLang: 'en',
        connectKey: 'en',
    },
    {
        key: 'sv_SE',
        name: 'Svenska',
        appLang: 'se',
        connectKey: 'sv',
    },
    {
        key: 'bg_BG',
        name: 'Български',
        connectKey: 'bg',
    },
]

// When deployed on .no-domains, the product is "Min Sky", not "Capture"
export const isMinSky =
    typeof location === 'object' && /\.no$/.test(location.hostname)

// When deployed on a *.univex.no-domain, a *.cptr.no-domain, or running in dev-mode, we are in testing environment
export const isTestEnv =
    (import.meta.env !== undefined && import.meta.env.DEV) ||
    (typeof location === 'object' &&
        (/(\.univex\.no)$/.test(location.hostname) ||
            location.hostname === 'redux-test' ||
            location.hostname === '0.0.0.0' ||
            location.hostname === 'localhost'))

// When deployed on a staging.*-domain, we are in staging environment
export const isStagingEnv =
    typeof location === 'object' && location.hostname.indexOf('staging.') !== -1

export const isLocalhost =
    typeof location === 'object' && location.hostname === 'localhost'

// Tool-function to provide environment-specific values
export function envSpecificValue<T>(
    testValue: T,
    stagingValue: T,
    productionValue: T,
): T {
    return isTestEnv ? testValue : isStagingEnv ? stagingValue : productionValue
}

const reduxHosts = {
    testing: isLocalhost ? 'localhost:9000' : 'redux.webdeployment.univex.no',
    staging: 'web-staging.min-sky.no',
    production: 'web.min-sky.no',
}

export const REDUX_HOST = reduxHosts[isTestEnv ? 'testing' : 'production']

export const PHOTO_EDITOR_SDK_API_KEY = isTestEnv
    ? '{"api_token":"pP9xdbzZsGIcbxedGcRsdQ","app_identifiers":["localhost"],"available_actions":[],"domains":["https://api.photoeditorsdk.com"],"enterprise_license":true,"expires_at":null,"features":["noexporttracking","camera","library","export","customassets","whitelabel","adjustment","brush","filter","focus","frame","overlay","sticker","text","textdesign","transform"],"issued_at":1656579399,"minimum_sdk_version":"1.0","owner":"Telenor Software Lab AS","platform":"HTML5","products":["pesdk"],"version":"2.4","signature":"iQ1t7qLbhv1xuv2OtiOOcZRej7HuxtN1Yk4Fi4qSv4LNALe4JIxNsnW9ndPUnV/iTyvucbjho+JXyjA1/20qqAoYlDXrN1d6G6ol0Bry2DClyjdVyOXCHy1HSkorU18ct8C3qQqdQxNOMySBoIq3gyDLqSSLf+PEfTw6rbdkFnWUHU1+omTNoBrSNpTZ94aJgBgvEImqkcL0VAoS6u4UbwbRSGcT+Codvf1PbZry6Hm9JBTRIX7gL63u/kmcMCkE2LG9qHWtIJlzgCgJeBnHaig39WiHGC6AfQuW059DOkDMJ1zX9TDqc3oHIiXyJYm0blBf9dH/ZNzIITXAalO0AGfjwJZRKpRZ2dJQ/v/40w90ZHoceYgZmpFHDXrobppA+HIXgyHn+W8sQ+UXQmCwCSvWL6O9j4O5yXvvjskkcWFGQlN/86kjRV9ei3P3+ps5K7sj9mCph1mAlBg0EsapyN5Smb4wg7vga6UBwvQbVW6Y6kzDZBNbq/7oYsjoPoV0RrhcH0f3iDBlS91EytMcEOCIsrvHwZOkUe9WmmoYUMfQZecJZ6KgA+hO3Ib1qGvXkCrS6t8pT1OjmXlYkujCczb3jzawl4k/0BTX0T/gMaRzNGvG7eXxWXtIcsQpPiOVqwEU3GPhcF7wjGV4zEF1vrtRKh2JWwg4VMI1x4bdSks="}'
    : '{"api_token":"pP9xdbzZsGIcbxedGcRsdQ","app_identifiers":["min-sky.no","web.min-sky.no","capture-app.com","web.capture-app.com","web-staging.min-sky.no","web-staging.capture-app.com"],"available_actions":[],"domains":["https://api.photoeditorsdk.com"],"enterprise_license":true,"expires_at":null,"features":["noexporttracking","camera","library","export","customassets","whitelabel","adjustment","brush","filter","focus","frame","overlay","sticker","text","textdesign","transform"],"issued_at":1673252507,"minimum_sdk_version":"1.0","owner":"Telenor Software Lab AS","platform":"HTML5","products":["pesdk"],"version":"2.4","signature":"az/yMTjWNzdm7Le/b57C2b8oGbTicBS3oysj3a2K5oj6xHmXX82mAf5mS9Acc//vmMq4110QyQvLp5psnC17l03DYtJObDcYaEP0fluxWGsvbXQJJWJ1EqAYM9WkPmBfLuCne3tPGGyT6yz9KGz5qoeAQuBlXF3KEViWhrKc5D9S5EbCyLYbeDJrmrgydC4ZGYAxWhk00BklrDS8t8MyRmmCKXzH/8JV3uJDm4LIdzTku/45kSPFRj/Odm+dOt5BTtFM2/i5MTWKNl+pKfiOUT8RJOVPEOs9oOnI98TcDeTr6xVcVhGlTMLv/h41AtBSH+UFcu2dOM/xsdQBDnRfEvhFylahH2kzybfLD7ljR14GgpVS1LiII+xtGh4hjpuR/HFNpQms3KUKuw+RNu7qT5drrmfc9HjP2KiLA4SfIRER921QLBcts0eKtgKvRrN314YL16FQt9WnHh2/3no7GBEiIcEkL4xEuuUQQq/GZ9hAAT5P2ltZ4qLYpTUxJjKiUPKxRsLHlSMoaa8gm58Di7U1twMOpicQmsVzJJ+bZx4TC1zjvsE2xSaQ/1UiCicQ4tNFA5UcdUKSbW7NC/Wp0g2Pp8NgpEjXBmQuc1loYHtmdvE3wFUpMVfzm9HwfRrz/K/wg5W5h9AhKbAnrRrJntUI3sgjuycVW5H4cwvZa4I="}'

// Try to get some language-setting from browser - and make sure it is one of the languages we know
const getValidatedBrowserLocale = () => {
    // Type-mock window.navigator to allow compiling with fallback-parameters for legacy-browsers and IE
    type Nav = Partial<NavigatorLanguage> & { userLanguage?: string }
    const n: Nav =
        typeof window !== 'undefined' && window.navigator
            ? window.navigator
            : {}
    const lang = n.languages ? n.languages[0] : n.language || n.userLanguage
    if (lang) {
        // The browser returns the user-language in different formats (may be 'no', 'nb', 'nb-no', 'nb_NO').
        if (lang === 'no') {
            return appLanguages[0].key
        }
        // Therefore: Find match by comparing first two letters
        const matches = appLanguages.filter(
            (l) => l.key.substring(0, 2) === lang.substring(0, 2),
        )
        if (matches.length > 0) {
            return matches[0].key
        }
    }
}

const defaultLocale = isMinSky ? 'nb_NO' : 'en_US'

export const setCurrentLocale = (langCode: string) => {
    document.cookie = `lang=${langCode};`
    window.location.reload()
}

export const getCurrentLocale = (): string => {
    // To be consistent with the way web-legacy stores and detects language, we are using a cookie-parameter "lang" to store the user language.
    // If the key is not present or is invalid, we fall back to the default language

    const langFromCookie: string | undefined = (/\blang=(\w+)\b/.exec(
        typeof document === 'object' ? document.cookie : '',
    ) || [])[1]
    if (
        langFromCookie &&
        appLanguages.filter((l) => l.key === langFromCookie).length === 1
    ) {
        return langFromCookie
    }
    return getValidatedBrowserLocale() || defaultLocale
}

const currentLocale = getCurrentLocale()

export const getCurrentLangDefinition = (): LanguageDefinition => {
    return (
        appLanguages.find((def) => def.key === currentLocale) ?? appLanguages[0]
    )
}

export const PRODUCT_NAME = isMinSky ? 'Min Sky' : 'Capture'

export const HOME_SITE_ADDRESS = isMinSky
    ? 'https://min-sky.no'
    : 'https://capture-app.com'
export const LOGGED_IN_SITE_ADDRESS = isMinSky
    ? 'https://web.min-sky.no'
    : 'https://web.capture-app.com'
export const SUPPORT_SITE_ADDRESS = isMinSky
    ? 'https://support.min-sky.no'
    : 'https://support.capture-app.com'
export const WELCOME_FAQ = isMinSky
    ? 'https://min-sky.no/welcome-faq'
    : 'https://capture-app.com/welcome-faq'
export const SUPPORT_SITE_ADDRESS_FOR_ACCOUNT_TERMINATED = isMinSky
    ? 'https://support.min-sky.no/hc/no/articles/236352848.html'
    : 'https://support.capture-app.com/hc/en-us/articles/236352848.html'

export const SUNSET_FAQ =
    currentLocale === 'sv_SE'
        ? 'https://capture-app.com/gratisversion-upphoer'
        : 'https://min-sky.no/gratisversjon-avsluttes'

export const APP_STORE_LINK_ANDROID =
    'https://play.google.com/store/apps/details?id=com.comoyo.capture'
export const APP_STORE_LINK_IOS = `https://itunes.apple.com/no/app/min-sky/id633980795?l=${currentLocale}&mt=8`

export const stripeMode: StripeProductsMode = isTestEnv ? 'test' : 'production'
export const STRIPE_API_TOKEN =
    stripeMode === 'test'
        ? 'pk_test_gh4Dv8dlZjJ2b5ONdOagN3jd'
        : 'pk_live_k4mPM31bcoAL1f5AtCCx9lav'

export const TERMS_OF_SERVICE_LINK =
    currentLocale === 'nb_NO'
        ? 'https://capture-app.com/ToS/capturetos-no.html'
        : 'https://capture-app.com/ToS/capturetos-en.html'

export const CUSTOMER_SERVICE_LINK = `${HOME_SITE_ADDRESS}/support?language=/hc/${
    getCurrentLangDefinition().connectKey
}`

export const TELENOR_MOBILE_PLANS_LINK =
    'https://www.telenor.no/privat/mobil/mobilabonnement/'
export const TELENOR_MANAGEID_LINK = 'https://manage.telenorid.com/'
export const TELENOR_MINE_SIDER_LINK =
    'https://www.telenor.no/mitt-telenor/mobil/'
export const TELENOR_PRIVACY_SETTINGS_LINK =
    'https://privacy.telenordigital.com/'

export const PRIVACY_STATEMENT_LINK =
    currentLocale === 'nb_NO'
        ? 'https://capture-app.com/ToS/privacy-no.html'
        : 'https://capture-app.com/ToS/privacy-en.html'
export const LEGAL_LINK =
    currentLocale === 'nb_NO'
        ? 'https://softwarelab.no/legal/index-no.html'
        : 'https://softwarelab.no/legal/index-en.html'

export const SIZE_DOWNLOAD_LIMIT: number = 15 * Math.pow(2, 30) // = 15 GB

export const RECEIVER_APPLICATION_ID = isTestEnv ? 'EF83CF99' : 'BAAFE086'
export const CHROMECAST_MESSAGE_CHANNEL = 'urn:x-cast:tnnCapture'

// Client set traash items per page limit when fetching trash items through pagination
export const TRASH_FETCH_LIMIT = 1000
