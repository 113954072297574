import type { Merge } from 'type-fest'
import type { RollbackErrorReasonDTO } from '@capture/client-api/src/orval'
import type { JobFile } from '~/@types/backend-types'
import type { AppService } from '~/API/services/AppService'
import type { AlbumViewMode } from '../album/selectors'
import { createActionCreator, createVoidActionCreator } from '../common/actions'

export const StartFetchingDefaultJob = createVoidActionCreator(
    'START_FETCHING_DEFAULT_JOB',
)

export const FetchedDefaultJob = createActionCreator<JobID>(
    'FETCHED_DEFAULT_JOB',
)
export const FetchedLastSerialOfDefaultJob = createActionCreator<number>(
    'FETCHED_DEFAULT_JOB_SERIAL',
)

export const UnableToFetchDefaultJob = createActionCreator<string | Error>(
    'UNABLE_TO_FETCH_DEFAULT_JOB',
)

export const UnableToFetchJobChanges = createActionCreator<JobID>(
    'UNABLE_TO_FETCH_JOB_CHANGES',
)

type FileReference = {
    jobID: JobID
    fileID: FileID
}

type MultiFileReference = {
    jobID: JobID
    files: FileID[]
}

export type FileDescription = Merge<
    Omit<JobFile, 'id' | 'aspect_ratio'>,
    FileReference & {
        user_uuid: UserID
    }
>

export const FilesWereAddedToJob = createActionCreator<FileDescription[]>(
    'FILES_WERE_ADDED_TO_JOB',
)
export type FileCopiedInfo = { from: FileID; to: FileReference }
export const FileWasCopiedToJob = createActionCreator<FileCopiedInfo>(
    'FILE_WAS_COPIED_TO_JOB',
)
export const FilesCopiedToAlbum = createActionCreator<
    MultiFileReference & { showAlbumLink?: boolean }
>('FILES_COPIED_TO_ALBUM')
export const FilesCopiedToAlbumFailed = createActionCreator<MultiFileReference>(
    'FILES_COPIED_TO_ALBUM_FAILED',
)

type FileRangePayload = {
    jobID: JobID
    start: DayRef
    end: DayRef
}
export type FilesFetchedPayload = {
    jobID: JobID
    lastEvent: number
    files: FileDescription[]
}
type FileRangeWasFetchedPayload = FilesFetchedPayload & FileRangePayload
export const FileRangeFetchingStarted = createActionCreator<FileRangePayload>(
    'FILE_RANGE_FETCHING_STARTED',
)
export const FileRangeWasFetched =
    createActionCreator<FileRangeWasFetchedPayload>('FILE_RANGE_WAS_FETHCED')
export const FileRangeFetchFailed = createActionCreator<FileRangePayload>(
    'FILE_RANGE_FETCH_FAILED',
)

export const JobChangesWasFetched = createActionCreator<{
    jobID: JobID
    lastSerial: number
}>('JOB_CHANGES_WAS_FETCHED')

export const AllJobFilesWasFetched = createActionCreator<FilesFetchedPayload>(
    'ALL_JOB_FILES_WAS_FETCHED',
)

export const FileWasRemovedFromJob = createActionCreator<FileReference>(
    'FILE_WAS_REMOVED_FROM_JOB',
)
export const FilesDeletionStarted = createActionCreator<MultiFileReference>(
    'FILES_DELETION_STARTED',
)
export const FilesDeletionSucceeded = createActionCreator<
    MultiFileReference & { usedSpace?: number }
>('FILES_DELETION_SUCCEEDED')
export const FilesDeletionFailed = createActionCreator<
    MultiFileReference & { supressRetry?: boolean }
>('FILES_DELETION_FAILED')

export const FilesRestorationStarted = createActionCreator<MultiFileReference>(
    'FILES_RESTORATION_STARTED',
)
export type FilesRestorationSucceededPayload = MultiFileReference & {
    usedSpace?: number
}
export const FilesRestorationSucceeded =
    createActionCreator<FilesRestorationSucceededPayload>(
        'FILES_RESTORATION_SUCCEEDED',
    )
export type FilesRestorationFailedPayload = MultiFileReference & {
    reasons: RollbackErrorReasonDTO[]
}
export const FilesRestorationFailed =
    createActionCreator<FilesRestorationFailedPayload>(
        'FILES_RESTORATION_FAILED',
    )

export const FileWasSetAsCoverPhoto = createActionCreator<FileReference>(
    'FILE_WAS_SET_AS_COVER_PHOTO',
)
export const SetFileAsCoverPhotoFailed = createActionCreator<FileReference>(
    'SET_FILE_AS_COVER_PHOTO_FAILED',
)
export const AlbumViewModeChanged = createActionCreator<{
    jobID: JobID
    viewMode: AlbumViewMode
}>('ALBUM_VIEWMODE_CHANGED')
export const AlbumViewModeFetched = createActionCreator<{
    jobID: JobID
    viewMode: AlbumViewMode
}>('ALBUM_VIEW_MODE_FETCHED')

export const JobInfoChangeDetected = createActionCreator<{
    jobID: JobID
    eventID: number
}>('JOB_INFO_CHANGE_DETECTED')

export const JobCopiedToTimeline = createVoidActionCreator(
    'JOB_COPIED_TO_TIMELINE',
)
export const JobCopiedToTimelineFailed = createActionCreator<{
    reason: 'out_of_storage' | 'unknown'
}>('JOB_COPIED_TO_TIMELINE_FAILED')
export type FilesDownloadType =
    | Parameters<AppService['downloadFilesFromJob']>[0]
    | 'download_deleted'
type DownloadFilesPayload = {
    type: FilesDownloadType
    jobID: JobID
    files: FileID[]
}

export const FilesDownloadSuccess = createActionCreator<DownloadFilesPayload>(
    'FILES_DOWNLOAD_SUCCESS',
)
export const FilesDownloadFailed = createActionCreator<DownloadFilesPayload>(
    'FILES_DOWNLOAD_FAILED',
)

export type JobDownloadType = Parameters<AppService['downloadJobAsArchive']>[0]
type DownloadJobPayload = {
    type: JobDownloadType
    jobID: JobID
}
export const JobDownloadSuccess = createActionCreator<DownloadJobPayload>(
    'JOB_DOWNLOAD_SUCCESS',
)
export const JobDownloadFailed = createActionCreator<DownloadJobPayload>(
    'JOB_DOWNLOAD_FAILED',
)

export const ShareWasCreated = createActionCreator<JobID>('SHARE_WAS_CREATED')
export const ShareCreationFailed = createVoidActionCreator(
    'SHARE_CREATION_FAILED',
)

export const JobWasPublished = createActionCreator<JobID>('JOB_WAS_PUBLISHED')
export const JobPublishingFailed = createActionCreator<JobID>(
    'JOB_PUBLISHING_FAILED',
)

export const imageCopiedToClipboard = createVoidActionCreator(
    'IMAGE_COPIED_TO_CLIPBOARD',
)

export const imageCopyToClipboardFailed = createVoidActionCreator(
    'IMAGE_COPY_TO_CLIPBOARD_FAILED',
)
