import type { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import axios from 'axios'

export type ResponseInterceptor = [
    (<T>(value: T) => T | Promise<T>) | undefined,
    ((error: any) => any) | undefined,
]

export interface AxiosRequestOptions extends AxiosRequestConfig {
    responseInterceptors?: ResponseInterceptor[]
}

// add a second `options` argument here if you want to pass extra options to each generated query
export const customInstance = <T>(
    config: AxiosRequestConfig,
    options?: AxiosRequestOptions,
): Promise<AxiosResponse<T>> => {
    const { responseInterceptors, ...axiosOptions } = options || {}
    const instance = axios.create({
        ...axiosOptions,
    })

    responseInterceptors?.forEach(([onFulfilled, onRejected]) => {
        instance.interceptors.response.use(onFulfilled, onRejected)
    })

    const promise = instance.request(config)

    return promise
}

// In some case with react-query and swr you want to be able to override the return error type so you can also do it here like this
export type ErrorType<Error> = AxiosError<Error>

export type BodyType<BodyData> = BodyData
