import * as React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { _ } from '~/assets/localization/util'
import { colors, fontSize } from '~/assets/styleConstants'
import { isLoggedIn } from '~/state/currentUser/selectors'
import { getViewportWidth, isMobileMode } from '~/state/viewMode/selectors'
import { goBack } from '~/utilities/navigation'
import type { ButtonProps } from '../Common/Button'
import { Button } from '../Common/Button'
import { PageWrapper } from '../Common/PageWrapper'
import { RainingCloudIcon } from '../Icons/RainingCloudIcon'
import { MainNavBarDesktop } from '../Navigation/MainNavBarDesktop'
import { NotLoggedInTopNavBar } from '../Navigation/NotLoggedInTopNavBar'
import { TopNavBarMobile } from '../Navigation/TopNavBarMobile'

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    p {
        color: ${colors.captureBlue};
        text-align: center;
        margin: 25px 0 45px 0;
        font-weight: bold;
        font-size: ${fontSize.medium_18};
    }
`

const HelpLinksWrapper = styled.div`
    margin: 0 auto;
    text-align: center;
`

const Link = styled.div`
    font-size: ${fontSize.medium_16};
    color: ${colors.captureBlue};
    cursor: pointer;
    text-decoration: underline;
    margin-bottom: 8px;
`

const HelpLink: React.FunctionComponent<ButtonProps> = (props) => (
    <Link
        onClick={props.onClick}
        onKeyUp={props.onClick}
        role="button"
        tabIndex={0}>
        {props.text}
    </Link>
)

type StateProps = {
    isMobile: boolean
    isLoggedIn: boolean
    viewportSize: number
}

type Props = StateProps

class PageNotFoundComponent extends React.Component<Props> {
    public render() {
        let navBar = null
        if (this.props.isLoggedIn) {
            navBar = this.props.isMobile ? (
                <TopNavBarMobile />
            ) : (
                <MainNavBarDesktop />
            )
        } else {
            navBar = <NotLoggedInTopNavBar />
        }

        const cloudSize = this.props.isMobile
            ? this.props.viewportSize / 2
            : this.props.viewportSize / 5

        return (
            <PageWrapper navBar={navBar}>
                <ContentWrapper>
                    <RainingCloudIcon size={cloudSize} />
                    <p>{_('oops_page_not_found')}</p>
                    <HelpLinksWrapper>
                        <HelpLink {...Button(`< ${_('go_back')}`, goBack)} />
                    </HelpLinksWrapper>
                </ContentWrapper>
            </PageWrapper>
        )
    }
}

const mapStateToProps = (
    state: StateOfSelector<typeof isMobileMode> &
        StateOfSelector<typeof isLoggedIn> &
        StateOfSelector<typeof getViewportWidth>,
): StateProps => ({
    isMobile: isMobileMode(state),
    isLoggedIn: isLoggedIn(state),
    viewportSize: getViewportWidth(state),
})

export const PageNotFound = connect(mapStateToProps)(PageNotFoundComponent)
