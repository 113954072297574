import { Form, Prompt } from '@capture/capture-components'
import type { DigitalLegacyContactDTO } from '@capture/client-api/src/orval'
import { ResponseNotOKError } from '@capture/client/API/toolbox'
import { _ } from '@capture/client/assets/localization/util'
import { fontSize, mediaQueries } from '@capture/client/assets/styleConstants'
import type { FormEvent } from 'react'
import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

const FormRoot = styled(Form.Root)`
    display: flex;
    flex-direction: column;
    gap: 2rem;
`

const FormFieldWrapper = styled.div`
    display: grid;
    grid-template: 'name phone' 'email email' 'address address';
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;

    ${mediaQueries.mobile} {
        grid-template: 'name' 'phone' 'email' 'address';
    }
`

const NameFormField = styled(Form.Field)`
    grid-area: name;
`
const PhoneNumberFormField = styled(Form.Field)`
    grid-area: phone;
`
const EmailFormField = styled(Form.Field)`
    grid-area: email;
`
const AddressFormField = styled(Form.Field)`
    grid-area: address;
    margin-bottom: -1rem;
`
const ContactNoticeText = styled.p`
    margin-top: -1.5rem;
    margin-bottom: 0;
    font-size: ${fontSize.small_14};
`

type DigitalLegacyPromptProps = {
    contactInfo?: DigitalLegacyContactDTO
    onSubmit: (contact: DigitalLegacyContactDTO) => Promise<void>
    onCancel: () => void
}

export const DigitalLegacyPrompt = ({
    contactInfo,
    onSubmit,
    onCancel,
}: DigitalLegacyPromptProps) => {
    const [name, setName] = useState(contactInfo?.name || '')
    const [phoneNumber, setPhoneNumber] = useState(
        contactInfo?.phone_number || '',
    )
    const [email, setEmail] = useState(contactInfo?.email_address || '')
    const [address, setAddress] = useState(contactInfo?.address || '')
    const [isEmailValid, setIsEmailValid] = useState(!!contactInfo)
    const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(!!contactInfo)

    const nameRef = useRef<HTMLInputElement>(null)
    const [serverError, setServerError] = useState('')

    useEffect(() => {
        nameRef.current?.focus()
    }, [])

    return (
        <Prompt.Content
            title={_('digital_legacy_provide_to_contact_modal_title')}
            preventCloseOnClickOutside={true}>
            <FormRoot
                onSubmit={async (event: FormEvent<HTMLFormElement>) => {
                    event.preventDefault()

                    const formData = Object.fromEntries(
                        new FormData(event.currentTarget),
                    )

                    try {
                        await onSubmit({
                            name: formData.name.toString(),
                            address: formData.address.toString(),
                            email_address: formData.email_address.toString(),
                            phone_number: formData.phone_number.toString(),
                        })
                    } catch (error) {
                        if (error instanceof ResponseNotOKError) {
                            error.response
                                .text()
                                .then((errorMessage: string) => {
                                    setServerError(errorMessage)
                                })
                        }
                    }
                }}>
                <FormFieldWrapper>
                    <NameFormField
                        name="name"
                        label={_('full_name').toUpperCase()}
                        required>
                        <Form.Input
                            value={name}
                            onChange={(event) => {
                                setName(event.currentTarget.value)
                            }}
                            required
                            ref={nameRef}
                        />
                    </NameFormField>
                    <PhoneNumberFormField
                        name="phone_number"
                        label={_('telephone_number').toUpperCase()}
                        required>
                        <Form.ValidityState>
                            {(validity) => (
                                <>
                                    <Form.Input
                                        value={phoneNumber}
                                        onChange={(event) => {
                                            setPhoneNumber(
                                                event.currentTarget.value,
                                            )
                                            setIsPhoneNumberValid(
                                                event.currentTarget.reportValidity(),
                                            )
                                        }}
                                        pattern="[0-9+]{8,14}"
                                        type="tel"
                                        required
                                    />
                                    {validity !== undefined && (
                                        <Form.ErrorMessage
                                            errorMatchers={[
                                                [
                                                    'patternMismatch',
                                                    _(
                                                        'digital_legacy_provide_to_contact_phone_error',
                                                    ),
                                                ],
                                            ]}
                                        />
                                    )}
                                    {validity === undefined && (
                                        <Form.HintMessage>
                                            {_(
                                                'digital_legacy_provide_to_contact_modal_email_hint',
                                            )}
                                        </Form.HintMessage>
                                    )}
                                </>
                            )}
                        </Form.ValidityState>
                    </PhoneNumberFormField>
                    <EmailFormField
                        name="email_address"
                        label={_('email').toUpperCase()}
                        required>
                        <Form.Input
                            type="email"
                            value={email}
                            onChange={(event) => {
                                setEmail(event.currentTarget.value)
                                setIsEmailValid(
                                    event.currentTarget.reportValidity(),
                                )
                            }}
                            required
                        />
                        <Form.ErrorMessage
                            errorMatchers={[
                                [
                                    'typeMismatch',
                                    _(
                                        'digital_legacy_provide_to_contact_modal_email_error',
                                    ),
                                ],
                            ]}
                        />
                    </EmailFormField>
                    <AddressFormField
                        name="address"
                        label={_('address').toUpperCase()}
                        required>
                        <Form.Input
                            value={address}
                            onChange={(event) => {
                                setAddress(event.currentTarget.value)
                            }}
                            required
                        />
                    </AddressFormField>
                </FormFieldWrapper>
                <ContactNoticeText>
                    {_('digital_legacy_provide_to_contact_modal_notice')}
                </ContactNoticeText>
                <Prompt.ButtonsSection>
                    <Prompt.ActionButton
                        variant="secondary"
                        onPress={onCancel}
                        key="secondary">
                        {_('cancel')}
                    </Prompt.ActionButton>
                    <Form.Submit asChild>
                        <Prompt.ActionButton
                            variant="primary"
                            type="submit"
                            isDisabled={
                                !name ||
                                !phoneNumber ||
                                !email ||
                                !address ||
                                !isEmailValid ||
                                !isPhoneNumberValid
                            }
                            key="primary">
                            {_('confirm')}
                        </Prompt.ActionButton>
                    </Form.Submit>
                </Prompt.ButtonsSection>
                <Prompt.ActionErrorMessage>
                    {serverError}
                </Prompt.ActionErrorMessage>
            </FormRoot>
        </Prompt.Content>
    )
}
