import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { _ } from '~/assets/localization/util'
import { setAlbumTitle } from '~/API/album'
import { colors, fontSize } from '~/assets/styleConstants'
import type { PendingAlbum } from '~/state/album/selectors'
import { isMobileMode } from '~/state/viewMode/selectors'
import { EditableInput } from '../Common/EditableInput'

const InputWrapper = styled.div`
    width: ${(props: { isMobile: boolean }) =>
        props.isMobile ? '100%' : '50%'};
    margin: 0 0 18px;
`

const ErrorText = styled.div`
    color: ${colors.warningRed};
    font-size: ${fontSize.small_12};
    padding: 8px 0;
    height: 1em;
`

export const EditAlbumTitle = ({
    album,
    albumID,
    setTitle,
}: {
    album: PendingAlbum
    albumID: string
    setTitle: (title: string) => void
}) => {
    const dispatch = useDispatch()
    const isMobile = useSelector(isMobileMode)

    // We need the input as a state to trigger the rerender for the error
    const [input, setInput] = useState<string>(album?.title ?? '')

    // Setting the error text based on the input and album type
    const errorText =
        !input.trim() && album?.isShared
            ? _('album_name_is_required_for_shared_albums')
            : undefined

    const handleEditTitleBlur = (newTitle: string) => {
        setAlbumTitle(dispatch, albumID, newTitle)
    }

    // Handle the case when you clear the default 'New album'
    const [firstInput, setFirstInput] = useState(!!album)
    const handleEditTitleFocus = () => {
        if (firstInput) {
            setFirstInput(false)
            setInput('')
        }
    }

    useEffect(() => {
        setTitle(input?.trim())
    }, [input, setTitle])

    return (
        <InputWrapper isMobile={isMobile} data-cy={'editAlbum__title'}>
            <EditableInput
                initialValue={album?.title ?? ''}
                iconSize={24}
                iconColor={colors.black}
                placeholder={_('add_album_title')}
                onChange={setInput}
                onBlur={handleEditTitleBlur}
                onFocus={handleEditTitleFocus}
                shouldClearInput={firstInput}
                maxLength={255}
                hasBottomBorder
            />
            <ErrorText data-cy="albumTitle_error">{errorText}</ErrorText>
        </InputWrapper>
    )
}
