import { type Stripe, loadStripe } from '@stripe/stripe-js'
import { STRIPE_API_TOKEN } from '~/config/constants'

let stripeObject: Stripe | null = null

export const getStripeObject = async (): Promise<Stripe> => {
    if (stripeObject) {
        return stripeObject
    }

    stripeObject = await loadStripe(STRIPE_API_TOKEN)
    if (stripeObject === null) {
        throw new Error('stripe failed to load')
    }
    return stripeObject
}
