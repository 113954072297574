import {
    FlowPrompt,
    StripeElements,
    StripePaymentElement,
    useStripeSetupIntent,
} from '@capture/capture-components'
import type { FormEvent, PropsWithChildren, ReactElement } from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { getStripeService } from '~/API/services/StripeService'
import { _ } from '~/assets/localization/util'
import { useStripeCustomer } from '~/components/StripePaymentPrompt/useStripeCustomer'
import { getEmailAdressOfLoggedInUser } from '~/state/currentUser/selectors'
import { StripeSetupIntentCreated } from '~/state/storagePlan/actions'

type StripePaymentFormProps = {
    confirmText: string
    onConfirmSucceeded: () => Promise<void>
    onCancel?: () => void
}
function PaymentForm({
    confirmText,
    onConfirmSucceeded,
    onCancel,
    children,
}: PropsWithChildren<StripePaymentFormProps>) {
    const { handleSubmitPaymentMethod } = useStripeSetupIntent()
    const userEmailAddress = useSelector(getEmailAdressOfLoggedInUser)
    const dispatch = useDispatch()

    const [isPendingSubmit, setIsPendingSubmit] = useState<boolean>(false)
    const [isFormComplete, setIsFormComplete] = useState<boolean>(false)
    const [_error, setError] = useState<string | undefined>(undefined)

    const handleUpdatePaymentMethod = async (e: FormEvent) => {
        setIsPendingSubmit(true)
        const { error, data } = await handleSubmitPaymentMethod(e)

        if (error) {
            setIsPendingSubmit(false)
            setError(error)
            return
        }

        if (data) {
            await getStripeService().finishSetupIntent(data.id)
            await onConfirmSucceeded()
            if (userEmailAddress) {
                dispatch(StripeSetupIntentCreated(userEmailAddress))
            }
        }

        setIsPendingSubmit(false)
    }

    return (
        <FlowPrompt.Form onSubmit={handleUpdatePaymentMethod}>
            <StripePaymentElement
                id="payment-element"
                onReady={(element) => element.focus()}
                onChange={(event) => {
                    if (event.complete) {
                        setIsFormComplete(true)
                    } else {
                        setIsFormComplete(false)
                    }
                }}
            />
            {children}
            <FlowPrompt.ButtonsSection>
                <FlowPrompt.ActionButton
                    key="confirm"
                    variant="primary"
                    type="submit"
                    isPending={isPendingSubmit}
                    isDisabled={!isFormComplete}>
                    {confirmText}
                </FlowPrompt.ActionButton>
                <FlowPrompt.ActionButton
                    key="cancel"
                    variant="secondary"
                    onPress={onCancel}>
                    {_('cancel')}
                </FlowPrompt.ActionButton>
            </FlowPrompt.ButtonsSection>
        </FlowPrompt.Form>
    )
}

const StripePaymentFormWrapper = styled.div.attrs({
    ['aria-hidden']: true,
})`
    will-change: transform;
    width: 100%;
    min-height: 320px; // placeholder height for stripe elements
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
`

type StripePaymentPromptProps = {
    title: string
    open: boolean
    onOpenChange: (open: boolean) => void
    renderTrigger: (onTrigger: () => void) => React.ReactNode
    paymentForm: React.ReactNode
}
function Prompt({
    title,
    open,
    onOpenChange,
    renderTrigger,
    paymentForm,
    children,
}: PropsWithChildren<StripePaymentPromptProps>) {
    // we want createSetupIntent run on trigger
    const { stripePromise, createStripeSetupIntent, intentInfo } =
        useStripeCustomer(false)

    return (
        <FlowPrompt.Root open={open} onOpenChange={onOpenChange}>
            {renderTrigger(createStripeSetupIntent)}
            <FlowPrompt.Content title={title}>
                {children}
                <StripePaymentFormWrapper>
                    {intentInfo && (
                        <StripeElements
                            key={intentInfo.client_secret}
                            stripe={stripePromise}
                            clientSecret={intentInfo.client_secret}>
                            {paymentForm}
                        </StripeElements>
                    )}
                </StripePaymentFormWrapper>
            </FlowPrompt.Content>
        </FlowPrompt.Root>
    )
}

type StripePaymentContentProps = {
    title: string
    icon?: ReactElement
    hideCloseButton?: boolean
    preventCloseOnClickOutside?: boolean
    paymentForm: React.ReactNode
}

function Content({
    title,
    icon,
    hideCloseButton,
    preventCloseOnClickOutside,
    paymentForm,
    children,
}: PropsWithChildren<StripePaymentContentProps>) {
    // we want createSetupIntent run on mount
    const { stripePromise, intentInfo } = useStripeCustomer(true)

    return (
        <FlowPrompt.Content
            title={title}
            icon={icon}
            hideCloseButton={hideCloseButton}
            preventCloseOnClickOutside={preventCloseOnClickOutside}>
            {children}
            <StripePaymentFormWrapper>
                {intentInfo && (
                    <StripeElements
                        key={intentInfo.client_secret}
                        stripe={stripePromise}
                        clientSecret={intentInfo.client_secret}>
                        {paymentForm}
                    </StripeElements>
                )}
            </StripePaymentFormWrapper>
        </FlowPrompt.Content>
    )
}

export const StripePayment = {
    Prompt,
    Content,
    PaymentForm,
}
