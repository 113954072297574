import {
    PageCard,
    HorizontalRadioGroup,
    Typography,
} from '@capture/capture-components'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
    getRequestUserEmailConsent,
    postRequestUserEmailConsent,
} from '@capture/client-api/src/orval'
import styled from 'styled-components'
import { EmailSubscribeSettingsChanged } from '~/state/users/actions'
import { isLocalhost } from '~/config/constants'
import { _ } from '../../assets/localization/util'
import { PageWrapper } from '../Common/PageWrapper'
import { CaptureLogoHorizontal } from '../Icons/CaptureLogoHorizontal'
import { TwoAreasTopNavBar } from '../Navigation/TwoAreasTopNavBar'

const Content = styled.div`
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
`

type EmailConsentValue = 'subscribe' | 'unsubscribe'

const baseUrl = isLocalhost
    ? 'https://a0-client.univex.no/st/4'
    : 'https://a10.cptr.no/st/4'

export const EmailSettingsPage = () => {
    const [emailConsent, setEmailConsent] =
        useState<EmailConsentValue>('subscribe')
    const dispatch = useDispatch()

    const params = new URLSearchParams(window.location.search)
    const emailId = params.get('id')

    useEffect(() => {
        const getUserEmailConsentValue = async () => {
            if (emailId) {
                const response = await getRequestUserEmailConsent(
                    {
                        email_consent_identifier: emailId,
                    },
                    {
                        baseURL: baseUrl,
                    },
                )
                const userEmailConsentValue = response.data.value
                    ? 'subscribe'
                    : 'unsubscribe'
                setEmailConsent(userEmailConsentValue)
            }
        }

        getUserEmailConsentValue()
    }, [emailId])

    const handleEmailConsentChange = async (value: EmailConsentValue) => {
        if (!emailId) return

        const response = await postRequestUserEmailConsent(
            {
                email_consent: value === 'subscribe' ? true : false,
                email_consent_identifier: emailId,
            },
            {
                baseURL: baseUrl,
            },
        )

        if (response.status === 200) {
            setEmailConsent(value)
            dispatch(EmailSubscribeSettingsChanged())
        } else {
            throw new Error('Failed to change email subscribe settings')
        }
    }

    return (
        <PageWrapper
            navBar={
                <TwoAreasTopNavBar
                    left={<CaptureLogoHorizontal />}
                    hideUserAvi={true}
                />
            }>
            <PageCard>
                <Typography.SectionHeader>
                    {_('unsubscribe_from_email_form_title')}
                </Typography.SectionHeader>
                <Typography.Divider />
                <Content>
                    <Typography.Paragraph>
                        {_('unsubscribe_from_email_form_text1')}
                    </Typography.Paragraph>
                    <Typography.Paragraph>
                        {_('unsubscribe_from_email_form_text2')}
                    </Typography.Paragraph>
                    <HorizontalRadioGroup.Root
                        value={emailConsent}
                        onValueChange={handleEmailConsentChange}>
                        <HorizontalRadioGroup.Item
                            label={_('unsubscribe_from_email_form_button_stay')}
                            id="subscribe"
                        />
                        <HorizontalRadioGroup.Item
                            label={_(
                                'unsubscribe_from_email_form_button_unsubscribe',
                            )}
                            id="unsubscribe"
                        />
                    </HorizontalRadioGroup.Root>
                </Content>
            </PageCard>
        </PageWrapper>
    )
}
