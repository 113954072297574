/**
 * Generated by orval v7.7.0 🍺
 * Do not edit manually.
 * FastAPI
 * OpenAPI spec version: 0.1.0
 */

export enum ClientGrantorDTO {
    apple_store = 'apple store',
    android_store = 'android store',
    capture = 'capture',
    capture_stripe = 'capture stripe',
    b2b = 'b2b',
    connect_store = 'connect store',
    customer_service = 'customer service',
    other = 'other',
}
