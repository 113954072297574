/**
 * Generated by orval v7.7.0 🍺
 * Do not edit manually.
 * FastAPI
 * OpenAPI spec version: 0.1.0
 */

export enum DigitalLegacyChoiceDTO {
    only_provide_content_explicitly_detailed_in_my_will = 'only_provide_content_explicitly_detailed_in_my_will',
    do_not_provide_content_to_anybody = 'do_not_provide_content_to_anybody',
    provide_content_to_contact = 'provide_content_to_contact',
}
