import type { Action } from '../common/actions'
import { isType } from '../common/actions'
import {
    AlbumFileCountExceedsDownloadLimit,
    AlbumSizeExceedsDownloadLimit,
    FileCountExceedsDownloadLimit,
    type PendingUserAction,
    SizeExceedsDownloadLimit,
    UserActionAlertHandled,
    UserActionConfirmHandled,
    UserActionInitiated,
    UserAlertInitiated,
    UserActionTypes,
} from './actions'

export enum UserActionsStatus {
    PENDING_USER = 'PENDING_USER', // User has initiated action (delete, unsubscribe, edit)
    PENDING_SERVER = 'PENDING_SERVER', // User has confirmed action
    SERVER_ERROR = 'SERVER_ERROR', // Something went wrong when trying to perform action
}

export type UserActionsState = {
    pendingConfirm?: PendingUserAction
    alerts: PendingUserAction[]
}

const initialState: UserActionsState = {
    alerts: [],
}

const newStateWithAddedAlert = (
    state: UserActionsState,
    alert: PendingUserAction,
) => {
    return {
        ...state,
        alerts: state.alerts.concat(alert),
    }
}

export const userActionsReducer = (
    state: UserActionsState = initialState,
    action: Action,
): UserActionsState => {
    if (isType(action, UserActionInitiated)) {
        return {
            ...state,
            pendingConfirm: action.payload,
        }
    }

    if (isType(action, UserAlertInitiated)) {
        return newStateWithAddedAlert(state, action.payload)
    }
    if (isType(action, UserActionConfirmHandled)) {
        return {
            ...state,
            pendingConfirm: undefined,
        }
    }
    if (isType(action, UserActionAlertHandled)) {
        return {
            ...state,
            alerts: state.alerts.slice(1),
        }
    }

    if (isType(action, FileCountExceedsDownloadLimit)) {
        return newStateWithAddedAlert(state, {
            type: UserActionTypes.FILE_COUNT_EXCEEDS_DOWNLOAD_LIMIT,
            target: action.payload,
        })
    }
    if (isType(action, SizeExceedsDownloadLimit)) {
        return newStateWithAddedAlert(state, {
            type: UserActionTypes.SIZE_EXCEEDS_DOWNLOAD_LIMIT,
            target: action.payload,
        })
    }
    if (isType(action, AlbumFileCountExceedsDownloadLimit)) {
        return newStateWithAddedAlert(state, {
            type: UserActionTypes.ALBUM_FILE_COUNT_EXCEEDS_DOWNLOAD_LIMIT,
            target: action.payload,
        })
    }
    if (isType(action, AlbumSizeExceedsDownloadLimit)) {
        return newStateWithAddedAlert(state, {
            type: UserActionTypes.ALBUM_SIZE_EXCEEDS_DOWNLOAD_LIMIT,
            target: action.payload,
        })
    }

    return state
}

export const userActionsReducerMapObj = {
    userActions: userActionsReducer,
}

export type StateWithUserActions = StateOfReducerMapObj<
    typeof userActionsReducerMapObj
>
