import { lazy, Suspense } from 'react'

import { AdvancedSettingsPage } from '@capture/client/components/AdvancedSettings/AdvancedSettingsPage'
import { CommentsListOverlay } from '@capture/client/components/Album/CommentsListOverlay'
import { LoadingPage } from '@capture/client/components/Common/LoadingPage'
import { TakeoutPage } from '@capture/client/components/Takeout/TakeoutPage'
import UnsubscribePage from '@capture/client/components/Unsubscribe/index'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { makeAlbumMount } from '@capture/client/components/Album/AlbumMount'
import { DocumentsPage } from '@capture/client/components/Documents/DocumentsPage'
import { EditAlbumPage } from '@capture/client/components/EditAlbum/EditAlbumPage'
import {
    AddToAlbumSelectionPage,
    MorePhotosSelectionPage,
} from '@capture/client/components/EditAlbum/PhotoSelectionPage'
import { ErrorPageMount } from '@capture/client/components/Info/ErrorPage'
import { PageNotFound } from '@capture/client/components/Info/PageNotFound'
import { LoginRequiredCopyAlbum } from '@capture/client/components/Info/RequireLoginCopyAlbum'
import { RequireLoginCreateNewAlbum } from '@capture/client/components/Info/RequireLoginCreateNewAlbum'
import { RequireLoginSubscribeToAlbum } from '@capture/client/components/Info/RequireLoginSubscribeToAlbum'
import { LoggedInDevicesPage } from '@capture/client/components/Settings/LoggedInDevicesPage'
import { SelectProfilePicturePage } from '@capture/client/components/Settings/SelectProfilePhotoPage'
import { TestFlagAdmin } from '@capture/client/components/Settings/TestFlagAdmin'
import { TrashAlbumsPage } from '@capture/client/components/Trash/TrashAlbumsPage'
import { TrashNavigationPage } from '@capture/client/components/Trash/TrashNavigationPage'
import { TrashPhotosPage } from '@capture/client/components/Trash/TrashPhotosPage'
import { RedirectToTimelineOrLogin } from '@capture/client/routing/RedirectOrLogin'
import { RoutePath } from '@capture/client/routing/routePath'
import { wrappedIn } from '@capture/client/utilities/hocComposition'
import { EmailSettingsPage } from '@capture/client/components/EmailSettings/EmailSettingsPage'
import { PrivacyPage } from '../components/DataProtection/PrivacyPage'
import { AlbumFileAsShareMount } from '../components/ShareReceiver/AlbumFileAsShare'
import { RequireLoginCopyShare } from '../components/ShareReceiver/RequireLoginCopyShare'
import { ShareMountUnpackedUUID } from '../components/ShareReceiver/ShareContainer'
import { LoggedInApp, MaybeLoggedInApp } from './App'

// lazy load some routes
const AlbumCarouselViewPage = lazy(() =>
    import('@capture/client/components/CarouselView/CarouselViewPage').then(
        (module) => ({
            default: module.AlbumCarouselViewPage,
        }),
    ),
)

const AlbumListPage = lazy(() =>
    import('@capture/client/components/AlbumList/AlbumListPage').then(
        (module) => ({
            default: module.AlbumListPage,
        }),
    ),
)

const AlbumReceiverPage = lazy(() =>
    import('@capture/client/components/Album/AlbumReceiverPage').then(
        (module) => ({
            default: module.AlbumReceiverPage,
        }),
    ),
)

const TimelineCarouselViewPage = lazy(() =>
    import('@capture/client/components/CarouselView/CarouselViewPage').then(
        (module) => ({
            default: module.TimelineCarouselViewPage,
        }),
    ),
)

const TimelinePage = lazy(() =>
    import('@capture/client/components/Timeline/TimelinePage').then(
        (module) => ({
            default: module.TimelinePage,
        }),
    ),
)

const SettingsStoragePage = lazy(() =>
    import('../routes/settings.storage').then((module) => ({
        default: module.SettingsStoragePage,
    })),
)

const SettingsPage = lazy(() =>
    import('@capture/client/components/Settings/SettingsPage').then(
        (module) => ({
            default: module.SettingsPage,
        }),
    ),
)

const AlbumMount = makeAlbumMount(AlbumReceiverPage) // TODO: New inner component

const loggedIn = wrappedIn(LoggedInApp)
const maybeLoggedIn = wrappedIn(MaybeLoggedInApp)

const LoggedInTimelinePage = loggedIn(TimelinePage)
const LoggedInTimelineCarouselViewPage = loggedIn(TimelineCarouselViewPage)
const LoggedInAlbumListPage = loggedIn(AlbumListPage)
const LoggedInAlbumMount = loggedIn(AlbumMount)

const LoggedInTrashNavigationPage = loggedIn(TrashNavigationPage)
const LoggedInTrashPhotosPage = loggedIn(TrashPhotosPage)
const LoggedInTrashAlbumsPage = loggedIn(TrashAlbumsPage)

const LoggedInMorePhotosSelectionPage = loggedIn(MorePhotosSelectionPage)
const LoggedInAddToAlbumSelectionPage = loggedIn(AddToAlbumSelectionPage)
const LoggedInEditAlbumPage = loggedIn(EditAlbumPage)
const LoggedInDocumentsPage = loggedIn(DocumentsPage)
const LoggedInSettingsPage = loggedIn(SettingsPage)
const LoggedInStoragePage = loggedIn(SettingsStoragePage)
const LoggedInPrivacyPage = loggedIn(PrivacyPage)
const LoggedInTakeoutPage = loggedIn(TakeoutPage)
const LoggedInLoggedInDevicesPage = loggedIn(LoggedInDevicesPage)
const LoggedInSelectProfilePicturePage = loggedIn(SelectProfilePicturePage)
const MaybeLoggedInAlbumFileAsShareMount = maybeLoggedIn(AlbumFileAsShareMount)
const MaybeLoggedInAlbumMount = maybeLoggedIn(AlbumMount)
const MaybeLoggedInAlbumCarouselViewPage = maybeLoggedIn(AlbumCarouselViewPage)
const MaybeLoggedInRequireLoginCreateNewAlbum = maybeLoggedIn(
    RequireLoginCreateNewAlbum,
)
const MaybeLoggedInLoginRequiredCopyAlbum = maybeLoggedIn(
    LoginRequiredCopyAlbum,
)
const MaybeLoggedInRequireLoginSubscribeToAlbum = maybeLoggedIn(
    RequireLoginSubscribeToAlbum,
)
const MaybeLoggedInShareMountUnpackedUUID = maybeLoggedIn(
    ShareMountUnpackedUUID,
)
const MaybeLoggedInEmailSettings = maybeLoggedIn(EmailSettingsPage)
const MaybeLoggedInRequireLoginCopyShare = maybeLoggedIn(RequireLoginCopyShare)
const MaybeLoggedInCommentsListOverlay = maybeLoggedIn(CommentsListOverlay)
const MaybeLoggedInErrorPageMount = maybeLoggedIn(ErrorPageMount)
const MaybeLoggedInPageNotFound = maybeLoggedIn(PageNotFound)

const Unsubscribe = loggedIn(UnsubscribePage)

const LoggedInAdvancedSettings = loggedIn(AdvancedSettingsPage)

export const MainRouter: React.FunctionComponent = () => (
    <Suspense fallback={<LoadingPage />}>
        <BrowserRouter basename={import.meta.env.BASE_URL}>
            <Routes>
                <Route path="/" element={<RedirectToTimelineOrLogin />} />
                <Route
                    path={RoutePath.Timeline}
                    element={<LoggedInTimelinePage />}
                />
                <Route
                    path={RoutePath.getTimelineCarouselPath(':fileID')}
                    element={<LoggedInTimelineCarouselViewPage />}
                />
                <Route
                    path={RoutePath.Albums}
                    element={<LoggedInAlbumListPage />}
                />
                <Route
                    path={RoutePath.getLoggedInAlbumPath(':albumIDB64')}
                    element={<LoggedInAlbumMount />}
                />

                <Route
                    path={RoutePath.InAppTrashNavigation}
                    element={<LoggedInTrashNavigationPage />}
                />
                <Route
                    path={RoutePath.TrashNavigation}
                    element={<LoggedInTrashNavigationPage />}
                />
                <Route
                    path={RoutePath.getInAppTrashPhotosPath()}
                    element={<LoggedInTrashPhotosPage />}
                />
                <Route
                    path={RoutePath.TrashPhotos}
                    element={<LoggedInTrashPhotosPage />}
                />
                <Route
                    path={RoutePath.getInAppTrashAlbumsPath()}
                    element={<LoggedInTrashAlbumsPage />}
                />
                <Route
                    path={RoutePath.TrashAlbums}
                    element={<LoggedInTrashAlbumsPage />}
                />
                <Route
                    path={RoutePath.morePhotosCreateAlbum(':albumIDB64')}
                    element={<LoggedInMorePhotosSelectionPage />}
                />
                <Route
                    path={RoutePath.morePhotosAlbum(':albumIDB64')}
                    element={<LoggedInAddToAlbumSelectionPage />}
                />
                <Route
                    path={RoutePath.getEditAlbumPath(
                        ':albumIDB64',
                        ':goToAfter',
                    )}
                    element={<LoggedInEditAlbumPage />}
                />
                <Route
                    path={RoutePath.Documents}
                    element={<LoggedInDocumentsPage />}
                />
                <Route
                    path={RoutePath.Settings}
                    element={<LoggedInSettingsPage />}
                />
                <Route
                    path={`${RoutePath.Settings}/testFlags`}
                    element={<TestFlagAdmin />}
                />
                <Route
                    path={RoutePath.SettingsStorage}
                    element={<LoggedInStoragePage />}
                />
                <Route
                    path={RoutePath.SettingsPrivacy}
                    element={<LoggedInPrivacyPage />}
                />
                <Route
                    path={RoutePath.SettingsTakeout}
                    element={<LoggedInTakeoutPage />}
                />
                <Route
                    path={RoutePath.SettingsUnsubscribe}
                    element={<Unsubscribe />}
                />
                <Route
                    path={RoutePath.InAppPrivacySettings}
                    element={<LoggedInPrivacyPage />}
                />
                <Route
                    path={RoutePath.SettingsLoggedInDevices}
                    element={<LoggedInLoggedInDevicesPage />}
                />
                <Route
                    path={RoutePath.SelectProfilePicture}
                    element={<LoggedInSelectProfilePicturePage />}
                />
                <Route
                    path={`${RoutePath.Settings}/_advanced`}
                    element={<LoggedInAdvancedSettings />}
                />
                {/*Maybe Logged In*/}
                <Route
                    path={RoutePath.getAlbumImageAsSharePath(
                        ':albumIDB64',
                        ':fileID',
                    )}
                    element={<MaybeLoggedInAlbumFileAsShareMount />}
                />
                <Route
                    path={RoutePath.getNotLoggedInAlbumPath(':albumIDB64')}
                    element={<MaybeLoggedInAlbumMount />}
                />
                <Route
                    path={RoutePath.getAlbumCarouselPath(
                        ':albumIDB64',
                        ':fileID',
                    )}
                    element={<MaybeLoggedInAlbumCarouselViewPage />}
                />
                <Route
                    path={RoutePath.getCreateAlbumLoginPath(':albumIDB64')}
                    element={<MaybeLoggedInRequireLoginCreateNewAlbum />}
                />
                <Route
                    path={RoutePath.getCopyAlbumLoginPath(':albumIDB64')}
                    element={<MaybeLoggedInLoginRequiredCopyAlbum />}
                />
                <Route
                    path={RoutePath.getCopyAlbumFileLoginPath(
                        ':albumIDB64',
                        ':fileID',
                    )}
                    element={<MaybeLoggedInLoginRequiredCopyAlbum />}
                />
                <Route
                    path={RoutePath.getSubscribeToAlbumLoginPath(':albumIDB64')}
                    element={<MaybeLoggedInRequireLoginSubscribeToAlbum />}
                />
                <Route
                    path={RoutePath.getShareReceiverPath(':shareID')}
                    element={<MaybeLoggedInShareMountUnpackedUUID />}
                />
                {/*TODO: use b64 encoded uuids */}
                <Route
                    path={RoutePath.getCopyShareLoginPath(':shareID')}
                    element={<MaybeLoggedInRequireLoginCopyShare />}
                />
                <Route
                    path={RoutePath.getAlbumFileCommentsPath(
                        ':albumIDB64',
                        ':fileID',
                    )}
                    element={<MaybeLoggedInCommentsListOverlay />}
                />
                <Route
                    path={RoutePath.EmailSettings}
                    element={<MaybeLoggedInEmailSettings />}
                />
                <Route
                    path={RoutePath.getErrorPath(':error')}
                    element={<MaybeLoggedInErrorPageMount />}
                />
                <Route path="*" element={<MaybeLoggedInPageNotFound />} />
            </Routes>
        </BrowserRouter>
    </Suspense>
)
