import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { FlowPrompt } from '@capture/capture-components'
import { RefusedSubscription } from '~/state/storagePlan/actions'
import { StepWelcome } from '~/components/SubscribeModal/StepWelcome'
import { StepSub } from '~/components/SubscribeModal/StepSub'
import { StepNoSub } from '~/components/SubscribeModal/StepNoSub'
import { StepStripe } from '~/components/SubscribeModal/StepStripe'
import { _ } from '~/assets/localization/util'
import { fetchStoragePlanInfo } from '~/API/storagePlan'

/** Modal Step
 *  (1) Welcome --> (2-1) Sub   --> (3) Stripe
 *              --> (2-2) NoSub
 */
enum ModalStep {
    WELCOME = 'WELCOME', // 1
    SUB = 'SUB', // 2-1
    NOSUB = 'NOSUB', // 2-2
    STRIPE = 'STRIPE', // 3
}

export const SubscribeModal = () => {
    // 1 (Welcome). 2-1 (Sub). 2-2 (NoSub). 3 (Stripe)
    const [currentStep, setCurrentStep] = useState<ModalStep>(ModalStep.WELCOME)
    const [isSignupModalOpen, setIsSignupModalOpen] = useState(true)
    const [selectedPlanId, setSelectedPlanId] = useState<string | undefined>(
        undefined,
    )
    const dispatch = useDispatch()

    useEffect(() => {
        fetchStoragePlanInfo(dispatch)
    }, [dispatch])

    return (
        <FlowPrompt.Root
            open={isSignupModalOpen}
            onOpenChange={setIsSignupModalOpen}>
            {currentStep === ModalStep.WELCOME && (
                <StepWelcome
                    closeModal={() => setIsSignupModalOpen(false)}
                    onPrimaryButtonClick={() => setCurrentStep(ModalStep.SUB)}
                    onSecondaryButtonClick={() =>
                        setCurrentStep(ModalStep.NOSUB)
                    }
                />
            )}
            {currentStep === ModalStep.SUB && (
                <StepSub
                    selectedPlanId={selectedPlanId}
                    onSelectedPlanIdChange={setSelectedPlanId}
                    onPrimaryButtonClick={() =>
                        setCurrentStep(ModalStep.STRIPE)
                    }
                    onBackButtonClick={() => setCurrentStep(ModalStep.WELCOME)}
                />
            )}
            {currentStep === ModalStep.NOSUB && (
                <StepNoSub
                    onPrimaryButtonClick={() => {
                        setIsSignupModalOpen(false)
                        dispatch(RefusedSubscription())
                    }}
                    onSecondaryButtonClick={() =>
                        setCurrentStep(ModalStep.WELCOME)
                    }
                />
            )}
            {currentStep === ModalStep.STRIPE && (
                <StepStripe
                    selectedPlanId={selectedPlanId!}
                    onBackButtonClick={() => setCurrentStep(ModalStep.SUB)}
                    onPurchasePlanConfirmed={() => {
                        setSelectedPlanId(undefined)
                        setIsSignupModalOpen(false)
                    }}
                />
            )}
        </FlowPrompt.Root>
    )
}
