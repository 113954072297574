import type {
    CommentResponseDTO,
    DeleteAlbumsResponseDTO,
    JobSetPermissionsResponseDTO,
    PartialMetadataResponseDTO,
    RestoreAlbumsResponseDTO,
    RollbackMultiResponseDTO,
    SetReactionResponseDTO,
    TrashcanAlbumItemDTO,
    UsersResponseDTO,
    PublishShareResponseDTO,
    TimelineMonthsDTO,
    PostRequestJobsPublishByJobUuidParams,
    GetRequestJobsFilesByJobUuidParams,
    PostRequestJobsFilesDedupByJobUuidParams,
    PostRequestJobsUploadByJobUuid200,
    PostRequestJobsPermissionsByJobUuidParams,
    PrivacyModeDTO,
    DataProtectionRequestResultDTO,
    UserStatsEventModelDTO,
    DigitalLegacyChoiceDTO,
    DigitalLegacyContactDTO,
    LanguageDTO,
    DigitalLegacyInfoDTO,
} from '@capture/client-api/src/orval'
import {
    ReactionTypeDTO,
    RollbackResultStatusDTO,
    UploadPolicyDTO,
    deleteRequestAccountAttribute,
    deleteRequestDeleteAlbumsFromTrashCan,
    deleteRequestDevicesByDeviceId,
    deleteRequestJobsByJobUuid,
    deleteRequestJobsFilesByIdByJobUuidAndFileUuid,
    deleteRequestJobsFilesByIdCommentsByJobUuidAndFileUuidAndCommentUuid,
    deleteRequestJobsFilesByIdReactionByJobUuidAndFileUuid,
    deleteRequestOptionsByName,
    getRequestAccountAttribute,
    getRequestAccountInfo,
    getRequestDataProtectionConsentValues,
    getRequestDataProtectionRequestDataAccessAvailability,
    getRequestDevices,
    getRequestDigitalLegacy,
    getRequestJobs,
    getRequestJobsChangesAndAsyncUploadStatusByJobUuid,
    getRequestJobsChangesByJobUuid,
    getRequestJobsDefault,
    getRequestJobsFilesByIdByJobUuidAndFileUuid,
    getRequestJobsFilesByJobUuid,
    getRequestJobsInfoByJobUuid,
    getRequestJobsMetadataByJobUuidAndFileUuid,
    getRequestJobsTimelineMonthsByJobUuid,
    getRequestJobsUsersByJobUuid,
    getRequestOptionsByName,
    getRequestSupportedExtensions,
    getRequestTestEmailConsentId,
    getRequestTrashCan,
    getRequestTrashCanAlbums,
    getRequestUserGrants,
    postRequestAccountAttribute,
    postRequestApproveTos,
    postRequestClientStats,
    postRequestDataProtectionConsentValues,
    postRequestDataProtectionRequestAccountDeletion,
    postRequestDigitalLegacy,
    postRequestEmptyTrashCan,
    postRequestJobs,
    postRequestJobsCoverByJobUuid,
    postRequestJobsFilesByIdCommentsByJobUuidAndFileUuid,
    postRequestJobsFilesByIdReactionByJobUuidAndFileUuid,
    postRequestJobsFilesDedupByJobUuid,
    postRequestJobsKeepAllFilesByJobUuid,
    postRequestJobsKeepFilesByJobUuid,
    postRequestJobsMultiRollbackByJobUuid,
    postRequestJobsNameByJobUuid,
    postRequestJobsPermissionsByJobUuid,
    postRequestJobsPrivacyModeByJobUuid,
    postRequestJobsPublishByJobUuid,
    postRequestJobsSubscribeByJobUuid,
    postRequestJobsUnsubscribeByJobUuid,
    postRequestJobsUploadByJobUuid,
    postRequestLogout,
    postRequestName,
    postRequestRollbackAlbums,
    postRequestTestDeleteStripeData,
    postRequestUserStatisticsEvents,
    putRequestJobsAttributeByJobUuidAndAttribute,
    putRequestJobsFilesByIdCommentsByJobUuidAndFileUuidAndCommentUuid,
    putRequestOptionsByName,
} from '@capture/client-api/src/orval'

import type { AxiosRequestConfig } from 'axios'
import { isAxiosError } from 'axios'
import type { Merge } from 'type-fest'
import type {
    AccountInfoResponse,
    ChangesAndAsyncUploadStatus,
    ClientStats,
    CreateJobProperties,
    DataProtectionConsentData,
    DeletedFile,
    DeletedFileResponse,
    ExtraJobQueryParamsOf,
    JobChange,
    JobFile,
    JobInfoResponse,
    StoryJobInfoResponse,
    UserAccountAttributeKey,
    UpsertUserAccountAttribute,
    JobListResponse,
    UserGrant,
    UserAccountAttribute,
    DataProtectionUpdateRequestResult,
} from '~/@types/backend-types'
import { PRODUCT_NAME, getCurrentLangDefinition } from '~/config/constants'
import { canReadFileContent } from '~/state/uploader/uploadFileURL'
import { UploadError, UploadFailedReason } from '~/state/uploader/uploadQueue'
import '~/API/apiUtils'
import { chunks } from '~/utilities/arrayUtils'
import { managedPromiseAll } from '~/utilities/promises'
import type { ServiceDict } from '../externals'
import {
    HostUrl,
    customFetch,
    downloadThroughAnchor,
    sendPOSTRedirect,
} from '../toolbox'
import { APIService } from './APIService'

export class AppService extends APIService {
    private hostUrl: HostUrl
    private downloadHost: HostUrl

    constructor(hosts: ServiceDict, authToken: string, foreignAuth?: string) {
        super(hosts.appHost, authToken, foreignAuth)

        // we maintain the authToken here for the endpoints which are not migrated
        // and still use the host provider
        this.hostUrl = new HostUrl(hosts.appHost, {
            ...this.commonQueryParams,
            auth: authToken,
        })
        this.downloadHost = new HostUrl(hosts.downloadHost, {
            ...this.commonQueryParams,
            auth: authToken,
        })
    }

    public async getAccountInfo(): Promise<AccountInfoResponse> {
        const response = await getRequestAccountInfo(
            {
                ...this.commonQueryParams,
                app_lang: getCurrentLangDefinition().appLang ?? 'en',
            },
            this.axiosRequestOptions,
        )

        return response.data as AccountInfoResponse
    }

    public async setProfileName(name: string) {
        const response = await postRequestName(
            {
                ...this.commonQueryParams,
                name,
            },
            this.axiosRequestOptions,
        )

        return response.data
    }

    public async setProfilePictureFromBlob(blob: Blob): Promise<Response> {
        const url = this.hostUrl.getPath(`/st/4/profile/picture`, {
            path: 'profile.jpg',
        })
        const payload = new FormData()
        payload.append('file', blob)

        const request = new XMLHttpRequest()
        await new Promise((success, error) => {
            request.addEventListener('load', success)
            request.addEventListener('error', error)
            request.addEventListener('abort', error)

            request.open('POST', url)
            request.send(payload)
        })
        if (request.status !== 201) {
            throw new Error(request.responseText)
        }
        return request.response
    }

    public async getUserOption(optionKey: string) {
        const response = await getRequestOptionsByName(
            optionKey,
            this.commonQueryParams,
            {
                ...this.axiosRequestOptions,
                responseType: 'text',
            },
        )

        return response.data
    }

    public async setUserOption(optionKey: string, value: string) {
        return putRequestOptionsByName(
            optionKey,
            {
                ...this.commonQueryParams,
                value,
            },
            this.axiosRequestOptions,
        )
    }

    public async deleteUserOption(optionKey: string) {
        return deleteRequestOptionsByName(
            optionKey,
            this.commonQueryParams,
            this.axiosRequestOptions,
        )
    }

    public async getConnectedDevices() {
        const response = await getRequestDevices(
            this.commonQueryParams,
            this.axiosRequestOptions,
        )

        return response.data
    }

    public async deleteConnectedDevice(deviceID: string) {
        await deleteRequestDevicesByDeviceId(
            deviceID,
            this.commonQueryParams,
            this.axiosRequestOptions,
        )
    }

    public logout() {
        return postRequestLogout(
            this.commonQueryParams,
            this.axiosRequestOptions,
        )
    }

    public async getJobList(): Promise<JobListResponse> {
        const response = await getRequestJobs(
            {
                ...this.commonQueryParams,
                stories: true,
                include_details: true,
            },
            this.axiosRequestOptions,
        )

        return response.data as JobListResponse
    }

    public async getDefaultJob() {
        const response = await getRequestJobsDefault(
            this.commonQueryParams,
            this.axiosRequestOptions,
        )

        return response.data
    }

    public async getTimelineMonths(jobID: JobID): Promise<TimelineMonthsDTO> {
        const response = await getRequestJobsTimelineMonthsByJobUuid(
            jobID,
            this.commonQueryParams,
            this.axiosRequestOptions,
        )

        return response.data
    }

    public async getJobInfo(jobID: JobID): Promise<JobInfoResponse> {
        const response = await getRequestJobsInfoByJobUuid(
            jobID,
            {
                ...this.commonQueryParams,
                include_details: true,
            },
            this.axiosRequestOptions,
        )

        return response.data as JobInfoResponse
    }

    public async getJobChanges(
        jobID: JobID,
        since: number,
    ): Promise<JobChange[]> {
        const response = await getRequestJobsChangesByJobUuid(
            jobID,
            {
                ...this.commonQueryParams,
                since,
            },
            this.axiosRequestOptions,
        )

        return response.data as JobChange[]
    }

    public async getJobContributors(
        jobID: JobID,
        includeProfilePicture: boolean,
    ): Promise<UsersResponseDTO> {
        const response = await getRequestJobsUsersByJobUuid(
            jobID,
            {
                ...this.commonQueryParams,
                include_profile_url: includeProfilePicture,
            },
            this.axiosRequestOptions,
        )

        return response.data
    }

    public async createJob(
        params: CreateJobProperties,
    ): Promise<StoryJobInfoResponse> {
        const response = await postRequestJobs(
            {
                ...this.commonQueryParams,
                ...params,
            },
            this.axiosRequestOptions,
        )

        return response.data as StoryJobInfoResponse
    }

    public async publishJob(
        jobID: JobID,
        params: ExtraJobQueryParamsOf<PostRequestJobsPublishByJobUuidParams>,
    ): Promise<PublishShareResponseDTO> {
        const response = await postRequestJobsPublishByJobUuid(
            jobID,
            {
                ...this.commonQueryParams,
                ...params,
            },
            this.axiosRequestOptions,
        )

        return response.data
    }

    public async deleteJob(jobID: JobID) {
        await deleteRequestJobsByJobUuid(
            jobID,
            this.commonQueryParams,
            this.axiosRequestOptions,
        )
    }

    public async getFiles(
        jobID: string,
        options: ExtraJobQueryParamsOf<GetRequestJobsFilesByJobUuidParams>,
    ): Promise<{
        lastEventSerial: number
        files: JobFile[]
    }> {
        const response = await getRequestJobsFilesByJobUuid(
            jobID,
            {
                ...this.commonQueryParams,
                ...options,
            },
            this.axiosRequestOptions,
        )

        return {
            files: response.data as JobFile[],
            lastEventSerial: parseInt(
                response.headers['x-last-event-serial'] || '-1',
                10,
            ),
        }
    }

    public async getDeletedFiles(
        offset: number,
        limit: number,
    ): Promise<DeletedFileResponse> {
        const response = await getRequestTrashCan(
            {
                ...this.commonQueryParams,
                total_item_count: true,
                offset,
                limit,
            },
            this.axiosRequestOptions,
        )

        const totalItems = response.headers['capture-total-items']

        return {
            deletedFiles: response.data as DeletedFile[],
            totalItemCount: totalItems
                ? parseInt(totalItems, 10)
                : response.data.length,
        }
    }

    public async getDeletedAlbums(): Promise<TrashcanAlbumItemDTO[]> {
        const response = await getRequestTrashCanAlbums(
            this.commonQueryParams,
            this.axiosRequestOptions,
        )

        return response.data
    }

    public async getFileMetadata(
        jobID: JobID,
        fileID: FileID,
    ): Promise<PartialMetadataResponseDTO> {
        const response = await getRequestJobsMetadataByJobUuidAndFileUuid(
            jobID,
            fileID,
            this.commonQueryParams,
            this.axiosRequestOptions,
        )

        return response.data
    }

    public async dedupFile(
        targetJob: JobID,
        params: ExtraJobQueryParamsOf<PostRequestJobsFilesDedupByJobUuidParams>,
    ): Promise<string> {
        const response = await postRequestJobsFilesDedupByJobUuid(
            targetJob,
            {
                ...this.commonQueryParams,
                ...params,
                want_json: true,
            },
            this.axiosRequestOptions,
        ).catch((_error) => {
            throw new UploadError(
                UploadFailedReason.FileError,
                'File rejected by server',
            )
        })

        return response.data.uuid
    }

    public async copyFilesToDefaultJob(
        sourceJobID: JobID,
        fileIDs: FileID[],
    ): Promise<void> {
        await postRequestJobsKeepFilesByJobUuid(
            sourceJobID,
            fileIDs.join('\n'),
            this.commonQueryParams,
            this.axiosRequestOptions,
        )
    }

    public async copyJobToDefaultJob(sourceJobID: JobID): Promise<void> {
        await postRequestJobsKeepAllFilesByJobUuid(
            sourceJobID,
            this.commonQueryParams,
            this.axiosRequestOptions,
        )
    }

    public async uploadFile(
        jobID: string,
        path: string,
        file: File | Blob,
        mtime: number,
        config?: AxiosRequestConfig,
    ): Promise<PostRequestJobsUploadByJobUuid200> {
        try {
            const response = await postRequestJobsUploadByJobUuid(
                jobID,
                { file },
                {
                    ...this.commonQueryParams,
                    path,
                    mtime,
                    policy: UploadPolicyDTO.no_duplicates,
                },
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    ...this.axiosRequestOptions,
                    ...config,
                },
            )

            return response.data
        } catch (e) {
            // CAPWEB-2088: Upload can fail because local files have been removed
            if (!(await canReadFileContent(file))) {
                throw new UploadError(
                    UploadFailedReason.LocalFileUnavailable,
                    'File no longer exists',
                )
            }

            if (isAxiosError(e) && e.status === 413) {
                throw new UploadError(
                    UploadFailedReason.OutOfStorage,
                    'Out of storage',
                )
            }

            throw new UploadError(
                UploadFailedReason.NetworkError,
                'Network error',
            )
        }
    }

    public async deleteFile(jobID: JobID, fileID: FileID) {
        const response = await deleteRequestJobsFilesByIdByJobUuidAndFileUuid(
            jobID,
            fileID,
            this.commonQueryParams,
            this.axiosRequestOptions,
        )

        return response.data
    }

    public async emptyTrashCan(fileid: FileID): Promise<void> {
        await postRequestEmptyTrashCan(
            {
                ...this.commonQueryParams,
                fileid,
            },
            this.axiosRequestOptions,
        )
    }

    public async restoreMultipleFiles(
        jobID: JobID,
        fileIDs: FileID[],
        onProgress?: (progress: number) => void,
    ): Promise<RollbackMultiResponseDTO> {
        // 200 fileIDs max for each multiRollbackPost from backend end point,
        // split fileIDs into chunks with max 200 fileIDs in each chunk
        const fileIDsChunks = chunks(fileIDs, 200)
        const chunkCount = fileIDsChunks.length
        const responses: RollbackMultiResponseDTO[] = []

        let finished = 0
        await managedPromiseAll(fileIDsChunks, async (chunk) => {
            try {
                const response = await postRequestJobsMultiRollbackByJobUuid(
                    jobID,
                    { file_uuids: chunk },
                    this.commonQueryParams,
                    this.axiosRequestOptions,
                )
                responses.push(response.data)
            } catch (_error) {
                responses.push({
                    results: chunk.map((fileID) => ({
                        file_uuid: fileID,
                        status: RollbackResultStatusDTO.error,
                    })),
                    quota: undefined,
                })
            } finally {
                finished += 1
                if (onProgress) {
                    onProgress(finished / chunkCount)
                }
            }
        })

        return responses.reduce(
            (acc, r) => {
                const results = acc.results.concat(r.results)

                let quota = acc.quota

                if (r.quota !== undefined) {
                    quota = {
                        used_space: Math.max(
                            acc.quota?.used_space ?? 0,
                            r.quota.used_space || 0,
                        ),
                        max_space: r.quota.max_space,
                    }
                }

                return {
                    results,
                    quota,
                }
            },
            {
                results: [],
                quota: undefined,
            } as RollbackMultiResponseDTO,
        )
    }

    /** Restores all albums in trash  */
    public async restoreAlbums(
        jobIDs: string[],
    ): Promise<RestoreAlbumsResponseDTO> {
        const response = await postRequestRollbackAlbums(
            {
                job_uuids: jobIDs,
            },
            this.commonQueryParams,
            this.axiosRequestOptions,
        )

        return response.data
    }

    /** Permanently deletes all albums in trash */
    public async emptyTrashCanAlbums(
        jobIDs: string[],
    ): Promise<DeleteAlbumsResponseDTO> {
        const response = await deleteRequestDeleteAlbumsFromTrashCan(
            {
                job_uuids: jobIDs,
            },
            this.commonQueryParams,
            this.axiosRequestOptions,
        )

        return response.data
    }

    public async addComment(
        jobID: JobID,
        fileID: string,
        comment: string,
    ): Promise<CommentResponseDTO> {
        const response =
            await postRequestJobsFilesByIdCommentsByJobUuidAndFileUuid(
                jobID,
                fileID,
                {
                    ...this.commonQueryParams,
                    comment,
                },
                this.axiosRequestOptions,
            )

        return response.data
    }

    public async deleteComment(
        jobID: JobID,
        fileID: string,
        commentID: CommentID,
    ): Promise<CommentResponseDTO> {
        const response =
            await deleteRequestJobsFilesByIdCommentsByJobUuidAndFileUuidAndCommentUuid(
                jobID,
                fileID,
                commentID,
                this.commonQueryParams,
                this.axiosRequestOptions,
            )

        return response.data
    }

    public async editComment(
        jobID: JobID,
        fileID: string,
        commentID: CommentID,
        commentText: string,
    ): Promise<CommentResponseDTO> {
        const response =
            await putRequestJobsFilesByIdCommentsByJobUuidAndFileUuidAndCommentUuid(
                jobID,
                fileID,
                commentID,
                {
                    ...this.commonQueryParams,
                    comment: commentText,
                },
                this.axiosRequestOptions,
            )

        return response.data
    }

    public async subscribeToJob(jobID: JobID) {
        await postRequestJobsSubscribeByJobUuid(
            jobID,
            this.commonQueryParams,
            this.axiosRequestOptions,
        )
    }

    public async unsubscribeFromJob(jobID: JobID) {
        await postRequestJobsUnsubscribeByJobUuid(
            jobID,
            this.commonQueryParams,
            this.axiosRequestOptions,
        )
    }

    public static getDownloadOptions(
        type: 'download' | 'export' | 'takeout',
        zipFileName: string,
        convertHEIC: boolean = false,
    ) {
        switch (type) {
            case 'download':
                return {
                    flattened: '1',
                    heic_to_jpeg: convertHEIC ? '1' : '0',
                    master_only: '1',
                    include_subrevisions: '0',
                    zip_filename: zipFileName,
                }
            case 'export':
                return {
                    flattened: '1',
                    heic_to_jpeg: '0',
                    master_only: '0',
                    include_subrevisions: '0',
                    zip_filename: zipFileName,
                }
            case 'takeout':
                return {
                    flattened: '1',
                    heic_to_jpeg: '0',
                    master_only: '0',
                    include_subrevisions: '1',
                    zip_filename: zipFileName,
                }
        }
    }

    /**
     * Downloads files from a specified job based on the download type.
     *
     * @param {'download' | 'export' | 'takeout'} downloadType - The type of download to perform.
     * @param {JobID} jobID - The unique identifier for the job.
     * @param {FileID[]} fileIDs - An array of file IDs to download.
     * @param {boolean} [hasHEIC] - Optional flag to indicate if the files include HEIC format.
     * @param {string} [zipFileName=PRODUCT_NAME] - The name for the resulting ZIP file. Defaults to PRODUCT_NAME.
     * @returns {Promise<void>} - A promise that resolves when the files have been successfully downloaded.
     *
     * @example behavior:
     * 1. downloadType === 'download':
     *     a. single file:
     *         uses `downloadThroughAnchor` with `GET` request to download the file through `a` tag.
     *         download single file thumbnail, for HEIC file it will convert to JPEG.
     *     b. multiple files:
     *         uses `sendPOSTRedirect` with `POST` request with `fileIDs` as body to download the files through `form` submit.
     *         download all files thumbnail as archive, for HEIC file it will convert to JPEG, for bust photos it will download only master photo.
     * 2. downloadType === 'export':
     *     a. single file:
     *         download single file in original format.
     *     b. multiple files:
     *         download all files in original format as archive.
     * 2. downloadType === 'takeout':
     *     a. single file:
     *         download single file in original format.
     *     b. multiple files:
     *         download all files in original format with its subrevisions as archive.
     *
     */
    public async downloadFilesFromJob(
        downloadType: 'download' | 'export' | 'takeout',
        jobID: JobID,
        fileIDs: FileID[],
        hasHEIC?: boolean,
        zipFileName: string = PRODUCT_NAME,
    ): Promise<void> {
        if (downloadType === 'download' && hasHEIC === undefined) {
            throw new Error('hasHEIC must be defined for download type')
        }

        if (fileIDs.length === 1) {
            downloadThroughAnchor(
                this.getFilePreviewURL(
                    jobID,
                    fileIDs[0],
                    downloadType === 'download' && hasHEIC,
                ),
            )
            return
        }

        return sendPOSTRedirect(
            this.downloadHost.getPath(
                `/st/4/jobs/${jobID}/files_as_archive`,
                AppService.getDownloadOptions(
                    downloadType,
                    zipFileName,
                    hasHEIC,
                ),
            ),
            fileIDs,
        )
    }
    /**
     * Downloads all files from a specified job as an archive based on the download type.
     *
     * @param {'download' | 'export' | 'takeout'} downloadType - The type of download to perform.
     * @param {JobID} jobID - The unique identifier for the job.
     * @param {boolean} [hasHEIC] - Optional flag to indicate if the files include HEIC format.
     * @param {string} [zipFileName=PRODUCT_NAME] - The name for the resulting ZIP file. Defaults to PRODUCT_NAME.
     * @returns {Promise<void>} - A promise that resolves when the archive has been successfully downloaded.
     *
     * @example behavior:
     * 1. downloadType === 'download':
     *     uses `downloadThroughAnchor` with `GET` request to download all files from job through `a` tag.
     *     download all files thumbnail from target job, for HEIC file it will convert to JPEG, for bust photos it will download only master photo.
     * 2. downloadType === 'export':
     *     download all files from target job in original format as archive.
     * 2. downloadType === 'takeout':
     *     download all files from target job in original format with its subrevisions as archive.
     *
     */
    public async downloadJobAsArchive(
        downloadType: 'download' | 'export' | 'takeout',
        jobID: JobID,
        hasHEIC?: boolean,
        zipFileName: string = PRODUCT_NAME,
    ): Promise<void> {
        if (downloadType === 'download' && hasHEIC === undefined) {
            throw new Error('hasHEIC must be defined for download type')
        }

        downloadThroughAnchor(
            this.downloadHost.getPath(
                `/st/4/jobs/${jobID}/files_as_archive`,
                AppService.getDownloadOptions(
                    downloadType,
                    zipFileName,
                    hasHEIC,
                ),
            ),
        )
    }

    public async getFileBlobFromId(
        jobID: JobID,
        fileID: FileID,
        toJpeg?: boolean,
    ): Promise<Blob> {
        const response = await getRequestJobsFilesByIdByJobUuidAndFileUuid(
            jobID,
            fileID,
            undefined,
            {
                ...this.commonQueryParams,
                to_jpeg: toJpeg,
            },
            { ...this.axiosRequestOptions, responseType: 'blob' },
        )

        return response.data as unknown as Blob
    }

    public getFilePreviewURL(
        jobID: JobID,
        fileID: FileID,
        thumbnail = false,
    ): URLstring {
        return this.hostUrl.getPath(
            `/st/4/jobs/${jobID}/files_by_id/${fileID}`,
            thumbnail ? { to_jpeg: '1' } : undefined,
        )
    }

    public downloadFilesFromTrashAsArchive(
        jobID: JobID,
        files: FileID[],
        zipFileName: string,
    ): Promise<void> {
        return sendPOSTRedirect(
            this.downloadHost.getPath(
                `/st/4/jobs/${jobID}/files_from_trash_as_archive`,
                {
                    flattened: 1,
                    zip_filename: zipFileName,
                },
            ),
            files,
        )
    }

    public async setJobName(jobID: JobID, name: string) {
        await postRequestJobsNameByJobUuid(
            jobID,
            {
                ...this.commonQueryParams,
                name,
            },
            this.axiosRequestOptions,
        )
    }

    public async setCoverPhoto(jobID: JobID, fileID: FileID) {
        await postRequestJobsCoverByJobUuid(
            jobID,
            {
                ...this.commonQueryParams,
                id: fileID,
            },
            this.axiosRequestOptions,
        )
    }

    public async setPermissionsforJob(
        jobID: JobID,
        permissions: ExtraJobQueryParamsOf<PostRequestJobsPermissionsByJobUuidParams>,
    ): Promise<JobSetPermissionsResponseDTO> {
        const response = await postRequestJobsPermissionsByJobUuid(
            jobID,
            {
                ...this.commonQueryParams,
                ...permissions,
            },
            this.axiosRequestOptions,
        )

        return response.data
    }

    public async setPrivacyModeForJob(
        jobID: JobID,
        mode: PrivacyModeDTO,
    ): Promise<JobSetPermissionsResponseDTO> {
        const response = await postRequestJobsPrivacyModeByJobUuid(
            jobID,
            {
                ...this.commonQueryParams,
                mode,
            },
            this.axiosRequestOptions,
        )

        return response.data
    }

    public async loveFile(
        jobID: JobID,
        fileID: FileID,
    ): Promise<SetReactionResponseDTO> {
        const response =
            await postRequestJobsFilesByIdReactionByJobUuidAndFileUuid(
                jobID,
                fileID,
                {
                    ...this.commonQueryParams,
                    reaction: ReactionTypeDTO.love,
                },
                this.axiosRequestOptions,
            )

        return response.data
    }

    public async unLoveFile(
        jobID: JobID,
        fileID: FileID,
    ): Promise<SetReactionResponseDTO> {
        /**
         * This endpoint call removes the file reaction, regardless of which
         * We currently assume that there is only one reaction per file:
         * - You can favourite timeline files
         * - You can love album files
         */
        const response =
            await deleteRequestJobsFilesByIdReactionByJobUuidAndFileUuid(
                jobID,
                fileID,
                this.commonQueryParams,
                this.axiosRequestOptions,
            )

        return response.data
    }

    public async getUserGrants() {
        const response = await getRequestUserGrants(
            this.commonQueryParams,
            this.axiosRequestOptions,
        )

        return response.data.result.grants as UserGrant[]
    }

    public executeGrantLink(link: string) {
        return customFetch(link, { method: 'POST' })
    }

    public async setAlbumAttribute(
        albumID: JobID,
        attributeName: string,
        attributeValue: string,
    ) {
        return putRequestJobsAttributeByJobUuidAndAttribute(
            albumID,
            attributeName,
            {
                ...this.commonQueryParams,
                value: attributeValue,
            },
            this.axiosRequestOptions,
        )
    }

    public async getDataProtectionConsentValues() {
        const response = await getRequestDataProtectionConsentValues(
            this.commonQueryParams,
            this.axiosRequestOptions,
        )

        // we unwrap the data from the response, datav2 is used in mobile client
        return response.data.data as DataProtectionConsentData
    }

    public async updateDataProtectionConsentValues(
        values: Partial<DataProtectionConsentData>,
    ) {
        const response = await postRequestDataProtectionConsentValues(
            values,
            this.commonQueryParams,
            this.axiosRequestOptions,
        )

        return response.data as DataProtectionUpdateRequestResult
    }

    public async getRequestDataAccessAvailability(): Promise<DataProtectionRequestResultDTO> {
        // Throws 503 if call to request_data_access will yield error due to rate-limiting
        const response =
            await getRequestDataProtectionRequestDataAccessAvailability(
                this.commonQueryParams,
                this.axiosRequestOptions,
            )

        return response.data
    }

    public downloadDataProtectionRequestedDataAccess() {
        window.location.href = this.hostUrl.getPath(
            '/st/4/data_protection/request_data_access',
        )
    }

    public async sendDataProtectionRequestForAccountDeletion() {
        return postRequestDataProtectionRequestAccountDeletion(
            this.commonQueryParams,
            this.axiosRequestOptions,
        )
    }

    public async postUserStatistics(events: UserStatsEventModelDTO[]) {
        return postRequestUserStatisticsEvents(
            { events },
            this.commonQueryParams,
            this.axiosRequestOptions,
        )
    }

    public async postClientStats(client_status: ClientStats) {
        return postRequestClientStats(
            client_status,
            this.commonQueryParams,
            this.axiosRequestOptions,
        )
    }

    public async approveTOS(tos_version: string) {
        return postRequestApproveTos(
            {
                ...this.commonQueryParams,
                tos_version,
            },
            this.axiosRequestOptions,
        )
    }

    public async getChangesAndAsyncUploadStatus(
        jobID: JobID,
        since: number,
    ): Promise<ChangesAndAsyncUploadStatus> {
        const response =
            await getRequestJobsChangesAndAsyncUploadStatusByJobUuid(
                jobID,
                {
                    ...this.commonQueryParams,
                    since,
                },
                this.axiosRequestOptions,
            )

        return response.data as ChangesAndAsyncUploadStatus
    }

    // Test endpoint for deleting stripe data
    public deleteStripeTestData() {
        return postRequestTestDeleteStripeData(
            this.commonQueryParams,
            this.axiosRequestOptions,
        )
    }

    public async getSupportedExtensions() {
        const response = await getRequestSupportedExtensions(
            this.axiosRequestOptions,
        )

        return response.data
    }

    // User account attributes endpoints
    public async getAccountAttributes(): Promise<UserAccountAttribute[]> {
        const response = await getRequestAccountAttribute(
            this.commonQueryParams,
            this.axiosRequestOptions,
        )

        return response.data.result as UserAccountAttribute[]
    }

    public async upsertAccountAttributes({
        account_attribute_key,
        value,
    }: UpsertUserAccountAttribute): Promise<UserAccountAttribute[]> {
        const response = await postRequestAccountAttribute(
            value,
            {
                ...this.commonQueryParams,
                account_attribute_key,
            },
            this.axiosRequestOptions,
        )

        return response.data.result as UserAccountAttribute[]
    }

    public async deleteAccountAttribute(key: UserAccountAttributeKey) {
        await deleteRequestAccountAttribute(
            {
                ...this.commonQueryParams,
                account_attribute_key: key,
            },
            this.axiosRequestOptions,
        )
    }

    public async updateDigitalLegacyOption(
        choice: DigitalLegacyChoiceDTO,
        contact?: DigitalLegacyContactDTO,
    ) {
        const { connectKey } = getCurrentLangDefinition()
        return postRequestDigitalLegacy(
            contact ? [contact] : [],
            {
                ...this.commonQueryParams,
                digital_legacy_choice: choice,
                language: connectKey as LanguageDTO,
            },
            this.axiosRequestOptions,
        )
    }
    public async getDigitalLegacyOption(user_uuid: UserID) {
        const response = await getRequestDigitalLegacy(
            {
                user_uuid: user_uuid,
                ...this.commonQueryParams,
            },
            this.axiosRequestOptions,
        )

        return response.data as Merge<
            DigitalLegacyInfoDTO,
            { choice: DigitalLegacyChoiceDTO }
        >
    }
    // Test endpoint for getting email identifier for unsubscribing email
    public async getTestEmailIdentifierToUnsubscribe() {
        const response = await getRequestTestEmailConsentId(
            this.commonQueryParams,
            this.axiosRequestOptions,
        )
        return response.data
    }
}
